import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Table, Select, DatePicker, TimePicker, Space, Typography, Divider, notification, message, Card } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import { DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getBranches, getBrands, getCategories, getOffer, getProducts, saveOffer, updateOffer } from '../../data';
import windowSize from 'react-window-size';
import dataService from '../../services/data-service';


const { Text } = Typography
const { Option } = Select


const OfferForm = ({ windowWidth }) => {

    const branch_id = localStorage.getItem('branch_id')

    const [product, setProduct] = useState([])
    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [branches, setBranches] = useState([]);

    const [allChange, setAllChange] = useState({ value: '0', type: '1', });
    const [selection, setSelection] = useState({ categoryId: [], brandId: [], product_id: [] });
    const [checkedList, setCheckedList] = useState();
    const [forceRefresh, setForceRefresh] = useState(true);
    const [check, setCheck] = useState([]);
    const isMobile = windowWidth <= 768

    const [form] = Form.useForm();
    let { id } = useParams()
    let history = useHistory()

    const handleAdd = () => {
        const selected_products = []
        const found_products = []

        product.map((v) => {
            // console.log(selection.categoryId.includes(parseInt(v.category_id)))
            if (selection.categoryId.includes(parseInt(v.category_id))) {
                selected_products.push(v.id)
            }

            if (selection.brandId.includes(parseInt(v.brand_id))) {
                selected_products.push(v.id)
            }

            if (selection.product_id.includes(v.id)) {
                selected_products.push(v.id)
            }

        })
        product.map((v) => {

            if (selected_products.includes(v.id)) {
                let row = {
                    product_id: v.id,
                    name: v.name,
                    selling_price: v.selling_price,
                    percentage: 0,
                    amount: 0,
                    discounted_price: 0,
                    type: 1,
                    value: ''
                }
                found_products.push(row)
            }

            return v;
        })
        setTableData(found_products)
        if (found_products.length == 0) {
            return message.error('Please fill products')
        }
    }

    const handleDelete = (product) => {
        let filter = []
        tableData.map((v) => {
            if (product.product_id !== v.product_id) {
                filter.push(v)
            }
        })
        setTableData(filter)
        // notification.open({message:'Product Successfuly Deleted'})
    }

    const handleAllDelete = () => {
        let filter = []
        if (checkedList === true) {
            setTableData('')
        } else {
            tableData.map((v, key) => {
                if (!check.includes(v.product_id)) {
                    filter.push(v)
                }

            })
            setTableData(filter)

        }
    }

    const handleTypeChange = (type, product_id) => {

        tableData.map(item => {
            if (item.product_id == product_id) {
                item.type = type;

                if (parseInt(item.type) === 1) {
                    item.amount = ((item.selling_price / 100) * item.value).toFixed("2")
                    item.percentage = item.value
                    item.discounted_price = item.selling_price - item.amount;
                } else {
                    item.percentage = ((item.value * 100) / item.selling_price).toFixed("2");
                    item.amount = item.value;
                    item.discounted_price = item.selling_price - item.value;
                }
            }

            return item;
        });

        setTableData([...tableData])
    }

    const handleChangeAll = () => {
        tableData.map(item => {

            if (parseInt(allChange.type) == 1) {
                item.amount = ((item.selling_price / 100) * allChange.value).toFixed("2")
                item.percentage = allChange.value
                item.discounted_price = item.selling_price - item.amount;
            } else {
                item.percentage = ((allChange.value * 100) / item.selling_price).toFixed("2");
                item.amount = allChange.value;
                item.value = allChange.value;
                item.discounted_price = item.selling_price - allChange.value;
            }

            return item;
        });

        setTableData([...tableData]);
    }

    const handleChange = (value, product_id) => {
        // if (id == 0) {
        tableData.map(item => {
            if (item.product_id === product_id) {
                if (parseInt(item.type) === 1) {
                    item.amount = ((item.selling_price / 100) * value).toFixed("2")
                    item.percentage = value
                    item.discounted_price = item.selling_price - item.amount;
                } else {
                    item.percentage = ((value * 100) / item.selling_price).toFixed("2");
                    item.amount = value;
                    item.value = value;
                    item.discounted_price = item.selling_price - value;
                }
            }
            return item;
        });

        setTableData([...tableData]);

    }

    const onFinish = async (values) => {
        let data = {
            title: values.title,
            start_date: values.start_date,
            start_time: values.start_time,
            end_date: values.end_date,
            end_time: values.end_time,
            items: tableData,
            branches: branch_id > 0 ? [branch_id] : values.branches
        }
        if (id == 0) {
            const saveData = await saveOffer(data);
            if (saveData) {
                history.push({ pathname: "/offer" });
                notification.open({ message: 'Offer has been  added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add product...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const updateData = await updateOffer(id, data);
            if (updateData) {
                history.push({ pathname: "/offer" });
                notification.open({ message: 'Offer has been updated successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update offer...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    };

    const handleCheck = (checked) => {
        setCheck(old => [...old, checked]);
    }

    const handleSelectAll = () => {
        setCheckedList(!checkedList)
        setCheck(tableData.map(li => li.id))
        if (checkedList) {
            setCheck([])
        }
    }

    const loadCategories = useCallback(async () => {
        const categories = await getCategories(forceRefresh);
        if (categories) {
            setCategories(categories);
            setForceRefresh(false)
        }
    }, [])

    const loadBrands = useCallback(async () => {
        const brands = await getBrands(forceRefresh);
        if (brands) {
            setBrands(brands);
            setForceRefresh(false)
        }
    }, [])

    const loadProducts = useCallback(async () => {
        const products = await dataService.getOfferProducts();
        if (products) {
            setProduct(products);
            setForceRefresh(false)
        }
    }, [])

    const loadBranches = async () => {
        const resposne = await getBranches(forceRefresh);
        if (resposne) {
            setBranches(resposne);
            setForceRefresh(false);
        }
    }

    const loadData = useCallback(async (id) => {
        if (id > 0) {
            let data = await getOffer(id);
            const offerBranches = (data.offer_branches).map(i => i.branch_id)

            if (data != null) {
                let dateTime = {
                    start_date: moment(data.start_date),
                    start_time: moment(data.start_time, 'HH:mm'),
                    end_date: moment(data.end_date),
                    end_time: moment(data.end_time, 'HH:mm'),
                    title: data.title
                }

                form.setFieldsValue({
                    ...dateTime,
                    branches: offerBranches,
                });
                setTableData(data.items)
            }
        }
    }, [])

    useEffect(() => {
        loadData(id)
        loadCategories();
        loadBrands();
        loadProducts();
        loadBranches();
    }, []);

    const renderOfferForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    initialValues={{
                        active: true,
                    }}
                    onFinish={onFinish}
                    form={form}
                >
                    <Row gutter={16} >
                        <Col span={3}></Col>
                        <Col span={18} className="gutter-row" >
                            <Row gutter={10}>
                                <Col span={8}>
                                    <Form.Item name='title' label="Title" rules={[
                                        {
                                            required: true,
                                            message: 'Title field is required'
                                        }
                                    ]}>
                                        <Input placeholder='Offer' />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name='start_date' label="Start Date" rules={[
                                        {
                                            required: true,
                                            message: 'Start date is required'
                                        }
                                    ]}>
                                        <DatePicker />
                                    </Form.Item>

                                </Col>
                                <Col span={4}>
                                    <Form.Item name='start_time' label="Start Time" rules={[
                                        {
                                            required: true,
                                            message: 'Start time is required'
                                        }
                                    ]}>
                                        <TimePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name='end_date' label="End Date" rules={[
                                        {
                                            required: true,
                                            message: 'End date is required'
                                        }
                                    ]}>
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name='end_time' label="End Time" rules={[
                                        {
                                            required: true,
                                            message: 'End time is required'
                                        }
                                    ]}>
                                        <TimePicker />
                                    </Form.Item>
                                </Col>
                                {
                                    !branch_id &&
                                    <Col span={8}>
                                        <Form.Item name='branches' label="Branches" rules={[{ required: true, message: 'Branches is required' }]} >
                                            <Select allowClear mode="multiple" placeholder="Select Branches"
                                                style={{
                                                    width: '100%',
                                                }}>
                                                {
                                                    branches.map(({ id, name }) => (
                                                        <Option value={id} key={id}>{name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>

                            <Divider />

                            <Row gutter={10}>
                                <Col span={8}>
                                    <Form.Item label="Category" >
                                        <Select placeholder="Click To Select Category" showSearch allowClear mode="multiple"
                                            onChange={(e) => setSelection({ ...selection, categoryId: e })}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {categories.map(order => {
                                                return <Option value={order.id} key={"order-" + order.id} >{order.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Brand" >
                                        <Select placeholder="Click To Select Brand" showSearch allowClear mode="multiple"
                                            onChange={(e) => setSelection({ ...selection, brandId: e })}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {brands.map(brand => {
                                                return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Product" name="product_id" >
                                        <Select placeholder="Click To Select Product" showSearch allowClear mode="multiple"
                                            onChange={(e) => setSelection({ ...selection, product_id: e })}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {product.map(product => {
                                                return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button onClick={handleAdd} type="primary">Include Products</Button>
                            </Form.Item>

                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <Divider />
                    {
                        tableData.length > 0 &&
                        <Row>
                            <Col span={3}></Col>
                            <Col span={18}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                                    <Button onClick={handleAllDelete} type="primary" >Delete Selected</Button>
                                    <Space>
                                        <Select defaultValue="1" onChange={(value) => setAllChange({ ...allChange, type: value })}>
                                            <Option value="1">Percentage</Option>
                                            <Option value="2">Amount</Option>
                                        </Select>
                                        <Input defaultValue='0' type="number" onChange={(v) => setAllChange({ ...allChange, value: v.target.value })} />
                                        <Button onClick={handleChangeAll} type="primary" >Apply</Button>
                                    </Space>
                                </div>
                                <Divider />
                                <Row gutter={16} >
                                    {/* <Col span={2}> <Checkbox onChange={(e) => setCheckedList(e.target.checked)} >All</Checkbox> </Col> */}
                                    <Col span={2}> <Checkbox onChange={handleSelectAll} checked={checkedList} >All</Checkbox> </Col>
                                    <Col span={4}> <Text>Product Name</Text></Col>
                                    <Col span={3}> <Text> Selling Price</Text></Col>
                                    <Col span={3}> Type</Col>
                                    <Col span={3}> Value</Col>
                                    <Col span={2}><Text>%</Text></Col>
                                    <Col span={2}><Text>Discount</Text></Col>
                                    <Col span={2}><Text>Amount</Text></Col>
                                    <Col span={3}><Text>Action</Text></Col>
                                </Row>
                                <Divider />
                                {tableData.map((product, key) => {

                                    let { name, selling_price, product_id, discounted_price, percentage, amount } = product;
                                    return (
                                        <Row key={key} gutter={[16, 16]}>
                                            <Col span={2}> <Checkbox onChange={() => handleCheck(product_id)}></Checkbox> </Col>
                                            <Col span={4}> <Text>{name}</Text></Col>
                                            <Col span={3}><Text> {selling_price} </Text></Col>
                                            <Col span={3}>
                                                <Select defaultValue="1" onChange={(value) => handleTypeChange(value, product_id)}>
                                                    <Option value="1">Percentage</Option>
                                                    <Option value="2">Amount</Option>
                                                </Select>
                                            </Col>
                                            <Col span={3}>
                                                <Input type="number" defaultValue={0} onChange={(v) => handleChange(v.target.value, product_id)} />
                                            </Col>
                                            <Col span={2}>{percentage}</Col>
                                            <Col span={2}>{amount}</Col>
                                            <Col span={2}><Text> {discounted_price == 0 ? selling_price : discounted_price}</Text></Col>
                                            <Col span={3}><Button onClick={() => handleDelete(product)}>Delete</Button></Col>
                                        </Row>
                                    )
                                    // < OfferProduct key = { key } product = { product } setData = { handleChildData } data = { tableData } />
                                })}
                                <Form.Item>
                                    <Space>
                                        <Button htmlType='submit' type="primary">Save Offer</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={3}></Col>
                        </Row>
                    }
                </Form>
            </>
        );
    }

    const renderOfferFormMobile = () => {
        return (
            <>
                <Form
                    initialValues={{
                        active: true,
                    }}
                    onFinish={onFinish}
                    form={form}
                >
                    <Row gutter={16} >
                        <Col span={24}>
                            <Form.Item name='title' label="Title" rules={[
                                {
                                    required: true,
                                    message: 'Title field is required'
                                }
                            ]}>
                                <Input placeholder='Offer' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='start_date' label="Start Date" rules={[
                                {
                                    required: true,
                                    message: 'Start date is required'
                                }
                            ]}>
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item name='start_time' label="Start Time" rules={[
                                {
                                    required: true,
                                    message: 'Start time is required'
                                }
                            ]}>
                                <TimePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='end_date' label="End Date" rules={[
                                {
                                    required: true,
                                    message: 'End date is required'
                                }
                            ]}>
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='end_time' label="End Time" rules={[
                                {
                                    required: true,
                                    message: 'End time is required'
                                }
                            ]}>
                                <TimePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='branches' label="Branches" rules={[{ required: true, message: 'Branches is required' }]} >
                                <Select allowClear mode="multiple" placeholder="Select Branches"
                                    style={{
                                        width: '100%',
                                    }}>
                                    {
                                        branches.map(({ id, name }) => (
                                            <Option value={id} key={id}>{name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Divider />
                        <Row gutter={48}>
                            <Col span={24}>
                                <Form.Item label="Category" >
                                    <Select placeholder="Click To Select Category" showSearch allowClear mode="multiple"
                                        onChange={(e) => setSelection({ ...selection, categoryId: e })}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {categories.map(order => {
                                            return <Option value={order.id} key={"order-" + order.id} >{order.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Brand" >
                                    <Select placeholder="Click To Select Brand" showSearch allowClear mode="multiple"
                                        onChange={(e) => setSelection({ ...selection, brandId: e })}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {brands.map(brand => {
                                            return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Product" name="product_id" >
                                    <Select placeholder="Click To Select Product" showSearch allowClear mode="multiple"
                                        onChange={(e) => setSelection({ ...selection, product_id: e })}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {product.map(product => {
                                            return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button onClick={handleAdd} type="primary">Include Products</Button>
                        </Form.Item>

                    </Row>
                    <Divider />
                    {tableData.length > 0 &&
                        <Row gutter={16}>
                            <Col span={24}>
                                <Space>
                                    <Select defaultValue="1" onChange={(value) => setAllChange({ ...allChange, type: value })}>
                                        <Option value="1">Percentage</Option>
                                        <Option value="2">Amount</Option>
                                    </Select>
                                    <Input defaultValue='0' type="number" onChange={(v) => setAllChange({ ...allChange, value: v.target.value })} />
                                    <Button onClick={handleChangeAll} type="primary" >Apply</Button>
                                </Space>
                            </Col>
                            <br /><br />
                            <Col span={24}>
                                <Button type='primary' danger icon={<DeleteOutlined />} onClick={handleAllDelete}>Delete Selected</Button>
                            </Col>

                            <Divider />
                            <br /><br />
                            <Col span={1}></Col>
                            <Col span={12}> <Checkbox onChange={handleSelectAll} checked={checkedList} >All</Checkbox> </Col>
                            <br />
                            {tableData.map((product, key) => {

                                let { name, selling_price, product_id, discounted_price, percentage, amount } = product;

                                return (
                                    <>
                                        <Card
                                            style={{ width: 330, marginBottom: 5 }}
                                            actions={[
                                                <Space size='small'>
                                                    <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => handleDelete(product)}>Delete</Button>
                                                </Space>
                                            ]}>
                                            <Row key={key} gutter={[16, 16]}>
                                                <Col span={24}> <Checkbox onChange={() => handleCheck(product_id)}></Checkbox> </Col>
                                                <Col span={12}>Product Name</Col>
                                                <Col span={12}> <Text>{name}</Text></Col>
                                                <Col span={12}>Selling Price</Col>
                                                <Col span={12}><Text> {selling_price} </Text></Col>
                                                <Col span={12}>Type</Col>
                                                <Col span={12}>
                                                    <Select defaultValue="1" onChange={(value) => handleTypeChange(value, product_id)}>
                                                        <Option value="1">Percentage</Option>
                                                        <Option value="2">Amount</Option>
                                                    </Select>
                                                </Col>
                                                <Col span={12}>Value</Col>
                                                <Col span={12}>
                                                    <Input type="number" defaultValue={0} onChange={(v) => handleChange(v.target.value, product_id)} />
                                                </Col>
                                                <Col span={12}>Percentage</Col>
                                                <Col span={12}>{percentage}</Col>
                                                <Col span={12}>Discount</Col>
                                                <Col span={12}>{amount}</Col>
                                                <Col span={12}>Amount</Col>
                                                <Col span={12}><Text> {discounted_price == 0 ? selling_price : discounted_price}</Text></Col>
                                            </Row>
                                        </Card>
                                    </>
                                )
                            })}
                            <Divider />
                            <br /><br />
                            <Form.Item>
                                <Space>
                                    <Button htmlType='submit' type="primary">Save Offer</Button>
                                    <Button onClick={() => history.goBack()}>Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>}
                </Form>
            </>
        );
    }

    const renderContent = () => {
        if (isMobile) {
            return renderOfferFormMobile()
        } else {
            return renderOfferForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

};


export default windowSize(OfferForm)