import { Button, Card, Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
const PandingAproval = () => {

    let history = useHistory()
    const location = useLocation();
    return (
        <div div className="site-card-border-less-wrapper" >
            <br /><br /><br />
            <Row>
                <Col span={8}></Col>
                <Card
                    title={location.search ?'Admin Aproval':'Verify Your Email'}
                    bordered={false}
                    style={{
                        width: 400,
                    }}
                    actions={[
                        <Button type='primary' onClick={() => history.push({ pathname: '/login' })}>Back to login</Button>
                    ]}
                >
                    <p>
                        {location.search ?
                            'Your account is registered please contact admin for approval 0322-2093633.'
                            :
                            'Your email is not verified, please click the link in the email to verify your email address.'}
                    </p>
                    <p></p>
                </Card>
            </Row>
        </div>
    )
}
export default PandingAproval;