import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { Button, Col, Input, Row, Space, Form, Select, notification } from "antd"
import { getBanner, getBranch, getBranchBrands, getBranchCategories, getBranchProducts, getOffers, getPages, saveBanner, updateBanner } from "../../data";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ImageUpload from "../../components/image-upload";
import windowSize from "react-window-size";
import { ajaxService } from "../../services";

const { Option } = Select

const options = ['Page', 'Brand', 'Category', 'Product', 'Offer', 'Cuisine']

function BannerForm({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams()

    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [pages, setPages] = useState([]);
    const [cuisine, setCuisine] = useState([]);
    const [offers, setOffers] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(true)
    const [type, setType] = useState('Brand');

    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);

    const branch_id = localStorage.getItem('branch_id')

    const handleWebBannerUpload = (info) => {
        let fileList = [...info.fileList];
        // Limit file list to 1 item
        fileList = fileList.slice(-1);
        // Update file list state
        setFileList(fileList);
    };

    const handleMobBannerUpload = (info) => {
        let fileList = [...info.fileList];
        // Limit file list to 1 item
        fileList = fileList.slice(-1);
        // Update file list state
        setFileList2(fileList);
    };

    const onFinish = async (values) => {

        let mobBanner = '';
        let webBanner = '';

        const { mobile_banner, web_banner } = values;
        [mobile_banner, web_banner].forEach((banner) => {

            if (typeof banner === "string") {
                if (banner === mobile_banner) {
                    mobBanner = banner;
                } else if (banner === web_banner) {
                    webBanner = banner;
                }
            } else {
                if (banner !== undefined) {
                    const { response } = banner.file;
                    if (response?.success) {
                        const { path } = response;
                        if (banner === mobile_banner) {
                            mobBanner = path;
                        } else if (banner === web_banner) {
                            webBanner = path;
                        }
                    }
                }
            };
        })

        let slug = '';
        let typeValue = 0;

        switch (values.type) {
            case "Page":
                slug = values.page;
                break;
            case "Cuisine":
                slug = values.cuisine;
                break;
            case "Brand":
                typeValue = values.brand_id;
                break;
            case "Category":
                typeValue = values.category_id;
                break;
            case "Product":
                typeValue = values.product_id;
                break;
            case "Offer":
                typeValue = values.offer_id;
                break;
            default:
                break;
        }

        let data = {
            branch_id: branch_id,
            title: values.title,
            subtitle: values.subtitle,
            web_banner: webBanner,
            mobile_banner: mobBanner,
            type: values.type,
            type_slug: slug,
            type_value: typeValue,
        }

        if (id == 0) {
            const saveData = await saveBanner(data)
            if (saveData) {
                history.push({ pathname: "/banners" });
                notification.open({ message: 'Banner has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add banner', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updateBanner(id, data);
            if (updateData) {
                history.push({ pathname: '/banners' });
                notification.open({ message: 'Banner has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update banner', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadBrands = async () => {
        let data = await getBranchBrands(branch_id);
        if (data) {
            setBrands(data)
        }
    }

    const loadCategories = async () => {
        let data = await getBranchCategories(branch_id)
        if (data) {
            setCategories(data)
        }
    }

    const loadProducts = async () => {
        let data = await getBranchProducts(branch_id)
        if (data && data.length > 0) {
            let products = data.map(b => {
                return b.product
            })
            setProducts(products)
        }
    }

    const loadPages = async () => {
        let data = await getPages(forceRefresh)
        if (data) {
            setPages(data)
            setForceRefresh(false)
        }
    }

    const loadOffers = async () => {
        let data = await getOffers(forceRefresh)
        if (data) {
            setOffers(data)
        }
    }

    const loadBranchCuisine = async () => {
        const data = await getBranch(branch_id)
        if (data) {
            setCuisine(data.cuisine ? data.cuisine : [''])
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            let data = await getBanner(id);
            if (data) {
                setType(data.type)
                switch (data.type) {
                    case "Page":
                        data.page = data.type_slug;
                        break;
                    case "Cuisine":
                        data.cuisine = data.type_slug;
                        break;
                    case "Brand":
                        data.brand_id = data.type_value;
                        break;
                    case "Category":
                        data.category_id = data.type_value;
                        break;
                    case "Product":
                        data.product_id = data.type_value;
                        break;
                    case "Offer":
                        data.offer_id = data.type_value;
                        break;
                    default:
                        break;
                }

                form.setFieldsValue({ ...data });

                if (data.web_banner) {
                    let wBanner = {
                        uid: data.web_banner,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(data.web_banner),
                        preview: false
                    }
                    setFileList([wBanner]);
                }

                if (data.mobile_banner) {
                    let mBanner = {
                        uid: data.mobile_banner,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(data.mobile_banner),
                        preview: false
                    }
                    setFileList2([mBanner]);
                }

            }
        }
    }

    useEffect(() => {
        loadBrands()
        loadCategories()
        loadProducts()
        loadPages()
        loadOffers()
        loadBranchCuisine()
        loadData(id)
    }, [])

    const renderBannerForm = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    type: "Brand"
                }}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Title" name='title' rules={[
                                    {
                                        required: true,
                                        message: 'Title is required',
                                    }]}>
                                    <Input placeholder="Enter title" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Subtitle" name='subtitle' rules={[
                                    {
                                        required: true,
                                        message: 'Subtitle is required',
                                    }]}>
                                    <Input placeholder="Enter subtitle" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Web Banner" name="web_banner">
                                    <ImageUpload onChange={handleWebBannerUpload} fileList={fileList} accept="image/*" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Mobile Banner" name="mobile_banner">
                                    <ImageUpload onChange={handleMobBannerUpload} fileList={fileList2} accept="image/*" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Link To" name='type' rules={[
                                    {
                                        required: true,
                                        message: 'Link to is required',
                                    }]}>
                                    <Select placeholder="Select type" onChange={setType}>
                                        {
                                            options.map((i, index) => (
                                                <Option key={index} value={i}>{i}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Brands" name='brand_id'
                                    rules={[
                                        {
                                            required: type === "Brand" ? true : false,
                                            message: 'Brand is required',
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select brands" disabled={type === "Brand" ? false : true}>
                                        {
                                            brands.map(({ id, name }) => (
                                                <Option key={id} value={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Category" name='category_id'
                                    rules={[
                                        {
                                            required: type === "Category" ? true : false,
                                            message: 'Category is required',
                                        }
                                    ]}>
                                    <Select placeholder="Select category" disabled={type === "Category" ? false : true}>
                                        {
                                            categories.map(({ id, name }) => (
                                                <Option key={id} value={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Product" name='product_id'
                                    rules={[
                                        {
                                            required: type === "Product" ? true : false,
                                            message: 'Product is required',
                                        }
                                    ]}>
                                    <Select placeholder="Select product" disabled={type === "Product" ? false : true}>
                                        {
                                            products.map(({ id, name }) => (
                                                <Option key={id} value={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Cuisine" name='cuisine'
                                    rules={[
                                        {
                                            required: type === "Cuisine" ? true : false,
                                            message: 'Cuisine is required',
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select cuisine" disabled={type === "Cuisine" ? false : true}>
                                        {
                                            cuisine.map((i, index) => (
                                                <Option key={index} value={i}>{i}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Page" name='page'
                                    rules={[
                                        {
                                            required: type === "Page" ? true : false,
                                            message: 'Page is required',
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select page" disabled={type === "Page" ? false : true}>
                                        {
                                            pages.map(({ slug, title }, index) => (
                                                <Option key={index} value={slug}>{title}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Offer" name='offer_id'
                                    rules={[
                                        {
                                            required: type === "Offer" ? true : false,
                                            message: 'Offer is required',
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select offer" disabled={type === "Offer" ? false : true}>
                                        {
                                            offers.map(({ id, title }) => (
                                                <Option key={id} value={id}>{title}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        </>
    )

    const renderBannerFormMobile = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{ type: "Brand" }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Title" name='title' rules={[
                            {
                                required: true,
                                message: 'Title is required',
                            }]}>
                            <Input placeholder="Enter title" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Subtitle" name='subtitle' rules={[
                            {
                                required: true,
                                message: 'Subtitle is required',
                            }]}>
                            <Input placeholder="Enter subtitle" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Link To" name='type' rules={[
                            {
                                required: true,
                                message: 'Link to is required',
                            }]}>
                            <Select placeholder="Select type" onChange={setType}>
                                {
                                    options.map((i, index) => (
                                        <Option key={index} value={i}>{i}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Brands" name='brand_id'
                            rules={[
                                {
                                    required: type === "Brand" ? true : false,
                                    message: 'Brand is required',
                                }
                            ]}
                        >
                            <Select placeholder="Select brands" disabled={type === "Brand" ? false : true}>
                                {
                                    brands.map(({ id, name }) => (
                                        <Option key={id} value={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Category" name='category_id'
                            rules={[
                                {
                                    required: type === "Category" ? true : false,
                                    message: 'Category is required',
                                }
                            ]}>
                            <Select placeholder="Select category" disabled={type === "Category" ? false : true}>
                                {
                                    categories.map(({ id, name }) => (
                                        <Option key={id} value={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Product" name='product_id'
                            rules={[
                                {
                                    required: type === "Product" ? true : false,
                                    message: 'Product is required',
                                }
                            ]}>
                            <Select placeholder="Select product" disabled={type === "Product" ? false : true}>
                                {
                                    products.map(({ id, name }) => (
                                        <Option key={id} value={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Cuisine" name='cuisine'
                            rules={[
                                {
                                    required: type === "Cuisine" ? true : false,
                                    message: 'Cuisine is required',
                                }
                            ]}
                        >
                            <Select placeholder="Select cuisine" disabled={type === "Cuisine" ? false : true}>
                                {
                                    cuisine.map((i, index) => (
                                        <Option key={index} value={i}>{i}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Page" name='page'
                            rules={[
                                {
                                    required: type === "Page" ? true : false,
                                    message: 'Page is required',
                                }
                            ]}
                        >
                            <Select placeholder="Select page" disabled={type === "Page" ? false : true}>
                                {
                                    pages.map(({ slug, title }, index) => (
                                        <Option key={index} value={slug}>{title}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Offer" name='offer_id'
                            rules={[
                                {
                                    required: type === "Offer" ? true : false,
                                    message: 'Offer is required',
                                }
                            ]}
                        >
                            <Select placeholder="Select offer" disabled={type === "Offer" ? false : true}>
                                {
                                    offers.map(({ id, title }) => (
                                        <Option key={id} value={id}>{title}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Web Banner" name="web_banner">
                            <ImageUpload onChange={handleWebBannerUpload} fileList={fileList} accept="image/*" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Mobile Banner" name="mobile_banner">
                            <ImageUpload onChange={handleMobBannerUpload} fileList={fileList2} accept="image/*" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type="primary">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Row>

            </Form >
        </>
    )

    return isMobile ? renderBannerFormMobile() : renderBannerForm()
}

export default windowSize(BannerForm) 