import { useState, useEffect } from "react"
import { Button, Card, Table, Typography } from "antd"
import { useParams } from "react-router-dom"
import { getStockTransfer } from "../../data"
import windowSize from "react-window-size"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const { Text, Title } = Typography

const StockTransferView = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    let history = useHistory()

    let { id } = useParams()

    const [data, setData] = useState([])

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',

        },
        {
            title: 'Grn Number',
            dataIndex: 'grn_number',
            key: 'grn_number',

        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Retail Price',
            dataIndex: 'retail_price',
            key: 'retail_price',
        }
    ]

    const initialize = async () => {
        const response = await getStockTransfer(id)
        if (response) {
            setData(response)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    const renderHeader = (
        <div style={isMobile ? {} : { display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Stock Transfer View</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/stock-transfer' })} icon={<ArrowLeftOutlined />}>Back To Stock Transfers</Button>
        </div>
    )

    const renderStockTransferView = () => (
        <>
            <Table dataSource={data} columns={columns} title={() => renderHeader} />
        </>
    )

    const renderStockTransferViewMobile = () => (
        <>
            {renderHeader}
            {
                data.map(v => (
                    <>
                        <br />
                        <Card style={{ width: '100%' }}>
                            <Text style={{ fontWeight: 'bolder' }}>Product Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.product_name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>GRN Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.grn_number}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Stock : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.stock}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Retail Price : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.retail_price}</span></Text>
                        </Card>
                    </>
                ))
            }
        </>
    )

    return isMobile ? renderStockTransferViewMobile() : renderStockTransferView()

}

export default windowSize(StockTransferView)