import { ajaxService, loadState, urlService } from '../services';
import { loadResource, Response } from '../utility';


const setDependency = () => { ajaxService.setDependency() }

export async function getBrands(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.brands, 'brands');
    } else {
        let brands = await loadState('brands');

        if (brands == null) {
            return await loadResource(urlService.brands, 'brands');
        } else {
            return Response(brands);
        }
    }
}

export async function getBrand(id) {
    let brands = await loadState('brands');
    if (brands !== null) {
        return brands.find(brand => brand.id == id);
    } else {
        return ajaxService.get(urlService.brands, { id: id }).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveBrand(data) {
    setDependency()
    return ajaxService.post(urlService.brands, data).then(response => {
        return Response(response.data);
    })
}

export async function updateBrand(id, data) {
    setDependency()
    return ajaxService.put(urlService.brands + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteBrand(id) {
    return ajaxService.delete(urlService.brands + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function getBranchBrands(id) {
    setDependency()
    return ajaxService.get(urlService.branchBrands + '/' + id).then(response => {
        return Response(response.data);
    })
}