import { Button, Card, Modal, notification, Space, Switch, Table, Typography } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import React from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { deleteShippingMethod, getShippingMethods, updateShippingMethod } from "../../data";
import windowSize from "react-window-size";
import { PAGINATION_OPTIONS } from "../../constants";
import { updateDataSource } from "../../utility/updateDataSource";

const { Title, Text } = Typography;

function ShippingMethods({ windowWidth }) {
    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768

    let history = useHistory()

    const updateValue = async (id, checked, type) => {

        const updatedShippingMethods = updateDataSource(dataSource, id, checked);
        setDataSource(updatedShippingMethods);

        const active = await updateShippingMethod(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            if (type === 'active' && checked === true) {
                notification.open({ message: 'Shipping method  activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (type === 'active' && checked === false) {
                notification.open({ message: 'Shipping method  deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
        },
        {
            title: 'Disclaimer',
            dataIndex: 'disclaimer',
            key: 'disclaimer',
            // width: '50%',
        },
        {
            title: 'Shipping Charges',
            dataIndex: 'charges',
            key: 'charges',
            width: '25%',
            // render: (text, record) => {
            //     return <Text>{(Math.round(record.charges * 100) / 100).toFixed(2)}</Text>
            // }
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => (
                <Switch
                    onChange={(checked) => updateValue(record.id, checked, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={active == 1 ? true : false}
                />
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: '/shipping-methods/form/' + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            )
        },
    ]

    const deleteRow = async () => {
        const shippingMethod_id = localStorage.getItem('shipping_type')
        const response = await deleteShippingMethod(shippingMethod_id);
        if (response) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Shipping method has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted shipping method', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('shipping_type', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getShippingMethods(forceRefresh);
        if (data) {
            setDataSource(data);
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize()
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title level={3}>Shipping Methods</Title>
                <Space size="small" >
                    <Button type="primary" onClick={() => history.push({ pathname: '/shipping-methods/form/0' })}>Add Shipping Method</Button>
                </Space>
            </div>
        </>
    )

    const renderModal = () => (
        <Modal
            title="Delete Shipping Method"
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteRow()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this shipping method?</p>
            </div>
        </Modal>
    )

    const renderShippingMethod = () => (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
        </>
    )

    const renderShippingMethodMobile = () => (
        <>
            {
                dataSource.map(shippingMethod => {
                    return (
                        <>
                            <br />
                            <Card
                                style={{ width: '100%' }}
                                actions={[
                                    <Space size='small'>
                                        <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: '/shipping-methods/form/' + shippingMethod.id })}>Edit</Button>
                                        <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(shippingMethod.id)}>Delete</Button>
                                    </Space>
                                ]}>
                                <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{shippingMethod.name}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Disclaimer : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{shippingMethod.disclaimer}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Shipping Charges : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{shippingMethod.charges}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Active :
                                    <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                        <Switch
                                            onChange={(val) => updateValue(shippingMethod.id, val, 'active')}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={shippingMethod.active == 1 ? true : false}
                                        />
                                    </span>
                                </Text>
                                <br />
                            </Card>
                        </>
                    );
                })
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderShippingMethodMobile()
        }
        else {
            return renderShippingMethod()
        }
    }

    return (
        <>
            {isMobile && renderHeader()}
            {renderContent()}
            {renderModal()}
        </>
    )
}

export default windowSize(ShippingMethods)