import Scenarios from '../constant/scenarios';
import { Response } from '../utility';
import ajaxService from './ajax-service';
import urlService from './url-serivce';

class DataService {

    async getProducts(queryString) {
        let qs = new URLSearchParams(queryString).toString();

        ajaxService.setDependency()

        let url = urlService.products + '?' + qs;

        return await ajaxService.getScenario(Scenarios.LIST, url).then(response => {
            return Response(response.data);
        });
    }

    async getCatalogueProducts() {

        ajaxService.setDependency()

        let url = urlService.products;

        return await ajaxService.getScenario(Scenarios.CATALOGUE, url).then(response => {
            return Response(response.data);
        });
    }

    async getOfferProducts() {

        ajaxService.setDependency()

        let url = urlService.products;

        return await ajaxService.getScenario(Scenarios.OFFERSEARCH, url).then(response => {
            return Response(response.data);
        });
    }

    async getPricebookProducts(queryString) {

        let qs = new URLSearchParams(queryString).toString();

        ajaxService.setDependency()

        let url = urlService.products + '?' + qs;

        return await ajaxService.getScenario(Scenarios.PRICEBOOK, url).then(response => {
            return Response(response.data);
        });
    }

    async getShortProducts(queryString) {

        let qs = new URLSearchParams(queryString).toString();

        ajaxService.setDependency()

        let url = urlService.products + '?' + qs;

        return await ajaxService.getScenario(Scenarios.BASICSEARCH, url).then(response => {
            return Response(response.data);
        });
    }    

}



const dataService = new DataService();

export default dataService; 
