import { Col, Row, Form, Input, Switch, Radio, } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const EnableSrbSetting = ({ Setting }) => {
    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='posId' label="POS ID" labelCol={{ span: 24 }} initialValue={data.posId} rules={[{ required: true, message: 'POS id is required' }]}>
                        <Input type="number" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='sntn_no' label="SNTN Number" labelCol={{ span: 24 }} initialValue={data.sntn_no} rules={[{ required: true, message: 'SNTN number id is required' }]}>
                        <Input type="number" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='pos_username' label="Pos Username" labelCol={{ span: 24 }} initialValue={data.pos_username} rules={[{ required: true, message: 'Pos username is required' }]}>
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='pos_password' label="Pos Password" labelCol={{ span: 24 }} initialValue={data.pos_password} rules={[{ required: true, message: 'Pos password is required' }]}>
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='tax_rate' label="Tax Rate" labelCol={{ span: 24 }} initialValue={data.tax_rate ?? 0} rules={[{ required: true, message: 'Tax rate is required' }]}>
                        <Input type="number" step={0.01} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='pos_service_fees' label="POS Service Fees" labelCol={{ span: 24 }} initialValue={data.pos_service_fees ?? 0} rules={[{ required: true, message: 'POS service fees is required' }]}>
                        <Input type="number" min={0} step={0.01} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='tax_type' label="Tax type" labelCol={{ span: 24 }} initialValue={data.tax_type ?? "inclusive"} rules={[{ required: true, message: 'Tax type is required' }]}>
                        <Radio.Group>
                            <Radio value="inclusive">Inclusive</Radio>
                            <Radio value="exclusive">Exclusive</Radio>
                        </Radio.Group>
                    </Form.Item>

                </Col>

                <Col span={12}>
                    <Form.Item name='mode' label="Test Mode" labelCol={{ span: 24 }} initialValue={data.mode} rules={[{ required: true, message: 'Mode is required' }]}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.mode == 1 ? true : false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
export default EnableSrbSetting