import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getSuppliers(param) {
    setDependency()
    return ajaxService.get(`${urlService.suppliers}${param}`).then(response => {
        return Response(response.data);
    })
}

export async function getSupplier(id) {

    return ajaxService.get(urlService.suppliers + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function saveSupplier(data) {
    setDependency()
    return ajaxService.post(urlService.suppliers, data).then(response => {
        return Response(response.data);
    })
}

export async function updateSupplier(id, data) {
    setDependency()
    return ajaxService.put(urlService.suppliers + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteSupplier(id) {
    return ajaxService.delete(urlService.suppliers + '/' + id).then(response => {
        return Response(response.data);
    })
}