import { useEffect, useState } from "react"
import { Col, Form, Row, Select, Typography, Space, Button, Card, Empty } from "antd";
import { filterProductInventoryReports, getBranches, getProducts } from "../../../data";

const { Option } = Select
const { Text } = Typography

const ProductInventoryMobile = ({ summary, hasBranch }) => {

    const [form] = Form.useForm();

    const [branches, setBranches] = useState([]);
    const [products, setProducts] = useState([]);
    const [items, setItems] = useState([])
    const [data, setData] = useState([])

    const [mainBranch, setMainBranch] = useState(0);
    const [forceRefresh, setForceRefresh] = useState(true);

    const handleSearch = async (values) => {
        const response = await filterProductInventoryReports(values)
        if (response) {
            setData(response)
            setItems(response.filter(i => i.branch_id == mainBranch))
        }
    }

    const onChange = (id) => {
        setItems(data.filter(i => i.branch_id == id))
    }

    const loadBranches = async () => {
        let response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setMainBranch(response.find(i => i.is_main == 1)?.id)
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        const response = await getProducts(forceRefresh)
        if (response) {
            setProducts(response.filter(i => !i.is_combo))
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        loadBranches()
        loadProducts()
    }, [])

    const renderFilter = (
        <>
            <Form form={form} layout={'vertical'} onFinish={handleSearch}>
                <Row gutter={16} >

                    <Col span={24}>
                        <Form.Item label="Products" name="product_id" labelCol={{ span: 24 }}>
                            <Select
                                showSearch allowClear
                                mode='multiple'
                                style={{ width: '100%' }}
                                placeholder="Select Products"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {products.map(({ id, name }) => {
                                    return <Option value={id} key={id}>{name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit">Search</Button>
                                <Button onClick={() => form.resetFields()}>Clear</Button>
                            </Space>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </>
    )

    return (
        <>
            {renderFilter}
            {summary}
            <br />
            {
                data.length > 0 &&
                <>
                    {hasBranch &&
                        <>
                            <Text style={{ fontWeight: 'bolder' }}>Branches</Text>
                            <Select
                                showSearch allowClear
                                // mode='multiple'
                                style={{ width: '100%' }}
                                placeholder="Select Branch"
                                onChange={onChange}
                                defaultValue={mainBranch}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {branches.map(({ id, name }) => {
                                    return <Option value={id} key={id}>{name}</Option>
                                })}
                            </Select>
                            <br />  <br />
                        </>
                    }
                    {
                        items.length > 0 ? (

                            items.map(i => (
                                <>
                                    <br />
                                    <Card>
                                        {
                                            hasBranch &&
                                            <>
                                                <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.branch_name ?? null}</span></Text>
                                                <br />
                                            </>
                                        }
                                        <Text style={{ fontWeight: 'bolder' }}>Product : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.name}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Units Available :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}> {i.stock}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Cost per Unit :  <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.cost_price }</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Total Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(i.stock * i.cost_price).toFixed(2)}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Price per Unit :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.selling_price}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Total Price :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(i.stock * i.selling_price).toFixed(2)}</span></Text>

                                    </Card>
                                </>
                            ))
                        ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                    }
                </>
            }
        </>
    )
}

export default ProductInventoryMobile