
import React, { useRef, useState, useCallback, useEffect } from "react";
import AntdInputTag from "antd-input-tag";
import { Form, Input, Button, notification, Row, Col, Checkbox, Select, Typography, Divider, Space, Tooltip, Switch, message } from 'antd';
import { CloseOutlined, PlusOutlined, CheckCircleOutlined, CheckOutlined, DeleteOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import ajaxService, { GlobalVariable } from "../../../services/ajax-service";
import ImageUpload from "../../../components/image-upload";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getCategories, getBrands, saveProduct, getVariant, getStoreSetting, getStore, updateProduct, getBranches, getProducts } from "../../../data";
import windowSize from "react-window-size";
import _ from 'lodash';
import moment from "moment";
import { AddonForm } from "./addonForm";
import ControlSettings from "../../../constant/controlSettings";
import IndustrySettings from "../../../constant/industrySettings";

const { Option } = Select
const { Title, Text } = Typography;
const { TextArea } = Input

const Variant = ({ windowWidth }) => {

    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams();
    const store_id = localStorage.getItem('store_id');
    const isMobile = windowWidth <= 768

    const [categories, setcategories] = useState([])
    const [brands, setBrands] = useState([])
    const [attribute, setAttribute] = useState({ first: '', second: '', third: '' });
    const [fileList, setFileList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [branches, setBranches] = useState([]);
    const [text, setText] = useState("")
    const [forceRefresh, setForceRefresh] = useState(true)
    const [branchCatalogue, setBranchCatalogue] = useState(false)

    const [ecommerce, setEcommerce] = useState(false)
    const [showMobile, setShowMobile] = useState(false)

    const [addonFileList, setAddonFileList] = useState([]);
    const [hasAddon, setHasAddon] = useState(false)
    const [store, setStore] = useState({})
    const [sku, setSku] = useState([])

    const initialValues = {
        variants: [],
        is_addon: false,
        image: []
    };

    const handleVariantImg = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        console.log(fileList)
        if (newFileList[0] !== undefined && newFileList[0].response !== undefined) {
            const image = newFileList[0].response.path;
            form.setFieldsValue({ image });
            console.log(newFileList)
        }
    };

    const generateVariants = () => {
        const arr = []

        if (childRef1.current.changeVal().length > 0) {
            childRef1.current.changeVal().map((v1) => {
                if (childRef2.current.changeVal().length) {
                    childRef2.current.changeVal().map((v2) => {

                        if (childRef3.current.changeVal().length > 0) {
                            childRef3.current.changeVal().map((v3) => {
                                arr.push([v1, v2, v3]);
                            })
                        } else {
                            arr.push([v1, v2]);
                        }
                    })
                } else {
                    arr.push([v1]);
                }
            })
        }

        if (arr.length > 0) {

            let variants = [];

            arr.map(variant => {
                variants.push(
                    {
                        attribute1: attribute.first, attribute1_value: variant[0],
                        attribute2: attribute.second, attribute2_value: variant[1],
                        attribute3: attribute.third, attribute3_value: variant[2],
                        id: 0,
                        branches: branches.map(branch => branch.id)
                    })

            })
            form.setFieldsValue({ variants });
        }
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onFinish = async (values) => {

        // if (text === '') {
        //     return message.error('Please fill in the description box')
        // }else {
        const variant_group = new Date().getTime()
        values.variants.map(async (variant, index) => {
            let imageData = [];

            if (fileList.length > 0) {
                fileList.map((item) => {
                    let { response } = item;
                    if (response != undefined) {
                        let { path, success } = response;
                        if (success) {
                            imageData.push(path);
                        }
                    } else if (item.url !== undefined) {
                        imageData.push(item.url.replace(GlobalVariable.BASE_IMAGE_URL + '/', ""));
                    }
                });
            }

            const variantdata = {
                name: values.name,
                meta_title: values.meta_title,
                brand_id: values.brand_id,
                category_id: values.category_id,
                meta_description: values.meta_description,
                active: variant.active,
                unlimited_stock: variant.unlimited_stock,
                show_in_pos: variant.show_in_pos,
                show_in_ecommerce: variant.show_in_ecommerce,
                display_in_ecommerce: variant.display_in_ecommerce,
                show_in_mobile: variant.show_in_mobile,
                display_in_mobile: variant.display_in_mobile,
                description: text,
                short_description: values.short_description,
                sku: variant.sku,
                cost_price: variant.cost_price,
                selling_price: variant.selling_price,
                compare_price: variant.compare_price,
                weight: variant.weight,
                length: variant.length,
                breath: variant.breath,
                height: variant.height,
                preparation_time: values.preparation_time,
                low_stock_point: variant.low_stock_point,
                reorder_point: variant.reorder_point,
                reorder_qty: variant.reorder_qty,
                sort_order: values.sort_order,
                urls: values.video_urls,
                offer: false,
                has_variant: true,
                stock: variant.stock,
                attribute1: variant.attribute1,
                attribute1_value: variant.attribute1_value,
                attribute2: variant.attribute2,
                attribute2_value: variant.attribute2_value,
                attribute3: variant.attribute3,
                attribute3_value: variant.attribute3_value,
                is_addon: values.is_addon,
                separately: values.separately,
                addonProducts: values.addons,
                image: imageData,
                branches: variant.branches,
                online_orders_cutoff_point: variant.online_orders_cutoff_point
            }

            if (id > 0) {
                // let list = [...imageData]
                // fileList.map((item) => {
                //     if (item.url !== undefined) {
                //         list.push(item.url)
                //     }
                // })
                // variantdata.image = list;
                variantdata.is_main = (index === 0);
                variantdata.active = variant.active
                variantdata.unlimited_stock = variant.unlimited_stock

                const response = await updateProduct(id, variantdata);

                if (response) {
                    history.push({ pathname: "/products" });
                    notification.open({ message: 'Product has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    history.push({ pathname: "/products" });
                    notification.open({ message: 'Unable to update product', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
            else {
                variantdata.is_main = (index === 0);
                variantdata.active = variant.active
                variantdata.unlimited_stock = variant.unlimited_stock
                variantdata.variant_group = variant_group;

                const saveData = await saveProduct(variantdata);

                console.log(variantdata)

                if (saveData) {
                    history.push({ pathname: "/products" });
                    notification.open({ message: 'Product has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to add product', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
        })
        // }
    };

    const onFinishFailed = errorInfo => {
        message.error('Form Not Completed.' + errorInfo)
    };

    const handleGenerate = (name) => {

        const generateRandomSKU = Math.floor(Math.random() * 10 ** 13).toString();

        let randomSKU;
        do {
            randomSKU = generateRandomSKU;
        } while (sku.includes(randomSKU));

        setSku([...sku, randomSKU]);

        const currentValues = form.getFieldsValue();
        const updatedSkus = [...currentValues.variants];

        updatedSkus[name] = { ...updatedSkus[name], sku: randomSKU };

        form.setFieldsValue({ variants: updatedSkus });
    }

    const loadCategories = useCallback(async () => {
        const categories = await getCategories(forceRefresh);
        if (categories) {
            setcategories(categories);
            setForceRefresh(false)
        }
    }, [])

    const loadBrands = useCallback(async () => {
        const brands = await getBrands(forceRefresh);
        if (brands) {
            setBrands(brands);
            setForceRefresh(false)
        }
    }, [])

    const branchCatalogueSetting = async () => {
        const response = await getStoreSetting(ControlSettings.BRANCH_CATALOG);
        if (response) {
            setBranchCatalogue(response.active)
        }
    }

    const loadStore = async () => {
        const storeId = localStorage.getItem('store_id')
        const response = await getStore(storeId)
        if (response) {
            setStore(response)
        }
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        let response = await getProducts(forceRefresh);
        if (response) {
            setSku(response.map(i => i.sku))
            setForceRefresh(false)
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            const response = await getVariant(id);
            if (response) {
                const data = response[0];

                if (data) {

                    const { product_items, category_id, brand_id, description, images, links, show_in_ecommerce, show_in_mobile } = data;

                    setEcommerce(show_in_ecommerce ? true : false)
                    setShowMobile(show_in_mobile ? true : false)

                    form.setFieldsValue({
                        ...data,
                        category_id: category_id ? parseInt(category_id) : null,
                        brand_id: brand_id ? parseInt(brand_id) : null,
                        video_urls: links,
                        addonProducts: product_items,
                    });

                    if (description != null) {
                        setText(description);
                    };

                    if (product_items.length > 0) {
                        setHasAddon(true)

                        let images = product_items.map(i => ([{
                            uid: i.id,
                            name: 'image.png',
                            status: 'done',
                            url: ajaxService.getImage(i.image),
                            preview: false,
                        }]));

                        setAddonFileList(images)
                    }

                    let variants = [];

                    response.map(variant => {

                        const assignedBranches = data.branch_products.filter(product => product.active === 1).map(product => product.branch_id);

                        variants.push({
                            active: variant.active,
                            attribute1: variant.attribute1,
                            attribute1_value: variant.attribute1_value,
                            attribute2: variant.attribute2,
                            attribute2_value: variant.attribute2_value,
                            attribute3: variant.attribute3,
                            attribute3_value: variant.attribute3_value,
                            breath: variant.breath,
                            cost_price: variant.cost_price,
                            image: variant.image,
                            height: variant.height,
                            length: variant.length,
                            selling_price: variant.selling_price,
                            sku: variant.sku,
                            stock: variant.stock,
                            unlimited_stock: variant.unlimited_stock,
                            weight: variant.weight,
                            id: variant.id,
                            low_stock_point: variant.low_stock_point,
                            reorder_point: variant.reorder_point,
                            reorder_qty: variant.reorder_qty,
                            compare_price: variant.compare_price,
                            show_in_pos: variant.show_in_pos,
                            show_in_ecommerce: variant.show_in_ecommerce,
                            display_in_ecommerce: variant.display_in_ecommerce,
                            show_in_mobile: variant.show_in_mobile,
                            display_in_mobile: variant.display_in_mobile,
                            online_orders_cutoff_point: variant.online_orders_cutoff_point,
                            branches: assignedBranches

                        })
                    })

                    form.setFieldsValue({ variants: variants });

                    setFileList(images.map(v => ({
                        uid: v.id,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(v.url),
                        preview: false,
                    })))

                    // Addons grouped wise
                    const groupedData = product_items.reduce((acc, item) => {
                        const { title, type, limit, image, item: itemName, price } = item;
                        if (!acc[title]) {
                            acc[title] = { title: title, type: type, limit: limit, items: [] };
                        }
                        acc[title].items.push({ image, item: itemName, price });
                        return acc;
                    }, {});

                    const formattedData = Object.values(groupedData);

                    form.setFieldsValue({ addons: formattedData });
                }
            }
        }
    }

    useEffect(() => {
        loadData(id);
        loadCategories();
        loadBrands();
        branchCatalogueSetting()
        loadStore()
        loadBranches()
        loadProducts()
    }, []);

    const renderButtons = () => {
        // let obj = form.getFieldsValue();
        // let variants = obj.variants;

        // console.log(variants);
        // if (variants != undefined && variants.length > 0) {
        // }
        return (
            <Row>
                <Col span={24}>
                    <Form.Item >
                        <Space>
                            <Button type="primary" htmlType="submit">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    const VariantToggles = ({ restField, name }) => (
        <>
            <Col span={isMobile ? 12 : 3}>
                <Form.Item {...restField} name={[name, 'show_in_pos']} label="Show In POS" valuePropName='checked' >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={false}
                    />
                </Form.Item>
            </Col>


            <Col span={isMobile ? 12 : 3}>
                <Form.Item {...restField} name={[name, 'show_in_ecommerce']} label="Show In Ecommerce" valuePropName='checked' >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(e) => setEcommerce(e)}
                        defaultChecked={false}
                    />
                </Form.Item>
            </Col>
            {
                ecommerce &&
                <Col span={isMobile ? 12 : 4}>
                    <Form.Item {...restField} name={[name, 'display_in_ecommerce']} label={<Text>Display Only <span style={{ color: '#FF6600' }}>(Not For Sale)</span></Text>} valuePropName='checked' >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={false}
                        />
                    </Form.Item>
                </Col>
            }

            <Col span={isMobile ? 12 : 3}>
                <Form.Item {...restField} name={[name, 'show_in_mobile']} label="Show In Mobile" valuePropName='checked' >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(e) => setShowMobile(e)}
                        defaultChecked={false}
                    />
                </Form.Item>
            </Col>
            {
                showMobile &&
                <Col span={isMobile ? 12 : 4}>
                    <Form.Item {...restField} name={[name, 'display_in_mobile']} label={<Text>Display Only <span style={{ color: '#FF6600' }}>(Not For Sale)</span></Text>} valuePropName='checked' >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={false}
                        />
                    </Form.Item>
                </Col>
            }

        </>
    )

    const renderVariantsDynamicForm = (
        <Form.List name="variants">
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }, index) => (
                        <>
                            <Row gutter={[16, 10]}>
                                <Col span={8}>
                                    <Title level={5}> Variant {index + 1}</Title>
                                </Col>
                            </Row>

                            <Space key={key} >
                                <Row gutter={6}>
                                    <Col span={8} >
                                        <Row gutter={7}>
                                            <Col span={12}>
                                                <Form.Item  {...restField} name={[name, 'id']} style={{ display: 'none' }} >
                                                    <Input type='hidden' />
                                                </Form.Item>
                                                <Form.Item  {...restField} label="Attribute 1" name={[name, 'attribute1']} >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item {...restField} label="Value" name={[name, 'attribute1_value']}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[6, 0]}>
                                            <Col span={12}>
                                                <Form.Item {...restField} label="Attribute 2" name={[name, 'attribute2']} >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item {...restField} label="Value" name={[name, 'attribute2_value']} >
                                                    <Input />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={[6, 0]}>
                                            <Col span={12}>
                                                <Form.Item {...restField} label="Attribute 3" name={[name, 'attribute3']} >
                                                    <Input />
                                                </Form.Item>

                                            </Col>
                                            <Col span={12}>
                                                <Form.Item {...restField} label="Value" name={[name, 'attribute3_value']}  >
                                                    <Input />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={4}>
                                        <Space.Compact style={{ width: '100%' }}>
                                            <Form.Item  {...restField} label="SKU" name={[name, 'sku']} rules={[{ required: true, message: 'SKU is required' }]}>
                                                <Input placeholder="SKU" />
                                            </Form.Item>
                                            <Form.Item label="&nbsp;">
                                                <Button type="primary" onClick={() => handleGenerate(name)}>Genrate</Button>
                                            </Form.Item>
                                        </Space.Compact>

                                        {/* {
                                            !branchCatalogue &&
                                            <Form.Item  {...restField} label="Stock" name={[name, 'stock']}>
                                                <Input placeholder="Stock" type="number" defaultValue={0} />
                                            </Form.Item>
                                        } */}

                                        <Form.Item {...restField} name={[name, 'compare_price']} label="Copmare Price" >
                                            <Input type='number' placeholder='Compare price' step={0.01} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                        <Form.Item  {...restField} label="Cost Price" name={[name, 'cost_price']} >
                                            <Input placeholder="Cost Price" type="number" defaultValue={0} />
                                        </Form.Item>

                                        <Form.Item  {...restField} label="Selling Price" name={[name, 'selling_price']} rules={[
                                            {
                                                required: true,
                                                message: 'Selling Price is required',
                                            },
                                        ]} >
                                            <Input placeholder="Selling Price" type="number" defaultValue={0} />
                                        </Form.Item>

                                        <Form.Item {...restField} name={[name, 'low_stock_point']} label="Low Stock Point" >
                                            <Input type='number' placeholder='Low Stock Point' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                        <Form.Item  {...restField} label="Weight" name={[name, 'weight']}   >
                                            <Input placeholder="Weight" type="number" defaultValue={0} step={0.01} />
                                        </Form.Item>

                                        <Form.Item  {...restField} label="Length" name={[name, 'length']} >
                                            <Input placeholder="Length" type="number" defaultValue={0} step={0.01} />
                                        </Form.Item>

                                        <Form.Item {...restField} name={[name, 'reorder_point']} label="Reorder Point" >
                                            <Input type='number' placeholder='Reorder Point' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                        <Form.Item  {...restField} label="Breath" name={[name, 'breath']}  >
                                            <Input placeholder="Breath" type="number" defaultValue={0} />
                                        </Form.Item>

                                        <Form.Item  {...restField} label="Height" name={[name, 'height']} >
                                            <Input placeholder="Height" type="number" defaultValue={0} step={0.01} />
                                        </Form.Item>

                                        <Form.Item {...restField} name={[name, 'reorder_qty']} label="Reorder Quantity" >
                                            <Input type='number' placeholder='Reorder Quantity' />
                                        </Form.Item>

                                        {/* <Form.Item label=" ">
                                        <Button type="dashed" onClick={() => remove(name)} block icon={<DeleteOutlined />}>
                                            Remove
                                        </Button>
                                    </Form.Item> */}
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item {...restField} name={[name, 'branches']} label="Assign To Branches">
                                            <Select mode="multiple" placeholder="Branches" style={{ width: '100%' }}>
                                                {branches.map(({ id, name }) => (
                                                    <Option key={id} value={id}>{name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={3}>
                                        <Form.Item  {...restField} label="Unlimited Stock" name={[name, 'unlimited_stock']} valuePropName="checked">
                                            {/* <Checkbox>Unlimited Stock</Checkbox> */}
                                            <Switch
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                defaultChecked={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item  {...restField} label="Active" name={[name, 'active']} valuePropName="checked">
                                            {/* <Checkbox>Active</Checkbox> */}
                                            <Switch
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                defaultChecked={false}
                                            />
                                        </Form.Item>
                                    </Col>


                                    {/* Toggles for pos , ecommerce & mobile */}
                                    <VariantToggles restField={restField} name={name} />

                                    <Col span={5}>
                                        <Form.Item  {...restField} name={[name, 'online_orders_cutoff_point']} label="Online Orders Cut-Off Point" >
                                            <Input type='number' placeholder='Online Orders Cut-Off Point' />
                                        </Form.Item>
                                    </Col>

                                </Row>

                            </Space>
                            <Row>
                                <Col span={4}>
                                    <Form.Item label=" ">
                                        <Button type="primary" danger onClick={() => remove(name)} block icon={<DeleteOutlined />}>
                                            Remove
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider />
                        </>
                    ))}

                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Variant
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )

    const renderVariant = () => {
        return (
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
                form={form}
            >
                <Row gutter={16}>
                    <Col span={3}></Col>
                    <Col span={18} >
                        <Row gutter={16} >

                            <Col span={8}  >
                                <Form.Item name='name' label="Name" rules={[
                                    {
                                        required: true,
                                        message: 'Product Name field is required',
                                    },
                                ]}>
                                    <Input placeholder="Product Name" />
                                </Form.Item>
                            </Col>

                            <Col span={8} >
                                <Form.Item name='meta_title' label="Meta Title" rules={[
                                    {
                                        required: false,
                                        message: 'Meta Title field is required',
                                    },
                                ]}>
                                    <Input placeholder="Meta Title" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name='meta_description' label="Meta Description" rules={[
                                    {
                                        required: false,
                                        message: 'Meta description field is required',
                                    },
                                ]}>
                                    <Input placeholder="Meta Description" />
                                </Form.Item>
                            </Col>

                            {/* <Form.Item label="Multiple Images">
                                <Checkbox onChange={() => setVariantImg(!variantImg)}>Use Variant Level Images</Checkbox>
                            </Form.Item> */}

                            <Col span={16}>
                                <Form.Item name='short_description' label="Short Description">
                                    <TextArea rows={3} maxLength={300} placeholder="Maximum Length Is 300" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label="Category" name="category_id" rules={[
                                    {
                                        required: true,
                                        message: 'Category is required',
                                    },
                                ]}>
                                    <Select
                                        // allowClear
                                        placeholder="Select Category"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {categories.map(category => {
                                            return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={16}>
                                <Form.Item label="Description">
                                    <CKEditor
                                        className='editor'
                                        editor={ClassicEditor}
                                        // onReady={editor => editor.setData(text)}
                                        data={text}
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setText(data);
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>

                                <Form.Item label="Brand" name="brand_id">
                                    <Select
                                        allowClear
                                        placeholder="Select Brand"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {brands.map(category => {
                                            return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                                <br />
                                <Form.Item name='preparation_time' label="Preparation Time (In minutes)" >
                                    <Input type='number' placeholder='Preparation Time' />
                                </Form.Item>

                                {
                                    IndustrySettings.ADDON ? (
                                        <Form.Item label="Has Addon" valuePropName="checked" name='is_addon'>
                                            <Switch
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                onChange={e => setHasAddon(e)}
                                            />
                                        </Form.Item>
                                    ) : null
                                }

                            </Col>

                            {/* Addon Form */}
                            <Col span={24}>
                                {hasAddon && <AddonForm addonFileList={addonFileList} setAddonFileList={setAddonFileList} />}
                            </Col>

                            <Col span={10}>
                                <Form.Item label={
                                    <span>
                                        Add Youtube Url
                                        <Tooltip placement="top" title={"hello"}>
                                            <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: 10 }} />
                                        </Tooltip>
                                    </span>
                                }>
                                    <Form.List name="video_urls">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row gutter={16}>
                                                        <Col span={23}>
                                                            <Form.Item {...restField} name={[name, 'url']}>
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>

                                                        <Form.Item>
                                                            <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => remove(name)} />
                                                        </Form.Item>
                                                    </Row>
                                                ))}
                                                <Col span={24}>
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Upload Multiple Videos Via Embed YouTube URL
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name='sort_order' label="Sort Order" >
                                    <Input type='number' placeholder='Sort Order' />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item label="Upload Multiple Images (Max File Size 1MB) but 1 by 1">
                                    <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Divider />
                        {id == 0 &&
                            <>
                                <Row >
                                    <Col span={20}>
                                        <Title level={4}> Generate Variants By Entering Attribute And Their Values.</Title>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Form.Item label="First Attribute">
                                            <Input onChange={(e) => setAttribute({ ...attribute, first: e.target.value })} placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item label={<Text>Values<span style={{ color: 'red' }}> (Write word then hit enter to create a value. you can create multiple values)</span></Text>}>
                                            <AntdInputTag onKeyDown={(e) => e.keyCode == 13 ? e.preventDefault() : ''} value={[]} ref={childRef1} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Form.Item label="Second Attribute">
                                            <Input onChange={(e) => setAttribute({ ...attribute, second: e.target.value })} placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item label={<Text>Values<span style={{ color: 'red' }}> (Write word then hit enter to create a value. you can create multiple values)</span></Text>}>
                                            <AntdInputTag value={[]} ref={childRef2} />

                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={6}>
                                        <Form.Item label="Third Attribute">
                                            <Input onChange={(e) => setAttribute({ ...attribute, third: e.target.value })} placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item label={<Text>Values<span style={{ color: 'red' }}> (Write word then hit enter to create a value. you can create multiple values)</span></Text>}>
                                            <AntdInputTag value={[]} ref={childRef3} />

                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>
                                        <Form.Item >
                                            <Space>
                                                <Button onClick={generateVariants} type="primary">Generate Variants</Button>
                                            </Space>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                            </>
                        }
                        {renderVariantsDynamicForm}
                        {renderButtons()}
                    </Col>
                    <Col span={3} ></Col>
                </Row >
            </Form >
        );
    }

    const renderVariantMobile = () => {
        return (
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
                form={form}>
                <Row gutter={16} >
                    <Col span={24}  >
                        <Form.Item name='name' label="Name" rules={[
                            {
                                required: true,
                                message: 'Product Name field is required',
                            },
                        ]}>
                            <Input placeholder="Product Name" />
                        </Form.Item>

                    </Col>

                    <Col span={24} >
                        <Form.Item name='meta_title' label="Meta Title" rules={[
                            {
                                required: false,
                                message: 'Meta Title field is required',
                            },
                        ]}>
                            <Input placeholder="Meta Title" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='meta_description' label="Meta Description" rules={[
                            {
                                required: false,
                                message: 'Meta description field is required',
                            },
                        ]}>
                            <Input placeholder="Meta Description" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Category" name="category_id" rules={[
                            {
                                required: true,
                                message: 'Category is required',
                            },
                        ]}>
                            <Select
                                // allowClear
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {categories.map(category => {
                                    return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Brand" name="brand_id">
                            <Select
                                allowClear
                                placeholder="Select Brand"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {brands.map(category => {
                                    return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='short_description' label="Short Description">
                            <TextArea rows={3} maxLength={300} placeholder="Maximum Length Is 300" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Description">
                            <div style={{ height: '230px' }}>
                                <CKEditor
                                    className='editor'
                                    editor={ClassicEditor}
                                    onReady={editor => editor.setData(text)}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        setText(data)
                                    }}
                                />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name='preparation_time' label="Preparation Time (In minutes)" >
                            <Input type='number' placeholder='Preparation Time' />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        {
                            (store?.industry?.addon ?? false) &&
                            <Form.Item label="Has Addon" valuePropName="checked" name='is_addon'>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={e => setHasAddon(e)}
                                />
                            </Form.Item>
                        }
                    </Col>

                    {/* Addon Form */}
                    <Col span={24}>
                        {
                            hasAddon &&
                            <AddonForm addonFileList={addonFileList} setAddonFileList={setAddonFileList} mobile={isMobile} />
                        }
                    </Col>

                    <Col span={24}>
                        <Form.Item label={
                            <span>
                                Upload Multiple Videos Via Embed YouTube URL
                                <Tooltip placement="top" title={"hello"}>
                                    <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: 10 }} />
                                </Tooltip>
                            </span>
                        }>
                            <Form.List name="video_urls">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row gutter={16}>
                                                <Col span={20}>
                                                    <Form.Item {...restField} name={[name, 'url']}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                                <Form.Item>
                                                    <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => remove(name)} />
                                                </Form.Item>
                                            </Row>
                                        ))}
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ whiteSpace: 'pre-wrap' }}>
                                                    Add Youtube Url
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Upload Multiple Images (Max File Size 1MB)">
                            <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                        </Form.Item>
                    </Col>
                    <Divider />
                    {id == 0 &&
                        <>
                            <Col span={24}>
                                <Title level={4}> Generate Variants By Entering Attribute And Their Values.</Title>
                            </Col>
                            <Col span={20}>
                                <Form.Item label="First Attribute">
                                    <Input onChange={(e) => setAttribute({ ...attribute, first: e.target.value })} placeholder="" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={<Text>Values<span style={{ color: 'red' }}> (Write word then hit enter to create a value. you can create multiple values)</span></Text>}>
                                    <AntdInputTag onKeyDown={(e) => e.keyCode == 13 ? e.preventDefault() : ''} value={[]} ref={childRef1} />
                                </Form.Item>
                            </Col>
                            <Divider />
                            <Col span={20}>
                                <Form.Item label="Second Attribute">
                                    <Input onChange={(e) => setAttribute({ ...attribute, second: e.target.value })} placeholder="" />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={<Text>Values<span style={{ color: 'red' }}> (Write word then hit enter to create a value. you can create multiple values)</span></Text>}>
                                    <AntdInputTag value={[]} ref={childRef2} />

                                </Form.Item>
                            </Col>
                            <Divider />
                            <Col span={20}>
                                <Form.Item label="Third Attribute">
                                    <Input onChange={(e) => setAttribute({ ...attribute, third: e.target.value })} placeholder="" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={<Text>Values<span style={{ color: 'red' }}> (Write word then hit enter to create a value. you can create multiple values)</span></Text>}>
                                    <AntdInputTag value={[]} ref={childRef3} />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item >
                                    <Space>
                                        <Button onClick={generateVariants} type="primary">Generate Variants</Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Divider />
                        </>
                    }
                    <Form.List name="variants">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <>
                                        <Space key={key} >
                                            <Row gutter={16}>

                                                <Col span={24}>
                                                    <Title level={5}>Variants {index + 1}</Title>
                                                </Col>
                                                <br /><br />
                                                <Col span={12}>
                                                    <Form.Item  {...restField} name={[name, 'id']} style={{ display: 'none' }} >
                                                        <Input type='hidden' />
                                                    </Form.Item>
                                                    <Form.Item  {...restField} label="Attribute 1" name={[name, 'attribute1']} >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item {...restField} label="Value" name={[name, 'attribute1_value']}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item {...restField} label="Attribute 2" name={[name, 'attribute2']} >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item {...restField} label="Value" name={[name, 'attribute2_value']} >
                                                        <Input />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item {...restField} label="Attribute 3" name={[name, 'attribute3']} >
                                                        <Input />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item {...restField} label="Value" name={[name, 'attribute3_value']}  >
                                                        <Input />
                                                    </Form.Item>

                                                </Col>

                                                <Col span={24}>
                                                    <Space.Compact style={{ width: '100%' }}>
                                                        <Form.Item style={{ width: '100%' }} {...restField} label="SKU" name={[name, 'sku']} rules={[{ required: true, message: 'SKU is required' }]}>
                                                            <Input placeholder="SKU" style={{ width: '100%' }} />
                                                        </Form.Item>

                                                        <Form.Item label="&nbsp;">
                                                            <Button type="primary" onClick={() => handleGenerate(name)}>Genrate</Button>
                                                        </Form.Item>
                                                    </Space.Compact>

                                                    {/* {
                                                        !branchCatalogue &&
                                                        <Form.Item  {...restField} label="Stock" name={[name, 'stock']}>
                                                            <Input placeholder="Stock" type="number" defaultValue={0} />
                                                        </Form.Item>
                                                    } */}

                                                    <Form.Item  {...restField} name={[name, 'unlimited_stock']} valuePropName="checked">
                                                        <Checkbox>Unlimited Stock</Checkbox>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item  {...restField} label="Cost Price" name={[name, 'cost_price']} >
                                                        <Input placeholder="Cost Price" type="number" defaultValue={0} />
                                                    </Form.Item>

                                                    <Form.Item  {...restField} label="Selling Price" name={[name, 'selling_price']} rules={[
                                                        {
                                                            required: true,
                                                            message: 'Selling Price is required',
                                                        },
                                                    ]} >
                                                        <Input placeholder="Selling Price" type="number" defaultValue={0} />
                                                    </Form.Item>

                                                    <Form.Item {...restField} name={[name, 'active']} valuePropName="checked">
                                                        <Checkbox>Active</Checkbox>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item  {...restField} label="Weight" name={[name, 'weight']}   >
                                                        <Input placeholder="Weight" type="number" defaultValue={0} step={0.01} />
                                                    </Form.Item>

                                                    <Form.Item  {...restField} label="Length" name={[name, 'length']} >
                                                        <Input placeholder="Length" type="number" defaultValue={0} step={0.01} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item {...restField} label="Breath" name={[name, 'breath']}  >
                                                        <Input placeholder="Breath" type="number" defaultValue={0} />
                                                    </Form.Item>

                                                    <Form.Item {...restField} label="Height" name={[name, 'height']} >
                                                        <Input placeholder="Height" type="number" defaultValue={0} step={0.01} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item {...restField} name={[name, 'compare_price']} label="Copmare Price" >
                                                        <Input type='number' placeholder='Compare price' step={0.01} />
                                                    </Form.Item>

                                                    <Form.Item {...restField} name={[name, 'low_stock_point']} label="Low Stock Point" >
                                                        <Input type='number' placeholder='Low Stock Point' />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item {...restField} name={[name, 'reorder_point']} label="Reorder Point" >
                                                        <Input type='number' placeholder='Reorder Point' />
                                                    </Form.Item>

                                                    <Form.Item {...restField} name={[name, 'reorder_qty']} label="Reorder Quantity" >
                                                        <Input type='number' placeholder='Reorder Quantity' />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item {...restField} name={[name, 'branches']} label="Assign To Branches">
                                                        <Select mode="multiple" placeholder="Branches" style={{ width: '100%' }}>
                                                            {branches.map(({ id, name }) => (
                                                                <Option key={id} value={id}>{name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                {/* Toggles for pos & ecommerce */}
                                                <VariantToggles restField={restField} name={name} />

                                                <Col span={24}>
                                                    <Form.Item  {...restField} name={[name, 'online_orders_cutoff_point']} label="Online Orders Cut-Off Point" >
                                                        <Input type='number' placeholder='Online Orders Cut-Off Point' />
                                                    </Form.Item>
                                                </Col>

                                                <br /><br />
                                                <Col span={24}>
                                                    <Form.Item>
                                                        <Button type="primary" danger onClick={() => remove(name)} block icon={<DeleteOutlined />}>
                                                            Remove
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Space>
                                        <Divider />
                                    </>
                                ))}
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Variant
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Form.List>
                    <br /><br />
                    {renderButtons()}
                </Row >
            </Form >
        );
    }

    const renderContent = () => {
        if (isMobile) {
            return renderVariantMobile()
        } else {
            return renderVariant()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
};


export default windowSize(Variant)