import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }

export async function getPackages(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.packages, 'packages');
    } else {
        let packages = await loadState('packages');

        if (packages == null) {
            return await loadResource(urlService.packages, 'packages');
        } else {
            return Response(packages);
        }
    }
}

export async function getPackage(id) {
    removeDependency()
    return ajaxService.get(urlService.packages + '/' + id).then(response => {
        return Response(response.data);
    })
}


export async function savePackage(data) {
    removeDependency()
    return ajaxService.post(urlService.packages, data).then(response => {
        return Response(response.data);
    })
}

export async function updatePackage(id, data) {
    removeDependency()
    return ajaxService.put(urlService.packages + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deletePackage(id) {
    removeDependency()
    return ajaxService.delete(urlService.packages + '/' + id).then(response => {
        return Response(response.data);
    })
}