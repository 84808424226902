import { Col, Form, Row, Select, Typography, Space, Button, Card } from "antd"
import { getBranches } from "../../../data";
import { useEffect, useState } from "react";

const { Option } = Select
const { Text } = Typography

const LowOfStockMobile = ({ summary, data, handleSearch, hasBranchName }) => {

    const [form] = Form.useForm();

    const [branches, setBranches] = useState([]);

    const [forceRefresh, setForceRefresh] = useState(true);

    const loadBranches = async () => {
        let response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        loadBranches()
    }, [])

    const renderFilter = (
        <Form form={form} layout={'vertical'} onFinish={handleSearch}>
            <Row gutter={16} >
                <Col span={24}>
                    <Form.Item label="Branches" name="branch_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch allowClear mode='multiple'
                            style={{ width: '100%' }}
                            placeholder="Select Branches"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {branches.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24} className="gutter-row">
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit">Search</Button>
                            <Button onClick={() => form.resetFields()}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

    return (
        <>
            {renderFilter}
            {summary}
            <br /><br />

            {
                data.map(i => (
                    <>
                        <br />
                        <Card>
                            {
                                hasBranchName &&
                                <>
                                    <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.branch_name}</span></Text>
                                    <br />
                                </>
                            }
                            <Text style={{ fontWeight: 'bolder' }}>Product : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Low Stock Level : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.low_stock_point}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Units Available : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.stock}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Cost per Unit : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.cost_price}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Total Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.total_cost}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Price per Unit : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.selling_price}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Total Price : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(i.stock * i.selling_price).toFixed(2)}</span></Text>

                        </Card>
                    </>
                ))
            }

        </>
    )
}

export default LowOfStockMobile