import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, notification, Row, Col, Select, Space, Switch } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from '../../../components/image-upload';
import { getCategory, getCategories, saveCategory, updateCategory } from '../../../data';
import windowSize from 'react-window-size';
import { ajaxService } from '../../../services';
import { GlobalVariable } from '../../../services/ajax-service';

const { Option } = Select;

const CategoryForm = ({ windowWidth }) => {

    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768

    let history = useHistory();
    let { id } = useParams();

    let initialValues = {
        description: '',
        image: '',
        meta_description: '',
        meta_title: '',
        name: '',
        parent_id: ''
    };

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (newFileList[0] !== undefined && newFileList[0].response !== undefined) {
            const file = newFileList[0].response.path;
            form.setFieldsValue({ image: file });
        }
    };

    const onFinish = async (values) => {
        let link = '';

        if (fileList.length > 0) {

            let { response } = fileList[0];

            if (response != undefined) {
                let { success, path } = response;

                if (success) {
                    link = path
                }
            }
            else if (fileList[0].url !== undefined) {
                link = fileList[0].url.replace(GlobalVariable.BASE_IMAGE_URL + '/', "");
            }
        } else {
            link = null;
        }

        let data = {
            description: values.description,
            image: link,
            meta_description: values.meta_description,
            meta_title: values.meta_title,
            name: values.name,
            parent_id: values.parent_id,
            active: values.active,
            sort_order: values.sort_order,
        }

        if (id == 0) {
            const saveData = await saveCategory(data);
            if (saveData) {
                history.push({ pathname: "/categories" });
                notification.open({ message: 'Category has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add category', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            if (data.image === '') {
                data.image = fileList[0].url;
                data.active = values.active
            }
            const updateData = await updateCategory(id, data);
            if (updateData) {
                history.push({ pathname: "/categories" });
                notification.open({ message: 'Category has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update category', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadCategories = useCallback(async () => {
        const data = await getCategories(forceRefresh);
        setCategories(data);
        setForceRefresh(false);
    }, [])

    const loadData = useCallback(async (id) => {
        if (id > 0) {
            let data = await getCategory(id);

            if (data) {
                form.setFieldsValue({ ...data });
                form.setFieldsValue({ parent_id: parseInt(data.parent_id) })

                if (data.image) {
                    let imageItem = {
                        uid: data.image,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(data.image),
                        preview: false
                    }
                    setFileList([imageItem]);
                }
            }
        }
    }, [])

    useEffect(() => {
        loadCategories();
        loadData(id);
    }, []);

    const renderCategoryForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                >
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Category name is required',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="category name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Parent Category" name="parent_id">
                                        <Select allowClear
                                            placeholder="Select Parent Category">
                                            {categories.map(category => {
                                                return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Meta Title" name="meta_title">
                                        <Input placeholder="meta title" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Meta description" name="meta_description">
                                        <Input placeholder="meta description" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='sort_order' label="Sort Order">
                                        <Input placeholder="sort order" type='number' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="active" label={'Active'} valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Upload Multiple Images (Max File Size 1MB) Image" name="file">
                                        <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>

                        <Col span={6}></Col>
                    </Row>
                </Form>

            </>
        );
    }

    const renderCategoryFormMobile = () => {
        return (
            <Form
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Name" name="name" rules={[
                            {
                                required: true,
                                message: 'Category name is required',
                            },
                        ]}>
                            <Input placeholder="category name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Parent Category" name="parent_id">
                            <Select allowClear
                                placeholder="Select Parent Category">
                                {categories.map(category => {
                                    return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Meta Title" name="meta_title">
                            <Input placeholder="meta title" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Meta description" name="meta_description">
                            <Input placeholder="meta description" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='sort_order' label="Sort Order">
                            <Input placeholder="sort order" type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Upload Multiple Images (Max File Size 1MB)" name="file">
                            <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                        </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={12}>
                        <Form.Item name="active" label={'Active'} valuePropName="checked">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderCategoryFormMobile()
        }
        else {
            return renderCategoryForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
};


export default windowSize(CategoryForm);