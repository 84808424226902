import { Button, Card, Modal, notification, Space, Switch, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { deleteStaffManagement, getStaffManagements, updateStaffManagement } from "../../data";
import { PAGINATION_OPTIONS } from "../../constants";
import { updateDataSource } from "../../utility/updateDataSource";
import windowSize from "react-window-size";

const { Title, Text } = Typography;

function StaffManagements({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);
    let history = useHistory()

    const updateValue = async (id, checked, type) => {

        const updatedStaffManagements = updateDataSource(dataSource, id, checked);
        setDataSource(updatedStaffManagements);

        const active = await updateStaffManagement(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            if (checked === true) {
                notification.open({ message: 'Staff activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (checked === false) {
                notification.open({ message: 'Staff deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Employee Code',
            dataIndex: 'employee_code',
            key: 'employee_code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '35%',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Salary',
            dataIndex: 'salary',
            key: 'salary',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (checked, record) => (
                <Switch
                    onChange={(val) => updateValue(record.id, val, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={checked == 1 ? true : false}
                />
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: '/staff-managements/form/' + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            )
        },
    ]

    const deleteRow = async () => {
        let item_id = localStorage.getItem('staff-management')
        const data = await deleteStaffManagement(item_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Staff has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted staff', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('staff-management', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getStaffManagements(forceRefresh);
        if(data){
            setDataSource(data);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Staff Managements</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/staff-managements/form/0' })}>Add Staff</Button>
            </Space>
        </div>
    )

    const renderModal = (
        <Modal
            title="Delete Staff"
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteRow()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this staff?</p>
            </div>
        </Modal>
    )

    const renderStaffManagements = () => (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
        </>
    )

    const renderStaffManagementsMobile = () => (
        <>
            {
                dataSource.map(staff => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Space size='small'>
                                    <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: '/staff-managements/form/' + staff.id })}>Edit</Button>
                                    <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(staff.id)}>Delete</Button>
                                </Space>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>Employee Code : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{staff.employee_code}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{staff.name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Role : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{staff.role}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Salary : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{staff.salary}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Active :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    <Switch
                                        onChange={(val) => updateValue(staff.id, val, 'active')}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={staff.active == 1 ? true : false} />
                                </span>
                            </Text>
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderStaffManagementsMobile()}
                </>
            )
        }
        else {
            return renderStaffManagements()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal}
        </>
    )
}

export default windowSize(StaffManagements)