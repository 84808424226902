import { ajaxService, urlService } from "../services";
import { Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }

export async function getDashboard(id) {
    removeDependency()
    return ajaxService.get(urlService.dashboard + '/' + id).then(response => {
        return Response(response.data);
    })
}