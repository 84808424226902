import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getPages(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.pages, 'pages');
    } else {
        let pages = await loadState('pages');

        if (pages == null) {
            return await loadResource(urlService.pages, 'pages');
        } else {
            return Response(pages);
        }
    }
}

export async function getPage(id) {
    let pages = await loadState('pages');
    if (pages !== null) {
        return pages.find(page => page.id == id);
    } else {
        return ajaxService.get(urlService.pages + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function savePage(data) {
    setDependency()
    return ajaxService.post(urlService.pages, data).then(response => {
        return Response(response.data);
    })
}

export async function updatePage(id, data) {
    setDependency()
    return ajaxService.put(urlService.pages + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deletePage(id) {
    setDependency()
    return ajaxService.delete(urlService.pages + '/' + id).then(response => {
        return Response(response.data);
    })
}