
import { Col, Row, Form, Input, Switch } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const ReceiptFormat = ({ Setting, mobile }) => {

    let data;

    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='display_branch_info' label="Display Branch Info"
                        labelCol={{ span: 24 }}
                        initialValue={data?.display_branch_info}
                    >
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data?.display_branch_info == 1 ? true : false}

                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
export default ReceiptFormat