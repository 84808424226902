import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, message, Modal, notification, Row, Space, Table, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import { showPurchaseGrn, updatePurchaseGrn } from "../../data";
import moment from "moment";
import windowSize from "react-window-size";
import IndustrySettings from "../../constant/industrySettings";

const { Text, Title } = Typography

const PurchaseGrnView = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    let { id } = useParams()
    let history = useHistory()
    const [form] = Form.useForm();
    const store_id = localStorage.getItem('store_id')

    const [items, setItems] = useState([])
    const [publishedGrnitems, setPublishedGrnitems] = useState([])
    const [returnedGrnItems, setReturnedGrnItems] = useState([])

    const [isCheck, setIsCheck] = useState([]);
    const [itemsLimit, setItemsLimit] = useState([]);
    const [purchaseGrn, setPurchaseGrn] = useState([])
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [showModal, setShowModal] = useState(false);

    let format = 'DD-MM-YYYY';

    const historyColumns = [
        {
            title: 'S.No:',
            key: 's.no',
            render: (text, record, index) => (<p>{index + 1}</p>)
        },
        {
            title: 'Items',
            dataIndex: 'product_name',
            key: 'product_name',
            width: '55%'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center'
        },
        {
            title: 'Cost',
            key: 'cost',
            align: 'center',
            render: (text, record) => (<p style={{ textAlign: 'right' }}>{record.cost}</p>)
        },
        {
            title: 'Total Amount',
            key: 'total_amount',
            align: 'center',
            render: (text, record) => (<p style={{ textAlign: 'right' }}>{(record.cost * record.quantity).toFixed(2)}</p>)
        },
        {
            title: 'Date',
            key: 'total_amount',
            align: 'center',
            render: (text, record) => (<p>{moment(record.created_at).format(format)}</p>)
        },
    ]

    const loadItems = async (id) => {
        const data = await showPurchaseGrn(id)
        if (data) {
            setItems(data[0].items)
            setPurchaseGrn(data[0])
            setPublishedGrnitems(data[1])
            setReturnedGrnItems(data[2])
        }
    }

    useEffect(() => {
        loadItems(id)
    }, [])

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(items.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const saveGrnItems = async (values) => {

        let data = {
            grn_number: purchaseGrn.grn_number,
            supplier: purchaseGrn.supplier,
        }

        let combinedData = {
            ...data,
            purchaseGrnItems: values.purchaseGrnItems,
            purchaseGrn: data,
            branch_id: purchaseGrn.branch_id,
            store_id: store_id
        };

        combinedData.purchaseGrnItems.map(i => {
            let quantity = parseInt(i.total_quantity)
            if (quantity > i.quantity) {
                throw new message.error('The quantity you entered is not compatible with the item quantity. Please enter a quantity that is within the acceptable range for the item')
            }
        })

        const response = await updatePurchaseGrn(id, combinedData);
        if (response) {
            setIsCheck('')
            loadItems(id)
            setIsCheckAll(false)
            setShowModal(false)
            history.push({ pathname: "/purchase-grn/view/" + id });
            notification.open({ message: 'Items has been published successfully ', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to change', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openModal = () => {

        let checkedData = []
        let rows = []
        items.map(item => {
            if (isCheck.includes(item.id)) {
                item.total_quantity = item.quantity - (item.return_qty + item.publish_qty);
                checkedData.push(item)
                rows.push(item.total_quantity)
            }
        })

        setItemsLimit(rows)

        form.setFieldsValue({ purchaseGrnItems: checkedData })

        setShowModal(true);
    }

    const renderItemsTable = (
        <Row>
            <Col span={24}>
                <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }}>
                    <Col span={2}>S.No:</Col>
                    <Col span={12}>Items</Col>
                    <Col span={3} style={{ textAlign: 'center' }}>Quantity</Col>
                    <Col span={1} style={{ textAlign: 'center' }}>Cost</Col>
                    <Col span={1}></Col>
                    <Col span={2} style={{ textAlign: 'center' }}>Total Amount</Col>
                    <Col span={1}></Col>

                    <Col span={2}>
                        <Checkbox type="checkbox" name="selectAll" id="selectAll" onChange={handleSelectAll} checked={isCheckAll}>
                            All
                        </Checkbox>
                    </Col>
                </Row>
                {items.map((v, index) => {
                    let quantity = v.quantity - (v.return_qty + v.publish_qty);
                    return (
                        <>
                            <Row style={{ backgroundColor: '#fff', padding: '15px 20px' }}>
                                <Col span={2}>{index + 1}</Col>
                                <Col span={12}>{v.product_name}</Col>
                                <Col span={3} style={{ textAlign: 'center' }}>{quantity}</Col>
                                <Col span={1} style={{ textAlign: 'right' }}>{v.cost}</Col>
                                <Col span={1} ></Col>
                                <Col span={2} style={{ textAlign: 'right' }}>{(v.cost * quantity).toFixed(2)}</Col>
                                <Col span={1} ></Col>
                                <Col span={2}>
                                    <Checkbox key={v.id} id={v.id} onChange={handleClick} checked={isCheck.includes(v.id)}>
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Divider style={{ padding: '0px', margin: '0' }} />
                        </>
                    )
                })}
                <Row style={{ backgroundColor: '#fff', padding: '10px 20px' }}>
                    <Col span={21}></Col>
                    <Space>
                        <Button htmlType="submit" type="primary" onClick={() => { openModal() }}>Publish</Button>
                    </Space>
                </Row>
            </Col>
            <Col span={2}></Col>
        </Row>
    )

    const QuantityValidation = (_, value) => {
        if (value > 0) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Invalid quantity'));
    };

    const renderModal = (
        <Modal
            width={'80%'}
            title={'Publish Items'}
            centered
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" type="primary" onClick={form.submit}>
                    Move to stock
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <Form
                layout={'vertical'}
                onFinish={saveGrnItems}
                form={form}>
                <Row>
                    <Col span={4}>
                        <b> Products Name</b>
                    </Col>

                    <Col span={2} style={{ textAlign: 'center' }}>
                        <b>Cost Price</b>
                    </Col>

                    <Col span={3} style={{ textAlign: 'center' }}>
                        <b>Quantity</b>
                    </Col>

                    <Col span={3} style={{ textAlign: 'center' }}>
                        <b>Retail Price</b>
                    </Col>

                    <Col span={3} style={{ textAlign: 'center' }}>
                        <b>Batch</b>
                    </Col>

                    <Col span={4} style={{ textAlign: 'center' }}>
                        <b>Menufacture Date</b>
                    </Col>

                    <Col span={4} style={{ textAlign: 'center' }}>
                        <b>Expiry Date</b>
                    </Col>

                </Row>
                <br />
                <Form.List name="purchaseGrnItems">
                    {(fields, { add, remove }) => (
                        fields.map(field => (
                            <>
                                <Row>
                                    <Col span={4}>
                                        <Form.Item name={[field.name, 'product_name']}>
                                            <input disabled className="productDropDown" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2} style={{ textAlign: 'right' }}>
                                        <Form.Item name={[field.name, 'cost']}>
                                            {/* <input style={{ width: '70px' }} disabled className="productDropDown" /> */}
                                            <Input type="number" style={{ marginTop: '4px', width: '100%' }} min={1} step='0.01' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={3} style={{ textAlign: 'center' }}>
                                        <Form.Item name={[field.name, 'total_quantity']}
                                            rules={[
                                                {
                                                    validator: QuantityValidation,
                                                }
                                            ]}
                                        >
                                            <Input type="number" style={{ marginTop: '4px', width: '70%' }} min={0} max={itemsLimit[[field.name]]} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={3} style={{ textAlign: 'center' }}>
                                        <Form.Item name={[field.name, 'retail_price']} rules={[{ required: true, message: 'Retail price is required' }]}>
                                            <Input type="number" style={{ marginTop: '4px', width: '70%' }} min={1} step='0.01' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={3} style={{ textAlign: 'center' }}>
                                        <Form.Item name={[field.name, 'batch_no']}>
                                            <Input style={{ marginTop: '4px', width: '70%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <Form.Item name={[field.name, 'manufacture_date']}>
                                            <DatePicker style={{ marginTop: '4px', width: '70%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <Form.Item name={[field.name, 'expiry_date']} rules={[{ required: IndustrySettings.EXPIRYDATE, message: 'Expiry date is required' }]}>
                                            <DatePicker style={{ marginTop: '4px', width: '70%' }} />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </>
                        ))
                    )}
                </Form.List>
            </Form>

        </Modal >
    )

    const renderPublishedGrnItems = (
        <Table
            title={() => <Title level={5}>Published Grn Items</Title>}
            dataSource={publishedGrnitems}
            columns={historyColumns}
            pagination={false}
        />
    )

    const renderReturnedGrnItems = (
        <Table
            title={() => <Title level={5}>Returned Grn Items</Title>}
            dataSource={returnedGrnItems}
            columns={historyColumns}
            pagination={false}
        />
    )

    const renderDetails = (
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div>
                <Title level={3}>Purchase GRN</Title>
                <Text><b>Date : </b></Text>
                <Text>{moment(purchaseGrn.created_at).format(format)}</Text>
                <br />
                <Text><b>Suplier Name : </b> </Text>
                <Text>{purchaseGrn.supplier}</Text>
                <br />
                <Text><b>GRN Number : </b></Text>
                <Text>{purchaseGrn.grn_number || 'N/A'}</Text>
                <br />
                <Text><b>Purchase Order Number : </b> </Text>
                <Text>{purchaseGrn.purchase_order_number|| 'N/A'}</Text>
                <br />
                <Text><b>Branch : </b> </Text>
                <Text>{purchaseGrn.branch_name}</Text>
            </div>
            {
                !isMobile &&
                <div>
                    <Button type="primary" onClick={() => history.push({ pathname: '/purchase-grn' })} icon={<ArrowLeftOutlined />}>Back To Grn</Button>
                </div>
            }
        </div>
    )

    const renderPurchaseGrnView = () => (
        <>
            <Card>
                {renderDetails}
                <br /><br />
                {renderItemsTable}
                {renderPublishedGrnItems}
                <br />
                {renderReturnedGrnItems}
            </Card>
        </>
    )

    const historyData = (data) => (
        <>
            {
                data.map((item, index) => (
                    <Card>
                        <Text style={{ fontWeight: 'bolder' }}>S.No : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{index + 1}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Item : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.product_name}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Quantity : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.quantity}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.cost}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Total Amount  : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(item.cost * item.quantity).toFixed(2)}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Date  : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(item.created_at).format(format)}</span></Text>
                    </Card>
                ))
            }
        </>
    )

    const renderPurchaseGrnViewMobile = () => (
        <>
            <Card>
                {renderDetails}

                {items.map((v, index) => {
                    let quantity = v.quantity - (v.return_qty + v.publish_qty);
                    return (
                        <>
                            <br />
                            <Card title={[
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Text>S.No : {index + 1}</Text>
                                    <Checkbox key={v.id} id={v.id} onChange={handleClick} checked={isCheck.includes(v.id)} />
                                </div>
                            ]}
                                bodyStyle={{ paddingTop: 4 }}
                            >
                                <Text style={{ fontWeight: 'bolder' }}>Item : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.product_name}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Quantity : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{quantity}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.cost}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Total Amount : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(v.cost * quantity).toFixed(2)}</span></Text>
                                <br />
                            </Card>
                        </>
                    )
                })}

                <Button htmlType="submit" type="primary" onClick={() => { openModal() }} style={{ width: "100%", marginTop: 10 }}>Publish</Button>

                <Divider plain>Published Grn Items</Divider>
                {historyData(publishedGrnitems)}

                <Divider plain>Returned Grn Items</Divider>
                {historyData(returnedGrnItems)}
            </Card>
        </>
    )

    const renderModalMobile = (
        <Modal
            width={'100%'}
            title={'Publish Items'}
            centered
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" type="primary" onClick={form.submit}>
                    Move to stock
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <Form
                onFinish={saveGrnItems}
                form={form}>
                <Form.List name="purchaseGrnItems">
                    {(fields, { add, remove }) => (
                        fields.map(field => (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name={[field.name, 'product_name']} label={[<b>Products Name</b>]}>
                                            <input disabled className="productDropDown" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name={[field.name, 'cost']} label={[<b>Cost Price</b>]}>
                                            <input style={{ width: '70px' }} disabled className="productDropDown" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name={[field.name, 'total_quantity']} label={[<b>Quantity</b>]}
                                            rules={[
                                                {
                                                    validator: QuantityValidation,
                                                }
                                            ]}
                                        >
                                            <Input type="number" style={{ marginTop: '4px', width: '70%' }} min={0} max={itemsLimit[[field.name]]} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name={[field.name, 'retail_price']} label={[<b>Retail Price</b>]}>
                                            <Input type="number" style={{ marginTop: '4px', width: '70%' }} min={1} step='0.01' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name={[field.name, 'batch_no']} label={[<b>Batch</b>]}>
                                            <Input style={{ marginTop: '4px', width: '70%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name={[field.name, 'manufacture_date']} label={[<b>Menufacture Date</b>]}>
                                            <DatePicker style={{ marginTop: '4px', width: '70%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item name={[field.name, 'expiry_date']} label={[<b>Expiry Date</b>]}>
                                            <DatePicker style={{ marginTop: '4px', width: '70%' }} />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </>
                        ))
                    )}
                </Form.List>
            </Form>

        </Modal >
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderModalMobile}
                    {renderPurchaseGrnViewMobile()}
                </>
            )
        }
        else {
            return (
                <>
                    {renderModal}
                    {renderPurchaseGrnView()}
                </>
            )

        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(PurchaseGrnView)