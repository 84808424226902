export const PERMISSIONS = {
    CREATE_ACCOUNT: 'create-account-request',
    GET_ALL_ACCOUNTS: 'get-all-accounts-request',
    CREATE_ADDRESS: 'create-address-request',
    DELETE_ADDRESS: 'delete-address-request',
    GET_ADDRESS: 'get-address-request',
    GET_ALL_ADDRESSES: 'get-all-addresses-request',
    UPDATE_ADDRESS: 'update-address-request',
    CREATE_BANNER: 'create-banner-request',
    DELETE_BANNER: 'delete-banner-request',
    GET_ALL_BANNERS: 'get-all-banners-request',
    GET_BANNER: 'get-banner-request',
    UPDATE_BANNER: 'update-banner-request',
    ADMIN_GET_BLOG: 'admin-get-blog-request',
    CREATE_BLOG: 'create-blog-request',
    DELETE_BLOG: 'delete-blog-request',
    GET_ALL_BLOGS: 'get-all-blogs-request',
    GET_BLOG: 'get-blog-request',
    UPDATE_BLOG: 'update-blog-request',
    CREATE_BRANCH: 'create-branch-request',
    DELETE_BRANCH: 'delete-branch-request',
    GET_ALL_BRANCHES: 'get-all-branches-request',
    GET_BRANCH: 'get-branch-request',
    UPDATE_BRANCH: 'update-branch-request',
    CREATE_BRAND: 'create-brand-request',
    DELETE_BRAND: 'delete-brand-request',
    GET_ALL_BRANDS: 'get-all-brands-request',
    GET_BRAND: 'get-brand-request',
    GET_BRANDS_FOR_BRANCH: 'get-brands-for-branch-request',
    UPDATE_BRAND: 'update-brand-request',
    CREATE_CASH_REGISTER_ENTRY: 'create-cash-register-entry-request',
    CREATE_CASH_REGISTER: 'create-cash-register-request',
    GET_ALL_CASH_REGISTER_ENTRIES: 'get-all-cash-register-entries-request',
    GET_ALL_CASH_REGISTERS: 'get-all-cash-registers-request',
    CREATE_CATEGORY: 'create-category-request',
    DELETE_CATEGORY: 'delete-category-request',
    GET_ACTIVE_CATEGORIES: 'get-active-categories-request',
    GET_ALL_CATEGORIES: 'get-all-categories-request',
    GET_CATEGORIES_FOR_BRANCH: 'get-categories-for-branch-request',
    GET_CATEGORY: 'get-category-request',
    UPDATE_CATEGORY: 'update-category-request',
    CREATE_CUISINE: 'create-cuisine-request',
    DELETE_CUISINE: 'delete-cuisine-request',
    GET_ALL_CUISINES: 'get-all-cuisines-request',
    GET_CUISINE: 'get-cuisine-request',
    UPDATE_CUISINE: 'update-cuisine-request',
    CREATE_CUSTOMER: 'create-customer-request',
    GET_ALL_CUSTOMERS: 'get-all-customers-request',
    GET_CUSTOMER: 'get-customer-request',
    UPDATE_CUSTOMER: 'update-customer-request',
    STORE_DASHBOARD: 'store-dashboard-request',
    SUPER_ADMIN_DASHBOARD_CHART_FILTER: 'super-admin-dashboard-chart-filter-request',
    SUPER_ADMIN_DASHBOARD: 'super-admin-dashboard-request',
    GET_ALL_FILTER: 'get-all-filter-request',
    GET_FILTER: 'get-filter-request',
    CREATE_LEDGER: 'create-ledger-request',
    GET_ALL_LEDGER: 'get-all-ledger-request',
    CREATE_NEWSLETTER: 'create-newsletter-request',
    DELETE_NEWSLETTER: 'delete-newsletter-request',
    GET_ALL_NEWSLETTERS: 'get-all-newsletters-request',
    GET_NEWSLETTER: 'get-newsletter-request',
    SEND_NEWSLETTER_EMAIL: 'send-newsletter-email-request',
    UPDATE_NEWSLETTER: 'update-newsletter-request',
    CREATE_OFFER: 'create-offer-request',
    DELETE_OFFER: 'delete-offer-request',
    GET_ALL_OFFERS: 'get-all-offers-request',
    GET_OFFER: 'get-offer-request',
    UPDATE_OFFER: 'update-offer-request',
    CREATE_ORDER: 'create-order-request',
    DELETE_ORDER: 'delete-order-request',
    GET_ALL_ORDERS: 'get-all-orders-request',
    GET_ORDER_FILTER: 'get-order-filter-request',
    GET_ORDER: 'get-order-request',
    GET_USER_ORDERS: 'get-user-orders-request',
    PLACE_ORDER: 'place-order-request',
    SALE_RETURN: 'sale-return-request',
    UPDATE_ORDER: 'update-order-request',
    CREATE_PACKAGE: 'create-package-request',
    DELETE_PACKAGE: 'delete-package-request',
    GET_ALL_PACKAGES: 'get-all-packages-request',
    GET_PACKAGE: 'get-package-request',
    UPDATE_PACKAGE: 'update-package-request',
    CREATE_PAGE: 'create-page-request',
    DELETE_PAGE: 'delete-page-request',
    GET_ALL_PAGES: 'get-all-pages-request',
    GET_PAGE: 'get-page-request',
    UPDATE_PAGE: 'update-page-request',
    CREATE_PAYMENT_METHOD: 'create-paymentmethod-request',
    DELETE_PAYMENT_METHOD: 'delete-paymentmethod-request',
    GET_ALL_PAYMENT_METHODS: 'get-all-paymentmethods-request',
    GET_PAYMENT_METHOD: 'get-paymentmethod-request',
    UPDATE_PAYMENT_METHOD: 'update-paymentmethod-request',
    CREATE_PERMISSION: 'create-permission-request',
    DELETE_PERMISSION: 'delete-permission-request',
    GET_ALL_PERMISSIONS: 'get-all-permissions-request',
    GET_PERMISSION: 'get-permission-request',
    UPDATE_PERMISSION: 'update-permission-request',
    CREATE_PRODUCT: 'create-product-request',
    DELETE_PRODUCT: 'delete-product-request',
    GET_ALL_PRODUCTS: 'get-all-products-request',
    GET_FILTER_PRODUCT: 'get-filter-product-request',
    GET_PRODUCT: 'get-product-request',
    GET_PRODUCTS_FOR_BRANCH: 'get-products-for-branch-request',
    UPDATE_PRODUCT: 'update-product-request',
    GET_ALL_PRODUCT_STOCKS: 'get-all-product-stocks-request',
    GET_FILTER_PRODUCT_STOCK: 'get-filter-product-stock-request',
    GET_PROFILE: 'get-profile-request',
    UPDATE_PROFILE: 'update-profile-request',
    CREATE_PURCHASE_GRN: 'create-purchase-grn-request',
    GET_ALL_PURCHASE_GRN: 'get-all-purchase-grn-request',
    GET_PUBLISH_PRODUCT: 'get-publish-product-request',
    GET_PURCHASE_GRN: 'get-purchase-grn-request',
    UPDATE_PURCHASE_GRN: 'update-purchase-grn-request',
    CREATE_PURCHASE_ORDER: 'create-purchase-order-request',
    DELETE_PURCHASE_ORDER: 'delete-purchase-order-request',
    GET_ALL_PURCHASE_ORDERS: 'get-all-purchase-orders-request',
    GET_PURCHASE_ORDER: 'get-purchase-order-request',
    UPDATE_PURCHASE_ORDER: 'update-purchase-order-request',
    DAMAGE_DISCARD: 'damage-discard-request',
    DETAILED_INVENTORY: 'detailed-inventory-request',
    GET_FILTER_DAMAGE_DISCARD: 'get-filter-damage-discard-request',
    GET_FILTER_DETAILED_INVENTORY: 'get-filter-detailed-inventory-request',
    GET_FILTER_OUT_OF_STOCK: 'get-filter-out-of-stock-request',
    GET_FILTER_PRODUCT_INVENTORY: 'get-filter-product-inventory-request',
    OUT_OF_STOCK: 'out-of-stock-request',
    PRODUCT_INVENTORY: 'product-inventory-request',
    PROFIT_LOSS_STATEMENTS: 'profit-loss-statements-request',
    IMAGE_UPLOAD: 'image-upload-request',
    CREATE_ROLE: 'create-role-request',
    DELETE_ROLE: 'delete-role-request',
    GET_ALL_ROLES: 'get-all-roles-request',
    GET_ROLE: 'get-role-request',
    SAVE_PERMISSIONS: 'save-permissions-request',
    UPDATE_ROLE: 'update-role-request',
    CREATE_PARK_SALE: 'create-park-sale-request',
    GET_ALL_PARK_SALES: 'get-all-park-sales-request',
    GET_CASH_REGISTER_ORDERS: 'get-cash-register-orders-request',
    PLACE_ORDER: 'place-order-request',
    UPDATE_PARK_SALE: 'update-park-sale-request',
    CREATE_SETTING: 'create-setting-request',
    DELETE_SETTING: 'delete-setting-request',
    GET_ALL_SETTING: 'get-all-setting-request',
    GET_SETTING: 'get-setting-request',
    UPDATE_SETTING: 'update-setting-request',
    CREATE_SHIPPING_METHOD: 'create-shipping-method-request',
    DELETE_SHIPPING_METHOD: 'delete-shipping-method-request',
    GET_ALL_SHIPPING_METHODS: 'get-all-shipping-methods-request',
    GET_SHIPPING_METHOD: 'get-shipping-method-request',
    UPDATE_SHIPPING_METHOD: 'update-shipping-method-request',
    UPDATE_STATUS: 'update-status-request',
    CREATE_STOCK_TRANSFER: 'create-stock-transfer-request',
    GET_ALL_STOCK_TRANSFERS: 'get-all-stock-transfers-request',
    GET_STOCK_TRANSFER: 'get-stock-transfer-request',
    UPDATE_STOCK_TRANSFER: 'update-stock-transfer-request',
    CREATE_STORE_PERMISSIONS: 'create-store-permissions-request',
    CREATE_STORE: 'create-store-request',
    DELETE_STORE: 'delete-store-request',
    GET_ALL_STORES: 'get-all-stores-request',
    GET_STORE_PERMISSIONS: 'get-store-permissions-request',
    GET_STORE: 'get-store-request',
    UPDATE_STORE: 'update-store-request',
    GET_ALL_STORE_SETTING: 'get-all-store-setting-request',
    GET_FILTER_STORE_SETTINGS: 'get-filter-store-settings-request',
    GET_STORE_SETTING: 'get-store-setting-request',
    UPDATE_STORE_SETTING: 'update-store-setting-request',
    CREATE_SUPPLIER: 'create-supplier-request',
    DELETE_SUPPLIER: 'delete-supplier-request',
    GET_ALL_SUPPLIERS: 'get-all-suppliers-request',
    GET_SUPPLIER: 'get-supplier-request',
    UPDATE_SUPPLIER: 'update-supplier-request',
    CREATE_TABLE_MANAGEMENT: 'create-table-management-request',
    DELETE_TABLE_MANAGEMENT: 'delete-table-management-request',
    GET_ALL_TABLE_MANAGEMENTS: 'get-all-table-managements-request',
    GET_TABLE_MANAGEMENT: 'get-table-management-request',
    UPDATE_TABLE_MANAGEMENT: 'update-table-management-request',
    CREATE_USER: 'create-user-request',
    DELETE_USER: 'delete-user-request',
    GET_ALL_USERS: 'get-all-users-request',
    GET_FILTER_USER: 'get-filter-user-request',
    GET_USER: 'get-user-request',
    UPDATE_USER: 'update-user-request',
    GET_ACTIVE_CATEGORIES: 'get-active-categories-request',
    GET_HOME_DATA: 'get-home-data-request',
    GET_PRODUCTS: 'get-products-request',
    GET_SINGLE_PRODUCT: 'get-single-product-request',
    SEARCH_PRODUCT: 'search-product-request',
    CREATE_WISHLIST: 'create-wishlist-request',
    DELETE_WISHLIST: 'delete-wishlist-request',
    GET_ALL_WISHLISTS: 'get-all-wishlists-request',
    GET_ALL_STAFF_MANAGEMENTS: 'get-all-staff-managements-request',
    GET_STAFF_MANAGEMENTS: 'get-staff-management-request',
    CREATE_STAFF_MANAGEMENTS: 'create-staff-management-request',
    UPDATE_STAFF_MANAGEMENTS: 'update-staff-management-request',
    DELETE_STAFF_MANAGEMENTS: 'delete-staff-management-request',
    VIEW_PRICE_BOOK: 'view-price-book',
    VIEW_STOCK_UPDATE: 'view-stock-update',
    VIEW_PRODUCT_LEDGER: 'view-product-ledger',
    GET_ALL_DAMAGE_DISCARD_PRODUCT: 'get-all-damage-discard-product-request',
    CREATE_DAMAGE_DISCARD_PRODUCT: 'create-damage-discard-product-request',
    UPDATE_DAMAGE_DISCARD_PRODUCT: 'update-damage-discard-product-request',
    INCOME_EXPENSE_REPORT: 'income-expense-request',
    GET_FILTER_INCOME_EXPENSE: 'get-filter-income-expense-request',
    PRODUCTS_EXPIRY_REPORT: 'products-expiry-request',
    GET_FILTER_PRODUCTS_EXPIRY: 'get-filter-products-expiry-request',
    CUSTOMIZE_SALES: 'customize-sales-request',
    GET_All_RECONCILIATIONS: 'get-all-reconciliations-request',
    GET_RECONCILIATION: 'get-reconciliation-request',
    CREATE_RECONCILIATION:'create-reconciliation-request'
};