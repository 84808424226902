import React, { useState, useEffect } from 'react';
import { Table, Card } from 'antd';
import { PAGINATION_OPTIONS } from '../../constants';
import { filterProductInventoryReports, getProductInventoryReports } from '../../data';
import Summary from './layout/summary';
import Search from './layout/search';
import windowSize from 'react-window-size';
import ProductInventoryMobile from './mobile_screen/productInventoryMobile';

function ProductInventoryReport({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalUnits, setTotalUnits] = useState(0);
    const [totalInventory, setTotalInventory] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const handleSearch = async (data) => {
        const response = await filterProductInventoryReports(data)
        if (response) {
            setDataSource(response)
        }
    }

    const initialize = async () => {
        let response = await getProductInventoryReports();

        if (response) {
            setDataSource(response)
            setTotalProducts(response.length)
            setTotalUnits(response.reduce((total, i) => total + parseInt(i.stock || 0), 0))
            setTotalInventory((response.map(entry => entry.stock * entry.cost_price).reduce((total, cost_price) => total + cost_price, 0)).toFixed(2));
            setTotalPrice((response.map(entry => entry.stock * entry.selling_price).reduce((total, selling_price) => total + selling_price, 0)).toFixed(2));
        }
    }

    const columns = [
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'product_name',

        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            render: (text) => text ? text : null,
        },
        {
            title: 'Units Available',
            dataIndex: 'stock',
            key: 'stock',
            align: 'center'
        },
        {
            title: 'Cost per Unit',
            dataIndex: 'cost_price',
            key: 'cost_price',
            align: 'center',
            render: (text) => <div style={{ textAlign: 'right' }}>{text}</div>,
        },
        {
            title: 'Total Cost',
            key: 'total_cost',
            align: 'center',
            render: (text, record) => <div style={{ textAlign: 'right' }}>{(record.stock * record.cost_price).toFixed(2)}</div>,
        },
        {
            title: 'Price per Unit',
            dataIndex: 'selling_price',
            key: 'selling_price',
            align: 'center',
            render: (text) => <div style={{ textAlign: 'right' }}>{text}</div>,
        },
        {
            title: 'Total Price',
            key: 'total_price',
            align: 'center',
            render: (text, record) => <div style={{ textAlign: 'right' }}>{(record.stock * record.selling_price).toFixed(2)}</div>,
        },

    ];

    const hasBranchName = dataSource && dataSource.length > 0 && dataSource[0].hasOwnProperty('branch_name');
    // Remove the 'Branch' column from the 'columns' array if 'branch_name' key is not present
    if (!hasBranchName) {
        columns.splice(1, 1);
    }

    useEffect(() => {
        initialize()
    }, [])

    const summary = (
        <Summary
            title='Product Inventory'
            totalProducts={totalProducts}
            totalUnits={totalUnits}
            totalInventory={totalInventory}
            totalPrice={totalPrice}
            mobile={isMobile}
        />
    )

    const renderProductInventoryReport = () => (
        <>
            <Search handleSearch={handleSearch} hasBranch={hasBranchName} hasProduct={true} />
            <Card>
                {summary}
                <br />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ ...PAGINATION_OPTIONS }}
                    rowKey="index"
                />
            </Card>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return <ProductInventoryMobile summary={summary} hasBranch={hasBranchName} />
            // return 'ads'

        } else {
            return renderProductInventoryReport()
        }
    }

    return renderContent()


}

export default windowSize(ProductInventoryReport)