import { Avatar, Button, Card, Col, Form, Image, Input, notification, Row, Select, Space, Switch, Table, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { CheckOutlined, CloseOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { updateValue } from './updateValue';
import { getBranches, getBrands, getCategories, getProducts, getStoreSetting } from '../../../data';
import windowSize from 'react-window-size';
import { ajaxService } from '../../../services';
import ControlSettings from '../../../constant/controlSettings';
import { PAGINATION_OPTIONS } from '../../../constants';
import dataService from '../../../services/data-service';

const { Option } = Select;
const { Text } = Typography;

const defaultColumns = [
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        width: '10%',
        render: (image) => <Avatar shape="square" size={64} src={ajaxService.getImage(image)} />

    },
    {
        title: 'Name / SKU',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (<>
            <span>{record.name}</span>
            <br />
            <span>{record.sku}</span>
        </>
        )
    },
    {
        title: 'Selling Price',
        dataIndex: 'selling_price',
        key: 'selling_price',
        render: (text, record) => (
            <Input onChange={(val) => updateValue(record.id, val, 'selling_price', 'Selling Price')} type='number' defaultValue={record.selling_price} placeholder={'0'} />
        )
    },
    {
        title: 'Compare Price',
        dataIndex: 'compare_price',
        key: 'compare_price',
        render: (text, record) => (
            <Input onChange={(val) => updateValue(record.id, val, 'compare_price', 'Compare Price')} type='number' defaultValue={record.compare_price} placeholder={'0'} />
        )
    }
]

function StockUpdate({ windowWidth, innerRef }) {

    const [branchCatalogToggle, setBranchCatalogToggle] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [categories, setCategories] = useState([]);
    const [branches, setBranches] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [brandFilter, setBrandFilter] = useState(0);
    const [branchFilter, setBranchFilter] = useState(0);
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768;
    const [columns, setColumns] = useState(defaultColumns)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGINATION_OPTIONS.pageSize);
    const [totalRecords, setTotalRecords] = useState(PAGINATION_OPTIONS.total);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const handlePagination = (pagination, filters, sorter) => {

        if (pagination.current !== currentPage) {
            setCurrentPage(pagination.current)
        }

        if (pagination.pageSize !== pageSize) {
            setCurrentPage(PAGINATION_OPTIONS.current) // reset current page when page size changes
            setPageSize(pagination.pageSize)
        }
    };

    const handleSearch = async () => {

        const inputValue = inputRef.current.value;

        setLoading(true);

        let response = null;

        let params = { per_page: pageSize, page: currentPage, keyword: inputValue };

        if (categoryFilter && categoryFilter > 0) {
            params['category_id'] = categoryFilter
        }
        console.log(brandFilter)
        if (brandFilter && brandFilter > 0) {
            params['brand_id'] = brandFilter
        }

        if (branchFilter && branchFilter > 0) {
            params['branch_id'] = branchFilter
        }

        response = await dataService.getPricebookProducts(params);
        setLoading(false);

        let { data, total } = response;

        if (total === 0) {
            notification.error({ message: 'Search Results', description: 'No records found.', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            setTotalRecords(0)
            setDataSource([]);
        } else {
            setTotalRecords(total)
            setDataSource(data);
        }
    }

    useEffect(() => {
        handleSearch();
    }, [currentPage, pageSize])

    const clear = () => {
        setCategoryFilter(0)
        setBrandFilter(0)
        setBranchFilter(0)
        inputRef.current.value = ''
        handleSearch();
    }

    const loadCategories = async () => {
        const categories = await getCategories(forceRefresh);
        if (categories) {
            setCategories(categories);
            setForceRefresh(false)
        }
    }

    const loadBrands = async () => {
        const brands = await getBrands(forceRefresh);
        if (brands) {
            setBrands(brands);
        }
    }

    const branchCatalogSetting = async () => {
        const response = await getStoreSetting(ControlSettings.BRANCH_CATALOG);
        if (response) {
            setBranchCatalogToggle(response.active)
        }
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh);
        if (response) {
            setBranches(response)
            setForceRefresh(false);
        }
    }

    const checkInventoryType = async () => {
        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);

        if (!response.active) {
            let columns = [
                ...defaultColumns,
                {
                    title: 'Stock',
                    dataIndex: 'stock',
                    key: 'stock',
                    render: (text, record) => (
                        <Input onChange={(val) => updateValue(record.id, val, 'stock', 'Stock')} type='number' defaultValue={record.stock} placeholder={'0'} />
                    )
                },
                {
                    title: 'Cost Price',
                    dataIndex: 'cost_price',
                    key: 'cost_price',
                    render: (text, record) => (
                        <Input onChange={(val) => updateValue(record.id, val, 'cost_price', 'Cost Price')} type='number' defaultValue={record.cost_price} placeholder={'0'} />
                    )
                }
            ]

            setColumns(columns);
        }
    }

    useEffect(() => {

        // loadBranches();
        loadCategories();
        loadBrands();
        branchCatalogSetting();
        checkInventoryType();
    }, []);

    const renderFilter = () => (
        <Form

        // onFinish={}
        >
            <Row gutter={16} >
                <Col span={24}>
                    <Form.Item label="Product / SKU" >
                        <input placeholder='Search by product name or sku' ref={inputRef} className='ant-input' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Category" >
                        <Select allowClear
                            value={categoryFilter === '' ? 'Select Category' : categoryFilter}
                            onChange={(e) => setCategoryFilter(e)}>
                            {categories.map(category => {
                                return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Brand" >
                        <Select placeholder="Select Brand" allowClear
                            value={brandFilter === '' ? 'Select Brand' : brandFilter}
                            onChange={(e) => setBrandFilter(e)}>
                            {brands.map(brand => {
                                return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} className="gutter-row">
                    <Form.Item>
                        <Space>
                            <Button type='primary' onClick={handleSearch}>Search</Button>
                            <Button onClick={clear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="primary" onClick={handleSearch}>Refresh</Button>
        </div>
    );

    const renderPriceBook = () => (
        <>
            {/* Filter */}
            <Form layout={'vertical'}>
                <Row gutter={16} >
                    <Col span={6}>
                        <Form.Item label="Product / SKU" >
                            <input placeholder='Search by product name or sku' ref={inputRef} className='ant-input' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Category" initialValue="Select Category" >
                            <Select allowClear
                                value={categoryFilter === 0 ? 'Select Category' : categoryFilter}
                                onChange={(e) => setCategoryFilter(e)}>
                                {categories.map(category => (
                                    <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Brand" initialValue="Select Brand">
                            <Select placeholder="Select Brand" allowClear
                                value={brandFilter === 0 ? 'Select Brand' : brandFilter}
                                onChange={(e) => setBrandFilter(e)}>
                                {brands.map(brand => (
                                    <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item label='&nbsp;'>
                            <Space>
                                <Button onClick={handleSearch}>Search</Button>
                                <Button onClick={clear}>Clear</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                loading={loading}
                rowKey={record => record.id}
                pagination={{
                    ...PAGINATION_OPTIONS,
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalRecords,
                }}
                onChange={handlePagination}
            />
        </>
    );

    const renderPriceBookMobile = () => {
        return (
            <>
                {renderFilter()}
                {
                    dataSource.map(priceBook => {
                        return (
                            <>
                                <Card>
                                    <Image.PreviewGroup>
                                        <Image width={250} height={150} sizes="small" src={ajaxService.getImage(priceBook.image)} />
                                    </Image.PreviewGroup>
                                    <br />
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Name / SKU :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{priceBook.name + ' / ' + priceBook.sku}</span>
                                    </Text>
                                    <br />
                                    <br />
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label><b>Cost Price :</b></label>
                                            <Input onChange={(val) => updateValue(priceBook.id, val, 'cost_price', 'Cost Price')}
                                                type='number' defaultValue={priceBook.cost_price} placeholder={'0'} />
                                        </Col>
                                        <Col span={24}>
                                            <br />
                                            <label><b>Selling Price :</b></label>
                                            <Input onChange={(val) => updateValue(priceBook.id, val, 'selling_price', 'Selling Price')}
                                                type='number' defaultValue={priceBook.selling_price} placeholder={'0'} />
                                        </Col>
                                        <Col span={24}>
                                            <br />
                                            <label><b>Compare Price :</b></label>
                                            <Input onChange={(val) => updateValue(priceBook.id, val, 'compare_price', 'Compare Price')}
                                                type='number' defaultValue={priceBook.compare_price} placeholder={'0'} />
                                        </Col>
                                        {/* {
                                            !branchCatalogToggle &&
                                            <Col span={24}>
                                                <br />
                                                <label><b>Stock :</b></label>
                                                <Input onChange={(val) => updateValue(priceBook.id, val, 'stock', 'Stock')}
                                                    type='number' defaultValue={priceBook.stock} placeholder={'0'} />
                                            </Col>
                                        } */}
                                    </Row>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Active :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                            <Switch
                                                onChange={(val) => updateValue(priceBook.id, val, 'active', 'Active')}
                                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                                defaultChecked={priceBook.active == 1 ? true : false}
                                            />
                                        </span>
                                    </Text>
                                </Card>
                                <br />
                            </>
                        )
                    })
                }
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderPriceBookMobile()
        }
        else {
            return renderPriceBook()
        }
    }

    return (
        <div ref={innerRef} >
            {isMobile && renderHeader()}
            {renderContent()}
        </div>
    )

}

export default windowSize(StockUpdate)

/*
import { useEffect, useState } from "react";
import { Col, Row, Typography, Form, Input, Button, notification, Card, Divider } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getBranches, getProducts, updateStoreSetting } from "../../../data";
import windowSize from "react-window-size";

const { Text, Title } = Typography

function StockUpdate({ windowWidth }) {

    const isMobile = windowWidth <= 768
    const [form] = Form.useForm();
    const [branches, setBranches] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)

    const handleUpdate = async (index) => {
        const rowData = form.getFieldValue(['productBranches']);
        const response = await updateStoreSetting(index, { type: 'product', values: rowData })
        if (response) {
            setForceRefresh(true)
            notification.open({ message: 'Quantity has been updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to update quantity', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    };

    const loadBranches = async () => {
        const data = await getBranches(forceRefresh);
        if (data) {
            setBranches(data);
            return data;
        }
    }

    const loadProducts = async () => {
        const response = await getProducts(forceRefresh);
        if (response) {
            let data = response.filter(i => i.active && !i.is_combo).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
            setForceRefresh(false)
            return data;
        }
    }

    const initialize = async () => {
        let rows = [];

        let [p, b] = await Promise.all([loadProducts(), loadBranches()]);

        p.map(product => {
            let item = { product_id: product.id, product_name: product.name, branches: [], update: false };

            b.map(row => {
                let matchingBranch = product.branch_products.find(i => i.branch_id === row.id);
                let branchData = matchingBranch ? { branch_id: row.id, quantity: matchingBranch.quantity == 0 ? null : matchingBranch.quantity } : { branch_id: row.id, quantity: null };
                item['branches'].push({ ...branchData });
            })

            rows.push(item);
        })
        form.setFieldsValue({ productBranches: rows });
    }

    useEffect(() => {
        initialize();
    }, []);

    const renderHeader = (
        <Title level={3}>Stock Update</Title>
    )

    const renderStockUpdate = () => (
        <>
            <Card>
                {renderHeader}


                <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px', marginTop: 10 }}>
                    <Col span={6}>Name</Col>
                    {
                        branches.map((branch, i) => (
                            <>
                                <Col span={4}>
                                    <Text style={{ textTransform: 'capitalize' }}>
                                        {branch.name}
                                    </Text>
                                </Col>
                                <Col span={1}></Col>
                            </>
                        ))
                    }
                </Row>

                <Form form={form}>
                    <Form.List name="productBranches">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Row gutter={16} key={field.key} style={{ padding: '15px 20px' }}>
                                        <Col key={field.name} span={6}>
                                            <Form.Item name={[field.name, 'product_name']}>
                                                <h4 style={{ marginTop: 15 }}>{form.getFieldValue(['productBranches', index, 'product_name'])}</h4>
                                            </Form.Item>
                                        </Col>
                                        <Form.List name={[field.name, 'branches']}>
                                            {(subFields, { add, remove }) => (
                                                <>
                                                    {subFields.map((subField, subIndex) => (
                                                        <>
                                                            <Col span={3} key={subField.key}>
                                                                <Form.Item
                                                                    name={[subField.name, 'quantity']}
                                                                    label="Quantity" labelCol={{ span: 24 }}
                                                                >
                                                                    <Input type="number" placeholder="0" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2}></Col>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                                        <Col span={1} style={{ textAlign: 'center' }}>
                                            <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
                                                <Button type="primary" onClick={() => handleUpdate(index)}>Update</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form>
            </Card>
        </>
    )

    const renderStockUpdateMobile = () => (
        <>

            <Form form={form}>
                <Form.List name="productBranches">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Card>
                                    <Text style={{ fontWeight: 'bolder' }}>Name :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{form.getFieldValue(['productBranches', index, 'product_name'])}</span>
                                    </Text>

                                    <Row gutter={16} key={field.key} style={{ padding: '15px 20px' }}>
                                        <Form.List name={[field.name, 'branches']}>
                                            {(subFields, { add, remove }) => (
                                                <>
                                                    {subFields.map((subField, subIndex) => (
                                                        <>
                                                            <Divider plain style={{ textTransform: 'capitalize' }}>{branches[subIndex].name}</Divider>

                                                            <Col span={24} key={subField.key}>
                                                                <Form.Item
                                                                    name={[subField.name, 'quantity']}
                                                                    label="Quantity" labelCol={{ span: 24 }}
                                                                >
                                                                    <Input type="number" placeholder="0" />
                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                                        <Col span={1} style={{ textAlign: 'center' }}>
                                            <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
                                                <Button type="primary" onClick={() => handleUpdate(index)}>Update</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Card>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader}
                    {renderStockUpdateMobile()}
                </>
            )
        }
        else {
            return renderStockUpdate()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(StockUpdate)
*/