import React, { useEffect, useState, } from 'react';
import { Form, Input, Button, notification, Row, Col, Space, Select, Divider, Switch, Typography } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { getBranches, getRoles, getUser, getUsers, saveUser, updateUser } from '../../data';
import windowSize from 'react-window-size';
import { Limits } from '../../constant';

const { Option } = Select
const { Text } = Typography

const UserForm = ({ windowWidth }) => {

  const isMobile = windowWidth <= 768
  let history = useHistory();
  const [form] = Form.useForm();
  let { id } = useParams();
  const [roles, setRoles] = useState([])
  const [forceRefresh, setForceRefresh] = useState(true)
  const [passwordField, setPasswordField] = useState([])
  const [branches, setBranches] = useState([])
  const [storeRestriction, setStoreRestriction] = useState([]);
  const [users, setUsers] = useState([]);

  const onFinish = async (values) => {
    if (id == 0) {
      const saveData = await saveUser(values)
      if (saveData) {
        history.push({ pathname: "/users" });
        notification.open({ message: 'User has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      } else {
        notification.open({ message: 'Unable to add user', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      }
    }
    else {
      const updateData = await updateUser(id, values)
      if (updateData) {
        history.push({ pathname: '/users' });
        notification.open({ message: 'User has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      } else {
        notification.open({ message: 'Unable to update user', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      }
    }
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  };

  const loadRoles = async () => {
    const data = await getRoles(forceRefresh);
    if (data) {
      setRoles(data)
      setForceRefresh(false)
    }

  }

  const loadBranches = async () => {
    const response = await getBranches(forceRefresh)
    if (response) {
      setBranches(response)
      setForceRefresh(false)
    }
  }

  const loadUsers = async () => {
    const response = await getUsers(forceRefresh);

    if (response) {

      const store = response[0].user_store

      if (store && store.store_restrictions) {
        setStoreRestriction(store.store_restrictions);
      } else {
        setStoreRestriction([]);
      }
      setUsers(response);
      setForceRefresh(false);
    }
  }

  const loadData = async (id) => {
    if (id > 0) {
      const data = await getUser(id)
      if (data) {
        const userBranches = (data.user_branches).map(i => i.branch_id)
        form.setFieldsValue({
          ...data,
          branches: userBranches,
          role_id: data.roles.length > 0 ? parseInt(data.roles[0].id) : null,
        })
      }
    }

  }

  useEffect(() => {
    loadBranches()
    loadRoles();
    loadData(id);
    loadUsers()
  }, []);

  const renderButtons = () => {
    if (id > 0 || (!storeRestriction || storeRestriction.find(i => i.type === Limits.USER_LIMITS)?.value !== users.length)) {
      return (
        <Space>
          <Button htmlType="submit" type="primary">Submit</Button>
          <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
        </Space>
      )
    } else {
      return <Text style={{ color: 'red' }}>{Limits.MESSAGE}</Text>
    }
  }

  const renderUserForm = () => {
    return (
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={{
          verified: true
        }}
      >
        <Row gutter={16} >
          <Col span={6}></Col>
          <Col span={12}>
            <Row gutter={16}>
              <Divider plain> Basic Information </Divider>
              <Col span={12}>
                <Form.Item name='name' label="Name" rules={[
                  {
                    required: true,
                    message: 'Name  is required',
                  },
                ]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='phone' label="Phone Number" rules={[{ required: true, message: 'Phone Number is required' }]}>
                  <Input placeholder="Phone Number" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='gender' label="Gender" rules={[{ required: true, message: 'Gender is required' }]} >
                  <Select placeholder={'Select Gender'}>
                    <Option value={'Male'} key={'male'}>Male</Option>
                    <Option value={'Female'} key={'female'}>Female</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='city' label="City" rules={[{ required: true, message: 'City is required' }]} >
                  <Input placeholder='City' />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='role_id' label="Role" rules={[{ required: true, message: 'Role is required' }]} >
                  <Select
                    allowClear
                    placeholder="Select Role"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {roles && roles.map(role => {
                      return <Option value={role.id} key={"role-" + role.id} >{role.title}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='branches' label="Branches" rules={[{ required: true, message: 'Branches is required' }]} >
                  <Select allowClear mode="multiple" placeholder="Select Branches"
                    style={{
                      width: '100%',
                    }}>
                    {
                      branches.map(({ id, name }) => (
                        <Option value={id} key={id}>{name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>

              <Divider plain>Account Details</Divider>
              <Col span={12}>
                <Form.Item name='email' label="Email" rules={[
                  {
                    required: true,
                    message: 'Please enter a valid email address',
                    type: 'email'
                  },
                ]}>
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='password' label='Password'
                  rules={[
                    id == 0 &&
                    {
                      required: true,
                      message: 'Password is required',
                    },
                    {
                      min: 8,
                      message: 'Password must be at least 8 characters'
                    }
                  ]}>
                  <Input.Password placeholder='Password' onChange={e => setPasswordField(e.target.value)} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='confirm_password' label='Confirm Password'
                  rules={[
                    passwordField.length > 0 &&
                    {
                      required: true,
                      message: 'Confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Confirm password does not match to password"));
                      },
                    }),
                  ]}>
                  <Input.Password placeholder='Confirm Password' disabled={passwordField.length > 0 ? false : true} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="verified" label="Email Verified" valuePropName="checked">
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row>
              <Form.Item>
                {renderButtons()}
              </Form.Item>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row >
      </Form >
    );
  }

  const renderUserFormMobile = () => {
    return (
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={{
          verified: true
        }}
      >
        <Row gutter={16} >
          <Divider plain> Basic Information </Divider>
          <Col span={24}>
            <Form.Item name='name' label="Name" rules={[
              {
                required: true,
                message: 'Name  is required',
              },
            ]}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='phone' label="Phone Number" rules={[{ required: true, message: 'Phone Number is required' }]}>
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='gender' label="Gender" rules={[{ required: true, message: 'Gender is required' }]} >
              <Select placeholder={'Select Gender'}>
                <Option value={'Male'} key={'male'}>Male</Option>
                <Option value={'Female'} key={'female'}>Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='city' label="City" rules={[{ required: true, message: 'City is required' }]} >
              <Input placeholder='City' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='role_id' label="Role" rules={[{ required: true, message: 'Role is required' }]} >
              <Select
                allowClear
                placeholder="Select Role"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {roles && roles.map(role => {
                  return <Option value={role.id} key={"role-" + role.id} >{role.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='branches' label="Branches" rules={[{ required: true, message: 'Gender is required' }]} >
              <Select allowClear mode="multiple" placeholder="Select Branches"
                style={{
                  width: '100%',
                }}>
                {
                  branches.map(({ id, name }) => (
                    <Option value={id} key={id}>{name}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Divider plain>Account Details</Divider>
          <Col span={24}>
            <Form.Item name='email' label="Email" rules={[
              {
                required: true,
                message: 'Please enter a valid email address',
                type: 'email'
              },
            ]}>
              <Input placeholder="Email" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name='password' label='Password'
              rules={[
                id == 0 &&
                {
                  required: true,
                  message: 'Password is required',
                },
                {
                  min: 8,
                  message: 'Password must be at least 8 characters'
                }
              ]}>
              <Input.Password placeholder='Password' onChange={e => setPasswordField(e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='confirm_password' label='Confirm Password'
              rules={[
                passwordField.length > 0 &&
                {
                  required: true,
                  message: 'Confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Confirm password does not match to password"));
                  },
                }),
              ]}>
              <Input.Password placeholder='Confirm Password' disabled={passwordField.length > 0 ? false : true} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="verified" label="Email Verified" valuePropName="checked">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              {renderButtons()}
            </Form.Item>
          </Col>

        </Row >
      </Form >
    );
  }

  const renderContent = () => {
    if (isMobile) {
      return renderUserFormMobile()
    } else {
      return renderUserForm()
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )

};
export default windowSize(UserForm)