import { Form, Input, Button, notification, Row, Col, Space, Select, Switch } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ImageUpload from '../../components/image-upload';
import { authUser, getIndustries, getStore, saveStore, updateStore } from '../../data';
import windowSize from 'react-window-size';
import ajaxService, { GlobalVariable } from '../../services/ajax-service';
import { getAbilities } from '../../data/user-service';

const { Option } = Select;

let currencies = [
    { label: "AFN", value: "AFN" }, { label: "AMD", value: "AMD" }, { label: "ANG", value: "ANG" }, { label: "AOA", value: "AOA" },
    { label: "ARS", value: "ARS" }, { label: "AUD", value: "AUD" }, { label: "AWG", value: "AWG" }, { label: "AZN", value: "AZN" },
    { label: "BAM", value: "BAM" }, { label: "BBD", value: "BBD" }, { label: "BDT", value: "BDT" }, { label: "BGN", value: "BGN" },
    { label: "BHD", value: "BHD" }, { label: "BIF", value: "BIF" }, { label: "BMD", value: "BMD" }, { label: "BND", value: "BND" },
    { label: "BOB", value: "BOB" }, { label: "BRL", value: "BRL" }, { label: "BSD", value: "BSD" }, { label: "BTN", value: "BTN" },
    { label: "BWP", value: "BWP" }, { label: "BYR", value: "BYR" }, { label: "BZD", value: "BZD" }, { label: "CAD", value: "CAD" },
    { label: "CHF", value: "CHF" }, { label: "CDF", value: "CDF" }, { label: "CLP", value: "CLP" }, { label: "CNY", value: "CNY" },
    { label: "COP", value: "COP" }, { label: "CRC", value: "CRC" }, { label: "CUC", value: "CUC" }, { label: "CUP", value: "CUP" },
    { label: "CVE", value: "CVE" }, { label: "CZK", value: "CZK" }, { label: "DJF", value: "DJF" }, { label: "DKK", value: "DKK" },
    { label: "DOP", value: "DOP" }, { label: "DZD", value: "DZD" }, { label: "EGP", value: "EGP" }, { label: "ERN", value: "ERN" },
    { label: "ETB", value: "ETB" }, { label: "EUR", value: "EUR" }, { label: "FJD", value: "FJD" }, { label: "FKP", value: "FKP" },
    { label: "GBP", value: "GBP" }, { label: "GEL", value: "GEL" }, { label: "GGP", value: "GGP" }, { label: "GHS", value: "GHS" },
    { label: "GIP", value: "GIP" }, { label: "GMD", value: "GMD" }, { label: "GNF", value: "GNF" }, { label: "GTQ", value: "GTQ" },
    { label: "GYD", value: "GYD" }, { label: "HKD", value: "HKD" }, { label: "HNL", value: "HNL" }, { label: "HRK", value: "HRK" },
    { label: "HTG", value: "HTG" }, { label: "HUF", value: "HUF" }, { label: "IDR", value: "IDR" }, { label: "ILS", value: "ILS" },
    { label: "IMP", value: "IMP" }, { label: "INR", value: "INR" }, { label: "IQD", value: "IQD" }, { label: "IRR", value: "IRR" },
    { label: "ISK", value: "ISK" }, { label: "JEP", value: "JEP" }, { label: "JMD", value: "JMD" }, { label: "JOD", value: "JOD" },
    { label: "JPY", value: "JPY" }, { label: "KES", value: "KES" }, { label: "KGS", value: "KGS" }, { label: "KHR", value: "KHR" },
    { label: "KMF", value: "KMF" }, { label: "KPW", value: "KPW" }, { label: "KRW", value: "KRW" }, { label: "KWD", value: "KWD" },
    { label: "KYD", value: "KYD" }, { label: "KZT", value: "KZT" }, { label: "LAK", value: "LAK" }, { label: "LBP", value: "LBP" },
    { label: "LKR", value: "LKR" }, { label: "LRD", value: "LRD" }, { label: "LSL", value: "LSL" }, { label: "LYD", value: "LYD" },
    { label: "MAD", value: "MAD" }, { label: "MDL", value: "MDL" }, { label: "MGA", value: "MGA" }, { label: "MKD", value: "MKD" },
    { label: "MMK", value: "MMK" }, { label: "MNT", value: "MNT" }, { label: "MOP", value: "MOP" }, { label: "MRO", value: "MRO" },
    { label: "MUR", value: "MUR" }, { label: "MVR", value: "MVR" }, { label: "MWK", value: "MWK" }, { label: "MXN", value: "MXN" },
    { label: "MYR", value: "MYR" }, { label: "MZN", value: "MZN" }, { label: "NAD", value: "NAD" }, { label: "NGN", value: "NGN" },
    { label: "NIO", value: "NIO" }, { label: "NOK", value: "NOK" }, { label: "NPR", value: "NPR" }, { label: "NZD", value: "NZD" },
    { label: "OMR", value: "OMR" }, { label: "PKR", value: "PKR" }, { label: "PAB", value: "PAB" }, { label: "PEN", value: "PEN" },
    { label: "PGK", value: "PGK" }, { label: "PHP", value: "PHP" }, { label: "PLN", value: "PLN" }, { label: "PYG", value: "PYG" },
    { label: "QAR", value: "QAR" }, { label: "RON", value: "RON" }, { label: "RSD", value: "RSD" }, { label: "RUB", value: "RUB" },
    { label: "RWF", value: "RWF" }, { label: "SAR", value: "SAR" }, { label: "SBD", value: "SBD" }, { label: "SCR", value: "SCR" },
    { label: "SDG", value: "SDG" }, { label: "SEK", value: "SEK" }, { label: "SGD", value: "SGD" }, { label: "SHP", value: "SHP" },
    { label: "SLL", value: "SLL" }, { label: "SOS", value: "SOS" }, { label: "SPL", value: "SPL" }, { label: "SRD", value: "SRD" },
    { label: "STD", value: "STD" }, { label: "SVC", value: "SVC" }, { label: "SYP", value: "SYP" }, { label: "SZL", value: "SZL" },
    { label: "THB", value: "THB" }, { label: "TJS", value: "TJS" }, { label: "TMT", value: "TMT" }, { label: "TND", value: "TND" },
    { label: "TOP", value: "TOP" }, { label: "TRY", value: "TRY" }, { label: "TTD", value: "TTD" }, { label: "TVD", value: "TVD" },
    { label: "TWD", value: "TWD" }, { label: "TZS", value: "TZS" }, { label: "UAH", value: "UAH" }, { label: "UGX", value: "UGX" },
    { label: "USD", value: "USD" }, { label: "UYU", value: "UYU" }, { label: "UZS", value: "UZS" }, { label: "VEF", value: "VEF" },
    { label: "VND", value: "VND" }, { label: "VUV", value: "VUV" }, { label: "WST", value: "WST" }, { label: "XAF", value: "XAF" },
    { label: "XCD", value: "XCD" }, { label: "XDR", value: "XDR" }, { label: "XOF", value: "XOF" }, { label: "XPF", value: "XPF" },
    { label: "YER", value: "YER" }, { label: "ZAR", value: "ZAR" }, { label: "ZMW", value: "ZMW" }, { label: "ZWD", value: "ZWD" }
];

let timezones = Intl.supportedValuesOf('timeZone')

function StoreForm({ windowWidth }) {

    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [industries, setIndustries] = useState([]);

    const [form] = Form.useForm();
    const isMobile = windowWidth <= 768;

    let history = useHistory();
    let { id } = useParams();

    const onChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
    };

    const handleCoverImage = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList2(fileList);
    };

    const onFinish = async (values) => {

        let link = fileList.length > 0 ? fileList[0].response?.success ? fileList[0].response.path : fileList[0].url?.replace(GlobalVariable.BASE_IMAGE_URL + '/', '') : null;
        let coverImage = fileList2.length > 0 ? fileList2[0].response?.success ? fileList2[0].response.path : fileList2[0].url?.replace(GlobalVariable.BASE_IMAGE_URL + '/', '') : null;

        delete values.file;
        let data = {
            name: values.name,
            domain: values.domain,
            currency : values.currency,
            timezone : values.timezone,
            logo: link,
            cover_image: coverImage,
            description: values.description,
            slogan: values.slogan,
            industry_id: values.industry_id,
            active: true
        }

        if (id == 0) {
            const response = await saveStore(data);
            if (response) {

                localStorage.setItem('store_id', response.id)
                localStorage.setItem('newAdmin', true);

                const user = await authUser();

                if (user.verified == 1) {

                    localStorage.setItem('store_id', user.store_id);

                    const abilities = await getAbilities()
                    if (abilities) {
                        localStorage.setItem('abilities', abilities.abilities)
                    }

                    history.push({ pathname: "/" });
                    // history.replace(from);
                }

                notification.open({ message: 'Store has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add store', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }

        } else {
            if (data.logo === '') {
                data.logo = fileList[0].url;
                data.active = values.active
            }
            const updateData = await updateStore(id, data);
            if (updateData) {
                history.push({ pathname: "/" });
                notification.open({ message: 'Store has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update store', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }

        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadIndutries = async () => {
        const response = await getIndustries();
        if (response) {
            setIndustries(response)
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            const data = await getStore(id)
            if (data != null) {
                form.setFieldsValue({ ...data });

                const { logo, cover_image } = data

                if (logo) {
                    let imageItem = {
                        uid: logo,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(logo),
                        preview: false
                    }
                    setFileList([imageItem]);
                }

                if (cover_image) {
                    let imageItem = {
                        uid: cover_image,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(cover_image),
                        preview: false
                    }
                    setFileList2([imageItem]);
                }

            }
        }
    }

    useEffect(() => {
        loadIndutries()
        loadData(id)
    }, [])


    const renderStoreForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{ active: true }}
                >
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Store Name" name='name' rules={[
                                        {
                                            required: true,
                                            message: 'Store Name is required',
                                        },
                                    ]}>
                                        <Input placeholder="Store name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='industry_id' label="Store Industry" rules={[
                                        {
                                            required: true,
                                            message: 'Store industry is required',
                                        },
                                    ]}>
                                        <Select placeholder="Store industry">
                                            {industries.map(({ id, name }, i) => (
                                                <Option key={i} value={id}>{name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='currency' label="Base Currency" rules={[
                                        {
                                            required: true,
                                            message: 'Base Currency is required',
                                        },
                                    ]}>
                                        <Select placeholder="Base Currency" showSearch>
                                            {currencies.map(({ label, value }, i) => (
                                                <Option key={i} value={value}>{label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='timezone' label="Timezone" rules={[
                                        {
                                            required: true,
                                            message: 'Timezone is required',
                                        },
                                    ]}>
                                        <Select
                                            placeholder="Timezone"
                                            showSearch
                                        >
                                            {timezones.map((item, i) => (
                                                <Option key={i} value={item}>{item}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item name='slogan' label="Store Slogan" rules={[
                                        {
                                            required: true,
                                            message: 'Slogan is required',
                                        },
                                    ]}>
                                        <Input placeholder="Store slogan" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='description' label="Store Description" rules={[
                                        {
                                            required: true,
                                            message: 'Store description is required',
                                        },
                                    ]}>
                                        <Input placeholder="Store description" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='domain' label="Store Website" rules={[
                                        {
                                            required: false,
                                            message: 'Store website is required',
                                        },
                                    ]}>
                                        <Input addonBefore="https://" placeholder="mydomain.com" />

                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    {/* <Form.Item name="active" label="Approve" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item> */}
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Upload Logo" name="file" rules={[
                                        {
                                            required: false,
                                            message: 'Store logo is required',
                                        },
                                    ]}>
                                        <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Cover Image" name="cover_image" >
                                        <ImageUpload onChange={handleCoverImage} fileList={fileList2} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        );
    }

    const renderStoreFormMobile = () => {

        return (
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{ active: true }}
            >
                <Row gutter={16} >
                    <Col span={24} >
                        <Form.Item label="Store Name" name='name' rules={[
                            {
                                required: true,
                                message: 'Store Name is required',
                            },
                        ]}>
                            <Input placeholder="Store name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='slogan' label="Store Slogan" rules={[
                            {
                                required: true,
                                message: 'Slogan is required',
                            },
                        ]}>
                            <Input placeholder="Store slogan" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='currency' label="Base Currency" rules={[
                            {
                                required: true,
                                message: 'Base Currency is required',
                            },
                        ]}>
                            <Select placeholder="Base Currency" showSearch>
                                {currencies.map(({ label, value }, i) => (
                                    <Option key={i} value={value}>{label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='timezone' label="Timezone" rules={[
                            {
                                required: true,
                                message: 'Timezone is required',
                            },
                        ]}>
                            <Select
                                placeholder="Timezone"
                                showSearch
                            >
                                {timezones.map((item, i) => (
                                    <Option key={i} value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='domain' label="Store Website" rules={[
                            {
                                required: true,
                                message: 'Store website is required',
                            },
                        ]}>
                            <Input placeholder="Store website" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='description' label="Store Description" rules={[
                            {
                                required: true,
                                message: 'Store description is required',
                            },
                        ]}>
                            <Input placeholder="Store description" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='industry_id' label="Store Industry" rules={[
                            {
                                required: true,
                                message: 'Store industry is required',
                            },
                        ]}>
                            <Select placeholder="Store industry">
                                {industries.map(({ id, name }, i) => (
                                    <Option key={i} value={id}>{name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="active" label="Approve" valuePropName="checked">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Upload Logo" name="file" rules={[
                            {
                                required: false,
                                message: 'Store logo is required',
                            },
                        ]}>
                            <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Cover Image" name="cover_image" >
                            <ImageUpload onChange={handleCoverImage} fileList={fileList2} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderStoreFormMobile()
        } else {
            return renderStoreForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )


};
export default windowSize(StoreForm)