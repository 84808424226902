import { Col, Row, Form, Switch, InputNumber, } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const EnableWebsite = ({ Setting }) => {

    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (

        <Row gutter={16}>

            <Col span={12}>
                <Form.Item name='custom_pages' label="Custom Pages"
                    labelCol={{ span: 24 }}
                    initialValue={data?.custom_pages}>
                    <Switch
                        style={{ marginLeft: 10 }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data?.custom_pages} />
                </Form.Item>
            </Col>
        </Row>

    )
}

export default EnableWebsite