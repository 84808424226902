import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

const branchDependency = (path) => { return ajaxService.getBranchDependencyUrl(path) }

export async function getBanners(refresh = false) {
    setDependency()

    if (refresh) {
        return await loadResource(branchDependency(urlService.banners), 'banners');
    } else {
        let banners = await loadState('banners');

        if (banners == null) {
            return await loadResource(branchDependency(urlService.banners), 'banners');
        } else {
            return Response(banners);
        }
    }
}

export async function getBanner(id) {
    let banners = await loadState('banners');
    if (banners !== null) {
        return banners.find(banner => banner.id == id);
    } else {
        return ajaxService.get(branchDependency(urlService.banners) + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveBanner(data) {
    setDependency()
    return ajaxService.post(branchDependency(urlService.banners), data).then(response => {
        return Response(response.data);
    })
}

export async function updateBanner(id, data) {
    setDependency()
    return ajaxService.put(branchDependency(urlService.banners) + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteBanner(id) {
    setDependency()
    return ajaxService.delete(branchDependency(urlService.banners) + '/' + id).then(response => {
        return Response(response.data);
    })
}