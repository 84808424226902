import { Button, Space, Table, Typography } from "antd"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { PAGINATION_OPTIONS } from "../../constants";
import { useEffect } from "react";
import { getReconciliations } from "../../data";
import moment from "moment";

const { Title, Text } = Typography
const format = 'DD-MMM-yyy hh:mm A'

const Reconciliations = () => {

    let history = useHistory()

    const [forceRefresh, setForceRefresh] = useState(true)

    const [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'Date & Times',
            dataIndex: 'date_time',
            key: 'date_time',
            width: '75%',
            render: (dateTime) => <Text>{moment(dateTime).format(format)}</Text>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: '/reconciliations/view/' + record.id })}>View</Button>
                </Space>
            )
        },
    ]

    const initialize = async () => {
        const response = await getReconciliations(forceRefresh)
        if (response) {
            setDataSource(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Reconciliations</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/reconciliations/form/0' })}>Add Reconciliation</Button>
        </div>
    )

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader}
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )

}
export default Reconciliations 