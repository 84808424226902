import { Button, Card, Modal, notification, Space, Switch, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { deleteTableManagement, getTableManagements, updateTableManagement } from "../../data";
import { PAGINATION_OPTIONS } from "../../constants";
import { updateDataSource } from "../../utility/updateDataSource";
import windowSize from "react-window-size";

const { Title, Text } = Typography;

function TableManagements({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);
    let history = useHistory()

    const updateValue = async (id, checked, type) => {

        const updatedTableManagements = updateDataSource(dataSource, id, checked);
        setDataSource(updatedTableManagements);

        const active = await updateTableManagement(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            if (checked === true) {
                notification.open({ message: 'Table activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (checked === false) {
                notification.open({ message: 'Table deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '75%',

        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => (
                <Switch
                    onChange={(checked) => updateValue(record.id, checked, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={active == 1 ? true : false}
                />
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: '/table-managements/form/' + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            )
        },
    ]

    const deleteRow = async () => {
        let item_id = localStorage.getItem('table-management')
        const data = await deleteTableManagement(item_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Table has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted table', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('table-management', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getTableManagements(forceRefresh);
        setDataSource(data);
        setForceRefresh(false);
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={isMobile ? {} : { display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Table Management</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/table-managements/form/0' })}>Add Table</Button>
            </Space>
        </div>
    )

    const renderModal = (
        <Modal
            title="Delete Table"
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteRow()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this table?</p>
            </div>
        </Modal>
    )

    const renderTableManagements = () => (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
        </>
    )

    const renderTableManagementsMobile = () => (
        <>
            {
                dataSource.map(table => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Space size='small'>
                                    <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: '/table-managements/form/' + table.id })}>Edit</Button>
                                    <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(table.id)}>Delete</Button>
                                </Space>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{table.name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Active :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    <Switch
                                        onChange={(val) => updateValue(table.id, val, 'active')}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={table.active == 1 ? true : false} />
                                </span>
                            </Text>
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderTableManagementsMobile()}
                </>
            )
        }
        else {
            return renderTableManagements()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal}
        </>
    )
}

export default windowSize(TableManagements)