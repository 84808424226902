import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getStockTransfers(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.stockTransfer, 'stockTransfer');
    } else {
        let stockTransfer = await loadState('stockTransfer');

        if (stockTransfer == null) {
            return await loadResource(urlService.stockTransfer, 'stockTransfer');
        } else {
            return Response(stockTransfer);
        }
    }
}

export async function getStockTransfer(id) {
    setDependency()
    return ajaxService.get(urlService.stockTransfer + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function saveStockTransfer(data) {
    setDependency()
    return ajaxService.post(urlService.stockTransfer, data).then(response => {
        return Response(response.data);
    })
}

export async function updateStockTransfer(id, data) {
    setDependency()
    return ajaxService.put(urlService.stockTransfer + '/' + id, data).then(response => {
        return Response(response.data);
    })
}