import { ajaxService, loadState, urlService } from '../services';
import { loadResource, Response } from '../utility';

const setDependency = () => { ajaxService.setDependency() }

export async function getProducts(refresh = false, queryString) {
    let qs = new URLSearchParams(queryString).toString()
    setDependency()
    if (refresh) {
        return await loadResource(urlService.products + '?' + qs, 'products');
    } else {
        let products = await loadState('products');

        if (products == null) {
            return await loadResource(urlService.products + '?' + qs, 'products');
        } else {
            return Response(products)
        }
    }
}

export async function getProduct(id) {
    setDependency()
    // let products = await loadState('products');

    // if (products !== null) {
    //     return products.find(product => product.id == id);
    // } else {
    // }
    return ajaxService.get(urlService.products + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function saveProduct(data) {
    setDependency()
    return ajaxService.post(urlService.products, data).then(response => {
        return Response(response.data);
    })
}

export async function updateProduct(id, data) {
    setDependency()
    return ajaxService.put(urlService.products + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteProduct(id) {
    return ajaxService.delete(urlService.products + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function filterProduct(data) {
    return await ajaxService.post(urlService.productFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getVariant(id) {
    setDependency()
    return ajaxService.get(urlService.products + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function getBranchProducts(id, queryString) {
    let qs = new URLSearchParams(queryString).toString()
    setDependency()
    return ajaxService.get(urlService.branchProducts + '/' + id + '?' + qs).then(response => {
        return Response(response.data);
    })
}