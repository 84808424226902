import { Col, Row, Form, Switch, InputNumber, } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const UserManagement = ({ Setting }) => {

    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (

        <Row gutter={16}>

            <Col span={12}>
                <Form.Item name='users_limit' label="Users Limit"
                    labelCol={{ span: 24 }}
                    initialValue={data?.users_limit}>
                    <InputNumber style={{ width: '100%' }} min={1} type="number" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name='enable_multiple_users' label="Enable Multiple Users"
                    labelCol={{ span: 24 }}
                    initialValue={data?.enable_multiple_users}>
                    <Switch
                        style={{ marginLeft: 10 }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data?.enable_multiple_users} />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name='enable_roles' label="Enable Roles"
                    labelCol={{ span: 24 }}
                    initialValue={data?.enable_roles}>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data?.enable_roles} />
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name='enable_permissions' label="Enable Permissions"
                    labelCol={{ span: 24 }}
                    initialValue={data?.enable_permissions}>
                    <Switch
                        style={{ marginLeft: 10 }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data?.enable_permissions} />
                </Form.Item>
            </Col>
        </Row>

    )
}

export default UserManagement