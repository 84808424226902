import React, { useState, useEffect } from 'react';
import { Table, Space, Typography, Button, Tag, Card } from 'antd';
import { useHistory } from "react-router-dom";
import { EyeOutlined } from '@ant-design/icons';
import windowSize from 'react-window-size';
import { getPurchaseGrn } from '../../data';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../../constants';

const { Title, Text } = Typography;

const format = 'DD/MM/YYYY'

function PurchaseGrn({ windowWidth }) {

    const [dataSource, setDataSource] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768

    let history = useHistory();
    const branch_id = parseInt(localStorage.getItem('branch_id'))

    const columns = [
        {
            title: 'GRN Number',
            dataIndex: 'grn_number',
            key: 'grn_number',

        },
        {
            title: 'Purchase Order Number',
            dataIndex: 'purchase_order_number',
            key: 'purchase_order_number',
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            key: 'supplier',
        },
        {
            title: 'Total Items',
            dataIndex: 'items_count',
            key: 'items_count',
            align: 'center'
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => {
                return (<p>{moment(record.created_at).format(format)}</p>)
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <Text style={{ color: status === "Unpublished" ? "#FF3333" : status === "Published" ? "#00CC66" : "#FF9900" }}>{status}</Text>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: "/purchase-grn/view/" + record.id })}>View</Button>
                </Space>
            ),
        },
    ];

    const initialize = async () => {
        const response = await getPurchaseGrn(forceRefresh);
        if (response) {
            let data = []
            if (branch_id) {
                data = response.filter(i => i.branch_id === branch_id)
            } else {
                data = response
            }
            setDataSource(data);
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize();
    }, [])

    const renderHeader = () => {
        return (
            <div style={!isMobile ? { display: 'flex', justifyContent: 'space-between' } : {}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Purchase GRN</Title>
                </div>
                <Space size="small" >
                    <Button type="primary" onClick={() => history.push({ pathname: "/purchase-grn/form/0" })}  >Add Purchase GRN</Button>
                </Space>
            </div>
        );
    }

    const renderPurchaseGrn = () => {
        return (
            <div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                    pagination={{ ...PAGINATION_OPTIONS }}
                />
            </div>
        )
    }

    const renderPurchaseGrnMobile = () => (
        <>
            {
                dataSource.map(row => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Space size='small'>
                                    <Button type='primary' icon={<EyeOutlined />} onClick={() => history.push({ pathname: "/purchase-grn/view/" + row.id })}>View</Button>
                                </Space>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>GRN Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{row.grn_number}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Purchase Order Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{row.purchase_order_number}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Supplier : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{row.supplier}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Total Items : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{row.items_count}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(row.created_at).format(format)}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Status :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    <Tag color={row.status === "Unpublished" ? "#FF3333" : row.status === "Published" ? "#00CC66" : "#FF9900"}>{row.status}</Tag>
                                </span>
                            </Text>
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderPurchaseGrnMobile()}
                </>
            )
        }
        else {
            return renderPurchaseGrn()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(PurchaseGrn)