import { Col, Row, Select, Form, } from "antd";

const { Option } = Select

const Currency = ({ Setting }) => {

    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = Setting
    }

    let currency = [
        { label: "AFN", value: "AFN" }, { label: "AMD", value: "AMD" }, { label: "ANG", value: "ANG" }, { label: "AOA", value: "AOA" },
        { label: "ARS", value: "ARS" }, { label: "AUD", value: "AUD" }, { label: "AWG", value: "AWG" }, { label: "AZN", value: "AZN" },
        { label: "BAM", value: "BAM" }, { label: "BBD", value: "BBD" }, { label: "BDT", value: "BDT" }, { label: "BGN", value: "BGN" },
        { label: "BHD", value: "BHD" }, { label: "BIF", value: "BIF" }, { label: "BMD", value: "BMD" }, { label: "BND", value: "BND" },
        { label: "BOB", value: "BOB" }, { label: "BRL", value: "BRL" }, { label: "BSD", value: "BSD" }, { label: "BTN", value: "BTN" },
        { label: "BWP", value: "BWP" }, { label: "BYR", value: "BYR" }, { label: "BZD", value: "BZD" }, { label: "CAD", value: "CAD" },
        { label: "CHF", value: "CHF" }, { label: "CDF", value: "CDF" }, { label: "CLP", value: "CLP" }, { label: "CNY", value: "CNY" },
        { label: "COP", value: "COP" }, { label: "CRC", value: "CRC" }, { label: "CUC", value: "CUC" }, { label: "CUP", value: "CUP" },
        { label: "CVE", value: "CVE" }, { label: "CZK", value: "CZK" }, { label: "DJF", value: "DJF" }, { label: "DKK", value: "DKK" },
        { label: "DOP", value: "DOP" }, { label: "DZD", value: "DZD" }, { label: "EGP", value: "EGP" }, { label: "ERN", value: "ERN" },
        { label: "ETB", value: "ETB" }, { label: "EUR", value: "EUR" }, { label: "FJD", value: "FJD" }, { label: "FKP", value: "FKP" },
        { label: "GBP", value: "GBP" }, { label: "GEL", value: "GEL" }, { label: "GGP", value: "GGP" }, { label: "GHS", value: "GHS" },
        { label: "GIP", value: "GIP" }, { label: "GMD", value: "GMD" }, { label: "GNF", value: "GNF" }, { label: "GTQ", value: "GTQ" },
        { label: "GYD", value: "GYD" }, { label: "HKD", value: "HKD" }, { label: "HNL", value: "HNL" }, { label: "HRK", value: "HRK" },
        { label: "HTG", value: "HTG" }, { label: "HUF", value: "HUF" }, { label: "IDR", value: "IDR" }, { label: "ILS", value: "ILS" },
        { label: "IMP", value: "IMP" }, { label: "INR", value: "INR" }, { label: "IQD", value: "IQD" }, { label: "IRR", value: "IRR" },
        { label: "ISK", value: "ISK" }, { label: "JEP", value: "JEP" }, { label: "JMD", value: "JMD" }, { label: "JOD", value: "JOD" },
        { label: "JPY", value: "JPY" }, { label: "KES", value: "KES" }, { label: "KGS", value: "KGS" }, { label: "KHR", value: "KHR" },
        { label: "KMF", value: "KMF" }, { label: "KPW", value: "KPW" }, { label: "KRW", value: "KRW" }, { label: "KWD", value: "KWD" },
        { label: "KYD", value: "KYD" }, { label: "KZT", value: "KZT" }, { label: "LAK", value: "LAK" }, { label: "LBP", value: "LBP" },
        { label: "LKR", value: "LKR" }, { label: "LRD", value: "LRD" }, { label: "LSL", value: "LSL" }, { label: "LYD", value: "LYD" },
        { label: "MAD", value: "MAD" }, { label: "MDL", value: "MDL" }, { label: "MGA", value: "MGA" }, { label: "MKD", value: "MKD" },
        { label: "MMK", value: "MMK" }, { label: "MNT", value: "MNT" }, { label: "MOP", value: "MOP" }, { label: "MRO", value: "MRO" },
        { label: "MUR", value: "MUR" }, { label: "MVR", value: "MVR" }, { label: "MWK", value: "MWK" }, { label: "MXN", value: "MXN" },
        { label: "MYR", value: "MYR" }, { label: "MZN", value: "MZN" }, { label: "NAD", value: "NAD" }, { label: "NGN", value: "NGN" },
        { label: "NIO", value: "NIO" }, { label: "NOK", value: "NOK" }, { label: "NPR", value: "NPR" }, { label: "NZD", value: "NZD" },
        { label: "OMR", value: "OMR" }, { label: "PKR", value: "PKR" }, { label: "PAB", value: "PAB" }, { label: "PEN", value: "PEN" },
        { label: "PGK", value: "PGK" }, { label: "PHP", value: "PHP" }, { label: "PLN", value: "PLN" }, { label: "PYG", value: "PYG" },
        { label: "QAR", value: "QAR" }, { label: "RON", value: "RON" }, { label: "RSD", value: "RSD" }, { label: "RUB", value: "RUB" },
        { label: "RWF", value: "RWF" }, { label: "SAR", value: "SAR" }, { label: "SBD", value: "SBD" }, { label: "SCR", value: "SCR" },
        { label: "SDG", value: "SDG" }, { label: "SEK", value: "SEK" }, { label: "SGD", value: "SGD" }, { label: "SHP", value: "SHP" },
        { label: "SLL", value: "SLL" }, { label: "SOS", value: "SOS" }, { label: "SPL", value: "SPL" }, { label: "SRD", value: "SRD" },
        { label: "STD", value: "STD" }, { label: "SVC", value: "SVC" }, { label: "SYP", value: "SYP" }, { label: "SZL", value: "SZL" },
        { label: "THB", value: "THB" }, { label: "TJS", value: "TJS" }, { label: "TMT", value: "TMT" }, { label: "TND", value: "TND" },
        { label: "TOP", value: "TOP" }, { label: "TRY", value: "TRY" }, { label: "TTD", value: "TTD" }, { label: "TVD", value: "TVD" },
        { label: "TWD", value: "TWD" }, { label: "TZS", value: "TZS" }, { label: "UAH", value: "UAH" }, { label: "UGX", value: "UGX" },
        { label: "USD", value: "USD" }, { label: "UYU", value: "UYU" }, { label: "UZS", value: "UZS" }, { label: "VEF", value: "VEF" },
        { label: "VND", value: "VND" }, { label: "VUV", value: "VUV" }, { label: "WST", value: "WST" }, { label: "XAF", value: "XAF" },
        { label: "XCD", value: "XCD" }, { label: "XDR", value: "XDR" }, { label: "XOF", value: "XOF" }, { label: "XPF", value: "XPF" },
        { label: "YER", value: "YER" }, { label: "ZAR", value: "ZAR" }, { label: "ZMW", value: "ZMW" }, { label: "ZWD", value: "ZWD" }
    ];

    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name='currency' label="Currency"
                        labelCol={{ span: 24 }}
                        initialValue={data}
                        rules={[
                            {
                                required: true,
                                message: 'Currency is required',
                            },
                        ]}>
                        <Select

                            showSearch allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Currency"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {currency.map(Currency => {
                                return <Option value={Currency.value}>{Currency.label}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
export default Currency 