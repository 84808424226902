import React, { useState, useEffect } from 'react';
import { Form, notification, Input, Divider, Row, Col, Select, Typography, Button, InputNumber } from 'antd';
import { PlusOutlined, InboxOutlined, MinusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import ajaxService from '../../services/ajax-service';
import ImageUpload from '../../components/image-upload';
import windowSize from 'react-window-size';

const { Option } = Select;
const { Title } = Typography
const successNotificationWithIcon = type => {
    notification[type]({ message: 'Landing page etting have been updated' });
};

const errorNotificationWithIcon = type => {
    notification[type]({ message: 'Unable to update landing page setting. Please update all fields.' });
};

function Setting({ windowWidth }) {

    const [selection, setSelection] = useState({ products: [], categories: [], subCategories: [] });
    const [fileList, setFileList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [products, setProducts] = useState([]);
    const [category, setcategories] = useState([]);
    const [filterProduct, setfilterProduct] = useState([]);
    const [form] = Form.useForm();
    const isMobile = windowWidth <= 768

    let initialize = {
        banners: [],
        sections: [],
        title: '',
        products: [],
        mainCategory: '',
        categories: [],
        charges_within: '',
        charges_outof: ''
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        let list = []

        fileList.map((item) => {
            if (item.response !== undefined) {
                list.push({
                    uid: item.response.path,
                    name: 'image.png',
                    status: 'done',
                    url: item.response.path,
                    preview: false
                })
            }
        })
        setImagesList(list)
    };

    const onFinish = async (values) => {

        let imageData = []
        imagesList.map((item) => {
            if (item.url !== '') {
                imageData.push(item.url)
            }
        })
        let list = [...imageData]
        fileList.map((item) => {
            if (item.url !== undefined) {
                list.push(item.url)
            }
        })
        let { sections, products, categories } = values
        if (products.length <= 12 & categories.length <= 4 & sections[0].products.length <= 12) {
            let data = {
                url: list,
                sections: values.sections,
                title: values.title,
                products: values.products,
                mainCategory: values.mainCategory,
                categories: values.categories,
                category_id: values.mainCategory,
                charges_within: values.charges_within,
                charges_outof: values.charges_outof
            }
            const response = ajaxService.post('settings', data);
            successNotificationWithIcon('success')
        } else {
            errorNotificationWithIcon('error')
        }

    };

    const filterProducts = (id) => {
        let filterItem = []
        products.map((v) => {

            if (v.category_id === id) {
                filterItem.push(v)
            }
        })
        console.log(filterItem)
        setfilterProduct(filterItem)
    }

    const loadProducts = async () => {
        const response = await ajaxService.get('products');

        const { status, data } = response;

        if (status === 200) {
            setProducts(data.payload);
        }
    }

    const loadCategories = async () => {
        const response = await ajaxService.get('categories');
        const { status, data } = response;

        if (status === 200) {
            setcategories(data.payload);
        }
    }

    const loadData = async () => {
        const response = await ajaxService.get('settings');

        if (response.data != null) {
            form.setFieldsValue({ ...response.data });
            form.setFieldsValue({ mainCategory: parseInt(response.data.mainCategory) })
            const section = []
            response.data.sections.map((v) => {
                section.push({ category_id: parseInt(v.category_id), products: v.products })
            })
            form.setFieldsValue({ sections: section })
        }
    }
    
    useEffect(() => {
        loadProducts()
        loadCategories()
        loadData()
    }, [])

    const renderSetting = () => {
        return (
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                initialValues={initialize}
                // onFinishFailed={onFinishFailed}
                // initialValues={initialValues}
                form={form}>
                <Row>
                    <Col span={3}></Col>
                    <Col span={18}>
                        <>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item name='title' label="Title">
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name='products'
                                        label="Products"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Select Product"
                                            style={{ width: '100%' }}
                                        >
                                            {products.map(product => {
                                                return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <hr />
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item name='mainCategory' label="Main Category">
                                        <Select
                                            placeholder="Select Product"
                                            style={{ width: '100%' }}
                                        >
                                            {category.map(brand => {
                                                return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item name='categories'
                                        rules={[{ required: true }]}
                                        label="Category">
                                        <Select
                                            mode="multiple"
                                            placeholder="Select Category"
                                            onChange={(e) => setSelection({ ...selection, subCategories: e })}
                                            style={{ width: '100%' }}


                                        >
                                            {category.map(brand => {
                                                return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                            })}
                                        </Select>

                                    </Form.Item>
                                </Col>

                            </Row>
                            <hr />

                            <Form.List name="sections">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <>
                                                <Row key={key} gutter={16}>
                                                    <Col span={6}>
                                                        <Form.Item  {...restField} name={[name, 'category_id']} label="Category Section" >
                                                            <Select
                                                                placeholder="Select Product"
                                                                style={{ width: '100%' }}
                                                                onChange={filterProducts}
                                                            >
                                                                {category.map(category => {
                                                                    return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                                                })}
                                                            </Select>

                                                        </Form.Item>
                                                        <Form.Item   {...restField} name={[name, 'type']} >
                                                            <Input type='hidden' value='2' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            {...restField} name={[name, 'products']}
                                                            rules={[{ required: true }]}
                                                            label="Products">
                                                            <Select
                                                                mode="multiple"
                                                                placeholder="Select Product"
                                                                style={{ width: '100%' }}


                                                            >
                                                                {filterProduct.length > 0 ?
                                                                    filterProduct.map(product => {
                                                                        return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                                                    })
                                                                    :
                                                                    products.map(product => {
                                                                        return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                                                    })
                                                                }
                                                            </Select>

                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Form.Item>
                                                            <label style={{ display: 'block' }}>&nbsp;</label>
                                                            <Button style={{ margin: '8px' }} onClick={() => remove(name)} ><DeleteOutlined /></Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </>
                                        ))}

                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                Add Category Section
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </>
                        <Form.Item>
                            <Button htmlType='submit' type='submit'>Save</Button>
                        </Form.Item>
                    </Col>
                    <Col span={3}></Col>

                </Row>
            </Form >
        )
    }

    const renderSettingMobile = () => {
        return (
            <Form
                onFinish={onFinish}
                initialValues={initialize}
                form={form}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Upload Banner Images" name="file">
                            <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name='title' label="Title">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name='products'
                            label="Products"
                            rules={[{ required: true }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select Product"
                                style={{ width: '100%' }}
                            >
                                {products.map(product => {
                                    return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={24}>
                        <Form.Item name='mainCategory' label="Main Category">
                            <Select
                                placeholder="Select Product"
                                style={{ width: '100%' }}
                            >
                                {category.map(brand => {
                                    return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='categories'
                            rules={[{ required: true }]}
                            label="Category">
                            <Select
                                mode="multiple"
                                placeholder="Select Category"
                                onChange={(e) => setSelection({ ...selection, subCategories: e })}
                                style={{ width: '100%' }}>
                                {category.map(brand => {
                                    return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Form.List name="sections">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <>
                                        <Col span={24} style={{ height: 100 }}>
                                            <Form.Item  {...restField} name={[name, 'category_id']} label="Category Section" >
                                                <Select
                                                    placeholder="Select Product"
                                                    style={{ width: '100%' }}
                                                    onChange={filterProducts}
                                                >
                                                    {category.map(category => {
                                                        return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                                    })}
                                                </Select>

                                            </Form.Item>
                                            <Form.Item   {...restField} name={[name, 'type']} >
                                                <Input type='hidden' value='2' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                {...restField} name={[name, 'products']}
                                                rules={[{ required: true }]}
                                                label="Products">
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Select Product"
                                                    style={{ width: '100%' }}>
                                                    {filterProduct.length > 0 ?
                                                        filterProduct.map(product => {
                                                            return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                                        })
                                                        :
                                                        products.map(product => {
                                                            return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type='primary' danger style={{ margin: '8px' }} onClick={() => remove(name)} ><DeleteOutlined /></Button>
                                            </Form.Item>
                                        </Col>
                                        <Divider />
                                    </>
                                ))}
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                            Add Category Section
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Form.List>

                    <Col span={24}>
                        <Title level={4}>Shipping Charges</Title>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='charges_within' label='Charges WithIn City (Karachi)'
                            rules={[{ required: true }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='charges_outof' label='Charges OutOf City'
                            rules={[{ required: true }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Button htmlType='submit' type='primary'>Save</Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form >
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderSettingMobile()
        } else {
            return renderSetting()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

}
export default windowSize(Setting)