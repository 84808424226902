import { ajaxService, loadState, urlService } from '../services';
import { loadResource, Response } from '../utility';


const setDependency = () => { ajaxService.setDependency() }

export async function getPaymentMethods(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.paymentMethods, 'paymentMethods');
    } else {

        let paymentMethods = await loadState('paymentMethods');

        if (paymentMethods == null) {
            return await loadResource(urlService.paymentMethods, 'paymentMethods');
        } else {
            return Response(paymentMethods);
        }

    }
}

export async function getPaymentMethod(id) {
    let paymentMethods = await loadState('paymentMethods');
    if (paymentMethods !== null) {
        return paymentMethods.find(paymentMethod => paymentMethod.id == id);
    }
    else {
        setDependency()
        return ajaxService.get(urlService.paymentMethods + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function savePaymentMethod(data) {
    setDependency()
    return ajaxService.post(urlService.paymentMethods, data).then(response => {
        return Response(response.data);
    })
}

export async function updatePaymentMethod(id, data) {
    setDependency()
    return ajaxService.put(urlService.paymentMethods + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deletePaymentMethod(id) {
    return ajaxService.delete(urlService.paymentMethods + '/' + id).then(response => {
        return Response(response.data);
    })
}
