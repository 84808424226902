import React, { useState, useEffect } from "react";
import { Button, Card, DatePicker, Form, Input, Modal, Space, Table, Tabs, Typography, notification } from "antd";
import { getAccounts, saveAccount } from "../../data";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { PAGINATION_OPTIONS } from "../../constants";
import moment from "moment";
import windowSize from "react-window-size";

const { Title, Text } = Typography
const { TextArea } = Input

const format = 'DD-MMM-yyy';

const columns = [
    {
        title: 'Date',
        key: 'created_at',
        render: (text, record) => (<Text>{moment(record.date ?? record.created_at).format(format)}</Text>)
    },
    {
        title: 'Description',
        dataIndex: 'reference',
        key: 'reference'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount) => <Text style={{ color: amount < 0 ? 'red' : '' }}>{amount}</Text>
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        render: (balance) => <Text>{balance.toFixed(2)}</Text>
    },
]

const Accounts = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm()
    const [type, setType] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true)

    const [income, setIncome] = useState([])
    const [expense, setExpense] = useState([])

    const onFinish = async (values) => {

        let data = {
            type: type,
            reference: values.reference,
            cash_in: type === 'Add Income' || type === 'Less Income' ? 1 : 0,
            amount: type === 'Add Income' || type === 'Add Expense' ? values.amount : -1 * values.amount,
            date: values.date,
        }

        let response = await saveAccount(data)

        if (response) {
            setForceRefresh(true)
            setShowModal(false)
            form.resetFields()
            notification.open({
                message: type === 'Add Income' ? 'Income has been added.' :
                    type === 'Less Income' ? 'Income has been reduced.' :
                        type === 'Add Expense' ? 'Expense has been added.' : 'Expense has been reduced.',
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
            });
        } else {
            notification.open({ message: 'Unable to add any transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleClick = (type) => {
        setShowModal(true)
        setType(type)
    }

    const initialize = async () => {
        const response = await getAccounts(forceRefresh)

        if (response) {
            const { income, expense } = response
            setIncome(income)
            setExpense(expense)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
    }, [forceRefresh])

    const renderModal = () => (
        <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Modal
                title={type}
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="submit" onClick={form.submit}>
                        Submit
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <Form.Item label="Amount" name="amount" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Amount is required"
                        }
                    ]}
                >
                    <Input type="number" step={0.01} placeholder="Enter Amount" />
                </Form.Item>
                <Form.Item label="Date" name="date" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Date is required"
                        }
                    ]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Description" name="reference" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Description is required"
                        }
                    ]}
                >
                    <TextArea rows={3} placeholder="Enter Description" />
                </Form.Item>
            </Modal>
        </Form>
    )

    const renderAccountMobile = (data) => (
        <>
            {
                data.map(v => (
                    <>
                        <br />
                        <Card>
                            <Text style={{ fontWeight: 'bolder' }}>Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(v.date ?? v.created_at).format(format)}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Description : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.reference}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Amount : <span style={{ fontWeight: 'lighter', marginLeft: 10, color: v.amount < 0 ? 'red' : '' }}>{v.amount}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Balance : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.balance.toFixed(2)}</span></Text>
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderIncome = () => (
        <Tabs.TabPane tab="Income" key="item-1">
            <Space style={{ display: 'flex', justifyContent: 'end' }}>
                <Button type="primary" onClick={() => handleClick('Add Income')}>Add</Button>
                <Button type="primary" danger onClick={() => handleClick('Less Income')}>Less</Button>
            </Space>
            <br />
            {
                isMobile ? (renderAccountMobile(income)) : (
                    <Table
                        dataSource={income}
                        columns={columns}
                        pagination={{ ...PAGINATION_OPTIONS }}
                    />
                )
            }
        </Tabs.TabPane>
    )

    const renderExpense = () => (
        <Tabs.TabPane tab="Expense" key='item-2'>
            <Space style={{ display: 'flex', justifyContent: 'end' }}>
                <Button type="primary" onClick={() => handleClick('Add Expense')}>Add</Button>
                <Button type="primary" danger onClick={() => handleClick('Less Expense')}>Less</Button>
            </Space>
            <br />

            {
                isMobile ? (renderAccountMobile(expense)) : (
                    <Table
                        dataSource={expense}
                        columns={columns}
                        pagination={{ ...PAGINATION_OPTIONS }}
                    />
                )
            }

        </Tabs.TabPane>
    )

    return (
        <>
            <Card>
                <Title level={3}>Account Transactions</Title>
                <Tabs>
                    {renderIncome()}
                    {renderExpense()}
                </Tabs >

                {renderModal()}
            </Card >
        </>
    )
}

export default windowSize(Accounts)