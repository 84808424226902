import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getPurchaseGrn(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.purchaseGrn, 'purchaseGrn');
    } else {
        let purchaseGrn = await loadState('purchaseGrn');

        if (purchaseGrn == null) {
            return await loadResource(urlService.purchaseGrn, 'purchaseGrn');
        } else {
            return Response(purchaseGrn);
        }
    }
}

export async function showPurchaseGrn(id) {
    setDependency()
    return ajaxService.get(urlService.purchaseGrn + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function savePurchaseGrn(data) {
    setDependency()
    return ajaxService.post(urlService.purchaseGrn, data).then(response => {
        return Response(response.data);
    })
}

export async function updatePurchaseGrn(id, data) {
    setDependency()
    return ajaxService.put(urlService.purchaseGrn + '/' + id, data).then(response => {
        return Response(response.data);
    })
}