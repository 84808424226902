import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => ajaxService.removeDependency()
const setDependency = () => ajaxService.setDependency()

export async function getProductStocks(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.productStock, 'productStock');
    } else {
        let productStock = await loadState('productStock');

        if (productStock == null) {
            return await loadResource(urlService.productStock, 'productStock');
        } else {
            return Response(productStock);
        }
    }
}

export async function filterProductStock(data) {
    removeDependency()
    return await ajaxService.post(urlService.productStockFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getProductStockForCombo(data) {
    setDependency()
    return await ajaxService.get(urlService.productStockForCombo, data).then(response => {
        return Response(response.data);
    });
}