import React, { useState, useEffect } from 'react';
import { Card, Col, Divider, Row, Table, Typography, Segmented } from 'antd';
import { getDashboard } from '../../data';
import windowSize from 'react-window-size';

const { Title, Text } = Typography

const Dashboard = ({ storeId, windowWidth }) => {

    const isMobile = windowWidth <= 768;


    const branch_id = localStorage.getItem('branch_id') ?? null;

    const type = branch_id ? 'branch' : 'store';
    const id = branch_id ? branch_id : storeId;

    // For cards
    const [totalSales, setTotalSales] = useState(0)
    const [totalOrders, setTotalOrders] = useState(0)
    const [totalCost, setTotalCost] = useState(0)
    const [todaySales, setTodaySales] = useState(0)
    const [todayOrders, setTodayOrders] = useState(0)
    const [todayCost, setTodayCost] = useState(0)
    const [sevenDaysSales, setSevenDaysSales] = useState(0)
    const [sevenDaysOrders, setSevenDaysOrders] = useState(0)
    const [sevenDaysCost, setSevenDaysCost] = useState(0)
    const [thisWeekSales, setThisWeekSales] = useState(0)
    const [thisWeekOrders, setThisWeekOrders] = useState(0)
    const [thisWeekCost, setThisWeekCost] = useState(0)
    const [lastWeekSales, setLastWeekSales] = useState(0)
    const [lastWeekOrders, setLastWeekOrders] = useState(0)
    const [lastWeekCost, setLastWeekCost] = useState(0)
    const [thisMonthSales, setThisMonthSales] = useState(0)
    const [thisMonthOrders, setThisMonthOrders] = useState(0)
    const [thisMonthCost, setThisMonthCost] = useState(0)
    const [lastMonthSales, setLastMonthSales] = useState(0)
    const [lastMonthOrders, setLastMonthOrders] = useState(0)
    const [lastMonthCost, setLastMonthCost] = useState(0)
    const [currency, setCurrency] = useState('')
    const [module, setModule] = useState('All')

    const [topProducts, setTopProducts] = useState([])
    const [topSales, setTopSales] = useState([])

    const data = [
        {
            headings: ["Today's Orders :", "Today's Sales :", "Today's Cost :"],
            totals: [todayOrders, todaySales, todayCost],
            color: 'Red',
        },
        {
            headings: ["This Week Orders :", "This Week Sales :", "This Week Cost :"],
            totals: [thisWeekOrders, thisWeekSales, thisWeekCost],
            color: 'Purple',
        },
        {
            headings: ["This Month Orders :", "This Month Sales :", "This Month Cost :"],
            totals: [thisMonthOrders, thisMonthSales, thisMonthCost],
            color: 'Teal',
        },
        {
            headings: ["Before 7 Days Orders :", "Before 7 Days Sales :", "Before 7 Days Cost :"],
            totals: [sevenDaysOrders, sevenDaysSales, sevenDaysCost],
            color: 'Orange',
        },
        {
            headings: ["Last Week Orders :", "Last Week Sales :", 'Last Week Cost'],
            totals: [lastWeekOrders, lastWeekSales, lastWeekCost],
            color: 'Purple',
        },
        {
            headings: ["Last Month Orders :", "Last Month Sales :", 'Last Month Cost'],
            totals: [lastMonthOrders, lastMonthSales, lastMonthCost],
            color: 'Teal',
        },
    ];

    const initialize = async () => {

        const response = await getDashboard(`${id}?type=${type}&module=${module}`)

        if (response) {

            setTotalOrders(parseFloat(response.totalOrders))
            setTodayOrders(parseFloat(response.todayOrders))
            setSevenDaysOrders(parseFloat(response.sevenDaysOrders))
            setThisWeekOrders(parseFloat(response.thisWeekOrders))
            setLastWeekOrders(parseFloat(response.lastWeekOrders))
            setThisMonthOrders(parseFloat(response.thisMonthOrders))
            setLastMonthOrders(parseFloat(response.lastMonthOrders))

            setTotalSales(parseFloat(response.totalSales).toFixed(2))
            setTotalCost(parseFloat(response.totalCost).toFixed(2))

            setTodaySales(parseFloat(response.todaySales).toFixed(2))
            setTodayCost(parseFloat(response.todayCost).toFixed(2))
            setSevenDaysSales(parseFloat(response.sevenDaysSales).toFixed(2))
            setSevenDaysCost(parseFloat(response.sevenDaysCost).toFixed(2))
            setThisWeekSales(parseFloat(response.thisWeekSales).toFixed(2))
            setLastWeekSales(parseFloat(response.lastWeekSales).toFixed(2))
            setThisMonthSales(parseFloat(response.thisMonthSales).toFixed(2))
            setLastMonthSales(parseFloat(response.lastMonthSales).toFixed(2))
            setThisWeekCost(parseFloat(response.thisWeekCost).toFixed(2))
            setLastWeekCost(parseFloat(response.lastWeekCost).toFixed(2))
            setThisMonthCost(parseFloat(response.thisMonthCost).toFixed(2))
            setLastMonthCost(parseFloat(response.lastMonthCost).toFixed(2))

            setTopProducts(response.topProducts)
            setTopSales(response.topSales)
        }
    }

    useEffect(() => {
        initialize(id)
    }, [module])

    useEffect(() => {
        let store = JSON.parse(localStorage.getItem('store'))
        if (store){
            setCurrency(store.currency);
        }
        console.log(store)
    }, [])


    const renderDashboard = () => (
        <Card>
            <Title level={3}>Dashboard</Title>
            <Segmented options={['All', 'Ecommerce', 'POS']} onChange={(module) => setModule(module)} />

            <Row gutter={[16, 18]}>
                <Col span={8}>
                    <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span><Title level={5} style={{ color: '#FFFFFF', margin: 0 }}>Total Orders</Title></span>
                            <span> <p style={{ color: '#FFFFFF' }}>{totalOrders}</p></span>
                        </div>
                    </Card>

                </Col>
                <Col span={8}>
                    <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span><Title level={5} style={{ color: '#FFFFFF', margin: 0 }}>Total Sales</Title></span>
                            <span> <p style={{ color: '#FFFFFF' }}>{currency} {totalSales}</p></span>
                        </div>
                    </Card>

                </Col>
                <Col span={8}>
                    <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span><Title level={5} style={{ color: '#FFFFFF', margin: 0 }}>Total Cost</Title></span>
                            <span> <p style={{ color: '#FFFFFF' }}>{currency} {totalCost}</p></span>
                        </div>
                    </Card>
                </Col>
            </Row>


            <Row gutter={[16, 18]}>
                {data.map((item, index) => (
                    <Col span={8}>
                        <Card key={index} style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                            {item.headings.map((heading, i) => (
                                <div key={i} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span><Title level={5} style={{ color: '#FFFFFF', margin: 0 }}>{heading}</Title></span>
                                    <span> <p style={{ color: '#FFFFFF' }}>{i === 0 ? '' : currency} {item.totals[i]}</p></span>
                                </div>
                            ))}
                        </Card>
                    </Col>
                ))}
                <br /><br />
                {/* <Divider /> */}
                <Col span={12}>
                    <Table
                        title={() => <Title level={3}>Top 10 Products Quantity Wise</Title>}
                        dataSource={topProducts}
                        pagination={false}
                        rowKey='index'
                        columns={[
                            {
                                title: 'S.No',
                                key: 'title',
                                render: (text, record, index) => <Text>{index + 1}</Text>
                            },
                            {
                                title: 'Product Name',
                                dataIndex: 'product_name',
                                key: 'product_name',
                            },
                            {
                                title: 'Total Quantity',
                                dataIndex: 'total_quantity',
                                key: 'total_quantity',
                                // align: 'center'
                            },
                        ]}
                    />
                </Col>
                <Col span={12}>
                    <Table
                        title={() => <Title level={3}>Top 10 Products Revenue Wise</Title>}
                        dataSource={topSales}
                        pagination={false}
                        rowKey='index'
                        columns={[
                            {
                                title: 'S.No',
                                key: 'title',
                                render: (text, record, index) => <Text>{index + 1}</Text>
                            },
                            {
                                title: 'Product Name',
                                dataIndex: 'product_name',
                                key: 'product_name',
                            },
                            {
                                title: 'Total Sales',
                                dataIndex: 'total_sales',
                                key: 'total_sales',
                                render : (record) => {
                                    return currency + ' '+ record
                                }
                            },

                        ]}
                    />
                </Col>
            </Row>
        </Card>
    );

    // Mobile
    const renderDashboardMobile = () => (
        <>
            {data.map((item, index) => (
                <Card key={index} style={{ backgroundColor: item.color, marginBottom: 10 }}>
                    {item.headings.map((heading, i) => (
                        <div key={i} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span><Title level={5} style={{ color: '#FFFFFF', margin: 0 }}>{heading}</Title></span>
                            <span> <p style={{ color: '#FFFFFF' }}> {i == 0 ? '' : currency}  {item.totals[i]}</p></span>
                        </div>
                    ))}
                </Card>
            ))}

            <Divider plain>Top 10 Products Quantity Wise</Divider>
            {
                topProducts.map((prod, index) => (
                    <>
                        <Text style={{ fontWeight: 'bolder' }}>S.No : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{index + 1}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Product Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{prod.product_name}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Total Quantity : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{prod.total_quantity}</span></Text>
                        <Divider />
                    </>
                ))
            }

            <Divider plain>Top 10 Products Revenue Wise</Divider>
            {
                topSales.map((prod, index) => (
                    <>
                        <Text style={{ fontWeight: 'bolder' }}>S.No : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{index + 1}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Product Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{prod.product_name}</span></Text>
                        <br />
                        <Text style={{ fontWeight: 'bolder' }}>Total Sales : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{currency} {prod.total_sales}</span></Text>
                        <Divider />
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderDashboardMobile()
        } else {
            return renderDashboard()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    );

}

export default windowSize(Dashboard)