import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Button, Col, Form, Input, message, notification, Row, Space, Switch } from "antd";
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getPage, savePage, updatePage } from "../../data";
import windowSize from "react-window-size";

const { TextArea } = Input;

const PageForm = ({ windowWidth }) => {
    const [form] = Form.useForm();
    const [text, setText] = useState("")
    const isMobile = windowWidth <= 768
    let history = useHistory()
    let { id } = useParams()

    const onFinish = async (values) => {
        let data = {
            content: text,
            title: values.title,
            active: values.active,
            meta_keywords: values.meta_keywords,
            meta_description: values.meta_description,
        }
        if (text === '') {
            return message.error('Please fill in the content box')
        } else {
            if (id == 0) {
                const saveData = await savePage(data)
                if (saveData) {
                    history.push({ pathname: "/pages" });
                    notification.open({ message: 'Page has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to add page', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
            else {
                const updateData = await updatePage(id, data);
                if (updateData) {
                    history.push({ pathname: '/pages' });
                    notification.open({ message: 'Page has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to update page', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadData = async (id) => {
        if (id > 0) {
            const page = await getPage(id)
            if (page !== null) {
                form.setFieldsValue({ ...page })
                if (page.content !== null) {
                    setText(page.content)
                }
            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    const renderPageForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Title" name='title' rules={[
                                        {
                                            required: true,
                                            message: 'Title is required',
                                        }]}>
                                        <Input placeholder="Page Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Meta Keywords" name='meta_keywords' rules={[
                                        {
                                            required: true,
                                            message: 'Meta keywords is required',
                                        }]}>
                                        <Input placeholder="Meta Keywords" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Meta Description" name='meta_description' rules={[
                                        {
                                            required: true,
                                            message: 'Meta description is required',
                                        }]}>
                                        <TextArea rows={3} placeholder="Meta Description" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Content" >
                                        <CKEditor
                                            className='editor'
                                            editor={ClassicEditor}
                                            onReady={editor => editor.setData(text)}
                                            onChange={(event, editor) => {
                                                const data = editor.getData()
                                                setText(data)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="active" label="Active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderPageFormMobile = () => {
        return (
            <>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >
                        <Col span={24}>
                            <Form.Item label="Title" name='title' rules={[
                                {
                                    required: true,
                                    message: 'Title is required',
                                }]}>
                                <Input placeholder="Page Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Meta Keywords" name='meta_keywords' rules={[
                                {
                                    required: true,
                                    message: 'Meta keywords is required',
                                }]}>
                                <Input placeholder="Meta Keywords" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Meta Description" name='meta_description' rules={[
                                {
                                    required: true,
                                    message: 'Meta description is required',
                                }]}>
                                <TextArea rows={3} placeholder="Meta Description" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Content" >
                                <CKEditor
                                    className='editor'
                                    editor={ClassicEditor}
                                    onReady={editor => editor.setData(text)}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        setText(data)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="active" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderPageFormMobile()
        } else {
            return renderPageForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(PageForm)