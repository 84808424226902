import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Row, Col, Space, Switch } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ImageUpload from '../../../components/image-upload';
import { getBrand, saveBrand, updateBrand } from '../../../data';
import windowSize from 'react-window-size';
import { ajaxService } from '../../../services';
import { GlobalVariable } from '../../../services/ajax-service';

const BrandForm = ({ windowWidth }) => {
    const [fileList, setFileList] = useState([]);
    const isMobile = windowWidth <= 768

    let history = useHistory();
    let { id } = useParams();
    const [form] = Form.useForm();

    const onChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
    };

    const onFinish = async (values) => {
        let link = '';

        if (fileList.length > 0) {

            let { response } = fileList[0];

            if (response != undefined) {
                let { success, path } = response;

                if (success) {
                    link = path
                }
            }
            else if (fileList[0].url !== undefined) {
                link = fileList[0].url.replace(GlobalVariable.BASE_IMAGE_URL + '/', "");
            }
        } else {
            link = null;
        }

        let data = {
            active: values.active,
            description: values.description,
            image: link,
            meta_description: values.meta_description,
            meta_title: values.meta_title,
            name: values.name,
            sort_order: values.sort_order
        }
        if (id == 0) {
            const saveData = await saveBrand(data);

            if (saveData) {
                history.push({ pathname: "/brands" });
                notification.open({ message: 'Brand has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add brand', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

            }

        } else {
            if (data.image === '') {
                data.image = fileList[0]?.url;
                data.active = values.active
            }
            const updateData = await updateBrand(id, data);
            if (updateData) {
                history.push({ pathname: "/brands" });
                notification.open({ message: 'Brand has been udated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update brand', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

            }
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadData = async (id) => {
        if (id > 0) {
            let data = await getBrand(id);
            if (data) {
                form.setFieldsValue({ ...data });

                if (data.image) {
                    let imageItem = {
                        uid: data.image,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(data.image),
                        preview: false
                    }
                    setFileList([imageItem]);
                }
            }
        }
    }

    useEffect(() => {
        loadData(id);
    }, []);

    const renderBrandForm = () => {
        return (
            <Form
                layout={'vertical'}
                initialValues={{
                    active: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Name" name='name' rules={[
                                    {
                                        required: true,
                                        message: 'Brand Name is required',
                                    },
                                ]}>
                                    <Input placeholder="brand name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='meta_title' label="Meta Title">
                                    <Input placeholder="meta title" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name='meta_description' label="Meta Description">
                                    <Input placeholder="meta Description" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='sort_order' label="Sort Order">
                                    <Input placeholder="sort order" type='number' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Upload Image" rules={[
                                    {
                                        required: false,
                                        message: 'Image is required',
                                    },
                                ]}>
                                    <ImageUpload onChange={onChange} fileList={fileList} crop={true} accept="image/*" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="active" label="Active" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>

                    </Col>

                    <Col span={6}></Col>
                </Row >
            </Form >
        );
    }

    const renderBrandFormMobile = () => {
        return (
            <Form
                initialValues={{ active: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Name" name='name' rules={[
                            {
                                required: true,
                                message: 'Brand Name is required',
                            },
                        ]}>
                            <Input placeholder="brand name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='meta_title' label="Meta Title" >
                            <Input placeholder="meta title" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='meta_description' label="Meta Description" >
                            <Input placeholder="meta Description" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='sort_order' label="Sort Order">
                            <Input placeholder="sort order" type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Upload Image" name="file" rules={[
                            {
                                required: false,
                                message: 'Image is required',
                            },
                        ]}>
                            <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                        </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={12}>
                        <Form.Item name="active" label="Active" valuePropName="checked">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>


            </Form >
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderBrandFormMobile()
        }
        else {
            return renderBrandForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

};


export default windowSize(BrandForm)