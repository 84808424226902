import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// import {
//     AuthLayout,
//     MainLayout,
// } from '../screens';

import AuthLayout from '../layout/auth';
import MainLayout from '../layout';
import Order from '../pages/order';
import OrderForm from '../pages/order/order';
import Store from '../pages/store';
import Category from '../pages/catalogue/category';
import Brands from '../pages/catalogue/brand';
import BrandForm from '../pages/catalogue/brand/form';
import Offer from '../pages/offer';
import CategoryForm from '../pages/catalogue/category/form'
import Settings from '../pages/settings';
import OfferForm from '../pages/offer/form'
import Product from '../pages/catalogue/product';
import ProductForm from '../pages/catalogue/product/form';
import ProductVariant from '../pages/catalogue/product/variant';
import ProductCombo from '../pages/catalogue/product/combo';
import Login from '../pages/auth/login';
import StoreForm from '../pages/store/form';
import BranchForm from '../pages/branch/form';
import PriceBook from '../pages/catalogue/product/price_book';
import Pages from '../pages/page';
import PageForm from '../pages/page/form';
import PaymentMethods from '../pages/payment_method';
import ShippingMethods from '../pages/shipping_method';
import PaymentMethodForm from '../pages/payment_method/form';
import ShippingMethodForm from '../pages/shipping_method/form';
import Users from '../pages/users';
import Profile from '../pages/user_profile';
import ProfileForm from '../pages/user_profile/form';
import ChangePasswordForm from '../pages/user_profile/changePassword';
import UserForm from '../pages/users/form';
import Role from '../pages/role';
import RoleForm from '../pages/role/form';
import BranchCatalog from '../pages/store_setting/branch_catalog';
import StoreSettings from '../pages/store_setting';
import Supplier from '../pages/supplier';
import SupplierForm from '../pages/supplier/form';
import PurchaseOrders from '../pages/purchase_order';
import PurchaseOrderForm from '../pages/purchase_order/form';
import PurchaseOrderView from '../pages/purchase_order/view';
import PurchaseGrn from '../pages/purchase_grn';
import PurchaseGrnForm from '../pages/purchase_grn/form';
import PurchaseGrnView from '../pages/purchase_grn/view';
import Ledger from '../pages/ledger';
import Customers from '../pages/customer';
import Stores from '../pages/store/listing';
import Packages from '../pages/package';
import PackageForm from '../pages/package/form';
import PackageView from '../pages/package/view';
import StorePermissions from '../pages/store/permission';
import TableManagements from '../pages/table_management';
import TableManagementForm from '../pages/table_management/form';
import StaffManagements from '../pages/staff_management';
import StaffManagementForm from '../pages/staff_management/form';
import StockTransfer from '../pages/stock_transfer';
import StockTransferForm from '../pages/stock_transfer/form';
import StockTransferView from '../pages/stock_transfer/view';
import ProductLedgerReport from '../pages/reports/productLedger';
import DetailedInventoryReport from '../pages/reports/detailedInventory';
import Banners from '../pages/banner';
import BannerForm from '../pages/banner/form';
import Cuisines from '../pages/catalogue/cuisine';
import CuisineForm from '../pages/catalogue/cuisine/form';
import ProductInventoryReport from '../pages/reports/productInventory';
import LowStockReport from '../pages/reports/lowStock';
import OutOfStock from '../pages/reports/outOfStock';
import StockUpdate from '../pages/catalogue/product/stock_update';
import Accounts from '../pages/accounts';
import ProfitLossStatements from '../pages/reports/profitLossStatement';
import DamageDiscardReport from '../pages/reports/damageDiscard';
import CustomerForm from '../pages/customer/form';
import Dashboard from '../pages/dasboard';
import DamageDiscardProductForm from '../pages/damage_discard_product/form';
import DamageDiscardProducts from '../pages/damage_discard_product';
import IncomeExpenseReport from '../pages/reports/incomeExpense';
import PandingAproval from '../pages/auth/pandingAproval';
import Permissions from '../pages/role/permissions';
import ProductsExpiryReport from '../pages/reports/productExpiry';
import Importer from '../pages/importer';
import ForgotPassword from '../pages/auth/forgot_password';
import ResetPassword from '../pages/auth/reset_password';
import BranchLedger from '../pages/branch/ledger';
import CustomizeSalesReport from '../pages/reports/customizeSales';
import OrderPrint from '../pages/order/print';
import Reconciliations from '../pages/reconciliation';
import ReconciliationForm from '../pages/reconciliation/form';
import ReconciliationView from '../pages/reconciliation/view';

const AuthRedirect = ({ destination }) => {
    return <Route render={(props) => (<Redirect to={{ pathname: destination, state: { from: props.location } }} />)} />;
}

const wrapper = (Layout, Component) => {

    let accessToken = localStorage.getItem('token');

    const redirect = <AuthRedirect destination={'/login'}></AuthRedirect>

    if (accessToken == null) return redirect;

    return (
        <Layout>
            <Component />
        </Layout>
    )
}

export default function Navigation() {
    return (
        <Router>

            {/* Auth */}
            <Route exact path={`/login`} component={() => <AuthLayout><Login /></AuthLayout>} />
            <Route exact path={`/pandingAproval`} component={() => <AuthLayout><PandingAproval /></AuthLayout>} />
            <Route exact path={`/forgotPassword`} component={() => <AuthLayout><ForgotPassword /></AuthLayout>} />
            <Route exact path={`/password/reset/:token?`} component={() => <AuthLayout><ResetPassword /></AuthLayout>} />

            {/* Catalogue */}
            <Route exact path="/brands/form/:id?" component={() => wrapper(MainLayout, BrandForm)} />
            <Route exact path="/brands" component={() => wrapper(MainLayout, Brands)} />
            <Route exact path="/categories/form/:id?" component={() => wrapper(MainLayout, CategoryForm)} />
            <Route exact path="/categories" component={() => wrapper(MainLayout, Category)} />
            <Route exact path="/products" component={() => wrapper(MainLayout, Product)} />
            <Route exact path="/products/form/:id?" component={() => wrapper(MainLayout, ProductForm)} />
            <Route exact path="/products/variant/:id?" component={() => wrapper(MainLayout, ProductVariant)} />
            <Route exact path="/products/combo/:id?" component={() => wrapper(MainLayout, ProductCombo)} />
            <Route exact path="/products/priceBook" component={() => wrapper(MainLayout, PriceBook)} />
            <Route exact path={`/stock-update`} component={() => wrapper(MainLayout, StockUpdate)} />

            {/* Ecommerce */}
            <Route exact path={`/pages`} component={() => wrapper(MainLayout, Pages)} />
            <Route exact path={`/pages/form/:id?`} component={() => wrapper(MainLayout, PageForm)} />
            <Route exact path={`/payment-methods`} component={() => wrapper(MainLayout, PaymentMethods)} />
            <Route exact path={`/payment-methods/form/:id?`} component={() => wrapper(MainLayout, PaymentMethodForm)} />
            <Route exact path={`/shipping-methods`} component={() => wrapper(MainLayout, ShippingMethods)} />
            <Route exact path={`/shipping-methods/form/:id?`} component={() => wrapper(MainLayout, ShippingMethodForm)} />

            {/* Manage Inventory */}
            <Route exact path={`/purchase-orders`} component={() => wrapper(MainLayout, PurchaseOrders)} />
            <Route exact path={`/purchase-orders/form/:id?`} component={() => wrapper(MainLayout, PurchaseOrderForm)} />
            <Route exact path={`/purchase-orders/view/:id?`} component={() => wrapper(MainLayout, PurchaseOrderView)} />
            <Route exact path={`/purchase-grn`} component={() => wrapper(MainLayout, PurchaseGrn)} />
            <Route exact path={`/purchase-grn/form/:id?`} component={() => wrapper(MainLayout, PurchaseGrnForm)} />
            <Route exact path={`/purchase-grn/view/:id?`} component={() => wrapper(MainLayout, PurchaseGrnView)} />
            <Route exact path={`/suppliers`} component={() => wrapper(MainLayout, Supplier)} />
            <Route exact path={`/suppliers/form/:id?`} component={() => wrapper(MainLayout, SupplierForm)} />
            <Route exact path={`/stock-transfer`} component={() => wrapper(MainLayout, StockTransfer)} />
            <Route exact path={`/stock-transfer/form/:id?`} component={() => wrapper(MainLayout, StockTransferForm)} />
            <Route exact path={`/stock-transfer/view/:id?`} component={() => wrapper(MainLayout, StockTransferView)} />
            <Route exact path={`/damage-discard-products`} component={() => wrapper(MainLayout, DamageDiscardProducts)} />
            <Route exact path={`/damage-discard-products/form/:id?`} component={() => wrapper(MainLayout, DamageDiscardProductForm)} />

            {/* User Management */}
            <Route exact path={`/roles`} component={() => wrapper(MainLayout, Role)} />
            <Route exact path={`/roles/form/:id?`} component={() => wrapper(MainLayout, RoleForm)} />
            <Route exact path={`/roles/:id?/permissions`} component={() => wrapper(MainLayout, Permissions)} />
            <Route exact path={`/staff-managements`} component={() => wrapper(MainLayout, StaffManagements)} />
            <Route exact path={`/staff-managements/form/:id?`} component={() => wrapper(MainLayout, StaffManagementForm)} />
            <Route exact path={`/users`} component={() => wrapper(MainLayout, Users)} />
            <Route exact path={`/users/form/:id?`} component={() => wrapper(MainLayout, UserForm)} />

            {/* Reports */}
            <Route exact path={`/customers-report`} component={() => wrapper(MainLayout, Customers)} />
            <Route exact path={`/customers/form/:id?`} component={() => wrapper(MainLayout, CustomerForm)} />
            <Route exact path={`/product-ledger-report`} component={() => wrapper(MainLayout, ProductLedgerReport)} />
            <Route exact path={`/detailed-inventory-report`} component={() => wrapper(MainLayout, DetailedInventoryReport)} />
            <Route exact path={`/product-inventory-report`} component={() => wrapper(MainLayout, ProductInventoryReport)} />
            <Route exact path={`/low-stock-report`} component={() => wrapper(MainLayout, LowStockReport)} />
            <Route exact path={`/out-of-stock-report`} component={() => wrapper(MainLayout, OutOfStock)} />
            <Route exact path={`/profit-and-loss-report`} component={() => wrapper(MainLayout, ProfitLossStatements)} />
            <Route exact path={`/damage-discard-report`} component={() => wrapper(MainLayout, DamageDiscardReport)} />
            <Route exact path={`/sales-income-expense-report`} component={() => wrapper(MainLayout, IncomeExpenseReport)} />
            <Route exact path={`/product-expiry-report`} component={() => wrapper(MainLayout, ProductsExpiryReport)} />
            <Route exact path={`/customize-sales-report`} component={() => wrapper(MainLayout, CustomizeSalesReport)} />

            {/* Store Settings */}
            <Route exact path={`/store-settings`} component={() => wrapper(MainLayout, StoreSettings)} />
            <Route exact path={`/store-settings/branch-catalog`} component={() => wrapper(MainLayout, BranchCatalog)} />

            {/* Offers */}
            <Route exact path={`/offer`} component={() => wrapper(MainLayout, Offer)} />
            <Route exact path={`/offer/form/:id?`} component={() => wrapper(MainLayout, OfferForm)} />

            {/* Orders */}
            <Route exact path={`/orders`} component={() => wrapper(MainLayout, Order)} />
            <Route exact path={`/orders/form/:id?`} component={() => wrapper(MainLayout, OrderForm)} />
            <Route exact path={`/orders/print/:id?`} component={() => wrapper(MainLayout, OrderPrint)} />

            {/* Banners */}
            <Route exact path={`/banners`} component={() => wrapper(MainLayout, Banners)} />
            <Route exact path={`/banners/form/:id?`} component={() => wrapper(MainLayout, BannerForm)} />

            {/* Table Management */}
            <Route exact path={`/table-managements`} component={() => wrapper(MainLayout, TableManagements)} />
            <Route exact path={`/table-managements/form/:id?`} component={() => wrapper(MainLayout, TableManagementForm)} />

            {/* Pakages */}
            <Route exact path={`/packages`} component={() => wrapper(MainLayout, Packages)} />
            <Route exact path={`/packages/form/:id?`} component={() => wrapper(MainLayout, PackageForm)} />
            <Route exact path={`/packages/view/:id?`} component={() => wrapper(MainLayout, PackageView)} />

            {/* Profile */}
            <Route exact path={`/profile`} component={() => wrapper(MainLayout, Profile)} />
            <Route exact path={`/profile/form/:id?`} component={() => wrapper(MainLayout, ProfileForm)} />
            <Route exact path={`/profile/change-password/form/:id?`} component={() => wrapper(MainLayout, ChangePasswordForm)} />

            {/* Stores */}
            {/* <Route exact path={`/stores`} component={() => wrapper(MainLayout, Stores)} /> */}
            <Route exact path={`/stores/permissions/:id?`} component={() => wrapper(MainLayout, StorePermissions)} />
            <Route exact path={`/stores/form/:id?`} component={() => wrapper(MainLayout, StoreForm)} />
            <Route exact path={`/branch-dashboard`} component={() => wrapper(MainLayout, Dashboard)} />
            <Route exact path={`/branches/:store_id/form/:id?`} component={() => wrapper(MainLayout, BranchForm)} />
            <Route exact path={`/branches/ledger/:id?`} component={() => wrapper(MainLayout, BranchLedger)} />

            {/* Others */}
            <Route exact path={`/setting`} component={() => wrapper(MainLayout, Settings)} />
            <Route exact path={`/`} component={() => wrapper(MainLayout, Store)} />
            <Route exact path={`/ledger/:id?`} component={() => wrapper(MainLayout, Ledger)} />
            {/* <Route exact path={`/cuisines`} component={() => wrapper(MainLayout, Cuisines)} />
            <Route exact path={`/cuisines/form/:id?`} component={() => wrapper(MainLayout, CuisineForm)} /> */}
            <Route exact path={`/accounts`} component={() => wrapper(MainLayout, Accounts)} />
            {/* Excel */}
            <Route exact path={`/importer`} component={() => wrapper(MainLayout, Importer)} />

            {/* Reconciliations */}
            <Route exact path={`/reconciliations`} component={() => wrapper(MainLayout, Reconciliations)} />
            <Route exact path={`/reconciliations/form/:id?`} component={() => wrapper(MainLayout, ReconciliationForm)} />
            <Route exact path={`/reconciliations/view/:id?`} component={() => wrapper(MainLayout, ReconciliationView)} />


        </Router>
    );
}