import { Col, Row, Form, Switch, Select, Divider } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { getProducts } from "../../data";

const { Option } = Select

const EnablePos = ({ Setting, mobile }) => {

    let data;

    const [options, setOptions] = useState([]);

    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    useEffect(() => {

        const getSelectOptions = async () => {
            let response = await getProducts(true)

            if (response.length > 0) {
                const options = response.map((item) => ({
                    value: item.id,
                    label: item.sku + ' ' + item.name,
                }));
                setOptions(options)
            }
        }

        getSelectOptions()

    }, [])

    return (
        <>
            <Row gutter={16}>
                <Col span={20}>
                    <Form.Item name='print_receipt' label="Print Receipt"
                        labelCol={{ span: 24 }}
                        initialValue={data.print_receipt ?? "thermal"}
                        rules={[
                            {
                                required: true,
                                message: 'Print receipt is required',
                            },
                        ]}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select print receipt">
                            <Option value="thermal">Thermal</Option>
                            <Option value="a4">A4</Option>
                            <Option value="a5">A5</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={20}>
                    <Form.Item name='featured_products' label="Featured Products"
                        labelCol={{ span: 24 }}
                        initialValue={data.featured_products?? []}
                        rules={[
                            {
                                required: true,
                                message: 'Featured Products field is required',
                            },
                        ]}>
                        <Select
                            mode="multiple"
                            showSearch
                            autoFocus
                            allowClear={true}
                            style={{
                                width: '100%',
                            }}
                            placeholder="Search by product name or SKU"
                            options={options}
                        />
                    </Form.Item>
                </Col>
                <Divider />

                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='enable_image' label="Enable Image"
                        labelCol={{ span: 24 }}
                        initialValue={data.enable_image}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.enable_image == 1 ? true : false} />
                    </Form.Item>
                </Col>
                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='editable_unit_price' label="Editable Unit Price"
                        labelCol={{ span: 24 }}
                        initialValue={data.editable_unit_price}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.editable_unit_price == 1 ? true : false} />
                    </Form.Item>
                </Col>
                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='enable_discount' label="Enable Discount"
                        labelCol={{ span: 24 }}
                        initialValue={data.enable_discount}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.enable_discount == 1 ? true : false} />
                    </Form.Item>
                </Col>
                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='enable_item_discount' label="Enable Item Discount"
                        labelCol={{ span: 24 }}
                        initialValue={data.enable_item_discount}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.enable_item_discount == 1 ? true : false} />
                    </Form.Item>
                </Col>

                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='cart_parking' label="Cart Parking"
                        labelCol={{ span: 24 }}
                        initialValue={data.cart_parking}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.cart_parking == 1 ? true : false} />
                    </Form.Item>
                </Col>
                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='verify_quantity' label="Verify Quantity"
                        labelCol={{ span: 24 }}
                        initialValue={data.verify_quantity}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.verify_quantity == 1 ? true : false} />
                    </Form.Item>
                </Col>
                <Col span={mobile ? 12 : 8}>
                    <Form.Item name='auto_adjust_quantity' label="Auto Adjust Quantity"
                        labelCol={{ span: 24 }}
                        initialValue={data.auto_adjust_quantity}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.auto_adjust_quantity == 1 ? true : false} />
                    </Form.Item>
                </Col>

                <Col span={mobile ? 12 : 16}>
                    <Form.Item name='display_branch_address' label="Display Branch Address in Receipt"
                        labelCol={{ span: 24 }}
                        initialValue={data.display_branch_address}>
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.display_branch_address == 1 ? true : false} />
                    </Form.Item>
                </Col>                
            </Row>
        </>
    )
}
export default EnablePos