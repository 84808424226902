import { Modal } from "antd";
import { Limits } from "../constant";

const LimitExceedModal = () => {
    Modal.error({
        title: 'Exceeding  Limits',
        content: Limits.MESSAGE,
    })
}

export default LimitExceedModal