import { Button, Card, Modal, notification, Space, Switch, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { deletePage, getPages, updatePage } from "../../data";
import { PAGINATION_OPTIONS } from "../../constants";
import { updateDataSource } from "../../utility/updateDataSource";
import windowSize from "react-window-size";

const { Title, Text } = Typography;

function Pages({ windowWidth }) {

    const isMobile = windowWidth <= 768
    let history = useHistory()

    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    const updateValue = async (id, checked, type) => {

        const updatedPages = updateDataSource(dataSource, id, checked);
        setDataSource(updatedPages);

        const active = await updatePage(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            if (checked === true) {
                notification.open({ message: 'Page activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (checked === false) {
                notification.open({ message: 'Page deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '75%',

        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => (
                <Switch
                    onChange={(checked) => updateValue(record.id, checked, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={active == 1 ? true : false}
                />
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: '/pages/form/' + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            )
        },
    ]

    const deleteRow = async () => {
        let page_id = localStorage.getItem('page')
        const data = await deletePage(page_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Page has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted page', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('page', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getPages(forceRefresh);
        if (data) {
            setDataSource(data);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Pages</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/pages/form/0' })}>Add Page</Button>
            </Space>
        </div>
    )

    const renderModal = () => (
        <Modal
            title="Delete Page"
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteRow()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this page?</p>
            </div>
        </Modal>
    )

    const renderPages = () => (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
        </>
    )

    const renderPagesMobile = () => (
        <>
            {
                dataSource.map(page => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Space size='small'>
                                    <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: '/pages/form/' + page.id })}>Edit</Button>
                                    <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(page.id)}>Delete</Button>
                                </Space>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{page.title}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Active :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    <Switch
                                        onChange={(val) => updateValue(page.id, val, 'active')}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={page.active == 1 ? true : false} />
                                </span>
                            </Text>
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderPagesMobile()}
                </>
            )
        }
        else {
            return renderPages()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal()}
        </>
    )
}

export default windowSize(Pages)