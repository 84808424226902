import { Form, Input, Button, Row, Col, Space, Switch, notification } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { getTableManagement, saveTableManagement, updateTableManagement } from '../../data';
import windowSize from 'react-window-size';

function TableManagementForm({ windowWidth }) {

    const [form] = Form.useForm();
    const isMobile = windowWidth <= 768;
    const branch_id = localStorage.getItem('branch_id')

    let history = useHistory();
    let { id } = useParams();

    const onFinish = async (values) => {

        let data = {
            name: values.name,
            branch_id: branch_id,
            active: values.active,
        }
        if (id == 0) {
            const saveData = await saveTableManagement(data);
            if (saveData) {
                history.push({ pathname: '/table-managements' });
                notification.open({ message: 'Table has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add table', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const updateData = await updateTableManagement(id, data);
            if (updateData) {
                history.push({ pathname: '/table-managements' });
                notification.open({ message: 'Table has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update table', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadData = async (id) => {
        if (id > 0) {
            let response = await getTableManagement(id);
            if (response != null) {
                form.setFieldsValue({ ...response });
            }
        }
    }

    useEffect(() => {
        loadData(id);
    }, []);

    const renderTableManagementForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    initialValues={{
                        active: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name='name' label="Name" rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        },
                                    ]}>
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='active' label="Active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        );
    }

    const renderTableManagementFormMobile = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    initialValues={{
                        active: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}>
                    <Row gutter={16} >
                        <Col span={24}>
                            <Form.Item name='name' label="Name" rules={[
                                {
                                    required: true,
                                    message: 'Name is required',
                                },
                            ]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='active' label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Row>
                </Form>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderTableManagementFormMobile()
        } else {
            return renderTableManagementForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

};
export default windowSize(TableManagementForm)