import { Button, Col, Form, Input, notification, Row, Select, Space, Switch } from "antd"
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom";
import { getShippingMethod, saveShippingMethod, updateShippingMethod } from "../../data";
import windowSize from "react-window-size";

const { TextArea } = Input;

const ShippingMethodForm = ({ windowWidth }) => {
    let history = useHistory()
    const [form] = Form.useForm()
    const isMobile = windowWidth <= 768
    let { id } = useParams()

    const onFinish = async (values) => {
        let data = {
            name: values.name,
            charges: values.charges,
            description: values.description,
            disclaimer: values.disclaimer,
            active: values.active
        }

        if (id == 0) {
            const saveData = await saveShippingMethod(data)
            if (saveData) {
                history.push({ pathname: "/shipping-methods" });
                notification.open({ message: 'Shipping method has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add shipping method', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updateShippingMethod(id, data)
            if (updateData) {
                history.push({ pathname: "/shipping-methods" });
                notification.open({ message: 'Shipping method has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update shipping method', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    const loadData = async (id) => {
        if (id > 0) {
            const data = await getShippingMethod(id)
            if (data) {
                form.setFieldsValue({ ...data })
            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    const renderShippingMethodForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Name" name='name' rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        }]}>
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Charges" name='charges' rules={[
                                        {
                                            required: true,
                                            message: 'Charges is required',
                                        }]}>
                                        <Input placeholder="Charges" type="number" step={0.01} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Disclaimer" name='disclaimer' rules={[
                                        {
                                            required: true,
                                            message: 'Disclaimer is required',
                                        }]}>
                                        <Input placeholder="Disclaimer" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="active" label="Active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Description" name='description' rules={[
                                        {
                                            required: true,
                                            message: 'Description is required',
                                        }]}>
                                        <TextArea rows={5} placeholder="Description" />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderShippingMethodFormMobile = () => {
        return (
            <>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >
                        <Col span={24}>
                            <Form.Item label="Name" name='name' rules={[
                                {
                                    required: true,
                                    message: 'Name is required',
                                }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Charges" name='charges' rules={[
                                {
                                    required: true,
                                    message: 'Charges is required',
                                }]}>
                                <Input placeholder="Charges" type="number" step={0.01} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Disclaimer" name='disclaimer' rules={[
                                {
                                    required: true,
                                    message: 'Disclaimer is required',
                                }]}>
                                <Input placeholder="Disclaimer" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Description" name='description' rules={[
                                {
                                    required: true,
                                    message: 'Description is required',
                                }]}>
                                <TextArea rows={3} placeholder="Description" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="active" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderShippingMethodFormMobile()
        }
        else {
            return renderShippingMethodForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}
export default windowSize(ShippingMethodForm)