import { ajaxService, urlService } from "../services";
import { Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getLedger(id) {
    setDependency()
    return ajaxService.get(urlService.ledger + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function saveLedger(data) {
    setDependency()
    return ajaxService.post(urlService.ledger, data).then(response => {
        return Response(response.data);
    })
}