import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, notification, Typography, Card } from 'antd';
import { useHistory } from "react-router-dom";
import { CloseCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deleteRole, getRoles } from '../../data';
import { PAGINATION_OPTIONS } from '../../constants';
import windowSize from "react-window-size";

const { Title, Text } = Typography;

const Role = ({ windowWidth }) => {

  const isMobile = windowWidth <= 768

  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(true);
  const viewRecords = true
  const addRecord = true
  const editRecord = true
  const deleteRecord = true
  const otherRecord = true

  let history = useHistory();

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',

    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text, { id, name }) => (
        <Space size='small' style={{ display: 'flex', justifyContent: 'end' }}>
          {editRecord && <Button onClick={() => history.push({ pathname: "/roles/form/" + id })}  >Edit</Button>}
          {deleteRecord && <Button onClick={() => openDeleteModal(id)}>Delete</Button>}
          {otherRecord && name != 'admin' && <Button type='primary' onClick={() => history.push({ pathname: "/roles/" + id + "/permissions" })}>Permissions</Button>}
        </Space>
      ),
    },
  ];

  const deleteRow = async () => {
    let role_id = localStorage.getItem('role_id')
    const data = await deleteRole(role_id);

    if (data) {
      setShowModal(false)
      setForceRefresh(true)
      notification.open({ message: 'Role has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      setShowModal(false)
      notification.open({ message: 'Unable to delete role', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const openDeleteModal = (id) => {
    localStorage.setItem('role_id', id);
    setShowModal(true)
  }

  const initialize = async () => {
    const data = await getRoles(forceRefresh);
    if (data) {
      setDataSource(data);
      setForceRefresh(false)
    }
  }

  useEffect(() => {
    if (forceRefresh) {
      initialize();
      setForceRefresh(false)
    }
  }, [forceRefresh])

  const renderHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Title level={3}>Roles</Title>
      <Space size="small" >
        {addRecord && <Button type="primary" onClick={() => history.push({ pathname: "/roles/form/0" })}>Add Role</Button>}
      </Space>
    </div>
  );

  const renderModal = (
    <Modal
      title="Delete Role"
      centered
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={[
        <Button key="delete" onClick={() => deleteRow()}>
          Delete
        </Button>
        ,
        <Button key="back" onClick={() => setShowModal(false)}>
          Close
        </Button>
      ]}
    >
      <p>Are you sure you want to delete this role?</p>
    </Modal>
  )

  const renderRole = () => (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        title={() => renderHeader()}
        pagination={{ ...PAGINATION_OPTIONS }}
      />

      {renderModal}
    </div>
  )

  const renderRoleMobile = () => (
    <>
      {
        dataSource.map(role => (
          <>
            <br />
            <Card
              style={{ width: '100%' }}
              actions={[
                <Space size='small'>
                  {editRecord && <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/roles/form/" + role.id })}>Edit</Button>}
                  {deleteRecord && <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(role.id)}>Delete</Button>}
                  {otherRecord && role.name != 'admin' && <Button onClick={() => history.push({ pathname: "/roles/" + role.id + "/permissions" })}>Permissions</Button>}
                </Space>
              ]}>
              <Text style={{ fontWeight: 'bolder' }}>Title : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{role.title}</span></Text>
            </Card>
          </>
        ))
      }
    </>
  )

  const renderContent = () => {
    if (isMobile) {
      return (
        <>
          {renderHeader()}
          {renderRoleMobile()}
        </>
      )
    }
    else {
      return renderRole()
    }
  }

  return (
    <>
      {renderContent()}
      {renderModal}
    </>
  )

}

export default windowSize(Role)