import { Col, Row, Form, Input } from "antd";

const BykeaDelivery = ({ Setting }) => {
    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='bykea_username' label="Bykea Username"
                        labelCol={{ span: 24 }}
                        initialValue={data?.bykea_username}
                        rules={[
                            {
                                required: true,
                                message: 'Bykea username is required',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='bykea_password' label="Bykea Password"
                        labelCol={{ span: 24 }}
                        initialValue={data?.bykea_password}
                        rules={[
                            {
                                required: true,
                                message: 'Bykea password is required',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
export default BykeaDelivery