import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getStoreSettings(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.storeSettings, 'controls');
    } else {
        let controls = await loadState('controls');

        if (controls == null) {
            return await loadResource(urlService.storeSettings, 'controls');
        } else {
            return Response(controls);
        }
    }
}

export async function getStoreSetting(slug) {
    setDependency()
    return ajaxService.get(urlService.storeSettings + '/' + slug).then(response => {
        return Response(response.data);
    })
}

export async function updateStoreSetting(id, data) {
    setDependency()
    return ajaxService.put(urlService.storeSettings + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function filterStoreSetting(data) {
    setDependency()
    return await ajaxService.post(urlService.storeSettingFilter, data).then(response => {
        return Response(response.data);
    });
}