import { Button, Col, Divider, Form, Input, message, notification, Row, Space, Switch } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import windowSize from "react-window-size";
import { getPackage, savePackage, updatePackage } from "../../data";

const { TextArea } = Input;

const PakageForm = ({ windowWidth }) => {
    const [form] = Form.useForm();
    const [text, setText] = useState("")
    const isMobile = windowWidth <= 768
    let history = useHistory()
    let { id } = useParams()

    const onFinish = async (values) => {
        if (id == 0) {
            const response = await savePackage(values)
            if (response) {
                history.push({ pathname: "/packages" });
                notification.open({ message: 'Package has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add package', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const response = await updatePackage(id, values)
            if (response) {
                history.push({ pathname: "/packages" });
                notification.open({ message: 'Package has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update package', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadData = async (id) => {
        if (id > 0) {
            const response = await getPackage(id)
            if (response !== null) {
                form.setFieldsValue({ ...response })
            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    const renderPakageForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Name" name='name' rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        }]}>
                                        <Input placeholder="Package Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Price" name='price' rules={[
                                        {
                                            required: true,
                                            message: 'Price is required',
                                        }]}>
                                        <Input placeholder="Price" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Description" name='description' rules={[
                                        {
                                            required: true,
                                            message: 'description is required',
                                        }]}>
                                        <TextArea rows={3} placeholder="Description" />
                                    </Form.Item>
                                </Col>

                                {/* Items */}
                                <Divider plain>Items</Divider>
                                <Col span={24}>
                                    <Form.List name="items">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row gutter={16}>
                                                        <Col span={8}>

                                                            <Form.Item
                                                                {...restField}
                                                                label="Name"
                                                                name={[name, 'name']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing name',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Name" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={8}>

                                                            <Form.Item
                                                                {...restField}
                                                                label='Description'
                                                                name={[name, 'description']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Missing description',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input placeholder="Description" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={4}>
                                                            <Form.Item name={[name, "available"]} valuePropName="checked" label="Available">
                                                                <Switch
                                                                    checkedChildren={<CheckOutlined />}
                                                                    unCheckedChildren={<CloseOutlined />}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Form.Item label=" ">
                                                            <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => remove(name)} />
                                                        </Form.Item>
                                                    </Row>
                                                ))}
                                                <Col span={24}>
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Add Item
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderPakageFormMobile = () => {
        return ("Working For Mobile")
    }

    const renderContent = () => {
        if (isMobile) {
            return renderPakageFormMobile()
        } else {
            return renderPakageForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(PakageForm)