import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Modal, notification, Space, Switch, Table, Typography } from "antd";
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { deletePaymentMethod, getPaymentMethods, updatePaymentMethod } from "../../data";
import { PAGINATION_OPTIONS } from "../../constants";
import { updateDataSource } from "../../utility/updateDataSource";
import windowSize from "react-window-size";

const { Title, Text } = Typography;

const data = [
    { id: 1, name: 'Cash on delivery', active: false },
    { id: 2, name: 'Bank transfer', active: false },
    { id: 3, name: 'Internet payment getway (IPG)', active: false }
]

function PaymentMethods({ windowWidth }) {

    const isMobile = windowWidth <= 768
    let history = useHistory()

    const [dataSource, setDataSource] = useState(data)
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    const updateValue = async (id, name, checked, type) => {

        const updatedPaymentMethods = updateDataSource(dataSource, id, checked);
        setDataSource(updatedPaymentMethods);

        const response = await updatePaymentMethod(id, { type: type, value: checked, name: name })
        if (response) {
            setForceRefresh(true)
            if (checked === true) {
                notification.open({ message: 'Payment method  activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (checked === false) {
                notification.open({ message: 'Payment method  deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
        setForceRefresh(false)
    }

    const columns = [
        {
            title: 'Payment Type',
            dataIndex: 'name',
            key: 'name',
            width: '75%',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (active, record) => (
                <Switch
                    onChange={(val) => updateValue(record.id, record.name, val, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={active == 1 ? true : false}
                />
            )
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     render: (text, record) => (
        //         <Space size='small'>
        //             <Button onClick={() => history.push({ pathname: '/payment-methods/form/' + record.id })}>Edit</Button>
        //             <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
        //         </Space>
        //     )
        // },
    ]

    const deleteCategory = async () => {

        const pamentMethod_id = localStorage.getItem('payment_type')
        const response = await deletePaymentMethod(pamentMethod_id);
        if (response) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Payment method has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted payment method', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('payment_type', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const response = await getPaymentMethods(forceRefresh);


        const updatedItems = dataSource.map(item => {

            const paymentMethod = response.find(i => i.name == item.name);

            if (paymentMethod) {
                return { ...item, active: paymentMethod.active };
            }
            return item;
        });

        setDataSource(updatedItems);
        setForceRefresh(false)
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={!isMobile ? { display: 'flex', justifyContent: 'space-between' } : {}}>
            <Title level={3}>Payment Methods</Title>
            {/* <Button type="primary" onClick={() => history.push({ pathname: '/payment-methods/form/0' })}>Add Payment Method</Button> */}
        </div>
    )

    const renderModal = () => (
        <Modal
            title="Delete Payment Method"
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteCategory()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this payment method?</p>
            </div>
        </Modal>
    )

    const renderPaymentMethods = () => (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader()}
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )

    const renderPaymentMethodsMobile = () => (
        <>
            {
                dataSource.map(paymentMethod => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                        // actions={[
                        //     <Space size='small'>
                        //         <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: '/payment-methods/form/' + paymentMethod.id })}>Edit</Button>
                        //         <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(paymentMethod.id)}>Delete</Button>
                        //     </Space>
                        // ]}
                        >
                            <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{paymentMethod.name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Active :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    <Switch
                                        onChange={(val) => updateValue(paymentMethod.id, val, 'active')}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={paymentMethod.active == 1 ? true : false} />
                                </span>
                            </Text>
                            <br />
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderPaymentMethodsMobile()}
                </>
            )
        }
        else {
            return renderPaymentMethods()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal()}
        </>
    )

}

export default windowSize(PaymentMethods)