import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getCustomers(param) {
    setDependency()
    return ajaxService.get(`${urlService.customers}${param}`).then(response => {
        return Response(response.data);
    })
}

export async function getCustomer(id) {
    setDependency()
    return ajaxService.get(urlService.customers + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function saveCustomer(data) {
    setDependency()
    return ajaxService.post(urlService.customers, data).then(response => {
        return Response(response.data);
    })
}

export async function updateCustomer(id, data) {
    setDependency()
    return ajaxService.put(urlService.customers + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteCustomer(id) {
    setDependency()
    return ajaxService.delete(urlService.customers + '/' + id).then(response => {
        return Response(response.data);
    })
}