import { Button, Modal, notification, Space, Table, Typography } from "antd"
import { useEffect } from "react"
import { useState } from "react"
import { deletePackage, getPackages } from "../../data"
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

const { Title } = Typography
function Packages() {

    const [dataSource, setDataSource] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)
    const [showModal, setShowModal] = useState(false)

    let history = useHistory()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/packages/view/' + record.id })}>View</Button>
                        <Button onClick={() => history.push({ pathname: '/packages/form/' + record.id })}>Edit</Button>
                        <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>)
            }
        },
    ]

    const openDeleteModal = (id) => {
        localStorage.setItem('package', id);
        setShowModal(true)
    }

    const deleteRow = async () => {
        let package_id = localStorage.getItem('package')
        const data = await deletePackage(package_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Package has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted package', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const initialize = async () => {
        const response = await getPackages(forceRefresh)
        setDataSource(response)
        setForceRefresh(false)
    }

    useEffect(() => {
        initialize();
    }, [])

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Packages</Title>
                    <Space size="small" >
                        <Button type="primary" onClick={() => history.push({ pathname: '/packages/form/0' })}>Add Package</Button>
                    </Space>
                </div>
            </>
        )
    }
    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
            />

            <Modal
                title="Delete Package"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this package?</p>
                </div>
            </Modal>
        </>
    )

}
export default Packages