import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }
const branchDependency = (path) => { return ajaxService.getBranchDependencyUrl(path) }


export async function getReconciliations(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(branchDependency(urlService.reconciliations), 'reconciliations');
    } else {
        let reconciliations = await loadState('reconciliations');

        if (reconciliations == null) {
            return await loadResource(urlService.reconciliations, 'reconciliations');
        } else {
            return Response(reconciliations);
        }
    }
}

export async function getReconciliation(id) {
    setDependency()
    return ajaxService.get(branchDependency(urlService.reconciliations + '/' + id)).then(response => {
        return Response(response.data);
    })
}

export async function saveReconciliation(data) {
    setDependency()
    return ajaxService.post(branchDependency(urlService.reconciliations), data).then(response => {
        return Response(response.data);
    })
}

export async function getProductsWithStocks() {
    setDependency()
    return ajaxService.get(branchDependency(urlService.productsWithStocks)).then(response => {
        return Response(response.data);
    })
}