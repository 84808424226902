import React from 'react'
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ajaxService from '../services/ajax-service';
import ImgCrop from 'antd-img-crop';
const ImageUpload = ({ onChange, fileList, crop = false }) => {

    const uploadComponent = (
        <Upload
            action={ajaxService.getImageUploadUrl}
            multiple={true}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            method={"post"}
            name="image"
            headers={{
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }}
        >
            {
                fileList.length >= 8 ? null : <div>
                    <PlusOutlined />
                    <div className="ant-upload-text">Upload</div>
                </div>
            }
        </Upload >
    )

    return crop ? (
        <ImgCrop
            aspect={1 / 1} // Aspect ratio (width / height)
            shape="rect" // Crop shape (rect or round)
            grid
            zoom
            rotate
            width={400} // Set the desired width of the crop area
            height={300} // Set the desired height of the crop area
        >
            {uploadComponent}
        </ImgCrop>
    ) : uploadComponent;

}

export default ImageUpload