import { Button, Col, Form, Input, notification, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getProfile, updateProfile } from "../../data";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import windowSize from "react-window-size";

function ProfileForm({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [forceRefresh, setForceRefresh] = useState(true)
    const [form] = Form.useForm()
    let history = useHistory()
    let { id } = useParams()

    const onFinish = async (values) => {
        const updateData = await updateProfile(id, values)
        if (updateData) {
            history.push({ pathname: '/profile' });
            notification.open({ message: 'Profile has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to update profile', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    const loadData = async () => {
        const data = await getProfile(forceRefresh)
        if (data !== null) {
            form.setFieldsValue({ ...data })
        }
        setForceRefresh(false)
    }

    useEffect(() => {
        loadData();
    }, [])

    const renderProfileForm = () => (
        <>
            <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16}>
                    <Col span={6}></Col>
                    <Col span={10}>
                        <Row gutter={16}>
                            <Col span={20}>
                                <Form.Item label="Name" name='name' rules={[
                                    {
                                        required: true,
                                        message: 'Name is required',
                                    }]}>
                                    <Input placeholder="Profile Name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )

    const renderProfileFormMobile = () => (
        <>
            <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Name" name='name' rules={[
                            {
                                required: true,
                                message: 'Name is required',
                            }]}>
                            <Input placeholder="Profile Name" />
                        </Form.Item>
                    </Col>

                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type="primary">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )

    return isMobile ? renderProfileFormMobile() : renderProfileForm()

}

export default windowSize(ProfileForm)