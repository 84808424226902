import React from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from './navigation';
import Sample, { Presenter , PresenterComponent } from './sample';

export default function App() {
  return (
    <Router>
      <Navigation />
      {/* <Sample /> */}
    </Router>
  );
}