import { Col, Row, Form, Input } from "antd";

const PushNotificationSetting = ({ Setting }) => {
    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='server_key' label="Server Key"
                        labelCol={{ span: 24 }}
                        initialValue={data.server_key}
                        rules={[
                            {
                                required: true,
                                message: 'Server key is required',
                            },
                        ]}>
                        <Input type="number"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='application_id' label="Application ID"
                        labelCol={{ span: 24 }}
                        initialValue={data.application_id}
                        rules={[
                            {
                                required: true,
                                message: 'Application id is required',
                            },
                        ]}>
                        <Input type="number"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='sender_id' label="Sender ID"
                        labelCol={{ span: 24 }}
                        initialValue={data.sender_id}
                        rules={[
                            {
                                required: true,
                                message: 'Sender id is required',
                            },
                        ]}>
                        <Input type="number"/>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
export default PushNotificationSetting