import { useState, useEffect } from "react"
import { Button, Card, Switch, Table, Typography, notification } from "antd"
import { useHistory } from "react-router-dom"
import { getStockTransfers, updateStockTransfer } from "../../data"
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { PAGINATION_OPTIONS } from "../../constants";
import { updateDataSource } from "../../utility/updateDataSource";
import windowSize from "react-window-size";

const { Title, Text } = Typography

const StockTransfer = ({ windowWidth }) => {

    const isMobile = windowWidth <= 762

    let history = useHistory()

    const [dataSource, setDataSource] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)

    // const updateValue = async (id, checked, type) => {

    //     const updatedStockTransfer = updateDataSource(dataSource, id, checked);
    //     setDataSource(updatedStockTransfer);

    //     const active = await updateStockTransfer(id, { type: type, value: checked })
    //     if (active) {
    //         setForceRefresh(true)
    //         if (checked === true) {
    //             notification.open({ message: 'Stock transfer activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
    //         } else if (checked === false) {
    //             notification.open({ message: 'Stock transfer deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
    //         }
    //     }
    //     else {
    //         notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    //     }
    // }

    const columns = [
        {
            title: 'From Branch',
            dataIndex: 'from_branch_name',
            key: 'from_branch_name',
        },
        {
            title: 'To Branch',
            dataIndex: 'to_branch_name',
            key: 'to_branch_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: 'Active',
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (active, record) => (
        //         <Switch
        //             onChange={(val) => updateValue(record.id, val, 'active')}
        //             checkedChildren={<CheckOutlined />}
        //             unCheckedChildren={<CloseOutlined />}
        //             checked={active == 1 ? true : false}
        //         />
        //     )
        // },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Button onClick={() => history.push({ pathname: '/stock-transfer/view/' + record.id })}>View</Button>
        },
    ]

    const initialize = async () => {
        const response = await getStockTransfers(forceRefresh)
        if (response) {
            setDataSource(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize()
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Stock Transfer</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/stock-transfer/form/0' })}>Add Stock Transfer</Button>
        </div>
    )

    const renderStockTransfer = () => (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader()}
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )

    const renderStockTransferMobile = () => (
        <>
            {
                dataSource.map(v => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Button type='primary' icon={<EyeOutlined />} onClick={() => history.push({ pathname: '/stock-transfer/view/' + v.id })}>View</Button>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>From Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.to_branch_name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>To Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.from_branch_name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Description : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.description}</span></Text>
                            {/* <br />
                            <Text style={{ fontWeight: 'bolder' }}>Active :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    <Switch
                                        onChange={(val) => updateValue(v.id, val, 'active')}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={v.active == 1 ? true : false}
                                    />
                                </span>
                            </Text> */}
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderStockTransferMobile()}
                </>
            )
        }
        else {
            return renderStockTransfer()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

}

export default windowSize(StockTransfer)