import React, { useState, useEffect } from 'react';
import { Table, Card } from 'antd';
import { PAGINATION_OPTIONS } from '../../constants';
import { filterDetailInventoryReports, getDetailInventoryReports } from '../../data';
import Summary from './layout/summary';
import Search from './layout/search';
import windowSize from 'react-window-size';
import DetailedInventoryMobile from "./mobile_screen/detailedInventoryMobile";

const columns = [
    {
        title: 'Product',
        dataIndex: 'name',
        key: 'product_name',

    },
    {
        title: 'Branch',
        dataIndex: 'branch_name',
        key: 'branch_name',

    },
    {
        title: 'GRN #',
        dataIndex: 'grn_number',
        key: 'grn_number',

    },
    {
        title: 'Units Available',
        dataIndex: 'available_stock',
        key: 'available_stock',
        align: 'center'
    },
    {
        title: 'Cost per Unit',
        dataIndex: 'cost',
        key: 'cost',
        align: 'center',
        render: (text) => <div style={{ textAlign: 'right' }}>{text}</div>,
    },
    {
        title: 'Total Cost',
        key: 'total_cost',
        align: 'center',
        render: (text, record) => <div style={{ textAlign: 'right' }}>{(record.available_stock * record.cost).toFixed(2)}</div>,
    },
    {
        title: 'Price per Unit',
        dataIndex: 'retail_price',
        key: 'retail_price',
        align: 'center',
        render: (text) => <div style={{ textAlign: 'right' }}>{text}</div>,
    },
    {
        title: 'Total Price',
        key: 'total_price',
        align: 'center',
        render: (text, record) => <div style={{ textAlign: 'right' }}>{(record.available_stock * record.retail_price).toFixed(2)}</div>,
    },

];

function DetailedInventoryReport({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalUnits, setTotalUnits] = useState(0);
    const [totalInventory, setTotalInventory] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const handleSearch = async (data) => {
        const response = await filterDetailInventoryReports(data)
        if (response) {
            setDataSource(response)
        }
    }

    const initialize = async () => {
        let response = await getDetailInventoryReports();
        if (response) {

            let data = response.filter(i => i.available_stock > 0)

            setDataSource(data)
            setTotalProducts(data.length)
            setTotalUnits(data.reduce((total, i) => total + i.available_stock, 0))
            setTotalInventory((data.map(entry => entry.available_stock * entry.cost).reduce((total, cost) => total + cost, 0)).toFixed(2));
            setTotalPrice((data.map(entry => entry.available_stock * entry.retail_price).reduce((total, retail_price) => total + retail_price, 0)).toFixed(2));
        }
    }

    useEffect(() => {
        initialize()
    }, [])


    const summary = (
        <Summary
            title='Detailed Inventory'
            totalProducts={totalProducts}
            totalUnits={totalUnits}
            totalInventory={totalInventory}
            totalPrice={totalPrice}
            mobile={isMobile}
        />
    )

    const renderDetailedInventoryReport = () => (
        <>
            <Search handleSearch={handleSearch} hasBranch={true} hasProduct={true} />
            <Card>
                {summary}
                <br />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ ...PAGINATION_OPTIONS }}
                    rowKey="index"
                />
            </Card>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return <DetailedInventoryMobile summary={summary} />
        } else {
            return renderDetailedInventoryReport()

        }
    }

    return renderContent()

}

export default windowSize(DetailedInventoryReport)