import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography } from "antd"
import { useEffect, useState } from "react"
import { filterProductStock, getBranches, getProductStocks, getProducts, getStoreSetting } from "../../data"
import moment from "moment"
import { PAGINATION_OPTIONS } from "../../constants";
import windowSize from "react-window-size";
import ProductLedgerMobile from "./mobile_screen/productLedgerMobile";
import ControlSettings from "../../constant/controlSettings";
import MENAGEINVENTORYCOSTING from "../../constant/manageInventoryCosting";

const { RangePicker } = DatePicker;
const { Option } = Select
const { Title, Text } = Typography

const format = "DD-MM-Y hh:mm a"

const ProductLedgerReport = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm();

    const [forceRefresh, setForceRefresh] = useState(true)
    const [dataSource, setDataSource] = useState([])
    const [branches, setBranches] = useState([])
    const [products, setProducts] = useState([])
    const [manageInventory, setManageInventory] = useState(null);

    const redIfInvalidEntryType = (entry_type) => (
        entry_type === 2 || entry_type === 4 || entry_type === 7 || entry_type === 8 ? 'red' : ''
    );

    const columns = [
        {
            title: 'Date & Time',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
            render: (created_at) => (
                <Text>{moment(created_at).format(format)}</Text>
            )
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: 'Product',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            key: 'supplier',
        },
        {
            title: 'GRN #',
            dataIndex: 'grn_number',
            key: 'grn_number',
        },
        {
            title: 'Entry Type',
            key: 'entry_type',
            render: (text, record) => (
                <Text style={{ color: redIfInvalidEntryType(record.entry_type) }}>
                    {record.entryTypeString}
                </Text>
            )
        },
        {
            title: 'Stock',
            key: 'stock',
            render: (text, record) => (
                <center>
                    <Text style={{ color: redIfInvalidEntryType(record.entry_type) }}>
                        {record.stock}
                    </Text>
                </center>
            )
        },
        {
            title: 'Available Stock',
            dataIndex: 'available_stock',
            key: 'available_stock',
            render: (as) => <center><Text>{as}</Text></center>
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
        },
        {
            title: 'Average Cost',
            dataIndex: 'average_cost',
            key: 'average_cost',
            align: 'right',
            hidden: true
        },
        {
            title: 'Selling Price',
            key: 'retail_price',
            render: (text, record) => <Text style={{ float: 'right' }}>{record.entry_type === 2 ? record.sold_price : record.retail_price}</Text>
        },
    ].filter(item => manageInventory?.costing === MENAGEINVENTORYCOSTING.AVERAGE ? item : !item.hidden)

    const handleSearch = async () => {
        const data = form.getFieldsValue()
        const response = await filterProductStock(data)
        if (response) {
            setDataSource(response)
        }
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        const response = await getProducts(forceRefresh)
        if (response) {
            setProducts(response.filter(i => !i.is_combo))
            setForceRefresh(false)
        }
    }

    const manageInventorySetting = async () => {
        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);
        if (response) {
            setManageInventory({ active: response.active, ...JSON.parse(response.setting) })
        }
    }

    const initialize = async () => {
        const response = await getProductStocks(forceRefresh)
        if (response) {
            setDataSource(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
        loadBranches()
        loadProducts()
        manageInventorySetting()
    }, [])

    const renderHeader = <Title level={3}>Product Ledger</Title>

    const renderFilter = (
        <Form form={form} layout={'vertical'}>
            <Row gutter={16} >
                <Col span={6}>
                    <Form.Item label="Product" name="product_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Product"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {products.map(({ id, name }, i) => (
                                <Option key={'product-' + i} value={id}>{name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Branch" name="branch_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch allowClear
                            style={{ width: '100%' }}
                            placeholder="Select branch"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {branches.map(({ id, name }, i) => (
                                <Option key={'branch-' + i} value={id}>{name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Date" name="date" labelCol={{ span: 24 }}>
                        <RangePicker style={{ width: '100%' }} allowClear />
                    </Form.Item>
                </Col>
                <Col span={6} className="gutter-row">
                    <Form.Item label={' '}>
                        <Space>
                            <Button onClick={handleSearch}>Search</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

    const renderProductLedgerReport = () => (
        <>
            {renderFilter}
            <Table
                title={() => renderHeader}
                dataSource={dataSource}
                columns={columns}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return <ProductLedgerMobile branches={branches} products={products} format={format} redIfInvalidEntryType={redIfInvalidEntryType} manageInventory={manageInventory} />

        } else {
            return renderProductLedgerReport()
        }
    }

    return (
        <>
            {isMobile && renderHeader}
            {renderContent()}
        </>
    )

}

export default windowSize(ProductLedgerReport)