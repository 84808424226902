import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Input, Table, Button, Typography, notification } from "antd";
import { getProductsWithStocks, saveReconciliation } from "../../data/reconciliation-service";
import { useState } from "react";
import { ArrowLeftOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const ReconciliationForm = () => {

    let history = useHistory();

    const [dataSource, setDataSource] = useState([]);

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Available Stock',
            dataIndex: 'available_stock',
            key: 'available_stock',
        },
        {
            title: 'Stocks',
            dataIndex: 'manual_stock',
            key: 'actions',
            render: (manual_stock, record) => <Input defaultValue={manual_stock} style={{ width: '20%' }} onChange={(e) => handleChange(e.target.value, record)} />
        },
        {
            title: 'Difference',
            dataIndex: 'difference',
            key: 'difference',
            render: (difference) => <Text style={{ color: difference != 0 ? "red" : '' }}>{(difference > 0 ? '+' : '') + difference}</Text>
        },
    ]

    const handleAddReconciliation = async () => {
        const response = await saveReconciliation(dataSource)
        if (response) {
            history.push({ pathname: '/reconciliations' });
            notification.open({ message: 'Reconciliation has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to add reconciliation', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const handleChange = (value, record) => {

        const productIndex = dataSource.findIndex(item => item.id === record.id);

        if (productIndex !== -1) {
            const updatedStocks = dataSource.map((data, index) => {
                if (index === productIndex) {
                    return { ...data, manual_stock: value, difference: data.available_stock - value };
                } else {
                    return data;
                }
            });
            setDataSource(updatedStocks);
        } else {
            setDataSource(prevStocks => [...prevStocks, { id: record.id, manual_stock: value }]);
        }
    }

    const loadData = async () => {
        const response = await getProductsWithStocks();
        if (response) {
            response.map(i => {
                i.difference = i.available_stock;
                i.manual_stock = 0;
            })
            setDataSource(response)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Add Reconciliation</Title>
            <Button type="primary" onClick={() => history.goBack()} icon={<ArrowLeftOutlined />}>Back To Reconciliations</Button>
        </div>
    )

    const renderFooter = (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" onClick={() => handleAddReconciliation()}>Save</Button>
        </div>
    )

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader}
            pagination={false}
            footer={() => renderFooter}
        />
    )
}
export default ReconciliationForm