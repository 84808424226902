import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getRoles(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.roles, 'roles');
    } else {
        let roles = await loadState('roles');

        if (roles == null) {
            return await loadResource(urlService.roles, 'roles');
        } else {
            return Response(roles);
        }
    }
}

export async function getRole(id) {
    setDependency()
    let roles = await loadState('roles');
    if (roles !== null) {
        return roles.find(role => role.id == id);
    } else {
        return ajaxService.get(urlService.roles, { id: id }).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveRole(data) {
    setDependency()
    return ajaxService.post(urlService.roles, data).then(response => {
        return Response(response.data);
    })
}

export async function updateRole(id, data) {
    setDependency()
    return ajaxService.put(urlService.roles + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteRole(id) {
    setDependency()
    return ajaxService.delete(urlService.roles + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function getRolePermissions(id) {
    setDependency()
    return await ajaxService.get(urlService.rolePermissions + '/' + id).then(response => {
        return Response(response.data);
    });
}

export async function AddPermission(id, data) {
    ajaxService.removeDependency()
    return ajaxService.post(urlService.rolePermissions + '/' + id, data).then(response => {
        return Response(response.data);
    })
}