import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getDamageDiscardProducts(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.damageDiscardProducts, 'damageDiscardProducts');
    } else {
        let damageDiscardProducts = await loadState('damageDiscardProducts');

        if (damageDiscardProducts == null) {
            return await loadResource(urlService.damageDiscardProducts, 'damageDiscardProducts');
        } else {
            return Response(damageDiscardProducts);
        }
    }
}

export async function saveDamageDiscardProducts(data) {
    setDependency()
    return ajaxService.post(urlService.damageDiscardProducts, data).then(response => {
        return Response(response.data);
    })
}

export async function updateDamageDiscardProduct(id, data) {
    setDependency()
    return ajaxService.put(urlService.damageDiscardProducts + '/' + id, data).then(response => {
        return Response(response.data);
    })
}