import React, { useState, useEffect } from 'react';
import { Space, Button, Typography, Row, Col, Divider, notification, Switch, Empty, Card } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import { AddPermission, getRole, getRolePermissions } from '../../data';
import { CheckCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import windowSize from "react-window-size";

const { Title } = Typography;

const Permissions = ({ windowWidth }) => {

  const isMobile = windowWidth <= 768

  const [dataSource, setDataSource] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  let history = useHistory();
  let { id } = useParams();

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route'
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Assign Feature',
      dataIndex: 'checked',
      key: 'checked',
      render: (checked, record) => (
        <Switch
          // onChange={(checked) => handleAssign(record.id, checked)}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={checked}
        />
      )
    },
  ];

  const loadRole = async (id) => {
    const response = await getRole(id)
    if (response) {
      setRoleName(response.title)
    }
  }

  const handleAssignPermission = async (permID, checked) => {

    const data = { permission_id: permID, checked: checked }

    // let params = [];
    // dataSource.map(permission => {
    //   if (isCheck.includes(permission.id)) {
    //     params.push(permission.name);
    //   }
    // })

    const response = await AddPermission(id, data);
    if (response) {
      // history.push({ pathname: "/roles" });
      notification.open({ message: 'The permission has been ' + (checked ? 'assigned' : 'unassigned'), icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to assigned the permission', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(dataSource.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const initialize = async (id) => {
    const response = await getRolePermissions(id);

    if (response) {
      setDataSource(response.filter(i => i.abilities.length > 0))
      // let ids = [];
      // response.map(item => {
      //   if (item.checked) {
      //     ids.push(item.id);
      //   }
      // })
      // setIsCheck(ids);

      // setIsCheckAll(response.length > 0 ? response.every(row => row.checked) : false)

    }
  }

  useEffect(() => {
    loadRole(id)
    initialize(id);
  }, [])

  const renderHeader = (
    <div style={isMobile ? {} : { display: 'flex', justifyContent: 'space-between' }}>
      <Title level={3}>Permissions For {roleName}</Title>
      <Button type="primary" onClick={() => history.push({ pathname: '/roles' })} icon={<ArrowLeftOutlined />}>Back To Roles</Button>
    </div>
  )

  const renderPermissions = (data) => {
    return (
      data.map(v => {
        return (
          <>
            <Row style={{ padding: '15px 20px' }} gutter={16}>
              <Col span={isMobile ? 21 : 12} style={!isMobile ? { display: 'flex', justifyContent: 'flex-end' } : {}}><label for={v.id} style={{ cursor: 'pointer' }}>{v.title}</label></Col>
              <Col span={isMobile ? 3 : 12}>
                <Switch
                  id={v.id}
                  onChange={(checked) => handleAssignPermission(v.id, checked)}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={v.checked}
                />

              </Col>
            </Row>
            <Divider style={{ padding: '0px', margin: '0' }} />
          </>
        )

      })
    )
  }

  const renderButtons = (
    <div style={{ padding: '10px 20px' }}>
      <Space style={{ float: 'right', marginTop: '10px' }}>
        {/* <Button htmlType="submit" type="primary" onClick={savePermissions}>Submit</Button> */}
        {/* <Button onClick={() => history.goBack()} type="primary">Cancel</Button> */}
      </Space>
    </div>
  )

  // const renderRolePermissions = () => (
  //   <Card>
  //     {renderHeader()}
  //     {renderButtons()}
  //     <br /><br />
  //     <Row>
  //       <Col span={24}>
  //         <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }}>
  //           <Col span={1}>S.No</Col>
  //           <Col span={22}>Title</Col>
  //           <Col span={1}>
  //             <Checkbox type="checkbox" name="selectAll" id="selectAll" onChange={handleSelectAll} checked={isCheckAll}>
  //               All
  //             </Checkbox>
  //           </Col>
  //         </Row>

  //         {renderPermissions()}

  //         {renderButtons()}
  //       </Col>
  //       <Col span={2}></Col>
  //     </Row>
  //   </Card>
  // )

  const renderRolePermissions = (
    <>
      {renderHeader}
      {/* {renderButtons} */}
      <br /><br />
      {dataSource.map((v, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Divider plain>{v.title}</Divider>
          <Card style={!isMobile ? { width: '80%' } : { width: '100%' }}>
            {renderPermissions(v.abilities)}
          </Card>
        </div>
      ))}
    </>
  )

  return renderRolePermissions

}

export default windowSize(Permissions)