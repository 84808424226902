import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { notification } from "antd";
import { updateProduct } from "../../../data";


const updateValue = async (id, val, type, column) => {
    let value;
    if (type === 'active') {
        value = val;
    } else {
        value = val.target.value;
    }
    let response = await updateProduct(id, { type: type, value: value });
    if (response) {
        if (type === 'active' && value === true) {
            notification.open({ message: 'Product  activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else if (type === 'active' && value === false) {
            notification.open({ message: 'Product  deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
        else {
            notification.open({ message: 'Product ' + column + ' has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    } else {
        notification.open({ message: 'Unable to add product' + column, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
}

export { updateValue };