import { useEffect, useState } from "react"
import { filterDamageDiscardReport, getBranches } from "../../../data"
import { Button, Card, Col, Empty, Form, Row, Select, Space, Typography } from "antd";

const { Option } = Select
const { Text } = Typography

const DamageDiscardMobile = ({ summary, damageProducts }) => {

    const [form] = Form.useForm();

    const [branches, setBranches] = useState([]);
    const [items, setItems] = useState([])
    const [data, setData] = useState([])

    const [mainBranch, setMainBranch] = useState(0);
    const [forceRefresh, setForceRefresh] = useState(true);

    const handleSearch = async (values) => {
        const response = await filterDamageDiscardReport(values)
        if (response) {
            setData(response)
            setItems(response.filter(i => i.branch_id == mainBranch))
        }
    }

    const onChange = (id) => {
        setItems(data.filter(i => i.branch_id == id))
    }

    const loadBranches = async () => {
        let response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setMainBranch(response.find(i => i.is_main == 1)?.id)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        loadBranches()
    }, [])

    const renderFilter = (
        <Form form={form} layout={'vertical'} onFinish={handleSearch}>
            <Row gutter={16} >

                <Col span={24}>
                    <Form.Item label="Products" name="product_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch allowClear mode='multiple'
                            style={{ width: '100%' }}
                            placeholder="Select Products"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {damageProducts.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24} className="gutter-row">
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit">Search</Button>
                            <Button onClick={() => form.resetFields()}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

    return (
        <>
            {renderFilter}
            {summary}
            <br />
            {
                data.length > 0 &&
                <>
                    <Text style={{ fontWeight: 'bolder' }}>Branches</Text>
                    <Select
                        showSearch allowClear
                        // mode='multiple'
                        style={{ width: '100%' }}
                        placeholder="Select Branch"
                        defaultValue={mainBranch}
                        onChange={onChange}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {branches.map(({ id, name }) => {
                            return <Option value={id} key={id}>{name}</Option>
                        })}
                    </Select>
                    <br /><br />
                    {
                        items.length > 0 ? (

                            items.map(i => (
                                <>
                                    <br />
                                    <Card>
                                        <Text style={{ fontWeight: 'bolder' }}>ID : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.id}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.branch_name}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Product : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.product_name}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Quantity : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.quantity}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Type : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.type == 0 ? "Discard" : "Damage"}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Refrence ID : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.refrence_id}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Total Cost :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}> {i.total_cost}</span></Text>

                                    </Card>
                                </>
                            ))
                        ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                    }
                </>
            }
        </>
    )
}
export default DamageDiscardMobile