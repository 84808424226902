import React, { useState, useEffect } from 'react';
import { Table, Space, Typography, Button, Modal, notification, Card, Tag } from 'antd';
import { useHistory } from "react-router-dom";
import { EditOutlined, CloseCircleOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import windowSize from 'react-window-size';
import { deletePurchaseOrder, getPurchaseOrders } from '../../data';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../../constants';

const { Title, Text } = Typography;

function PurchaseOrders({ windowWidth }) {

    const [dataSource, setDataSource] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768

    let history = useHistory();
    const branch_id = parseInt(localStorage.getItem('branch_id'))

    let format = 'DD-MM-YYYY'
    const columns = [
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            key: 'order_number',

        },

        {
            title: 'Supplier',
            dataIndex: 'supplier',
            key: 'supplier',
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },

        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
        },

        {
            title: 'Total Items',
            dataIndex: 'items_count',
            key: 'total_item',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (<Tag color={record.status == 0 ? 'geekblue' : 'green'}>{record.status == 1 ? 'Published' : 'Draft'} </Tag>)
            }
        },

        {
            title: 'Order Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => {
                return (<p>{moment(record.created_at).format(format)}</p>)
            }
        },

        {
            title: 'Last Update',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (text, record) => {
                return (<p>{moment(record.updated_at).format(format)}</p>)
            }
        },

        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (text, record) => (
                <Space size='small'>
                    <Button style={{ display: record.status == 0 ? 'none' : '' }} onClick={() => history.push({ pathname: "/purchase-orders/view/" + record.id })}>View</Button>
                    <Space style={{ position: 'relative', right: 8 }}>
                        <Button style={{ display: record.status == 1 ? 'none' : '' }} onClick={() => history.push({ pathname: "/purchase-orders/form/" + record.id })}>Edit</Button>
                        <Button style={{ display: record.status == 1 ? 'none' : '' }} onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>
                </Space>
            ),
        },
    ];

    const deleteRow = async () => {
        const purchaseOrder_id = localStorage.getItem('purchase-order')
        let data = await deletePurchaseOrder(purchaseOrder_id);
        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Purchase order has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted Purchase order', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('purchase-order', id);
        setShowModal(true)
    }

    const addPurchaseOrders = () => {
        history.push({ pathname: "/purchase-orders/form/0" });
    }

    const initialize = async () => {
        const response = await getPurchaseOrders(forceRefresh);
        if (response) {
            let data = []
            if (branch_id) {
                data = response.filter(i => i.branch_id === branch_id)
            } else {
                data = response
            }
            setDataSource(data);
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize();
    }, [])

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => {
        return (
            <div style={!isMobile ? { display: 'flex', justifyContent: 'space-between' } : {}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Purchase Orders</Title>
                </div>
                <Space size="small" >
                    <Button type="primary" onClick={() => history.push({ pathname: "/purchase-orders/form/0" })}  >Add Purchase Order</Button>
                </Space>
            </div>
        );
    }

    const renderPurchaseOrders = () => {
        return (
            <div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                    pagination={{ ...PAGINATION_OPTIONS }}
                />
            </div>
        )
    }

    const renderPurchaseOrdersMobile = () => {
        return (
            <>
                {
                    dataSource.map(po => {
                        return (
                            <>
                                <br />
                                <Card
                                    style={{ width: '100%' }}
                                    actions={[
                                        <Space size='small'>
                                            <Button type='primary' style={{ display: po.status == 0 ? 'none' : '' }} icon={<EyeOutlined />} onClick={() => history.push({ pathname: "/purchase-orders/view/" + po.id })}>View</Button>
                                            <Button type='primary' style={{ display: po.status == 1 ? 'none' : '' }} icon={<EditOutlined />} onClick={() => history.push({ pathname: "/purchase-orders/form/" + po.id })}>Edit</Button>
                                            <Button type='primary' style={{ display: po.status == 1 ? 'none' : '' }} icon={<DeleteOutlined />} onClick={() => openDeleteModal(po.id)} danger>Delete</Button>
                                        </Space>
                                    ]}>
                                    <Text style={{ fontWeight: 'bolder' }}>Order Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{po.order_number}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Supplier : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{po.supplier}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Email : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{po.email}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Phone Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{po.phone}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Total Items : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{po.items_count}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Status :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                            <Tag color={po.status == 0 ? 'geekblue' : 'green'}>{po.status == 1 ? 'Published' : 'Draft'} </Tag>
                                        </span>
                                    </Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Order Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(po.created_at).format(format)}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Last Update : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(po.updated_at).format(format)}</span></Text>
                                    <br />
                                </Card>
                                <br />
                            </>
                        );
                    })
                }
            </>
        )
    }

    const renderModal = () => {
        return (
            <Modal
                title="Delete purchase order"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>
                    ,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this purchase order?</p>
                </div>
            </Modal>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderPurchaseOrdersMobile()
        }
        else {
            return renderPurchaseOrders()
        }
    }

    return (
        <>
            {isMobile && renderHeader()}
            {renderContent()}
            {renderModal()}
        </>
    )
}

export default windowSize(PurchaseOrders)