import React, { useState, useEffect } from 'react';
import { Table, Typography, Card } from 'antd';
import { PAGINATION_OPTIONS } from '../../constants';
import { filterOutOfStockReports, getOutOfStockReports } from '../../data';
import Summary from './layout/summary';
import moment from 'moment';
import Search from './layout/search';
import windowSize from 'react-window-size';
import OutOfStockMobile from './mobile_screen/outOfStockMobile';

const { Text } = Typography;

function OutOfStock({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);

    const handleSearch = async (data) => {
        if (data.branch_id) {
            const response = await filterOutOfStockReports(data)
            if (response) {
                setDataSource(response)
            }
        }
    }

    const initialize = async () => {
        let response = await getOutOfStockReports();
        if (response) {
            setDataSource(response)
            setTotalProducts(response.length)
        }
    }

    const columns = [
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'product_name',

        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',

        },
        {
            title: 'Out Of Stock Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (date) => <Text>{moment(date).format('LL')}</Text>,
        },
    ];

    const hasBranchName = dataSource && dataSource.length > 0 && dataSource[0].hasOwnProperty('branch_name');
    // Remove the 'Branch' column from the 'columns' array if 'branch_name' key is not present
    if (!hasBranchName) {
        columns.splice(1, 1);
    }

    useEffect(() => {
        initialize()
    }, [])

    const summary = (
        <Summary
            title='Out Of Stock'
            totalProducts={totalProducts}
            mobile={isMobile}
        />
    )

    const renderOutOfStock = () => (
        <>
            <Search handleSearch={handleSearch} hasBranch={hasBranchName} />
            <Card>
                {summary}
                <br />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ ...PAGINATION_OPTIONS }}
                    rowKey="index"
                />
            </Card>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return <OutOfStockMobile summary={summary} data={dataSource} handleSearch={handleSearch} />
        } else {
            return renderOutOfStock()
        }
    }

    return renderContent()

}

export default windowSize(OutOfStock)