import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Button, Space, Row, Col, Select, notification, Switch, Tooltip } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from '../../../components/image-upload';
import { getBranches, getBrands, getCategories, getProduct, getProducts, saveProduct, updateProduct } from '../../../data';
import windowSize from 'react-window-size';
import _ from 'lodash';
import { AddonForm } from './addonForm';
import { ajaxService } from '../../../services';
import { GlobalVariable } from '../../../services/ajax-service';
import Toggles from './toggels';
import IndustrySettings from '../../../constant/industrySettings';
import { handleGenerate } from '../../../utility/skuGenerator';

const { Option } = Select
const { TextArea } = Input

const ProductForm = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768
    let history = useHistory();
    let { id } = useParams();

    const [fileList, setFileList] = useState([]);
    const [categories, setcategories] = useState([])
    const [brands, setBrands] = useState([])
    const [imagesList, setImagesList] = useState([]);
    const [text, setText] = useState("")
    const [form] = Form.useForm();
    const [forceRefresh, setForceRefresh] = useState(true);
    const [branches, setBranches] = useState([])
    const [addonFileList, setAddonFileList] = useState([]);
    const [hasAddon, setHasAddon] = useState(false)
    const [sku, setSku] = useState([])

    const [ecommerce, setEcommerce] = useState(false);
    const [showMobile, setShowMobile] = useState(false);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const getPath = (index) => {
        let result = undefined;

        if (addonFileList[index].length > 0) {
            addonFileList[index].forEach((item) => {
                let { response } = item;
                if (response != undefined) {
                    let { path, success } = response;
                    if (success) {
                        result = path;
                    }
                } else if (item.url !== undefined) {
                    result = item.url.replace(GlobalVariable.BASE_IMAGE_URL + '/', "");
                }
            });
        }
        return result;
    };

    const onFinish = async (values) => {

        if (values.is_addon && addonFileList.length > 0) {
            values.addons.map(i => {
                i.items.map((item, index) => {
                    item.image = getPath(index);
                    return item;
                })
                return i
            })
        }

        let imageData = [];
        if (fileList.length > 0) {
            fileList.map((item) => {
                let { response } = item;
                if (response != undefined) {
                    let { path, success } = response;
                    if (success) {
                        imageData.push(path);
                    }
                } else if (item.url !== undefined) {
                    imageData.push(item.url.replace(GlobalVariable.BASE_IMAGE_URL + '/', ""));
                }
            });
        }

        let data = { description: text, image: imageData, has_variant: false, is_main: true, offer: true, urls: values.video_urls, addonProducts: values.addons, ...values }

        if (id == 0) {
            const saveData = await saveProduct(data);
            if (saveData) {
                history.push({ pathname: "/products" });
                notification.open({ message: 'Product has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add product', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            // let list = [...imageData]
            // fileList.map((item) => {
            //     if (item.url !== undefined) {
            //         list.push(item.url)
            //     }
            // })
            // data.image = list;
            data.active = values.active
            const updateData = await updateProduct(id, data);
            if (updateData) {
                history.push({ pathname: "/products" });
                notification.open({ message: 'Product has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update product', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

            }
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadCategories = useCallback(async () => {
        const categories = await getCategories(forceRefresh);
        if (categories) {
            setcategories(categories);
            setForceRefresh(false)
        }
    }, [])

    const loadBrands = useCallback(async () => {
        const brands = await getBrands(forceRefresh);
        if (brands) {
            setBrands(brands);
            setForceRefresh(false)
        }
    }, []);

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {

            if (id == 0) {
                form.setFieldsValue({ branches: response.map(branch => branch.id) })
            }

            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        let response = await getProducts(forceRefresh);
        if (response) {
            setSku(response.map(i => i.sku))
            setForceRefresh(false)
        }
    }

    const loadData = async (id) => {
        
        if (id > 0) {
            let data = await getProduct(id);

            // let data = JSON.parse(localStorage.getItem('product'))

            if (data) {
                
                const { product_items, category_id, brand_id, description, images, links, show_in_ecommerce, show_in_mobile } = data;

                setEcommerce(show_in_ecommerce ? true : false)
                setShowMobile(show_in_mobile ? true : false)
                
                let assignedBranches = [];

                if(data.branch_products){
                    assignedBranches = data.branch_products.filter(product => product.active === 1).map(product => product.branch_id);
                }

                form.setFieldsValue({
                    ...data,
                    category_id: category_id ? parseInt(category_id) : null,

                    brand_id: brand_id ? parseInt(brand_id) : null,

                    branches: assignedBranches,

                    video_urls: links,
                });

                if (product_items.length > 0) {
                    setHasAddon(true)

                    let images = product_items.map(i => ([{
                        uid: i.id,
                        name: 'image.png',
                        status: 'done',
                        url: ajaxService.getImage(i.image),
                        preview: false,
                    }]));

                    setAddonFileList(images)
                }

                if (description != null) {
                    setText(description);
                };

                setFileList(images.map(v => ({
                    uid: v.id,
                    name: 'image.png',
                    status: 'done',
                    url: ajaxService.getImage(v.url),
                    preview: false,
                })))

                // Addons grouped wise
                const groupedData = product_items.reduce((acc, item) => {
                    const { title, type, limit, image, item: itemName, price } = item;
                    if (!acc[title]) {
                        acc[title] = { title: title, type: type, limit: limit, items: [] };
                    }
                    acc[title].items.push({ image, item: itemName, price });
                    return acc;
                }, {});

                const formattedData = Object.values(groupedData);

                form.setFieldsValue({ addons: formattedData });
            }else{
                console.log('Here')
            }
        }
    };

    useEffect(() => {
        loadCategories();
        loadBrands();
        loadBranches()
        loadData(id);
        // loadProducts()
    }, []);

    const initialValues = {
        is_addon: false,
        active: false,
        unlimited_stock: false,
        show_in_pos: false,
        show_in_ecommerce: false,
        display_in_ecommerce: false,
        show_in_mobile: false,
        display_in_mobile: false,
    };

    // if (forceRefresh) {
    //     return <div>Loading...</div>;
    // }

    const toggles = (
        <Toggles ecommerce={ecommerce} setEcommerce={setEcommerce} showMobile={showMobile} setShowMobile={setShowMobile} isMobile={isMobile} />
    )

    const renderProductForm = () => (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            initialValues={initialValues}
        >
            <Row gutter={16} >
                <Col span={3}></Col>
                <Col span={18}>
                    <Row gutter={16} >

                        <Col span={16}  >
                            <Form.Item name='name' label="Name" rules={[
                                {
                                    required: true,
                                    message: 'Product Name field is required',
                                },
                            ]}>
                                <Input placeholder="Product Name" />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item name='meta_title' label="Meta Title" rules={[
                                {
                                    required: false,
                                    message: 'Meta Title field is required',
                                },
                            ]}>
                                <Input placeholder="Meta Title" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="Brand" name="brand_id" rules={[
                                {
                                    required: true,
                                    message: 'Brand is required',
                                },
                            ]}>
                                <Select
                                    // allowClear
                                    placeholder="Select Brand"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {brands.map(brand => {
                                        return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="Category" name="category_id" rules={[
                                {
                                    required: true,
                                    message: 'Category is required',
                                },
                            ]}>
                                <Select
                                    // allowClear
                                    placeholder="Select Category"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {categories.map(category => {
                                        return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name='meta_description' label="Meta Description" rules={[
                        {
                            required: false,
                            message: 'Meta Description field is required',
                        },
                    ]}>
                                <Input placeholder="Meta Description" />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item name='short_description' label="Short Description">
                                <TextArea maxLength={300} placeholder="Maximum Length Is 300" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name='branches' label="Assign To Branches">
                                <Select mode="multiple" placeholder="Branches" style={{ width: '100%' }}>
                                    {branches.map(({ id, name }) => (
                                        <Option key={id} value={id}>{name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Toggles */}
                        <Col span={24}>
                            <Row>
                                <Col span={4}>
                                    <Form.Item name="active" label="Active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name='unlimited_stock' label="Unlimited Stock" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    IndustrySettings.ADDON ? (
                                        <Col span={4}>
                                            <Form.Item label="Has Addon" name='is_addon' valuePropName="checked">
                                                <Switch
                                                    // valuePropName="checked"
                                                    // defaultChecked={hasAddon}
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    onChange={e => setHasAddon(e)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null
                                }
                                {/* Toggles for pos , ecommerce & mobile */}
                                {toggles}
                            </Row>
                        </Col>
                        {/* Addons Form */}
                        <Col span={24}>
                            {
                                hasAddon &&
                                <AddonForm addonFileList={addonFileList} setAddonFileList={setAddonFileList} />
                            }
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Description" >
                                {/* {text} */}
                                <CKEditor
                                    className='editor'
                                    editor={ClassicEditor}
                                    onReady={editor => editor.setData(text)}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        setText(data)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item name='sku' label="SKU (Barcode)" rules={[
                                    {
                                        required: true,
                                        message: 'SKU is required and must be a valid EAN13 barcode',
                                    },
                                ]}>
                                    <Input placeholder="SKU" allowClear />
                                </Form.Item>
                                <Form.Item label="&nbsp;">
                                    <Button type="primary" onClick={() => handleGenerate(sku, form)}>Genrate</Button>
                                </Form.Item>
                            </Space.Compact>
                        </Col>
                        {/* {
                            !branchCatalogue &&
                            <Col span={6}>
                                <Form.Item name='stock' label="Stock" >
                                    <Input placeholder="Stock" type='number' defaultValue={0} />
                                </Form.Item>
                            </Col>
                        } */}

                        <Col span={6}>
                            <Form.Item name='cost_price' label="Cost Price">
                                <Input placeholder="Cost Price" type='number' defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='selling_price' label="Selling Price" rules={[
                                {
                                    required: true,
                                    message: 'Selling Price is required',
                                },
                            ]}>
                                <Input placeholder="Selling Price" type='number' defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='compare_price' label="Compare Price">
                                <Input placeholder="Compare Price" type='number' defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={24} style={{ marginBottom : 25 }}>
                            <span className='ant-form-item-explain-error'> Caution: Barcode works only with the EAN13 standard.</span>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='weight' label="Weight In Grams">
                                <Input placeholder="Weight In Grams" type='number' step={0.01} defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='length' label="Lenght In Inches">
                                <Input placeholder="Length In Inches" type='number' step={0.01} defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='breath' label="Breath In Inches">
                                <Input placeholder="Breath In Inches" type='number' defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='height' label="Height In Inches">
                                <Input placeholder="Height In Inches" type='number' step={0.01} defaultValue={0} />
                            </Form.Item>

                        </Col>

                        <Col span={6}>
                            <Form.Item name='preparation_time' label="Preparation Time (In minutes)" >
                                <Input type='number' placeholder='Preparation Time' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='low_stock_point' label="Low Stock Point" >
                                <Input type='number' placeholder='Low Stock Point' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='reorder_point' label="Reorder Point" >
                                <Input type='number' placeholder='Reorder Point' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='reorder_qty' label="Reorder Quantity" >
                                <Input type='number' placeholder='Reorder Quantity' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='sort_order' label="Sort Order" >
                                <Input type='number' placeholder='Sort Order' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name='online_orders_cutoff_point' label="Online Orders Cut-Off Point" >
                                <Input type='number' placeholder='Online Orders Cut-Off Point' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={
                                <span>
                                    Add Youtube Url
                                    <Tooltip placement="top" title={"hello"}>
                                        <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: 10 }} />
                                    </Tooltip>
                                </span>
                            }>
                                <Form.List name="video_urls">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Row gutter={16}>
                                                    <Col span={12}>
                                                        <Form.Item {...restField} name={[name, 'url']}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Form.Item>
                                                        <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => remove(name)} />
                                                    </Form.Item>
                                                </Row>
                                            ))}
                                            <Col span={12}>
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Upload Multiple Videos Via Embed YouTube URL
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </Form.List>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Upload Multiple Images (Max File Size 1MB) but 1 by 1" >
                                <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                            </Form.Item>
                        </Col>

                        <Space>
                            <Button type="primary" htmlType="submit">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>

                    </Row>
                </Col>
                <Col span={3}></Col>
            </Row>
        </Form>
    );

    const renderProductFormMobile = () => (
        <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            initialValues={initialValues}
        >
            <Row gutter={16} >

                <Col span={24}  >
                    <Form.Item name='name' label="Name" rules={[
                        {
                            required: true,
                            message: 'Product Name field is required',
                        },
                    ]}>
                        <Input placeholder="Product Name" />
                    </Form.Item>

                </Col>

                <Col span={24} >
                    <Form.Item name='meta_title' label="Meta Title" rules={[
                        {
                            required: false,
                            message: 'Meta Title field is required',
                        },
                    ]}>
                        <Input placeholder="Meta Title" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Brand" name="brand_id" rules={[
                        {
                            required: true,
                            message: 'Brand is required',
                        },
                    ]}>
                        <Select
                            // allowClear
                            placeholder="Select Brand"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {brands.map(brand => {
                                return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Category" name="category_id" rules={[
                        {
                            required: true,
                            message: 'Category is required',
                        },
                    ]}>
                        <Select
                            // allowClear
                            placeholder="Select Category"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {categories.map(category => {
                                return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='meta_description' label="Meta Description" rules={[
                        {
                            required: false,
                            message: 'Meta Description field is required',
                        },
                    ]}>
                        <Input placeholder="Meta Description" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='short_description' label="Short Description">
                        <TextArea rows={2} maxLength={300} placeholder="Maximum Length Is 300" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='branches' label="Assign To Branches">
                        <Select mode="multiple" placeholder="Branches" style={{ width: '100%' }}>
                            {branches.map(({ id, name }) => (
                                <Option key={id} value={id}>{name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="active" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='unlimited_stock' label="Unlimited Stock" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>
                        {/* Toggles for pos , ecommerce & mobile */}
                        {toggles}

                        {
                            IndustrySettings.ADDON ? (
                                <Col span={12}>
                                    <Form.Item label="Has Addon" name='is_addon' valuePropName="checked">
                                        <Switch
                                            // defaultChecked={hasAddon}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            onChange={e => setHasAddon(e)}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : null
                        }
                    </Row>
                </Col>

                {/* Addon Form */}
                <Col span={24}>
                    {hasAddon && <AddonForm addonFileList={addonFileList} setAddonFileList={setAddonFileList} mobile={isMobile} />}
                </Col>

                <Col span={24}>
                    <Form.Item label="Description" >
                        <CKEditor
                            className='editor'
                            editor={ClassicEditor}
                            onReady={editor => editor.setData(text)}
                            onChange={(event, editor) => {
                                const data = editor.getData()
                                setText(data)
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Form.Item name='sku' label="SKU (Barcode)" rules={[
                            {
                                required: true,
                                message: 'SKU is required and must be a valid EAN13 barcode',
                            },
                        ]}>
                            <Input placeholder="SKU" allowClear />
                        </Form.Item>
                        <Form.Item label="&nbsp;">
                            <Button type="primary" onClick={() => handleGenerate(sku, form)}>Genrate</Button>
                        </Form.Item>
                    </Space.Compact>
                    <span className='ant-form-item-explain-error'> Caution: Barcode works only with the EAN13 standard.</span>
                </Col>

                {/* {
                    !branchCatalogue &&
                    <Col span={12}>
                        <Form.Item name='stock' label="Stock" >
                            <Input placeholder="Stock" type='number' defaultValue={0} />
                        </Form.Item>
                    </Col>
                } */}

                <Col span={12}>
                    <Form.Item name='cost_price' label="Cost Price">
                        <Input placeholder="Cost Price" type='number' defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='selling_price' label="Selling Price" rules={[
                        {
                            required: true,
                            message: 'Selling Price is required',
                        },
                    ]}>
                        <Input placeholder="Selling Price" type='number' defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='compare_price' label="Compare Price">
                        <Input placeholder="Compare Price" type='number' defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='weight' label="Weight In Grams">
                        <Input placeholder="Weight In Grams" type='number' step={0.01} defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='length' label="Lenght In Inches">
                        <Input placeholder="Length In Inches" type='number' step={0.01} defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='breath' label="Breath In Inches">
                        <Input placeholder="Breath In Inches" type='number' defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='height' label="Height In Inches">
                        <Input placeholder="Height In Inches" type='number' step={0.01} defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='preparation_time' label="Preparation Time (In minutes)">
                        <Input type='number' placeholder='Preparation Time' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='low_stock_point' label="Low Stock Point" >
                        <Input type='number' placeholder='Low Stock Point' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='reorder_point' label="Reorder Point" >
                        <Input type='number' placeholder='Reorder Point' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='reorder_qty' label="Reorder Quantity" >
                        <Input type='number' placeholder='Reorder Quantity' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='sort_order' label="Sort Order" >
                        <Input type='number' placeholder='Sort Order' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='online_orders_cutoff_point' label="Online Orders Cut-Off Point" >
                        <Input type='number' placeholder='Online Orders Cut-Off Point' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={
                        <span>
                            Upload Multiple Videos Via Embed YouTube URL
                            <Tooltip placement="top" title={"hello"}>
                                <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: 10 }} />
                            </Tooltip>
                        </span>
                    }>
                        <Form.List name="video_urls">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Row gutter={16}>
                                            <Col span={20}>
                                                <Form.Item {...restField} name={[name, 'url']}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Form.Item>
                                                <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Row>
                                    ))}
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ whiteSpace: 'pre-wrap' }}>
                                                Add Youtube Url
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Upload Multiple Images (Max File Size 1MB)" >
                        <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                    </Form.Item>
                </Col>
                <br />
                <Col span={24}>
                    <Space>
                        <Button type="primary" htmlType="submit">Submit</Button>
                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    )


    const renderContent = () => {
        if (isMobile) {
            return renderProductFormMobile()
        }
        else {
            return renderProductForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
};


export default windowSize(ProductForm)