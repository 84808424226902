import { ajaxService, urlService } from "../services";

const removeDependency = () => { ajaxService.removeDependency() }

export async function forgotPassword(data) {
    removeDependency()
    return ajaxService.post(urlService.forgotPassword, data).then(response => {
        return response;
    })
}

export async function resetPassword(data) {
    removeDependency()
    return ajaxService.post(urlService.resetPassword, data).then(response => {
        return response;
    })
}