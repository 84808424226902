import { Col, Row, Form, Switch, Divider, Radio, Checkbox, } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const ManageInventory = ({ Setting }) => {

    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <Row gutter={16}>
            <Divider plain>Costing System</Divider>
            <Col span={24}>
                <Form.Item name='costing' initialValue={data.costing ? data.costing : "fifo"}>
                    <Radio.Group>
                        <Row gutter={[16, 16]}>
                            <Col span={7}>
                                <Radio value='fifo'>FIFO</Radio>
                            </Col>
                            <Col span={7}>
                                <Radio value='lifo'>LIFO</Radio>
                            </Col>
                            <Col span={10}>
                                <Radio value='average'>Weighted Average</Radio>
                            </Col>
                            <Col span={24}>
                                <Radio value='specific_identification'>Specific Identification</Radio>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>
            </Col>


            {/* <Col span={12}>
                <Form.Item name='supplier_ledger' label="Supplier & Ledger"
                    labelCol={{ span: 24 }}
                    initialValue={data.supplier_ledger}>
                    <Switch
                        style={{ marginLeft: 10 }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data.supplier_ledger} />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name='b_mfg_exp' label="Batch - Mfg Date - Exp Date"
                    labelCol={{ span: 24 }}
                    initialValue={data.b_mfg_exp}>
                    <Switch
                        style={{ marginLeft: 60 }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data.b_mfg_exp} />
                </Form.Item>
            </Col> */}

            <Divider style={{ margin: 0 }} />
            <br />
            <Col span={24}>
                <Form.Item name="supplier" valuePropName='checked' initialValue={data.supplier ? data.supplier : false}>
                    <Checkbox>Allow branch to add supplier</Checkbox>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="purchase_order" valuePropName='checked' initialValue={data.purchase_order ? data.purchase_order : false}>
                    <Checkbox>Allow branch to add Purchase Order</Checkbox>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="purchase_grn" valuePropName='checked' initialValue={data.purchase_grn ? data.purchase_grn : false}>
                    <Checkbox>Allow branch to add GRN</Checkbox>
                </Form.Item>
            </Col>
        </Row >

    )
}
export default ManageInventory 