import { Button, Card, Col, Divider, Form, Input, notification, Radio, Row, Select, Space, Table, Typography } from "antd";
import { PlusOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getBranches, getProducts, getPurchaseOrder, getSuppliers, savePurchaseOrder, updatePurchaseOrder } from "../../data";
import windowSize from "react-window-size";
import dataService from "../../services/data-service";

const { Option } = Select
const { Text } = Typography

const PurchaseOrderForm = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768
    const [form] = Form.useForm();
    const [suppliers, setSuppliers] = useState([]);
    const [productValue, setProductValue] = useState();
    const [products, setProducts] = useState([]);
    const [branches, setBranches] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(true);

    let history = useHistory()
    let { id } = useParams()
    const branch_id = localStorage.getItem('branch_id') ? parseInt(localStorage.getItem('branch_id')) : null

    const onFinish = async (values) => {

        if (id === 0) {
            const saveData = await savePurchaseOrder(values)
            if (saveData) {
                history.push({ pathname: "/purchase-orders" });
                notification.open({ message: 'Purchase order has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add purchase order', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updatePurchaseOrder(id, values);
            if (updateData) {
                history.push({ pathname: '/purchase-orders' });
                notification.open({ message: 'Purchase order has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update purchase order', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadSupplier = async () => {
        const data = await getSuppliers('?branch_id=' + branch_id)
        if (data) {
            setSuppliers(data);
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        const data = await dataService.getShortProducts();
        if (data) {
            setProducts(data);
            setForceRefresh(false)
        }
    }

    const handleChange = (e) => {
        if (e) {
            let product = products.find(i => i.id === e)
            setProductValue({ name: product.name, id: product.id })
        }
    }

    const loadBranches = async () => {
        const data = await getBranches(forceRefresh);
        if (data) {
            setBranches(data)
            setForceRefresh(false)
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            const response = await getPurchaseOrder(id)
            if (response) {
                form.setFieldsValue({ ...response })
                form.setFieldsValue({ purchaseOrderItems: response.items })
            }
        }
    }

    useEffect(() => {
        loadData(id)
        loadSupplier()
        loadProducts()
        loadBranches()
    }, [])

    const onChange = (e) => {

        if (e) {
            suppliers.map(supplier => {
                if (supplier.id === e) {
                    form.setFieldsValue({ supplier: supplier.name, email: supplier.email, phone: supplier.phone })
                }
            })
        } else {
            form.setFieldsValue({ supplier: '', email: '', phone: '' })
        }
    }

    const renderPurchaseOrderForm = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    active: true
                }}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Col span={12}>
                                <Form.Item label="Supplier" name=''>
                                    <Select
                                        showSearch allowClear
                                        style={{ width: '100%' }}
                                        onChange={(e) => onChange(e)}
                                        placeholder="Select Supplier"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {suppliers.map(supplier => {
                                            return <Option value={supplier.id}>{supplier.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Name" name='supplier' rules={[
                                    {
                                        required: true,
                                        message: 'Name is required',
                                    }]}>
                                    <Input placeholder="Name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Email" name='email' rules={[
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    }]}>
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Phone" name='phone' rules={[
                                    {
                                        required: true,
                                        message: 'Phone is required',
                                    }]}>
                                    <Input placeholder="Phone" type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='branch_id' label="Branch"
                                    initialValue={branch_id}
                                    rules={[{ required: true, message: 'Branch is required' }]} >
                                    <Select placeholder="Select Branch"
                                        style={{
                                            width: '100%',
                                        }}>
                                        {
                                            branches.map(({ id, name }) => {
                                                if (branch_id) {
                                                    if (id === branch_id) {
                                                        return (
                                                            <Option value={id}>{name}</Option>
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <Option value={id}>{name}</Option>
                                                    )
                                                }
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Status" name='status'
                                    initialValue={1}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Status is required',
                                        }]}>
                                    <Radio.Group>
                                        <Radio value={1}>Publish</Radio>
                                        <Radio value={0}>Draft</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Divider plain> Products </Divider>

                            <Col span={12}>
                                <Form.Item label="Product" name='product'>
                                    <Select
                                        showSearch allowClear
                                        style={{ width: '100%' }}
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Select Product"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {products.map(product => {
                                            return <Option value={product.id}>{product.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.List name="purchaseOrderItems">

                                    {(fields, { add, remove }) => (
                                        <>
                                            <Form.Item>
                                                <Button
                                                    style={{ width: 100, }}
                                                    disabled={productValue == null ? true : false}
                                                    onClick={() => add()}
                                                    block icon={<PlusOutlined />}>
                                                    Add
                                                </Button>
                                            </Form.Item>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <>
                                                    <Form.Item
                                                        name={[name, 'product_id']}
                                                        initialValue={productValue?.id ?? null}
                                                        style={{ display: 'none' }}
                                                    >
                                                        <input disabled className="productDropDown" />
                                                    </Form.Item>

                                                    <Row gutter={16}>
                                                        <Col span={8}>
                                                            <Form.Item label="Item" name={[name, 'product_name']}
                                                                initialValue={productValue?.name ?? null}>
                                                                <input disabled className="productDropDown" style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={6}>

                                                            <Form.Item label="Quantity" name={[name, 'quantity']} rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Quantity is required',
                                                                }]}>
                                                                <Input type="number" />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={6}>
                                                            <Form.Item label="Cost" name={[name, 'cost']} rules={[
                                                                {
                                                                    required: false,
                                                                    message: 'cost is required',
                                                                }]}>
                                                                <Input type="number" step={'0.01'} />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={2}>
                                                            <Form.Item label=" " >
                                                                <DeleteOutlined style={{ color: 'red', fontSize: '20px', }} onClick={() => remove(name)} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                </>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Form>
        </>
    )

    const renderPurchaseOrderFormMobile = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    active: true
                }}>
                <Row gutter={16} >

                    <Col span={24}>
                        <Form.Item label="Supplier" name=''>
                            <Select
                                showSearch allowClear
                                style={{ width: '100%' }}
                                onChange={(e) => onChange(e)}
                                placeholder="Select Supplier"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {suppliers.map(supplier => {
                                    return <Option value={supplier.id}>{supplier.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Name" name='supplier' rules={[
                            {
                                required: true,
                                message: 'Name is required',
                            }]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Email" name='email' rules={[
                            {
                                required: true,
                                message: 'Email is required',
                            }]}>
                            <Input placeholder="Email" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Phone" name='phone' rules={[
                            {
                                required: true,
                                message: 'Phone is required',
                            }]}>
                            <Input placeholder="Phone" type="number" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='branch_id' label="Branch" rules={[{ required: true, message: 'Branch is required' }]} >
                            <Select placeholder="Select Branch"
                                style={{
                                    width: '100%',
                                }}>
                                {
                                    branches.map(({ id, name }) => (
                                        <Option value={id} key={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Status" name='status'
                            initialValue={1}
                            rules={[
                                {
                                    required: true,
                                    message: 'Status is required',
                                }]}>
                            <Radio.Group>
                                <Radio value={1}>Publish</Radio>
                                <Radio value={0}>Draft</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Divider plain> Products </Divider>

                    <Col span={24}>
                        <Form.Item label="Product" name='product'>
                            <Select
                                showSearch allowClear
                                style={{ width: '100%' }}
                                onChange={(e) => setProductValue(e)}
                                placeholder="Select Product"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {products.map(product => {
                                    return <Option value={product.name}>{product.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.List name="purchaseOrderItems">

                            {(fields, { add, remove }) => (
                                <>
                                    <Form.Item>
                                        <Button
                                            style={{ width: 100, }}
                                            disabled={productValue == null ? true : false}
                                            onClick={() => add()}
                                            block icon={<PlusOutlined />}>
                                            Add
                                        </Button>
                                    </Form.Item>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <>
                                            <Card style={{ marginBottom: 10 }}>

                                                <div style={{ display: 'flex', width: "100%" }}>
                                                    <Text style={{ fontWeight: 'bolder' }}>Item : </Text>
                                                    <Form.Item name={[name, 'product_name']} initialValue={productValue} style={{ position: 'relative', bottom: '5px' }}>
                                                        <input disabled className="productDropDown" style={{ width: "170%" }} />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item label="Quantity" name={[name, 'quantity']} rules={[
                                                    {
                                                        required: true,
                                                        message: 'Quantity is required',
                                                    }]}>
                                                    <Input type="number" />
                                                </Form.Item>

                                                <Form.Item label="Cost" name={[name, 'cost']} rules={[
                                                    {
                                                        required: true,
                                                        message: 'cost is required',
                                                    }]}>
                                                    <Input type="number" step={'0.01'} />
                                                </Form.Item>

                                                <Form.Item label=" ">
                                                    <DeleteOutlined style={{ marginTop: '10px', color: 'red', fontSize: '20px', }} onClick={() => remove(name)} />
                                                </Form.Item>
                                            </Card>
                                        </>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type="primary">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderPurchaseOrderFormMobile()
        } else {
            return renderPurchaseOrderForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(PurchaseOrderForm)