import { ajaxService, loadState, urlService } from '../services';
import { loadResource, Response } from '../utility';


const setDependency = () => { ajaxService.setDependency() }

export async function getCategories(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.categories, 'categories');
    } else {
        let categories = await loadState('categories');

        if (categories == null) {
            return await loadResource(urlService.categories, 'categories');
        } else {
            return Response(categories);
        }
    }
}

export async function getCategory(id) {
    let categories = await loadState('categories');
    if (categories !== null) {
        return categories.find(category => category.id == id);
    } else {
        return ajaxService.get(urlService.categories, { id: id }).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveCategory(data) {
    return ajaxService.post(urlService.categories, data).then(response => {
        return Response(response.data);
    })
}

export async function updateCategory(id, data) {
    return ajaxService.put(urlService.categories + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteCategory(id) {
    return ajaxService.delete(urlService.categories + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function getBranchCategories(id) {
    setDependency()
    return ajaxService.get(urlService.branchCategories + '/' + id).then(response => {
        return Response(response.data);
    })
}
