import React, { useState, useEffect } from 'react';
import { Table, Space, Typography, Button, Modal, Avatar, notification, Switch, Card, Image } from 'antd';
import { useHistory } from "react-router-dom";
import { EditOutlined, CloseCircleOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import windowSize from 'react-window-size';
import { deleteSupplier, getSuppliers } from '../../data';
import { PAGINATION_OPTIONS } from '../../constants';

const { Title, Text } = Typography;

function Supplier({ windowWidth }) {

    const [dataSource, setDataSource] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768

    let history = useHistory();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Contact',
            key: 'email',
            render: (text, record) => (
                <>
                    <Text>{record.email}</Text>
                    <br />
                    <Text>{record.phone}</Text>
                </>
            )
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Address',
            key: 'address',
            render: (text, record) => (
                <>
                    <Text>{record.address}, </Text>
                    <Text style={{ color: 'green' }}>{record.city}, </Text>
                    <Text style={{ color: 'blue' }}>{record.country}</Text>
                </>
            )
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (text) => <Text>{text}</Text>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            width: 100,
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: "/ledger/" + record.id, search: 'supplier' })}>Ledger</Button>
                    <Button onClick={() => history.push({ pathname: "/suppliers/form/" + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            ),
        },
    ];

    const deleteRow = async () => {
        const supplier_id = localStorage.getItem('supplier')
        let data = await deleteSupplier(supplier_id);
        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Supplier has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted supplier', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('supplier', id);
        setShowModal(true)
    }

    const addSupplier = () => {
        history.push({ pathname: "/suppliers/form/0" });
    }

    const initialize = async () => {
        const branch_id = localStorage.getItem('branch_id') ?? 0
        const data = await getSuppliers('?branch_id=' + branch_id);
        if (data) {
            setDataSource(data);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Suppliers</Title>
                </div>
                <Space size="small" >
                    <Button type="primary" onClick={() => history.push({ pathname: "/suppliers/form/0" })}>Add Supplier</Button>
                </Space>
            </div>
        );
    }

    const renderSupplier = () => {
        return (
            <div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                    pagination={{ ...PAGINATION_OPTIONS }}
                />
            </div>
        )
    }

    const renderSupplierMobile = () => {
        return (
            <>
                {
                    dataSource.map(supplier => {
                        return (
                            <>
                                <br />
                                <Card
                                    style={{ width: "100%" }}
                                    actions={[
                                        <Space size='small'>
                                            <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/ledger/" + supplier.id, search: 'supplier' })}>Ledger</Button>
                                            <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/suppliers/form/" + supplier.id })}>Edit</Button>
                                            <Button type='primary' icon={<DeleteOutlined />} onClick={() => openDeleteModal(supplier.id)} danger>Delete</Button>
                                        </Space>
                                    ]}>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.name}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Email : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.email}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Phone Number: <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.phone}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Company : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.company}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Address : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.address}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>City : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.city}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Country : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.country}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Balance : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{supplier.balance}</span></Text>
                                </Card>
                            </>
                        );
                    })
                }
            </>
        )
    }

    const renderModal = () => {
        return (
            <Modal
                title="Delete Supplier"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>
                    ,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this supplier?</p>
                </div>
            </Modal>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderSupplierMobile()
        }
        else {
            return renderSupplier()
        }
    }

    return (
        <>
            {isMobile && renderHeader()}
            {renderContent()}
            {renderModal()}
        </>
    )
}

export default windowSize(Supplier)