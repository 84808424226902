import { notification, messgae } from "antd";
import { ajaxService } from "../services";
import { loadState, saveState } from "../services";
import { CloseCircleOutlined } from '@ant-design/icons';

export function Response(data) {

    if(!data){
        return;
    }
    const { authorize, message, payload, valid } = data;
    
    if (!authorize && message) {
        notification.open({ message: message, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
    
    if (valid && payload) {
        return payload;
    }

    if (!valid && message) {
        notification.open({ message: message, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
    else {
        return null
    }
}

async function loadResource(url, key) {
    await ajaxService.get(url).then(response => {

        let data = Response(response.data);

        if (data != null) {
            saveState(key, data);
        }
    });

    return await loadState(key);
}

function formatTime(time) {
    if (time == '') {
        return '';
    }

    // Prepend any date. Use your birthday.
    return new Date('1970-01-01T' + time + 'Z')
        .toLocaleTimeString('en-US',
            { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
        );
}

// export async function getResource(key, refresh = false) {

//     if (refresh) {
//         return await loadResource(urlService[key], key);
//     } else {
//         let resource = await loadState(key);

//         if (resource == null) {
//             return await loadResource(urlService[key], key);
//         } else {
//             return resource;
//         }
//     }
// }

export {
    loadResource,
    formatTime,
}