import { Col, DatePicker, Form, Row, Select, Typography, Space, Button, Card, Radio, Table } from "antd";
import { useEffect, useState } from "react";
import { filterIncomExpenseReport, getBranches, getIncomExpenseReport } from "../../data";
import moment from "moment";
import windowSize from "react-window-size";
import IncomeExpenseMobile from "./mobile_screen/incomeExpenseMobile";

const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const format = 'LL'

function IncomeExpenseReport({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm()

    const [radio, setRadio] = useState(1)
    const [forceRefresh, setForceRefresh] = useState(true)

    const [branches, setBranches] = useState([])
    const [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => <Text>{moment(date).format(format)}</Text>,
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: 'Description',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const salesColumn = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '' }}>{moment(date).format(format)}</Text>,
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            key: 'order_number',
        },
        {
            title: 'Total cost',
            dataIndex: 'total_cost',
            key: 'total_cost',
            render: (cost, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '', float: 'right' }}>{cost}</Text>,
        },
        {
            title: 'Sales',
            dataIndex: 'subtotal',
            key: 'subtotal',
            render: (sales, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '', float: 'right' }}>{sales}</Text>,
        },
        {
            title: 'Gross Profit / Loss',
            key: 'gross_profit',
            render: (text, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '', float: 'right' }}>{(record.subtotal - record.total_cost).toFixed(2)}</Text>
        },
        {
            title: 'Shipping',
            dataIndex: 'shipping',
            key: 'shipping',
            render: (shipping, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '', float: 'right' }}>{shipping}</Text>,
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (discount, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '', float: 'right' }}>{discount}</Text>,
        },
        {
            title: 'Total Sales',
            dataIndex: 'total',
            key: 'total',
            render: (total, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '', float: 'right' }}>{total}</Text>,
        },
        {
            title: 'Net Profit / Loss',
            dataIndex: 'profit_loss',
            key: 'profit_loss',
            render: (text, record) => <Text style={{ color: record.parent_id > 0 ? "red" : '', float: 'right' }}>{(record.total - record.total_cost).toFixed(2)}</Text>
        },
    ];

    const handleSearch = async (values) => {
        const response = await filterIncomExpenseReport(values)
        if (response) {
            setDataSource(response)
        }
    }

    const loadBranches = async () => {
        let response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    const initialize = async () => {
        const response = await getIncomExpenseReport()
        if (response) {
            setDataSource(response)
        }
    }

    useEffect(() => {
        initialize()
        loadBranches()
    }, [])

    const renderFilter = () => (
        <Form form={form} onFinish={handleSearch}>
            <Row gutter={16}>
                <Col
                    span={6}>
                    <Form.Item name="date_range" label="Date Range" labelCol={{ span: 24 }}>
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Branches" name="branch_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Branches"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {branches.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4} className="gutter-row">
                    <Form.Item label='&nbsp;' labelCol={{ span: 24 }}>
                        <Space>
                            <Button htmlType="submit">Search</Button>
                            <Button onClick={() => { form.resetFields(); form.submit() }}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

    const renderHeader = (
        <>
            <Title level={3}>Sales, Income & Expense Report</Title>

            <Radio.Group onChange={(e) => setRadio(e.target.value)} defaultValue={1}>
                <Radio value={1}>Income</Radio>
                <Radio value={0}>Expense</Radio>
                <Radio value={2}>Sales</Radio>
            </Radio.Group>
        </>
    )

    const renderIncomeExpenseReport = () => (
        <>
            {renderFilter()}
            <Card>
                {renderHeader}
                <br />  <br />
                <Table
                    dataSource={radio == 1 ? dataSource.income : (radio == 2 ? dataSource.sales : dataSource.expense)}
                    columns={radio == 2 ? salesColumn : columns}
                    pagination={false}
                    rowKey="index"
                    rowClassName={(record) => record.parent_id > 0 ? 'rowColor' : ''}
                />
            </Card>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    <IncomeExpenseMobile header={renderHeader} branches={branches} radio={radio} format={format} />
                </>
            )
        } else {
            return renderIncomeExpenseReport()
        }
    }

    return renderContent()

}
export default windowSize(IncomeExpenseReport)