import { useState, useEffect } from "react"
import { Button, Card, Form, Input, Modal, Table, Tooltip, Typography, message, notification } from "antd"
import { PAGINATION_OPTIONS } from "../../constants"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getDamageDiscardProducts, updateDamageDiscardProduct } from "../../data"
import moment from "moment"
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import windowSize from "react-window-size"

const { Title, Text } = Typography

const format = 'DD-MMM-YYYY';

function DamageDiscardProducts({ windowWidth }) {

    const isMobile = windowWidth <= 768

    let history = useHistory()
    let [form] = Form.useForm()

    const [forceRefresh, setForceRefresh] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: 'Product',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Grn Number',
            dataIndex: 'grn_number',
            key: 'grn_number',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
        },
        {
            title: 'Total Cost',
            dataIndex: 'total_cost',
            key: 'total_cost',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => <Text style={{ color: type === 1 ? '#FF0000' : '#f59556' }}>{type === 1 ? "Damage" : "Discarded"}</Text>
        },
        {
            title: 'Refrence ID',
            dataIndex: 'refrence_id',
            key: 'refrence_id',
            align:'center'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 20,
            render: (description) => {
                if (description) return <Tooltip title={description}><Button type="primary">Description</Button></Tooltip>
            }
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date) => <Text>{moment(date).format(format)}</Text>
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                if (record.type == 1 && record.quantity > 0) return <Button type="danger" onClick={() => openDiscardModal(record.id, record.quantity)}>Discard</Button>
            }
        },
    ]

    const openDiscardModal = (id, qty) => {
        localStorage.setItem('damageID', id)
        form.setFieldsValue({ quantity: qty })
        setShowModal(true)
    }

    const discard = async (values) => {

        const rowID = localStorage.getItem('damageID')
        const item = dataSource.find(i => i.id == rowID)

        if (values.quantity > item.quantity) return message.error("The quantity you are trying to return is more than the damage.")

        const response = await updateDamageDiscardProduct(rowID, values)
        if (response) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Some item has been discarded', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to discard item', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const initialize = async () => {
        const response = await getDamageDiscardProducts(forceRefresh)
        if (response) {
            setDataSource(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
    }, [forceRefresh])

    const renderModal = () => (
        <Modal
            title="Discard"
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" type="danger" onClick={() => form.submit()}>
                    Discard
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}
        >
            <Form form={form} onFinish={discard}>
                <Form.Item name='quantity' rules={[{ required: true, message: 'This Field is required' }]}>
                    <Input type="number" />
                </Form.Item>
            </Form>
        </Modal>
    )

    const renderHeader = () => (
        <div style={!isMobile ? { display: 'flex', justifyContent: 'space-between' } : {}}>
            <Title level={3}>Damage & Discard Product</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/damage-discard-products/form/0' })}>Add Damage & Discard</Button>
        </div>
    )

    const renderDamageDiscardProducts = () => (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader()}
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )

    const renderDamageDiscardProductsMobile = () => (
        <>
            {
                dataSource.map(v => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                (v.type == 1 && v.quantity > 0) && <Button type="danger" onClick={() => openDiscardModal(v.id, v.quantity)}>Discard</Button>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>ID : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.id}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.branch_name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Product : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.product_name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>GRN Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.grn_number}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Quantity : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.quantity}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.cost}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Total Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.total_cost}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Type :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10, color: v.type === 1 ? '#FF0000' : '#f59556' }}>
                                    {v.type === 1 ? "Damage" : "Discarded"}
                                </span>
                            </Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Refrence ID : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.refrence_id}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(v.created_at).format(format)}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Description :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    {v.description && <Tooltip title={v.description}><Button type="primary">Description</Button></Tooltip>}
                                </span>
                            </Text>
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderDamageDiscardProductsMobile()}
                </>
            )
        }
        else {
            return renderDamageDiscardProducts()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal()}
        </>
    )

}

export default windowSize(DamageDiscardProducts)