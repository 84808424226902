import { Button, Col, Form, Input, message, notification, Row, Space } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getSupplier, getSuppliers, saveSupplier, updateSupplier } from "../../data";
import windowSize from "react-window-size";

const { TextArea } = Input;

const SupplierForm = ({ windowWidth }) => {
    const [form] = Form.useForm();
    const isMobile = windowWidth <= 768
    let history = useHistory()
    let { id } = useParams()

    const [suppliers, setSuppliers] = useState([])

    const onFinish = async (values) => {

        const notUnique = suppliers.find(i => i.name == values.name);

        if (notUnique) {
            return message.error("Duplicate Supplier Name. Please provide a unique supplier name.")
        }

        if (id == 0) {
            const saveData = await saveSupplier(values)
            if (saveData) {
                history.push({ pathname: "/suppliers" });
                notification.open({ message: 'Supplier has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add supplier', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updateSupplier(id, values);
            if (updateData) {
                history.push({ pathname: '/suppliers' });
                notification.open({ message: 'Supplier has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update supplier', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadSuppliers = async () => {
        const branch_id = localStorage.getItem('branch_id') ?? 0
        const data = await getSuppliers('?branch_id=' + branch_id);
        if (data) {
            setSuppliers(data.filter(i => i.id != id));
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            const supplier = await getSupplier(id)
            if (supplier) {
                form.setFieldsValue({ ...supplier })
            }
        }
    }

    useEffect(() => {
        loadData(id)
        loadSuppliers()
    }, [])

    const renderSupplierForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Name" name='name' rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        }]}>
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Email" name='email' rules={[
                                        {
                                            required: true,
                                            message: 'Email is required',
                                        }]}>
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Phone" name='phone' rules={[
                                        {
                                            required: true,
                                            message: 'Phone is required',
                                        }]}>
                                        <Input placeholder="Phone" type="number" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Company" name='company' rules={[
                                        {
                                            required: true,
                                            message: 'Company is required',
                                        }]}>
                                        <Input placeholder="Company" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Department" name='department' rules={[
                                        {
                                            required: false,
                                            message: 'Department is required',
                                        }]}>
                                        <Input placeholder="Department" />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item label="Address" name='address' rules={[
                                        {
                                            required: false,
                                            message: 'Address is required',
                                        }]}>
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Fax" name='fax' rules={[
                                        {
                                            required: false,
                                            message: 'Fax is required',
                                        }]}>
                                        <Input placeholder="Fax" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="State" name='state' rules={[
                                        {
                                            required: false,
                                            message: 'State is required',
                                        }]}>
                                        <Input placeholder="State" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="City" name='city' rules={[
                                        {
                                            required: false,
                                            message: 'City is required',
                                        }]}>
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Country" name='country' rules={[
                                        {
                                            required: false,
                                            message: 'Country is required',
                                        }]}>
                                        <Input placeholder="Country" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Latitude" name='latitude' rules={[
                                        {
                                            required: false,
                                            message: 'Latitude is required',
                                        }]}>
                                        <Input placeholder="Latitude" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Longitiude" name='longitiude' rules={[
                                        {
                                            required: false,
                                            message: 'Longitiude is required',
                                        }]}>
                                        <Input placeholder="Longitiude" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Zip Code" name='zip_code' rules={[
                                        {
                                            required: false,
                                            message: 'Zip Code is required',
                                        }]}>
                                        <Input placeholder="Zip Code" />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderSupplierFormMobile = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >

                        <Col span={24}>
                            <Form.Item label="Name" name='name' rules={[
                                {
                                    required: true,
                                    message: 'Name is required',
                                }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Email" name='email' rules={[
                                {
                                    required: true,
                                    message: 'Email is required',
                                }]}>
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Phone" name='phone' rules={[
                                {
                                    required: true,
                                    message: 'Phone is required',
                                }]}>
                                <Input placeholder="Phone" type="number" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Company" name='company' rules={[
                                {
                                    required: true,
                                    message: 'Company is required',
                                }]}>
                                <Input placeholder="Company" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Department" name='department' rules={[
                                {
                                    required: true,
                                    message: 'Department is required',
                                }]}>
                                <Input placeholder="Department" />
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item label="Address" name='address' rules={[
                                {
                                    required: true,
                                    message: 'Address is required',
                                }]}>
                                <Input placeholder="Address" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Fax" name='fax' rules={[
                                {
                                    required: true,
                                    message: 'Fax is required',
                                }]}>
                                <Input placeholder="Fax" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="State" name='state' rules={[
                                {
                                    required: true,
                                    message: 'State is required',
                                }]}>
                                <Input placeholder="State" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="City" name='city' rules={[
                                {
                                    required: true,
                                    message: 'City is required',
                                }]}>
                                <Input placeholder="City" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Country" name='country' rules={[
                                {
                                    required: true,
                                    message: 'Country is required',
                                }]}>
                                <Input placeholder="Country" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Latitude" name='latitude' rules={[
                                {
                                    required: true,
                                    message: 'Latitude is required',
                                }]}>
                                <Input placeholder="Latitude" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Longitiude" name='longitiude' rules={[
                                {
                                    required: true,
                                    message: 'Longitiude is required',
                                }]}>
                                <Input placeholder="Longitiude" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Zip Code" name='zip_code' rules={[
                                {
                                    required: true,
                                    message: 'Zip Code is required',
                                }]}>
                                <Input placeholder="Zip Code" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderSupplierFormMobile()
        } else {
            return renderSupplierForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(SupplierForm)