import { Col, Row, Form, Switch, Typography } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const { Text } = Typography

const ComboInventory = ({ Setting }) => {

    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <>
            <br />
            <Row gutter={10}>

                <Col span={9}>
                    <Text>Assign inventory at the beginning</Text>
                </Col>

                <Col span={3}>
                    <Form.Item name='combo_inventory_control'
                        initialValue={data.combo_inventory_control}>
                        <Switch
                            style={{
                                marginLeft: 10,
                                backgroundColor: '#4b90ff'
                            }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CheckOutlined />}
                            defaultChecked={data.combo_inventory_control}
                        />
                    </Form.Item>
                </Col>

                <Col span={10}>
                    <Text>Deduct inventory at the time of sale</Text>
                </Col>
            </Row>
        </>

    )
}

export default ComboInventory