import { Button, Card, Col, Row, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCustomers } from "../../data";
import { PAGINATION_OPTIONS } from "../../constants";
import { EditOutlined } from '@ant-design/icons';
import windowSize from "react-window-size";
import Summary from "../reports/layout/summary";

const { Title, Text } = Typography;

function Customers({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([])
    const [balance, setBalance] = useState(0)
    const [advance, setAdvance] = useState(0)
    let history = useHistory()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (text) => <Text>{text}</Text>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/ledger/' + record.id, search: 'customer' })}>Ledger</Button>
                        <Button onClick={() => history.push({ pathname: 'customers/form/' + record.id })}>Edit</Button>
                    </Space>)
            }
        },
    ]

    const initialize = async () => {
        const branch_id = localStorage.getItem('branch_id') ?? 0
        const data = await getCustomers('?branch_id=' + branch_id);
        if (data) {
            setDataSource(data);

            let balance = 0;
            let advance = 0;

            data.map(item => {
                let price = parseFloat(item.balance)

                if (price > 0) {
                    balance += price
                } else {
                    advance += price
                }
            })

            setAdvance(advance);
            setBalance(balance);

        }
    }

    useEffect(() => {
        initialize()
    }, [])


    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Customers</Title>
                    <Button type="primary" onClick={() => history.push({ pathname: 'customers/form/0' })}>Add Customer</Button>
                </div>
            </>
        )
    }

    const summary = (
        <>
            <Title level={3}>Customers Summary</Title>

            <Row gutter={16}>
                <Col span={isMobile ? 24 : 6}>
                    <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                        <b>Total Customers :</b>
                        <br />
                        {dataSource.length}
                    </Card>
                </Col>
                <Col span={isMobile ? 24 : 6}>
                    <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                        <b>Total Receivable :</b>
                        <br />
                        {balance}
                    </Card>
                </Col>
                <Col span={isMobile ? 24 : 6}>
                    <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                        <b>Total Advance :</b>
                        <br />
                        {Math.abs(advance)}
                    </Card>
                </Col>
                <Col span={isMobile ? 24 : 6}>
                    <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                        <b>Net Balance :</b>
                        <br />
                        {Math.abs(balance + advance)} { Math.abs(balance) > Math.abs(advance) ? 'Receivables' : 'Advances' }
                    </Card>
                </Col>
            </Row>
        </>
    )

    const renderCustomers = () => (
        <>
            <Card>
                {summary}
                <br />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                    pagination={{ ...PAGINATION_OPTIONS }}
                />
            </Card>
        </>
    )

    const renderCustomersMobile = () => (
        <Card>
            {summary}
            <br />
            {
                dataSource.map(c => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Space size='small'>
                                    <Button onClick={() => history.push({ pathname: '/ledger/' + c.id, search: 'customer' })}>Ledger</Button>
                                    <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: 'customers/form/' + c.id })}>Edit</Button>
                                </Space>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{c.name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Phone : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{c.phone}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Balance : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{c.balance}</span></Text>
                            <br />
                        </Card>
                    </>
                ))
            }
        </Card>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderCustomersMobile()}
                </>
            )
        }
        else {
            return renderCustomers()
        }
    }

    return renderContent()

}

export default windowSize(Customers)