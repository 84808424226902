import React, { useEffect, useState } from "react";
import { Button, Space, Table, Typography, Avatar, Modal, notification, Card, Divider,Image } from "antd"
import { PAGINATION_OPTIONS } from "../../constants"
import { useHistory } from "react-router-dom"
import { getBanners, deleteBanner } from "../../data";
import { CloseCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ajaxService } from "../../services";
import windowSize from "react-window-size";

const { Title, Text } = Typography

function Banners({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    let history = useHistory()

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Subtitle',
            dataIndex: 'subtitle',
            key: 'subtitle'
        },
        {
            title: 'Web Banner',
            dataIndex: 'web_banner',
            key: 'web_banner',
            render: (web_banner) => <Avatar shape="square" size={64} src={ajaxService.getImage(web_banner)} />
        },
        {
            title: 'Mobile Banner',
            dataIndex: 'mobile_banner',
            key: 'mobile_banner',
            render: (mobile_banner) => <Avatar shape="square" size={64} src={ajaxService.getImage(mobile_banner)} />
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/banners/form/' + record.id })}>Edit</Button>
                        <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>)
            }
        },
    ]

    const deleteRow = async () => {
        let banner_id = localStorage.getItem('banner_id')
        const data = await deleteBanner(banner_id);

        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Banner has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted banner', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('banner_id', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const data = await getBanners(forceRefresh);
        if (data) {
            setDataSource(data);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Banners</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/banners/form/0' })}>Add Banner</Button>
            </Space>
        </div>
    )

    const renderModal = (
        <Modal
            title="Delete Banner"
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteRow()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this banner?</p>
            </div>
        </Modal>
    )

    const renderBanners = () => (
        <Table
            title={() => renderHeader()}
            dataSource={dataSource}
            columns={columns}
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )

    const renderBannersMobile = () => (
        <>
            {
                dataSource.map(b => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Space size='small'>
                                    <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/banners/form/" + b.id })}>Edit</Button>
                                    <Button type='primary' icon={<DeleteOutlined />} onClick={() => openDeleteModal(b.id)} danger>Delete</Button>
                                </Space>
                            ]}>
                            <Text style={{ fontWeight: 'bolder' }}>Title : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{b.title}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Subtitle : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{b.subtitle}</span></Text>
                            <Divider plain>Web Banner</Divider>
                            <Image.PreviewGroup>
                                <Image width={250} height={150} sizes="small" src={ajaxService.getImage(b.web_banner)} />
                            </Image.PreviewGroup>
                            <Divider plain>Mobile Banner</Divider>
                            <Image.PreviewGroup>
                                <Image width={250} height={150} sizes="small" src={ajaxService.getImage(b.mobile_banner)} />
                            </Image.PreviewGroup>
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderBannersMobile()}
                </>
            )
        }
        else {
            return renderBanners()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal}
        </>
    )
}
export default windowSize(Banners)