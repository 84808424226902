import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getStaffManagements(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.staffManagements, 'staffManagements');
    } else {
        let staffManagements = await loadState('staffManagements');

        if (staffManagements == null) {
            return await loadResource(urlService.staffManagements, 'staffManagements');
        } else {
            return Response(staffManagements);
        }
    }
}

export async function getStaffManagement(id) {
    let staffManagements = await loadState('staffManagements');
    if (staffManagements !== null) {
        return staffManagements.find(staffManagement => staffManagement.id == id);
    } else {
        return ajaxService.get(urlService.staffManagements, { id: id }).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveStaffManagement(data) {
    setDependency()
    return ajaxService.post(urlService.staffManagements, data).then(response => {
        return Response(response.data);
    })
}

export async function updateStaffManagement(id, data) {
    setDependency()
    return ajaxService.put(urlService.staffManagements + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteStaffManagement(id) {
    setDependency()
    return ajaxService.delete(urlService.staffManagements + '/' + id).then(response => {
        return Response(response.data);
    })
}