import { Avatar, Button, Card, Col, Form, Image, Input, notification, Row, Select, Space, Switch, Table, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { CheckOutlined, CloseOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { updateValue } from './updateValue';
import { getBrands, getCategories, getStoreSetting } from '../../../data';
import windowSize from 'react-window-size';
import { ajaxService } from '../../../services';
import ControlSettings from '../../../constant/controlSettings';
import dataService from '../../../services/data-service';
import { PAGINATION_OPTIONS } from '../../../constants';

const { Option } = Select;
const { Text } = Typography;

const defaultColumns = [
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        width: '10%',
        render: (image) => <Avatar shape="square" size={64} src={image} />

    },
    {
        title: 'Name / SKU',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (<>
            <span>{record.name}</span>
            <br />
            <span>{record.sku}</span>
        </>
        )
    },
    {
        title: 'Selling Price',
        dataIndex: 'selling_price',
        key: 'selling_price',
        render: (text, record) => (
            <Input onChange={(val) => updateValue(record.id, val, 'selling_price', 'Selling Price')} type='number' defaultValue={record.selling_price} placeholder={'0'} />
        )
    },
    {
        title: 'Compare Price',
        dataIndex: 'compare_price',
        key: 'compare_price',
        render: (text, record) => (
            <Input onChange={(val) => updateValue(record.id, val, 'compare_price', 'Compare Price')} type='number' defaultValue={record.compare_price} placeholder={'0'} />
        )
    }
]

function PriceBook({ windowWidth, innerRef }) {

    const [branchCatalogToggle, setBranchCatalogToggle] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [categories, setCategories] = useState([]);
    const [branches, setBranches] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [brandFilter, setBrandFilter] = useState(0);
    const [branchFilter, setBranchFilter] = useState(0);
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768;
    const [columns, setColumns] = useState(defaultColumns)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGINATION_OPTIONS.pageSize);
    const [totalRecords, setTotalRecords] = useState(PAGINATION_OPTIONS.total);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const handlePagination = (pagination, filters, sorter) => {

        if (pagination.current !== currentPage) {
            setCurrentPage(pagination.current)
        }

        if (pagination.pageSize !== pageSize) {
            setCurrentPage(PAGINATION_OPTIONS.current) // reset current page when page size changes
            setPageSize(pagination.pageSize)
        }
    };

    const handleSearch = async () => {

        const inputValue = inputRef.current.value;

        setLoading(true);

        let response = null;

        let params = { per_page: pageSize, page: currentPage, keyword: inputValue };

        if (categoryFilter && categoryFilter > 0) {
            params['category_id'] = categoryFilter
        }
        console.log(brandFilter)
        if (brandFilter && brandFilter > 0) {
            params['brand_id'] = brandFilter
        }

        if (branchFilter && branchFilter > 0) {
            params['branch_id'] = branchFilter
        }

        response = await dataService.getPricebookProducts(params);
        setLoading(false);

        let { data, total } = response;

        if (total === 0) {
            notification.error({ message: 'Search Results', description: 'No records found.', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            setTotalRecords(0)
            setDataSource([]);
        } else {
            setTotalRecords(total)
            setDataSource(data);
        }
    }

    useEffect(() => {
        handleSearch();
    }, [currentPage, pageSize])

    const clear = () => {
        setCategoryFilter(0)
        setBrandFilter(0)
        setBranchFilter(0)
        inputRef.current.value = ''
        handleSearch();
    }

    const loadCategories = async () => {
        const categories = await getCategories(forceRefresh);
        if (categories) {
            setCategories(categories);
            setForceRefresh(false)
        }
    }

    const loadBrands = async () => {
        const brands = await getBrands(forceRefresh);
        if (brands) {
            setBrands(brands);
        }
    }

    const branchCatalogSetting = async () => {
        const response = await getStoreSetting(ControlSettings.BRANCH_CATALOG);
        if (response) {
            setBranchCatalogToggle(response.active)
        }
    }

    // const loadBranches = async () => {

    //     let branches = JSON.parse(localStorage.getItem('branches'))

    //     if (branches && branches.length > 0) {
    //         setBranches(branches)
    //         setForceRefresh(false);
    //     }
    // }

    const checkInventoryType = async () => {

        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);

        if (!response.active) {
            let columns = [
                ...defaultColumns,
                {
                    title: 'Stock',
                    dataIndex: 'stock',
                    key: 'stock',
                    render: (text, record) => (
                        <Input onChange={(val) => updateValue(record.id, val, 'stock', 'Stock')} type='number' value={record.stock} placeholder={'0'} />
                    )
                },
                {
                    title: 'Cost Price',
                    dataIndex: 'cost_price',
                    key: 'cost_price',
                    render: (text, record) => (
                        <Input onChange={(val) => updateValue(record.id, val, 'cost_price', 'Cost Price')} type='number' value={record.cost_price} placeholder={'0'} />
                    )
                }
            ]

            setColumns(columns);
        }
    }

    useEffect(() => {
        // initialize()
        // loadBranches();
        loadCategories();
        loadBrands();
        checkInventoryType();
    }, []);

    const renderFilter = () => (
        <Form

        // onFinish={}
        >
            <Row gutter={16} >
                <Col span={24}>
                    <Form.Item label="Product / SKU" >
                        <input placeholder='Search by product name or sku' ref={inputRef} className='ant-input' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Category" initialValue="Select Category">
                        <Select allowClear
                            value={categoryFilter === 0 ? 'Select Category' : categoryFilter}
                            onChange={(e) => setCategoryFilter(e)}>
                            {categories.map(category => {
                                return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Brand" initialValue="Select Brand">
                        <Select placeholder="Select Brand" allowClear
                            value={brandFilter === 0 ? 'Select Brand' : brandFilter}
                            onChange={(e) => setBrandFilter(e)}>
                            {brands.map(brand => {
                                return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} className="gutter-row">
                    <Form.Item>
                        <Space>
                            <Button type='primary' onClick={handleSearch}>Search</Button>
                            <Button onClick={clear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="primary" onClick={handleSearch}>Refresh</Button>
        </div>
    );

    const renderPriceBook = () => (
        <>
            {/* Filter */}
            <Form layout={'vertical'}>
                <Row gutter={16} >
                    <Col span={6}>
                        <Form.Item label="Product / SKU" >
                            <input placeholder='Search by product name or sku' ref={inputRef} className='ant-input' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Category" initialValue="Select Category">
                            <Select allowClear
                                value={categoryFilter === 0 ? 'Select Category' : categoryFilter}
                                onChange={(e) => setCategoryFilter(e)}>
                                {categories.map(category => (
                                    <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Brand" initialValue="Select Brand">
                            <Select placeholder="Select Brand" allowClear
                                value={brandFilter === 0 ? 'Select Brand' : brandFilter}
                                onChange={(e) => setBrandFilter(e)}>
                                {brands.map(brand => (
                                    <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item label='&nbsp;'>
                            <Space>
                                <Button onClick={handleSearch}>Search</Button>
                                <Button onClick={clear}>Clear</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                rowKey={record => record.id}
                pagination={{
                    ...PAGINATION_OPTIONS,
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalRecords,
                }}
                loading={loading}
                onChange={handlePagination}
            />
        </>
    );

    const renderPriceBookMobile = () => {
        return (
            <>
                {renderFilter()}
                {
                    dataSource.map(priceBook => {
                        return (
                            <>
                                <Card>
                                    <Image.PreviewGroup>
                                        <Image width={250} height={150} sizes="small" src={ajaxService.getImage(priceBook.image)} />
                                    </Image.PreviewGroup>
                                    <br />
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Name / SKU :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{priceBook.name + ' / ' + priceBook.sku}</span>
                                    </Text>
                                    <br />
                                    <br />
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label><b>Cost Price :</b></label>
                                            <Input onChange={(val) => updateValue(priceBook.id, val, 'cost_price', 'Cost Price')}
                                                type='number' defaultValue={priceBook.cost_price} placeholder={'0'} />
                                        </Col>
                                        <Col span={24}>
                                            <br />
                                            <label><b>Selling Price :</b></label>
                                            <Input onChange={(val) => updateValue(priceBook.id, val, 'selling_price', 'Selling Price')}
                                                type='number' defaultValue={priceBook.selling_price} placeholder={'0'} />
                                        </Col>
                                        <Col span={24}>
                                            <br />
                                            <label><b>Compare Price :</b></label>
                                            <Input onChange={(val) => updateValue(priceBook.id, val, 'compare_price', 'Compare Price')}
                                                type='number' defaultValue={priceBook.compare_price} placeholder={'0'} />
                                        </Col>
                                        {/* {
                                            !branchCatalogToggle &&
                                            <Col span={24}>
                                                <br />
                                                <label><b>Stock :</b></label>
                                                <Input onChange={(val) => updateValue(priceBook.id, val, 'stock', 'Stock')}
                                                    type='number' defaultValue={priceBook.stock} placeholder={'0'} />
                                            </Col>
                                        } */}
                                    </Row>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Active :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                            <Switch
                                                onChange={(val) => updateValue(priceBook.id, val, 'active', 'Active')}
                                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                                defaultChecked={priceBook.active == 1 ? true : false}
                                            />
                                        </span>
                                    </Text>
                                </Card>
                                <br />
                            </>
                        )
                    })
                }
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderPriceBookMobile()
        }
        else {
            return renderPriceBook()
        }
    }

    return (
        <div ref={innerRef} >
            {isMobile && renderHeader()}
            {renderContent()}
        </div>
    )

}

export default windowSize(PriceBook)