import { useEffect, useState } from "react";
import { Card, Col, notification, Row, Switch, Tabs, Typography } from "antd";
import { getBranches, getBrands, getCategories, getProducts, updateStoreSetting } from "../../data";
import { CloseOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import windowSize from "react-window-size";
import dataService from "../../services/data-service";

const { Title, Text } = Typography;

const BranchCatalog = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    const [brands, setBrands] = useState([])
    const [products, setProducts] = useState([])
    const [branches, setBranches] = useState([])
    const [categories, setCategories] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)

    const updateValue = async (id, val, type, branch_id) => {
        const active = await updateStoreSetting(id, { type: type, value: val, branch_id: branch_id })
        if (active) {
            setForceRefresh(true)
        } else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const loadBranches = async () => {
        const data = await getBranches(forceRefresh);
        if (data) {
            setBranches(data);
        }
    }

    const loadBrands = async () => {
        const data = await getBrands(forceRefresh);
        if (data) {
            setBrands(data.filter(i => i.active));
            setForceRefresh(false)
        }
    }

    const loadCategories = async () => {
        const data = await getCategories(forceRefresh);
        if (data) {
            setCategories(data.filter(i => i.active));
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        const data = await dataService.getCatalogueProducts();
        if (data) {
            setProducts(data);
        }
    }

    useEffect(() => {
        loadCategories();
        loadBrands();
        loadProducts();
        loadBranches()
    }, []);

    const brandDefaultChecked = (branch_id, brand) => {

        let branch = brand.branch_brands.find(i => i.branch_id == branch_id);

        if (branch) {
            return branch.active == 1;
        }

        return false;
    }

    const categoryDefaultChecked = (branch_id, category) => {

        let branch = category.branch_categories.find(i => i.branch_id == branch_id);

        if (branch) {
            return branch.active == 1;
        }

        return false;
    }

    const productDefaultChecked = (branch_id, product) => {

        let branch = product.branch_products.find(i => i.branch_id === branch_id);

        if (branch) {
            return branch.active === 1;
        }

        return false;
    }

    const renderTableHeader = () => (
        <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }}>
            <Col span={6}>Name</Col>
            {
                branches.map((branch, i) => (
                    <>
                        <Col span={3}>
                            <Text style={{ textTransform: 'capitalize' }}>
                                {branch.name}
                            </Text>
                        </Col>
                    </>
                ))
            }
        </Row>
    )

    const renderBranchCatalogue = () => (
        <>
            <Card>
                <Title level={3}>Branch Catalog</Title>
                <Tabs>
                    <Tabs.TabPane tab="Brands" key="item-1">
                        {renderTableHeader()}
                        {
                            brands.map((brand, index) => (
                                <>
                                    <Row gutter={16} style={{ padding: '15px 20px' }}>
                                        <Col span={6}>
                                            <Text><label>{brand.name}</label></Text>
                                        </Col>
                                        {
                                            branches.map((branch, i) => (
                                                <>
                                                    <Col span={3}>
                                                        <Switch
                                                            id={'brand-' + i}
                                                            onChange={(val) => updateValue(brand.id, val, 'brand', branch.id)}
                                                            checkedChildren={<CheckOutlined />}
                                                            unCheckedChildren={<CloseOutlined />}
                                                            defaultChecked={brandDefaultChecked(branch.id, brand)}
                                                        />
                                                    </Col>
                                                </>
                                            ))
                                        }

                                    </Row>
                                </>
                            ))
                        }
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Categories" key="item-2">
                        {renderTableHeader()}
                        {
                            categories.map((category, index) => (
                                <>
                                    <Row gutter={16} style={{ padding: '15px 20px' }}>
                                        <Col span={6}>
                                            <Text><label>{category.name}</label></Text>
                                        </Col>

                                        {
                                            branches.map((branch) => (
                                                <Col span={3}>
                                                    <Switch
                                                        id={'category-' + index}
                                                        onChange={(val) => updateValue(category.id, val, 'category', branch.id)}
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        defaultChecked={categoryDefaultChecked(branch.id, category)}
                                                    />
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </>
                            ))
                        }
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Products" key="item-3">
                        {renderTableHeader()}
                        {
                            products.map((product, index) => (
                                <>
                                    <Row gutter={16} style={{ padding: '15px 20px' }}>
                                        <Col span={6}>
                                            <Text><label>{product.name}</label></Text>
                                        </Col>
                                        {
                                            branches.map((branch) => (
                                                <Col span={3}>
                                                    <Switch
                                                        id={'product-' + index}
                                                        onChange={(val) => updateValue(product.id, val, 'productToggle', branch.id)}
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        defaultChecked={productDefaultChecked(branch.id, product)}
                                                    />
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </>
                            ))
                        }
                    </Tabs.TabPane>

                </Tabs>
            </Card>
        </>
    )

    const renderBranchCatalogueMobile = () => (
        <>
            <Title level={3}>Branch Catalog</Title>

            <Card>
                <Tabs>
                    <Tabs.TabPane tab="Brands" key="item-1">
                        {
                            brands.map((brand, index) => (
                                <>
                                    <br />
                                    <Card>
                                        <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{brand.name}</span></Text>
                                        <br /><br />
                                        {
                                            branches.map((branch, i) => (
                                                <>
                                                    <Text style={{ fontWeight: 'bolder' }}>{branch.name} :
                                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                            <Switch
                                                                id={'brand-' + i}
                                                                onChange={(val) => updateValue(brand.id, val, 'brand', branch.id)}
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={<CloseOutlined />}
                                                                defaultChecked={brandDefaultChecked(branch.id, brand)}
                                                            />
                                                        </span>
                                                    </Text>
                                                    <br /><br />
                                                </>
                                            ))
                                        }
                                    </Card>
                                </>
                            ))
                        }
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Categories" key="item-2">
                        {
                            categories.map((category, index) => (
                                <>
                                    <br />
                                    <Card>
                                        <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{category.name}</span></Text>
                                        <br /><br />
                                        {
                                            branches.map((branch, i) => (
                                                <>
                                                    <Text style={{ fontWeight: 'bolder' }}>{branch.name} :
                                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                            <Switch
                                                                id={'category-' + index}
                                                                onChange={(val) => updateValue(category.id, val, 'category', branch.id)}
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={<CloseOutlined />}
                                                                defaultChecked={categoryDefaultChecked(branch.id, category)}
                                                            />
                                                        </span>
                                                    </Text>
                                                    <br /><br />
                                                </>
                                            ))
                                        }
                                    </Card>
                                </>
                            ))
                        }
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Products" key="item-3">
                        {
                            products.map((product, index) => (
                                <>
                                    <br />
                                    <Card>
                                        <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{product.name}</span></Text>
                                        <br /><br />
                                        {
                                            branches.map((branch, i) => (
                                                <>
                                                    <Text style={{ fontWeight: 'bolder' }}>{branch.name} :
                                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                            <Switch
                                                                id={'product-' + index}
                                                                onChange={(val) => updateValue(product.id, val, 'productToggle', branch.id)}
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={<CloseOutlined />}
                                                                defaultChecked={productDefaultChecked(branch.id, product)}
                                                            />
                                                        </span>
                                                    </Text>
                                                    <br /><br />
                                                </>
                                            ))
                                        }
                                    </Card>
                                </>
                            ))
                        }
                    </Tabs.TabPane>

                </Tabs>
            </Card>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderBranchCatalogueMobile()
        } else {
            return renderBranchCatalogue()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

}

export default windowSize(BranchCatalog)