import { Button, Card, Checkbox, Col, Divider, notification, Row, Space, Typography } from "antd"
import { useState } from "react"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { getStore, getStorePermissions, saveStorePermissions } from "../../data"
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Title } = Typography
function StorePermissions() {

    let { id } = useParams()
    let history = useHistory()

    const [store, setStore] = useState({})
    const [permissions, setPermissions] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const savePermissions = async () => {

        let params = [];
        permissions.map(permission => {
            if (permission.checked) {
                params.push(permission.id);
            }
        })

        const response = await saveStorePermissions(id, { permissions: params });
        if (response) {
            history.push({ pathname: "/stores" });
            notification.open({ message: 'Added permissions for ' + store.name, icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to added permissions for ' + store.name, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(permissions.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        let checkedPermission = permissions.find(i => i.id === id)
        checkedPermission.checked = checked
    };

    const loadStore = async (id) => {
        const response = await getStore(id)
        if (response) {
            setStore(response)
        }
    }

    const initialize = async (id) => {
        const response = await getStorePermissions(id)
        if (response) {
            setPermissions(response)
        }
    }

    useEffect(() => {
        initialize(id)
        loadStore(id)
    }, [])

    const renderHeader = () => {
        return (
            <>
                <Title level={3}>Permissions For {store.name}</Title>
            </>
        )
    }

    const renderPermissions = () => (
        permissions.map((v) => (
            <>
                <Row style={{ backgroundColor: '#fff', padding: '15px 20px' }}>
                    <Col span={22}><label for={v.id} style={{ cursor: 'pointer' }}>{v.title}</label></Col>
                    <Col span={2}>
                        <Checkbox key={isCheck.includes(v.id)} id={v.id}
                            onChange={handleClick}
                            defaultChecked={isCheck.includes(v.id) || v.checked ? true : false}
                        />
                    </Col>
                </Row>
                <Divider style={{ padding: '0px', margin: '0' }} />
            </>
        ))
    )

    return (
        <>
            <Card>
                {renderHeader()}

                <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }}>
                    <Col span={22}>Title</Col>
                    <Col span={2}>
                        <Checkbox type="checkbox" name="selectAll" id="selectAll"
                            onChange={handleSelectAll}
                            checked={isCheckAll}>
                            All
                        </Checkbox>
                    </Col>
                </Row>

                {renderPermissions()}

                <Row style={{ backgroundColor: '#fff', padding: '10px 20px' }}>
                    <Col span={20}></Col>
                    <Space style={{ float: 'right', marginTop: '10px' }}>
                        <Button htmlType="submit" type="primary" onClick={savePermissions}>Submit</Button>
                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                    </Space>
                </Row>

            </Card>
        </>
    )
}

export default StorePermissions