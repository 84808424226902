import React, { useState, useEffect, useCallback } from 'react';
import { Col, notification, Typography, Row, Divider, Form, Button, Dropdown, Menu, Card, Checkbox, Space, Modal, Input, message, Select, Table } from 'antd';
import { useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { getOrder, getProducts, getStoreSetting, saleReturnOrder, updateStatus } from '../../data';
import windowSize from 'react-window-size';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MENAGEINVENTORYCOSTING from '../../constant/manageInventoryCosting';
import ControlSettings from '../../constant/controlSettings';
import OrderStatus from '../../constant/orderStatus';

const { Text, Title } = Typography
const { Option } = Select

const format = 'DD-MM-Y';

const status = Object.values(OrderStatus)

const OrderForm = ({ windowWidth }) => {

  const [form] = Form.useForm()
  const isMobile = windowWidth <= 768
  let { id } = useParams()
  const branch_id = localStorage.getItem('branch_id')
  let history = useHistory()

  const [order, setOrder] = useState({})
  const [orderItems, setOrderItems] = useState([])
  const [allOrderItems, setAllOrderItems] = useState([])
  const [returnedItems, setReturnedItems] = useState([])
  const [returnedOrders, setReturnedOrders] = useState([])
  const [products, setProducts] = useState([])
  const [currency, setCurrency] = useState('');
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [itemsLimit, setItemsLimit] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [forceRefresh, setForceRefresh] = useState(true);

  const [remainingOrderDiscount, setRemainingOrderDiscount] = useState(0)
  const [remainingOrderShipping, setRemainingOrderShipping] = useState(0)

  const [condition, setCondition] = useState(false)

  const saleReturn = async (values) => {

    if (values.shipping_charges > remainingOrderShipping) return message.error('The shipping charges you are trying to return are more than the total shipping charges.');

    if (values.total_discount > remainingOrderDiscount) return message.error('The discount you are trying to return is more than the total discount.');

    values.saleReturn.map(i => {
      if (i.total_quantity > i.available_qty) {
        message.error('The quantity you are trying to return is more than the sold.');
        throw new Error('Invalid quantity');
      }

      // if (values.saleReturn.length === 1 && i.total_quantity == 0) return message.error('Invalid Quantity.')

      return null;
    });

    values.saleReturn = values.saleReturn.filter(i => i.total_quantity > 0);

    const response = await saleReturnOrder({ ...values, order, branch_id: branch_id })

    if (response) {
      initialize(id)
      setShowModal(false)
      setIsCheckAll(false);
      setIsCheck([]);
      notification.open({ message: 'The items has been returned successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to returned items', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const handleSelectAll = (e) => {

    setIsCheckAll(!isCheckAll);
    setIsCheck(orderItems.map(li => condition ? li.id : li.product_id));

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e, productID) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, condition ? id : productID]);

    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== (condition ? id : productID)));
    }
  };

  function singleProduct(product_id) {
    let productRow = products.find(i => i.id === product_id);
    return productRow
  }

  const openModal = () => {

    const returnQty = returnedItems.reduce((acc, item) => {

      let productName = item.product_name.replace(/\s+/g, '_');

      const key = `${productName}_${item.grn_number}`;
      if (!acc[key]) {
        acc[key] = { grn_number: item.grn_number, quantity: item.quantity, product_name: item.product_name, product_id: item.product_id };
      } else {
        acc[key].quantity += item.quantity;
      }
      return acc;
    }, {})

    const returnQtyArray = Object.values(returnQty);

    let checkedData = []
    let rows = []

    allOrderItems.map((item, i) => {
      if (isCheck.includes(condition ? item.id : item.product_id)) {

        let returnItem = returnQtyArray.find((returnItem) => returnItem.product_id === item.product_id && returnItem.grn_number === item.grn_number);

        let q;

        if (returnItem && returnItem.grn_number === item.grn_number) {
          q = returnItem.quantity - item.quantity;

          if (q === 0) {
            return false;
          }
        }

        item.available_qty = returnItem ? item.quantity - returnItem.quantity : item.quantity;
        item.status = 'Saleable';
        item.total_quantity = 0;

        if (item.available_qty > 0) {
          checkedData.push(item);
        }

        rows.push(item.available_qty);
      }
      return null;
    });

    let returnedOrdersDiscount = returnedOrders.reduce((acc, entry) => acc + parseFloat(entry.discount), 0);
    const calculateRemainingOrderDiscount = order.discount - returnedOrdersDiscount
    setRemainingOrderDiscount(calculateRemainingOrderDiscount)

    let returnedOrdersShipping = returnedOrders.reduce((acc, entry) => acc + parseFloat(entry.shipping), 0);
    const calculateRemainingOrderShipping = order.shipping - returnedOrdersShipping
    setRemainingOrderShipping(calculateRemainingOrderShipping)

    setItemsLimit(rows)

    form.setFieldsValue({ saleReturn: checkedData, shipping_charges: calculateRemainingOrderShipping, total_discount: calculateRemainingOrderDiscount })

    setShowModal(true);
  }

  const changeStatus = async (status) => {
    let data = { id, status }
    const response = await updateStatus(id, data);
    if (response) {
      notification.open({ message: 'Order status has been  updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      window.location.reload()
    } else {
      notification.open({ message: 'Unable to update order status...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const loadProducts = async () => {
    const response = await getProducts(forceRefresh)
    if (response) {
      setProducts(response)
      setForceRefresh(false)
    }
  }


  const initialize = useCallback(async (id) => {
    if (id > 0) {
      let data = await getOrder(id);
      if (data != null) {

        let { order, orderItems, returnedOrderItems, returnOrders } = data
        setOrder(order);
        setReturnedItems(returnedOrderItems)
        setAllOrderItems(orderItems)
        setReturnedOrders(returnOrders)

        const groupedOrderItems = orderItems.reduce((acc, orderItem) => {
          const existingOrderItem = acc.find(item => item.product_name === orderItem.product_name);
          if (existingOrderItem) {
            existingOrderItem.quantity += orderItem.quantity;
          } else {
            acc.push({ ...orderItem });
          }
          return acc;
        }, []);

        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);
        
        if (response) {
          const data = { active: response.active, ...JSON.parse(response.setting) };

          const specificIdentification = data.active && data.costing === MENAGEINVENTORYCOSTING.SPECIFICIDENTIFICATION

          setCondition(specificIdentification)

          let items = specificIdentification ? orderItems : groupedOrderItems;

          let rows = [];

          items.map(orderItem => {
  
            let orderSellingPrice = 0;
  
            if (orderItem.additions) {
              let records = [];
              orderItem.additions.map(addon => {
  
                orderSellingPrice += parseFloat(addon.price);
  
                records.push({
                  total: parseFloat(addon.price) * orderItem.quantity,
                  product_name: addon.title + ': ' + addon.item,
                  quantity: orderItem.quantity,
                  selling_price: parseFloat(addon.price),
                  isMain: false
                })
              })
  
              records.sort((a, b) => {
                if (a.product_name < b.product_name) return -1;
                if (a.product_name > b.product_name) return 1;
                return 0;
              });
  
              orderItem.isMain = true;
              rows.push(orderItem);
  
              rows = [...rows, ...records];
            }
          });          

          setOrderItems(rows);

        }
      }
    }
  }, [id])

  useEffect(() => {
    initialize(id);
    loadProducts();

    const branch_id = localStorage.getItem('branch_id');

    let store = JSON.parse(localStorage.getItem('store'))

    let branch = store.branches.find(b => b.id == branch_id);

    setCurrency(branch.currency)

  }, []);

  const renderOrderDetail = (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Title level={4}>Order Details</Title>
        <div className='flexItem'>
          <Text>Order Number</Text>
          <Text>#{order.order_number}</Text>
        </div>
        <div className='flexItem'>
          <Text>Order Date</Text>
          <Text>{order.date}</Text>
        </div>
        <div className='flexItem'>
          <Text>Order status</Text>
          <Dropdown
            overlay={
              <Menu>
                {status.slice(status.indexOf(order.status) + 1).map((status, key) => (
                  <Menu.Item onClick={() => changeStatus(status)} key={key}>
                    {status}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomCenter"
          >
            <Button value={order.status}>{order.status}</Button>
          </Dropdown>
        </div>
        <div className='flexItem'>
          <Text>Shipping Address</Text>
          <Text>{order.shipping_address || 'N/A'}</Text>
        </div>
        <div className='flexItem'>
          <Title level={4}>Payment Method</Title>
          <Text>{order.payment_method}</Text>
        </div>
      </Col>
      <Col span={12}>
        <Title level={4}>Customer Information</Title>
        <div className='flexItem'>
          <Text>Customer</Text>
          <Text>{order.name || 'N/A'}</Text>
        </div>
        <div className='flexItem'>
          <Text>Email</Text>
          <Text>{order.email || 'N/A'}</Text>
        </div>
        <div className='flexItem'>
          <Text>Phone</Text>
          <Text>{order.phone || 'N/A'}</Text>
        </div>
      </Col>
    </Row>
  )

  // const QuantityValidation = (_, value) => {

  //   if (value > 0) {
  //     return Promise.resolve();
  //   }

  //   return Promise.reject(new Error('Invalid quantity'));
  // };

  const renderModal = (
    <Form layout={'vertical'} onFinish={saleReturn} form={form}>
      <Modal
        title={'Sale Return'}
        centered
        open={showModal}
        width={'80%'}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" type="primary" onClick={form.submit}> Sale Return </Button>,
          <Button key="back" onClick={() => setShowModal(false)}> Close </Button>
        ]}
      >
        <Row>
          <Col span={3}>
            <b>Grn No</b>
          </Col>
          <Col span={6}>
            <b> Products Name</b>
          </Col>
          <Col span={5}>
            <b>Price</b>
          </Col>
          <Col span={3}>
            <b>Available Qty</b>
          </Col>
          <Col span={4}>
            <b>Quantity</b>
          </Col>
          <Col span={3}>
            <b>Send To</b>
          </Col>
        </Row>
        <br />
        <Form.List name="saleReturn">
          {(fields, { add, remove }) => (
            fields.map((field, index) => {
              const rowData = form.getFieldValue('saleReturn')[index];

              let product = singleProduct(rowData.product_id)

              return (
                <>
                  <Row>
                    <Col span={3}>
                      <Form.Item name={[field.name, 'grn_number']} key={`${field.key}-grn_number`}>
                        <input disabled className="productDropDown" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name={[field.name, 'product_name']} key={`${field.key}-product_name`}>
                        <input disabled className="productDropDown" style={{ width: '98%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item name={[field.name, 'selling_price']}
                        key={`${field.key}-selling_price`}
                      // rules={[{ validator: QuantityValidation, }]}
                      >
                        <Input type="number" style={{ marginTop: '4px', width: '60%' }} min={0} />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item name={[field.name, 'available_qty']} key={`${field.key}-available_qty`}>
                        <input disabled className="productDropDown" style={{ marginLeft: 20 }} />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item name={[field.name, 'total_quantity']} key={`${field.key}-total_quantity`}>
                        <Input type="number" style={{ marginTop: '4px', width: '60%' }} min={0} max={itemsLimit[[field.name]]} />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item name={[field.name, 'status']} key={`${field.key}-status`} initialValue={'Saleable'}>
                        <Select style={{ marginTop: '4px' }}>
                          <Option value="Saleable"> Saleable </Option>
                          {
                            !product.is_combo &&
                            <>
                              <Option value="Discard"> Discard </Option>
                              <Option value="Damage"> Damage </Option>
                            </>
                          }

                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={4}></Col>
                    <Col span={20}>
                      {/* {renderCombo(rowData)} */}
                    </Col>
                  </Row>
                </>
              )
            })
          )}
        </Form.List>
        <Divider />

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="total_discount" label="Return Discount">
              <Input type='number' min={0} step={0.01} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="shipping_charges" label="Return Shipping Charges">
              <Input type='number' min={0} step={0.01} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} >
            <Form.Item label="Total Discount Amount">
              {order.discount}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Shipping Charges">
              {order.shipping}
            </Form.Item>
          </Col>
        </Row>

      </Modal >
    </Form>
  )

  const renderCombo = (item) => {

    let product = singleProduct(item.product_id)

    if (product && product.is_combo) {
      return (
        product.product_combo.map(i => {
          let p = products.find(obj => obj.id === i.item_id)
          return (
            <Row style={{ marginLeft: 20 }}>
              <Col span={12}>
                <Text style={{ color: '#00CC66' }}>{p ? p.name : null}</Text>
              </Col>
              <Col span={4}>
                <Text style={{ color: '#00CC66' }}>{i.quantity} <span style={{ opacity: 0.6 }}>({item.quantity * i.quantity})</span></Text>
              </Col>
            </Row>
          )
        })
      )
    }
  }

  const renderTable = () => {

    const statusCondition = (order.status == OrderStatus.COMPLETED || order.status == OrderStatus.PARTIAL_RETURNED)

    return (
      <Row>
        <Col span={24}>
          <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }} gutter={16}>
            <Col span={condition ? 8 : 10}>Item</Col>
            {
              condition &&
              <Col span={2}>GRN Number</Col>
            }
            <Col span={1} style={{ textAlign: 'center' }}>Price</Col>
            <Col span={3} style={{ textAlign: 'center' }}>Quantity</Col>
            <Col span={2} style={{ textAlign: 'center' }}>Subtotal</Col>
            <Col span={3} style={{ textAlign: 'center' }}>Discount Amount</Col>
            <Col span={2} style={{ textAlign: 'center' }}>Total</Col>
            {
              statusCondition &&
              <>
                <Col span={1}></Col>
                <Col span={2} style={{ textAlign: "right", paddingRight: "10px" }}>
                  <Checkbox type="checkbox" name="selectAll" id="selectAll" onChange={handleSelectAll} checked={isCheckAll}>All</Checkbox>
                </Col>
              </>
            }
          </Row>
          {orderItems.map((item) => (
            <>
              <Row style={{ backgroundColor: '#fff', padding: item.isMain ? '15px 20px' : '0 20px' }} gutter={16}>
                <Col span={condition ? 8 : 10} style={{ paddingLeft : item.isMain ? 0 : 10 }}  >  {item.product_name}</Col>
                {
                  condition &&
                  <Col span={2}>{item.grn_number}</Col>
                }
                <Col span={1} style={{ textAlign: 'right' }}>{item.selling_price}</Col>
                <Col span={3} style={{ textAlign: 'center' }}>{item.quantity}</Col>
                <Col span={2} style={{ textAlign: 'center' }}>{item.subtotal}</Col>
                <Col span={3} style={{ textAlign: 'center' }}>{item.discount}</Col>
                <Col span={2} style={{ textAlign: 'center' }}>
                  <Text style={{ marginLeft: 10 }}>
                    {item.total}
                  </Text>
                </Col>
                {
                  statusCondition &&
                  <>
                    <Col span={1}></Col>
                    <Col span={2} style={{ textAlign: 'center', paddingLeft: "22px" }}>
                      <Checkbox key={item.id} id={item.id} onChange={(e) => handleClick(e, item.product_id)} checked={isCheck.includes(condition ? item.id : item.product_id)} />
                    </Col>
                  </>
                }
              </Row>

              {renderCombo(item)}

              <Divider style={{ padding: '0px', margin: '0' }} />
            </>
          ))}

          <div style={{ backgroundColor: '#fff', padding: '10px 20px', textAlign: 'right' }}>
            {
              statusCondition &&
              <Button type="primary" onClick={() => { isCheck.length > 0 ? openModal() : message.error('Please check the row and then try clicking the return button') }}>
                Return
              </Button>
            }
          </div>

        </Col>
        <Col span={2}></Col>
      </Row>
    )
  }

  const returnedOrderItemsTable = (returnItems) => (
    <Row>
      <Col span={24}>
        <Row style={{ backgroundColor: '#fafafa', padding: '15px 20px' }} gutter={16}>
          <Col span={8}>Item</Col>
          <Col span={2}>GRN Number</Col>
          <Col span={1} style={{ textAlign: 'center' }}>Price</Col>
          <Col span={3} style={{ textAlign: 'center' }}>Quantity</Col>
          <Col span={2} style={{ textAlign: 'center' }}>Subtotal</Col>
          <Col span={3} style={{ textAlign: 'center' }}>Discount Amount</Col>
          <Col span={3} style={{ textAlign: 'center' }}>Status</Col>
          <Col span={2} style={{ textAlign: 'center' }}>Total</Col>
        </Row>
        {returnItems.map((item) => (
          <>
            <Row style={{ backgroundColor: '#fff', padding: '15px 20px' }} gutter={16}>
              <Col span={8}>{item.product_name}</Col>
              <Col span={2}>{item.grn_number}</Col>
              <Col span={1} style={{ textAlign: 'right' }}>{item.selling_price}</Col>
              <Col span={3} style={{ textAlign: 'center' }}>{item.quantity}</Col>
              <Col span={2} style={{ textAlign: 'center' }}>{item.subtotal}</Col>
              <Col span={3} style={{ textAlign: 'center' }}>{item.discount}</Col>
              <Col span={3} style={{ textAlign: 'center' }}>{item.status}</Col>
              <Col span={2} style={{ textAlign: 'center' }}>
                <Text style={{ marginLeft: 10 }}>
                  {item.total}
                </Text>
              </Col>
            </Row>

            {renderCombo(item)}

            <Divider style={{ padding: '0px', margin: '0' }} />
          </>
        ))}
      </Col>
      <Col span={2}></Col>
    </Row >
  )

  const renderReturnedOrders = () => (
    <>
      <Title level={5}>Returned Items</Title>
      {
        returnedOrders.map((order, index) => (
          <Card style={{ marginBottom: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Title level={5}>{moment(order.date).format(format)}</Title>
            </div>
            {returnedOrderItemsTable(order.order_items)}
            <div className='flexItem'>
              <Text>Subtotal</Text>
              <Text>{currency} {order.subtotal}</Text>
            </div>
            <div className='flex'>
              <Text>Discount</Text>
              <Text>{currency} {order.discount}</Text>
            </div>
            <div className='flex'>
              <Text>Shipping Charges</Text>
              <Text> {currency} {order.shipping}</Text>
            </div>
            <div className='flexItem' >
              <Text>Total</Text>
              <Text>{currency}   {order.total}</Text>
            </div>
          </Card>
        ))
      }
    </>
  )

  const renderOrderView = () => (
    <>
      <div style={{ background: '#fff', padding: '20px' }}>
        <div className='flex' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 15, paddingBottom: 15 }}>
          <Title level={3}>Order #{order.order_number}</Title>
          <Space>
            {order.status == OrderStatus.COMPLETED && <Button type='primary' onClick={() => history.push({ pathname: "/orders/print/" + id })}>Print</Button>}
            <Button type="primary" onClick={() => history.push({ pathname: '/orders' })} icon={<ArrowLeftOutlined />}>Back</Button>

          </Space>

        </div>
        {renderOrderDetail}
        <Row>
          <Title level={3}>Invoice Description</Title>
          <Divider className='divide' />
        </Row>
        {renderTable()}
        <div className='flexItem'>
          <Text>Subtotal</Text>
          <Text>{currency} {order.subtotal}</Text>
        </div>
        <div className='flex'>
          <Text>Discount</Text>
          <Text>{currency} {order.discount}</Text>
        </div>
        <div className='flex'>
          <Text>Shipping Charges</Text>
          <Text> {currency} {order.shipping}</Text>
        </div>
        <div className='flexItem' >
          <Text>Total</Text>
          <Text>{currency}   {order.total}</Text>
        </div>
      </div>
      <br />
      {
        returnedOrders.length > 0 &&
        renderReturnedOrders()
      }

    </>
  );

  const renderOrderViewMobile = () => (
    <>
      <Title level={3}>Order #{order.order_number}</Title>

      <Card style={{ width: '100%' }}>
        {/* Order Details */}
        <center><Title level={5}>Order Details </Title></center>
        <Divider />
        <Text style={{ fontWeight: 'bolder' }}>Order Number :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>#{order.order_number}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Order Date :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.date}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Shipping Address :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping_address}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Payment Method :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.payment_method}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Order status :
          <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
            <Dropdown overlay={
              <Menu >
                <Menu.Item onClick={() => changeStatus('Confirm')} key="1">
                  Confirm
                </Menu.Item>
                <Menu.Item onClick={() => changeStatus('Processing')} key="2">
                  Processing
                </Menu.Item>
                <Menu.Item onClick={() => changeStatus('  On The Way')} key="3">
                  On The Way
                </Menu.Item>
                <Menu.Item onClick={() => changeStatus('Complete')} key="4">
                  Complete
                </Menu.Item>
              </Menu>
            }
              placement="bottomCenter">
              <Button value={order.status}>{order.status}</Button>
            </Dropdown>
          </span>
        </Text>
        <br />

        <br /><br /><br />

        {/* Customer Information */}
        <center><Title level={5}>Customer Information</Title></center>
        <Divider />
        <Text style={{ fontWeight: 'bolder' }}>Customer :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.name || 'N/A'}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Email :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.email || 'N/A'}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Phone :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.phone || 'N/A'}</span></Text>
        <br />
      </Card>

      {/* Invoice Description */}
      <br />
      <Card style={{ width: '100%' }}>
        <center><Title level={5}>Invoice Description </Title></center>
        {
          orderItems.map((v, index) => {
            return (
              <>
                <br />
                <Card extra={<Checkbox key={v.id} id={v.id} onChange={(e) => handleClick(e, v.product_id)} checked={isCheck.includes(v.product_id)}></Checkbox>}>
                  <Text style={{ fontWeight: 'bolder' }}>Item :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.product_name}</span></Text>
                  <br />
                  {
                    condition &&
                    <>
                      <Text style={{ fontWeight: 'bolder' }}>GRN Number :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.grn_number}</span></Text>
                      <br />
                    </>
                  }
                  <Text style={{ fontWeight: 'bolder' }}>Price :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{currency}{v.total}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Quantity :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.quantity}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Subtotal :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.subtotal}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Discount :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.discount}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Total :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{currency}{v.total}</span></Text>
                </Card>
              </>
            )
          })
        }
        <br />
        <Button style={{ float: 'right' }} htmlType="submit" type="primary"
          onClick={() => {
            isCheck.length > 0 ? openModal() : message.error('Please check the row and then try clicking the return button')
          }}>
          Return
        </Button>
        <br />

        <Text style={{ fontWeight: 'bolder' }}>SubTotal :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.subtotal}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Discount :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.discount}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Shipping Charges :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping}</span></Text>
        <br />
        <Text style={{ fontWeight: 'bolder' }}>Total :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.total}</span></Text>
      </Card>

      {/* Returned Items */}
      {returnedOrders.length > 0 &&
        <>
          <br />
          <Card style={{ width: '100%' }}>
            <center><Title level={5}>Returned Items</Title></center>
            {
              returnedOrders.map(v => (
                <>
                  <Card extra={<Title level={5}>{moment(order.date).format(format)}</Title>}>

                    {
                      v.order_items.map(o => (
                        <>
                          <Text style={{ fontWeight: 'bolder' }}>Item :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{o.product_name}</span></Text>
                          <br />
                          <Text style={{ fontWeight: 'bolder' }}>GRN Number :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{o.grn_number}</span></Text>
                          <br />
                          <Text style={{ fontWeight: 'bolder' }}>Price :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{currency}{o.total}</span></Text>
                          <br />
                          <Text style={{ fontWeight: 'bolder' }}>Quantity :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{o.quantity}</span></Text>
                          <br />
                          <Text style={{ fontWeight: 'bolder' }}>Subtotal :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{o.subtotal}</span></Text>
                          <br />
                          <Text style={{ fontWeight: 'bolder' }}>Discount :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{o.discount}</span></Text>
                          <br />
                          <Text style={{ fontWeight: 'bolder' }}>Total :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{currency}{o.total}</span></Text>
                          <Divider />
                        </>
                      ))
                    }

                  </Card>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>SubTotal :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.subtotal}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Discount :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.discount}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Shipping Charges :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.shipping}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Total :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{v.total}</span></Text>
                </>
              ))
            }

          </Card>
        </>
      }

    </>
  )

  const renderModalMobile = (
    <Form layout={'vertical'} onFinish={saleReturn} form={form}>
      <Modal
        title={'Sale Return'}
        centered
        open={showModal}
        width={'100%'}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" type="primary" onClick={form.submit}> Sale Return </Button>,
          <Button key="back" onClick={() => setShowModal(false)}> Close </Button>
        ]}
      >
        <Form.List name="saleReturn">
          {(fields, { add, remove }) => (
            fields.map((field, index) => {
              const rowData = form.getFieldValue('saleReturn')[index];

              let product = singleProduct(rowData.product_id)

              return (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="GRN No : " name={[field.name, 'grn_number']} key={`${field.key}-grn_number`}>
                        <input disabled className="productDropDown" />
                      </Form.Item>
                    </Col>


                    <Col span={24}>
                      <Form.Item label="Product Name : " name={[field.name, 'product_name']} key={`${field.key}-product_name`}>
                        <input disabled className="productDropDown" style={{ width: '98%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label="Price : " name={[field.name, 'selling_price']} key={`${field.key}-selling_price`}>
                        <Input type="number" style={{ marginTop: '4px', width: '60%' }} min={0} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label="Available Qty : " name={[field.name, 'available_qty']} key={`${field.key}-available_qty`}>
                        <input disabled className="productDropDown" />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label="Quantity : " name={[field.name, 'total_quantity']} key={`${field.key}-total_quantity`}>
                        <Input type="number" style={{ marginTop: '4px', width: '60%' }} min={0} max={itemsLimit[[field.name]]} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label="Send To : " name={[field.name, 'status']} key={`${field.key}-status`} initialValue={'Saleable'}>
                        <Select style={{ marginTop: '4px' }}>
                          <Option value="Saleable"> Saleable </Option>
                          {
                            !product.is_combo &&
                            <>
                              <Option value="Discard"> Discard </Option>
                              <Option value="Damage"> Damage </Option>
                            </>
                          }

                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    fields.length > 1 &&
                    <Divider />
                  }
                </>
              )
            })
          )}
        </Form.List>
        <Divider />

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="total_discount" label="Return Discount">
              <Input type='number' min={0} step={0.01} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="shipping_charges" label="Return Shipping Charges">
              <Input type='number' min={0} step={0.01} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} >
            <Form.Item label="Total Discount Amount">
              {order.discount}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Shipping Charges">
              {order.shipping}
            </Form.Item>
          </Col>
        </Row>

      </Modal >
    </Form>
  )

  const renderContent = () => {
    if (isMobile) {
      return (
        <>
          {renderModalMobile}
          {renderOrderViewMobile()}
        </>
      )
    } else {
      return (
        <>
          {renderModal}
          {renderOrderView()}
        </>
      )
    }
  }

  return renderContent()

};

export default windowSize(OrderForm);