import { Card, Divider, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getPurchaseOrder } from "../../data";
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import windowSize from "react-window-size";

const { Text, Title } = Typography

const PurchaseOrderView = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768
    const [items, setItems] = useState([])
    const [purchaseOrder, setPurchaseOrder] = useState({
        store: {
            name: '',
            domain: ''
        }
    })

    const componentRef = useRef();
    let { id } = useParams()

    const columns = [
        {
            title: "S.No:",
            key: "index",
            width: 50,
            render: (text, record, index) => index + 1
        },
        {
            title: 'Item',
            dataIndex: 'product_name',
            key: 'product_name',
            width: '60%',
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center'
        },

        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            align: 'center',
            render: (text, record) => { return <p style={{ textAlign: 'right' }} >{record.cost}</p> }
        },

        {
            title: 'Total Amount',
            key: 'total',
            align: 'center',
            render: (text, record) => { return <p style={{ textAlign: 'right' }} >{(record.quantity * record.cost).toFixed(2)}</p> }
        },
    ]

    const loadItems = async (id) => {
        const data = await getPurchaseOrder(id)
        if (data) {
            setItems(data.items)
            setPurchaseOrder(data)
        }
    }

    useEffect(() => {
        loadItems(id)
    }, [])

    const renderItemsTable = () => {
        return (
            <>
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={false}
                />
            </>
        )
    }

    const renderPurchaseOrderView = () => {
        return (
            <>
                <div className="printIcon">
                    <p>{purchaseOrder.order_number}</p>

                    <ReactToPrint
                        trigger={() => <PrinterOutlined style={{ fontSize: 23 }} />}
                        content={() => componentRef.current}
                    />
                </div>

                <Card ref={componentRef}>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Title level={3}>{purchaseOrder.store.name}</Title>
                            <a href={purchaseOrder.store.domain}>{purchaseOrder.store.domain}</a>
                        </div>

                        <div style={{ width: '20%' }}>
                            <Title level={3}>Purchase Order</Title>
                            <Text>{moment(purchaseOrder.order_date).format('DD/MM/YYYY')}</Text>
                            <br />
                            <Text style={{ color: 'green' }}>{purchaseOrder.order_number}</Text>

                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <div>
                            <div style={{ background: '#2596be', width: 100 }}>
                                <Title style={{ paddingLeft: 10 }} level={5}>VENDOR</Title>
                            </div>
                            <Text>{purchaseOrder.supplier}</Text>
                            <br />
                            <Text>{purchaseOrder.email}</Text>
                            <br />
                            <Text>{purchaseOrder.phone}</Text>
                        </div>

                        <div style={{ width: '20%' }}>
                            <div style={{ background: '#2596be', width: 100 }}>
                                <Title style={{ paddingLeft: 10 }} level={5}>SHIP TO</Title>
                            </div>
                            <Text>{purchaseOrder.store.name}</Text>
                            <br />
                            <Text>{purchaseOrder.order_number}</Text>
                        </div>
                    </div>

                    <br /><br />
                    {renderItemsTable()}
                </Card>
            </>
        )
    }

    const renderPurchaseOrderViewMobile = () => (
        <Card title={purchaseOrder.order_number}>
            <div style={{ marginBottom: 10 }}>
                <Title level={4}>{purchaseOrder.store.name}</Title>
                <a href={purchaseOrder.store.domain}>{purchaseOrder.store.domain}</a>
            </div>

            <div style={{ marginBottom: 10 }}>
                <Title level={4}>Purchase Order</Title>
                <Text style={{ color: 'green' }}>{purchaseOrder.order_number}</Text>
                <br />
                <Text>{moment(purchaseOrder.order_date).format('DD/MM/YYYY')}</Text>
            </div>

            <div style={{ marginBottom: 10 }}>
                <div style={{ background: '#2596be' }}>
                    <Title style={{ paddingLeft: 10 }} level={5}>VENDOR</Title>
                </div>
                <Text>{purchaseOrder.supplier}</Text>
                <br />
                <Text>{purchaseOrder.email}</Text>
                <br />
                <Text>{purchaseOrder.phone}</Text>
            </div>

            <div>
                <div style={{ background: '#2596be' }}>
                    <Title style={{ paddingLeft: 10 }} level={5}>SHIP TO</Title>
                </div>
                <Text>{purchaseOrder.store.name}</Text>
                <br />
                <Text>{purchaseOrder.order_number}</Text>
            </div>
            <Divider plain>Items</Divider>

            {
                items.map((item, index) => (
                    <>
                        <br />
                        <Card style={{ width: '100%' }}>
                            <Text style={{ fontWeight: 'bolder' }}>S.No : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{index + 1}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Item : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.product_name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Quantity : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.quantity}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.cost}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Total Amount : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(item.quantity * item.cost).toFixed(2)}</span></Text>
                            <br />
                        </Card>
                    </>
                ))
            }

        </Card>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderPurchaseOrderViewMobile()
        }
        else {
            return renderPurchaseOrderView()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )


}

export default windowSize(PurchaseOrderView)