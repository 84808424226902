import { Form, Modal, Button, notification, Spin } from 'antd';
import { updateStoreSetting } from '../data';
import {
    MinimumOrderAmount,
    Timezone,
    Currency,
    EnableFbrSetting,
    EnableSrbSetting,
    BykeaDelivery,
    PushNotificationSetting,
    EnablePos,
    EnablePickup,
    ManageCustomers,
    EnableWebsite,
    ManageInventory,
    UserManagement,
    WhatsappMessaging,
    PandaGo,
    Trax,
    EnableDelivery,
    EnableDinein,
    ComboInventory,
    ReceiptFormat,
} from '../pages/partials';
import ControlSettings from '../constant/controlSettings';
import { useState } from 'react';

const FormModal = ({ display, toggle, slug = '', id, title, setting, mobile, setForceRefresh }) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const onFinish = async (values) => {

        setLoading(true)

        const response = await updateStoreSetting(id, values);

        if (response) {
            setForceRefresh(true)

            toggle(false)
            notification.open({ message: title + ' has been updated' })
            setLoading(false)
        }
        else {
            notification.open({ message: 'Unable to update data' })
            setLoading(false)
        }

    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const renderComponent = (slug) => {

        if (loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size="large" />
                </div>
            )
        }

        switch (slug) {
            case (ControlSettings.TIMEZONE):
                return <Timezone Setting={setting} form={form} />
            case (ControlSettings.CURRENCY):
                return <Currency Setting={setting} form={form} />
            case (ControlSettings.ENABLE_FBR_SETTINGS):
                return <EnableFbrSetting Setting={setting} form={form} />
            case (ControlSettings.SRB_SETTINGS):
                return <EnableSrbSetting Setting={setting} form={form} />
            case (ControlSettings.BYKEA_DELIVERY):
                return <BykeaDelivery Setting={setting} form={form} />
            case (ControlSettings.TRAX):
                return <Trax Setting={setting} form={form} />
            case (ControlSettings.PUSH_NOTIFICATION):
                return <PushNotificationSetting Setting={setting} form={form} />
            case (ControlSettings.ENABLE_POS):
                return <EnablePos Setting={setting} form={form} mobile={mobile} />
            case (ControlSettings.ENABLE_PICKUP):
                return <EnablePickup Setting={setting} form={form} />
            case (ControlSettings.MANAGE_INVENTORY):
                return <ManageInventory Setting={setting} form={form} />
            case (ControlSettings.USER_MANAGEMENT):
                return <UserManagement Setting={setting} form={form} />
            case (ControlSettings.MANAGE_CUSTOMERS):
                return <ManageCustomers Setting={setting} form={form} />
            case (ControlSettings.ENABLE_WEBSITE):
                return <EnableWebsite Setting={setting} form={form} />
            case (ControlSettings.WHATSAPP_MESSAGING):
                return <WhatsappMessaging Setting={setting} form={form} />
            case (ControlSettings.ENABLE_DELIVERY):
                return <EnableDelivery Setting={setting} form={form} />
            case (ControlSettings.ENABLE_DINEIN):
                return <EnableDinein Setting={setting} form={form} />
            case (ControlSettings.COMBO_INVENTORY):
                return <ComboInventory Setting={setting} form={form} />
            case (ControlSettings.RECEIPT_FORMAT):
                return <ReceiptFormat Setting={setting} form={form} />
            default:
                return null;
        }

    }

    return (
        <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}>
            <Modal
                title={title}
                visible={display}
                // width="40%"
                onCancel={() => toggle(false)}
                footer={[
                    <Button type='primary' key="submit" onClick={form.submit} disabled={loading} >
                        Submit
                    </Button>
                    ,
                    <Button key="back" onClick={() => toggle(false)}>
                        Close
                    </Button>
                ]}
            >
                {renderComponent(slug)}
            </Modal>
        </Form>
    );
}

export default FormModal;