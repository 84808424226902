import { Col, Divider, Input, Row, Form, Button, Radio, Space } from "antd";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

export function AddonForm({ addonFileList, setAddonFileList, mobile = false }) {

    const handleImageUpload = (info, fieldName) => {
        let addonfileList = [...addonFileList];
        addonfileList[fieldName] = [...info.fileList];
        // Limit file list to 1 item
        addonfileList[fieldName] = addonfileList[fieldName].slice(-1);
        // Update file list state
        setAddonFileList(addonfileList);
    };

    const renderAddonForm = () => (
        <>
            <Form.List name="addons" initialValue={[{ type: 1 }]}>
                {(fields, { add, remove }) => (
                    <>
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    const newItem = { type: 1, items: [{}] };
                                    add(newItem);
                                }}>
                                Add Addons/Options
                            </Button>
                        </div>
                        {fields.map((field, index) => (
                            <>
                                <Row gutter={16}>
                                    <Col span={mobile ? 24 : 6}>
                                        <Form.Item name={[field.name, 'title']} label="Title" rules={[{ required: true, message: 'Title is required' }]}>
                                            <Input placeholder="Title" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={mobile ? 24 : 6}>
                                        <Form.Item name={[field.name, 'type']} initialValue={1} label="Type" rules={[{ required: true, message: 'Type is required' }]}>
                                            <Radio.Group >
                                                <Space size="middle">
                                                    <Radio value={1}>Addons</Radio>
                                                    <Radio value={0}>Options</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={mobile ? 24 : 4}>
                                        <Form.Item name={[field.name, 'limit']} initialValue={0} label="Limit">
                                            <Input type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        {fields.length > 1 && (
                                            <Form.Item label=" ">
                                                <DeleteOutlined
                                                    style={{ color: 'red', fontSize: '20px' }}
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                                {/* Nested Form.List for 'items' */}
                                <Form.List name={[field.name, 'items']} initialValue={[{}]}>
                                    {(itemFields, { add: addItem, remove: removeItem }) => (
                                        <>
                                            <div style={{ textAlign: 'right' }}>
                                                <Button type="primary" icon={<PlusOutlined />} onClick={() => addItem()} style={{ position: 'relative', bottom: '65px' }}>
                                                    Add Item
                                                </Button>
                                            </div>
                                            {itemFields.map((itemField, itemIndex) => (
                                                <>
                                                    {/* Add your item-specific form items here */}
                                                    <Row gutter={16}>
                                                        {/* <Col span={mobile ? 24 : 3}>
                                                            <Form.Item
                                                                name={[itemField.name, 'image']}
                                                                label="Image"
                                                                getValueFromEvent={(e) => handleImageUpload(e, itemField.name)}
                                                            >
                                                                <ImageUpload fileList={addonFileList[itemField.name] || []} crop={true} />
                                                            </Form.Item>
                                                        </Col> */}

                                                        <Col span={mobile ? 11 : 8}>
                                                            <Form.Item
                                                                name={[itemField.name, 'item']}
                                                                label="Item"
                                                                rules={[{ required: true, message: 'Item is required' }]}
                                                            >
                                                                <Input placeholder="Item" />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={mobile ? 11 : 7}>
                                                            <Form.Item
                                                                name={[itemField.name, 'price']}
                                                                label="Price"
                                                            // rules={[{ required: true, message: 'Price is required' }]}
                                                            >
                                                                <Input placeholder="Price" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={mobile ? 11 : 7}>
                                                            <Form.Item
                                                                name={[itemField.name, 'cost']}
                                                                label="Cost"
                                                            // rules={[{ required: true, message: 'cost is required' }]}
                                                            >
                                                                <Input placeholder="Cost" />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={2}>
                                                            {itemFields.length > 1 && (
                                                                <Form.Item label=" ">
                                                                    <DeleteOutlined
                                                                        style={{ color: 'red', fontSize: '20px' }}
                                                                        onClick={() => removeItem(itemField.name)}
                                                                    />
                                                                </Form.Item>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </>
                                            ))}
                                        </>
                                    )}
                                </Form.List>

                                <Divider />

                            </>
                        ))}
                    </>
                )}
            </Form.List>
        </>
    );
    return (
        <>
            <Divider plain>Addon/Option</Divider>
            {renderAddonForm()}
        </>
    )
}