import { Card, Col, Row, Typography } from "antd";

const { Title } = Typography
const Summary = ({ title, totalProducts, totalUnits, totalInventory, totalPrice, damageReport, mobile }) => (
    <>
        <Title level={3}>{title}</Title>

        <Row gutter={16}>
            <Col span={mobile ? 24 : 5}>
                <Card style={{ background: '#001F3F', color: '#fff', marginBottom: 10 }}>
                    <b># Of Products :</b>
                    <br />
                    {totalProducts}
                </Card>
            </Col>
            {
                title !== "Out Of Stock" &&
                <>
                    <Col span={mobile ? 24 : 6}>
                        <Card style={{ background: '#228B22', color: '#fff', marginBottom: 10 }}>
                            <b>  # Of Units :</b>
                            <br />
                            {totalUnits}
                        </Card>
                    </Col>
                    <Col span={mobile ? 24 : 6}>
                        <Card style={{ background: '#333333', color: '#fff', marginBottom: 10 }}>
                            <b> Cost of Goods Available {damageReport ? '' : 'for Sale'} :</b>
                            <br />
                            {totalInventory}
                        </Card>
                    </Col>
                    {
                        !damageReport &&
                        <Col span={mobile ? 24 : 6}>
                            <Card style={{ background: '#FF7F11', color: '#fff', marginBottom: 10 }}>
                                <b>Price of Goods Available for Sale :</b>
                                <br />
                                {totalPrice}
                            </Card>
                        </Col>
                    }
                </>
            }
        </Row>
    </>
)

export default Summary