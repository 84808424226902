import { ajaxService, loadState, urlService } from '../services';
import { loadResource, Response } from '../utility';


const setDependency = () => { ajaxService.setDependency() }

export async function getShippingMethods(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.shippingMethods, 'shippingMethods');
    } else {

        let shippingMethods = await loadState('shippingMethods');

        if (shippingMethods == null) {
            return await loadResource(urlService.shippingMethods, 'shippingMethods');
        } else {
            return Response(shippingMethods);
        }

    }
}

export async function getShippingMethod(id) {
    let shippingMethods = await loadState('shippingMethods');
    if (shippingMethods !== null) {
        return shippingMethods.find(shippingMethod => shippingMethod.id == id);
    }
    else {
        setDependency()
        return ajaxService.get(urlService.shippingMethods + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveShippingMethod(data) {
    setDependency()
    return ajaxService.post(urlService.shippingMethods, data).then(response => {
        return Response(response.data);
    })
}

export async function updateShippingMethod(id, data) {
    setDependency()
    return ajaxService.put(urlService.shippingMethods + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteShippingMethod(id) {
    return ajaxService.delete(urlService.shippingMethods + '/' + id).then(response => {
        return Response(response.data);
    })
}
