
import { Col, Row, Form, Input } from "antd";

const Trax = ({ Setting }) => {
    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='trax_username' label="Username"
                        labelCol={{ span: 24 }}
                        initialValue={data.trax_username}
                        rules={[
                            {
                                required: true,
                                message: 'Username is required',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='trax_password' label="Password"
                        labelCol={{ span: 24 }}
                        initialValue={data.trax_password}
                        rules={[
                            {
                                required: true,
                                message: 'Password is required',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
export default Trax