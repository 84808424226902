import React, { useState, useEffect, useRef } from 'react';
import { Table, Space, Button, Typography, notification, Modal, Avatar, Select, Row, Col, Form, Switch, Card, Image, Checkbox, Breadcrumb, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { updateValue } from './updateValue';
import { deleteProduct, filterProduct, getBranchProducts, getProducts, getStoreSettings } from '../../../data';
import windowSize from 'react-window-size';
import { PAGINATION_OPTIONS } from '../../../constants';
import { ajaxService } from '../../../services';
import { updateDataSource } from '../../../utility/updateDataSource';
import MENAGEINVENTORYCOSTING from '../../../constant/manageInventoryCosting';
import Barcode from 'react-barcode';
import ControlSettings from '../../../constant/controlSettings';
import ReactToPrint from 'react-to-print';
import dataService from '../../../services/data-service';

const { Title, Text } = Typography;
const { Option } = Select;

const pageStyle = `

@media print {
  html, body {
  width: 100%;
 -webkit-print-color-adjust: exact;
 text-align:center
  }
 
 @page {
 size: 100% 100%; margin: 0% !important; padding: 0%;
 }


`

function Product({ windowWidth }) {

  const history = useHistory();
  const isMobile = windowWidth <= 768
  let branch_id = localStorage.getItem('branch_id')
  const componentRef = useRef();

  const [dataSource, setDataSource] = useState([])
  const [forceRefresh, setForceRefresh] = useState(false);
  const [comboInventoryToggle, setComboInventoryToggle] = useState(false);
  const [manageInventory, setManageInventory] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [price, setPrice] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(false);
  const [printValue, setPrintValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(PAGINATION_OPTIONS.pageSize);
  const [totalRecords, setTotalRecords] = useState(PAGINATION_OPTIONS.total)
  const [keyword, setKeyword] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;


  const handlePagination = (pagination, filters, sorter) => {

    if (pagination.current !== currentPage) {
      setCurrentPage(pagination.current)
    }

    if (pagination.pageSize !== pageSize) {
      setCurrentPage(PAGINATION_OPTIONS.current) // reset current page when page size changes
      setPageSize(pagination.pageSize)
    }
  };

  const editProduct = (record) => {
    if (!record.has_variant && !record.is_combo) {

      // let product = dataSource.find(p => p.id === record.id);
      // localStorage.setItem('product', JSON.stringify(product))

      history.push({ pathname: "/products/form/" + record.id })
    } else if (record.has_variant) {
      history.push({ pathname: "/products/variant/" + record.id })
    } else {
      history.push({ pathname: "/products/combo/" + record.id })
    }
  }

  const handleToggle = (id, checked) => {

    const updatedProducts = updateDataSource(dataSource, id, checked)
    setDataSource(updatedProducts);

    updateValue(id, checked, 'active', 'Active')
    setForceRefresh(true)
  }

  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <Avatar shape="square" size={64} src={image} />
    },
    {
      title: 'Name / SKU',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <>
            <span>{record.name}</span>
            <br />
            <span>{record.sku}</span>
          </>
        )
      }
    },
    {
      title: 'Category',
      dataIndex: 'category_id',
      key: 'category',
      render: (text, record) => { return <Text >{record.category?.name ?? null}</Text> }
    },
    {
      title: 'Brand',
      dataIndex: 'brand_id',
      key: 'brand_id',
      render: (text, record) => { return <Text >{record.brand ? record.brand.name : null}</Text> }
    },
    // {
    //   title: 'Stock',
    //   dataIndex: 'stock',
    //   key: 'stock',
    //   hidden: true,
    // },
    {
      title: 'Selling Price',
      dataIndex: 'selling_price',
      key: 'selling_price',
    },
    {
      title: 'Type',
      // dataIndex: 'has_variant',
      key: 'has_variant',
      render: (text, record) => <Text>{record.has_variant ? 'Variants' : record.is_combo ? 'Combo' : 'Standard'}</Text>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      hidden: true,
      render: (active, record) => {

        console.log(record);

        return <Switch
          checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
          checked={active}
          onChange={(checked) => handleToggle(record.id, checked)}
        />
      }

    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      hidden: true,
      render: (text, record) => {

        let link = '';

        if (!record.has_variant && !record.is_combo) {

          // let product = dataSource.find(p => p.id === record.id);
          // localStorage.setItem('product', JSON.stringify(product))
          link = "/products/form/" + record.id;
        } else if (record.has_variant) {
          link = "/products/variant/" + record.id;
        } else {
          link = "/products/combo/" + record.id;
        }

        return (

          <Space size='small'>
            <a href={link} className='ant-btn ant-btn-default'>Edit</a>
            <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
            <Button onClick={() => handleBarcodeModal(record.sku, record.barcode, record.selling_price)} type='primary'>Print Barcode</Button>
          </Space>
        )
      }
    },
  ].filter(item => branch_id ? !item.hidden : item);

  const deleteRow = async () => {
    const product_id = localStorage.getItem('product')
    let response = await deleteProduct(product_id);

    if (response) {
      setShowModal(false)
      setForceRefresh(true)
      notification.open({ message: 'Product has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to delete product', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const openDeleteModal = (id) => {
    localStorage.setItem('product', id);
    setShowModal(true)
  }

  const handleSearch = () => {

    const inputValue = inputRef.current.value;

    if (inputValue.length > 3) {
      setKeyword(inputValue)
    } else {
      notification.error({ message: 'Search Criteria Required', description: 'Please enter at least 3 letters for the product name or SKU to proceed with the search.', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const clearSearch = () => {
    inputRef.current.value = ''
    setKeyword('')
  }

  const initialize = async () => {

    setLoading(true);

    let response = null;

    let params = { per_page: pageSize, page: currentPage, keyword: keyword };

    if (branch_id) {
      params = { ...params, branch_id: branch_id };
    }

    response = await dataService.getProducts(params);
    setLoading(false);

    let { data, total } = response;

    if (total === 0) {
      notification.error({ message: 'Search Results', description : 'No records found.', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      setTotalRecords(0)
      setDataSource([]);
    } else {
      setTotalRecords(total)
      setDataSource(data);
    }
  }

  const loadStoreSettings = async () => {

    const response = await getStoreSettings(forceRefresh)
    if (response) {

      const comboInventoryData = response.find(i => i.slug === ControlSettings.COMBO_INVENTORY);
      if (comboInventoryData) {
        setComboInventoryToggle(comboInventoryData.active)
      }

      const manageInventoryData = response.find(i => i.slug === ControlSettings.MANAGE_INVENTORY);
      if (manageInventoryData) {
        const data = { active: manageInventoryData.active, ...JSON.parse(manageInventoryData.setting) }
        setManageInventory(data)
      }
      setForceRefresh(false)
    }
  }

  useEffect(() => {
    initialize();
  }, [currentPage, pageSize, keyword])

  useEffect(() => {
    loadStoreSettings();
  }, [])

  const handleBarcodeModal = (sku, barcode, price) => {
    setPrice(price)
    setPrintValue(sku)
    setShowBarcodeModal(true)
  }

  const renderFilter = (
    <Form layout={'vertical'}>
      <Row gutter={16} >
        <Col span={isMobile ? 24 : 6}>
          <Form.Item label="" >
            <input placeholder='Search by product name or sku' ref={inputRef} className='ant-input' />
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 6} className="gutter-row">
          <Form.Item label={''}>
            <Space>
              <Button onClick={handleSearch}>Search</Button>
              <Button onClick={clearSearch}>Clear</Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Divider dashed />
    </Form>
  )

  const renderHeader = (

    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Space>
        {/* <Select defaultValue="bulk_action" style={{ width: 120 }}>
          <Option value="bulk_action">Bulk Action</Option>
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
          <Option value="delete">Delete</Option>
        </Select>
        <Button type="button" disabled={!hasSelected} >Update</Button> */}
        <Button type="primary" onClick={initialize}>Refresh</Button>
      </Space>
      {/* {renderFilter} */}
      <Space style={{ display: branch_id ? 'none' : '' }} >
        <Button type="primary" onClick={() => history.push({ pathname: "/products/form/0" })}>Add Product</Button>
        <Button type="primary" onClick={() => history.push({ pathname: "/products/variant/0" })}>Add Variant Product</Button>
        {
          (comboInventoryToggle || (manageInventory.active && manageInventory.costing !== MENAGEINVENTORYCOSTING.SPECIFICIDENTIFICATION)) &&
          <Button type="primary" onClick={() => history.push({ pathname: "/products/combo/0" })}>Add Combo Product</Button>
        }
        {
          !manageInventory.active &&
          <Button type="primary" onClick={() => history.push({ pathname: "/products/priceBook" })}>Price Book</Button>
        }
      </Space>


    </div>
  );

  const renderProduct = () => (
    <>
      <Table
        title={() => renderHeader}
        columns={columns}
        rowKey={record => record.id}
        dataSource={dataSource}
        pagination={{
          ...PAGINATION_OPTIONS,
          current: currentPage,
          pageSize: pageSize,
          total: totalRecords,
          // itemRender: (page, type, originalElement) => {
          //   return (
          //     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          //       <Select defaultValue="Option 1">
          //         <Option value="Option 1">Option 1</Option>
          //         <Option value="Option 2">Option 2</Option>
          //         <Option value="Option 3">Option 3</Option>
          //       </Select>
          //       {originalElement}
          //     </div>
          //   )
          // },
        }}
        loading={loading}
        onChange={handlePagination}
      // rowSelection={rowSelection}
      />
    </>
  )

  const renderProductMobile = () => (
    <>
      {/* Title */}
      <div>
        <Title level={3}>Products</Title>
        <Row gutter={[16, 16]} style={{ display: branch_id ? 'none' : '' }}>
          <Col span={8}>
            <Button type="primary" onClick={() => history.push({ pathname: "/products/form/0" })} >Add Product</Button>
          </Col>
          <Col span={12}>
            <Button style={{ marginLeft: 8 }} type="primary" onClick={() => history.push({ pathname: "/products/variant/0" })} >Add Variant Product</Button>
          </Col>
          {
            (comboInventoryToggle || (manageInventory.active && manageInventory.costing !== MENAGEINVENTORYCOSTING.SPECIFICIDENTIFICATION)) &&
            <Col span={12}>
              <Button type="primary" style={{ marginRight: 8 }} onClick={() => history.push({ pathname: "/products/combo/0" })}>Add Combo Product</Button>
            </Col>
          }
          {
            !manageInventory.active &&
            <Col span={12}>
              <Button type="primary" onClick={() => history.push({ pathname: "/products/priceBook" })}>Price Book</Button>
            </Col>
          }
        </Row >
      </div>
      {
        dataSource.map(product => (
          <>
            <br />
            <Card
              style={{ width: '100%' }}
              actions={[
                <Space size='small' style={{ display: branch_id ? 'none' : '' }} >
                  <Button type='primary' icon={<EditOutlined />} onClick={() => editProduct(product)}>Edit</Button>
                  <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(product.id)}>Delete</Button>
                  <Button onClick={() => handleBarcodeModal(product.sku, product.barcode, product.selling_price)}>Print Barcode</Button>
                </Space>
              ]}>
              <Image.PreviewGroup>
                <Image width={250} height={150} sizes="small" src={ajaxService.getImage(product.image)} />
              </Image.PreviewGroup>
              <br />
              <br />
              <Text style={{ fontWeight: 'bolder' }}>Name / SKU :
                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{product.name + ' / ' + product.sku}</span>
              </Text>
              <br />
              <Text style={{ fontWeight: 'bolder' }}>Category :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{product?.category?.name ?? null}</span></Text>
              <br />
              <Text style={{ fontWeight: 'bolder' }}>Brand :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{product?.brand?.name ?? null}</span></Text>
              <br />
              {/* <Text style={{ fontWeight: 'bolder' }}>Stock :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{product.stock}</span></Text>
                  <br /> */}
              <Text style={{ fontWeight: 'bolder' }}>Selling Price :
                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{product.selling_price}</span>
              </Text>
              <br />
              <Text style={{ fontWeight: 'bolder' }}>Type :
                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{product.has_variant ? 'Variants' : 'Standard'}</span>
              </Text>
              <br />
              <Text style={{ fontWeight: 'bolder' }}>Active :
                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                  <Switch
                    onChange={(val) => handleToggle(product.id, val)}
                    checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                    defaultChecked={product.active == 1 ? true : false}
                  />
                </span>
              </Text>
            </Card>
            <br />
          </>
        ))
      }

    </>
  )

  const renderModal = (
    <Modal
      title="Delete Product"
      centered
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={[
        <Button key="delete" onClick={() => deleteRow()}>
          Delete
        </Button>
        ,
        <Button key="back" onClick={() => setShowModal(false)}>
          Close
        </Button>
      ]}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Are you sure you want to delete this product?</p>
      </div>
    </Modal>
  )


  const renderBarcodeModal = (
    <Modal
      title="Print Barcode"
      centered
      open={showBarcodeModal}
      onCancel={() => setShowBarcodeModal(false)}
      width={900}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Checkbox onChange={(e) => setDisplayPrice(e.target.checked)} value={displayPrice}>Display Price On Label</Checkbox>
          <div>
            <ReactToPrint
              trigger={() => <Button key="print" type='primary'>Print</Button>}
              content={() => componentRef.current}
              pageStyle={pageStyle}
            />,
            <Button key="back" onClick={() => setShowBarcodeModal(false)}>Close</Button>
          </div>
        </div>
      ]}
    >

      <div ref={componentRef} style={{ display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }}>
        <div className='label' id="div-svg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Barcode value={printValue} width={1} height={40} displayValue={true} marginTop={10}
            format="EAN13"
          />
          {
            displayPrice &&
            <Text style={{ fontSize: 15, fontWeight: 'bolder', textAlign: 'center', bottom: 10 }}>Price: {price}</Text>
          }
        </div>
        <div className='label' id="div-svg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingLeft: 8, paddingRight: 8 }}>
          <Barcode value={printValue} width={1} height={40} displayValue={true} marginTop={10}
            format="EAN13"
          />
          {
            displayPrice &&
            <Text style={{ fontSize: 15, fontWeight: 'bolder', textAlign: 'center', bottom: 10 }}>Price: {price}</Text>
          }
        </div>
        <div className='label' id="div-svg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
          <Barcode value={printValue} width={1} height={40} displayValue={true} marginTop={10}
            format="EAN13"
          />
          {
            displayPrice &&
            <Text style={{ fontSize: 15, fontWeight: 'bolder', textAlign: 'center', bottom: 10 }}>Price: {price}</Text>
          }
        </div>
      </div>
    </Modal>
  )

  const renderContent = () => {
    if (isMobile) {
      return renderProductMobile()
    }
    else {
      return renderProduct()
    }
  }

  return (
    <>
      {renderFilter}
      {renderContent()}
      {renderModal}
      {renderBarcodeModal}
    </>
  )
}

export default windowSize(Product)