import { useEffect, useState } from "react"
import { Button, Table, Typography } from "antd"
import { useParams, useHistory } from "react-router-dom"
import { getReconciliation } from "../../data"
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from "moment";

const { Text, Title } = Typography

const ReconciliationView = () => {

    let { id } = useParams()
    let history = useHistory()

    const [data, setData] = useState([])

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'name',
        },
        {
            title: 'Available Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Manual Stocks',
            dataIndex: 'manual_stock',
            key: 'manual_stock',
        },
        {
            title: 'Difference',
            key: 'difference',
            render: (text, record) => {
                let difference = record.stock - record.manual_stock;
                return <Text style={{ color: difference != 0 ? "red" : '' }}>{(difference > 0 ? '+' : '') + difference}</Text>
            }
        },
    ];

    const loadData = async () => {
        const response = await getReconciliation(id);
        if (response) {
            setData(response)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={5}>{moment(data.date_time).format('DD-MMM-yyy hh:mm A')}</Title>
            <Button type="primary" onClick={() => history.goBack()} icon={<ArrowLeftOutlined />}>Back To Reconciliations</Button>
        </div>
    )

    return (
        <Table
            dataSource={data.reconciliation_items}
            title={() => renderHeader}
            columns={columns}
        />
    )
}
export default ReconciliationView