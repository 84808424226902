import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getOffers(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.offers, 'offers');
    } else {
        let offers = await loadState('offers');

        if (offers == null) {
            return await loadResource(urlService.offers, 'offers');
        } else {
            return Response(offers);
        }
    }
}

export async function getOffer(id) {
    let offers = await loadState('offers');
    if (offers !== null) {
        return offers.find(offer => offer.id == id);
    } else {
        return ajaxService.get(urlService.offers, { id: id }).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveOffer(data) {
    return ajaxService.post(urlService.offers, data).then(response => {
        return Response(response.data);
    })
}

export async function updateOffer(id, data) {
    return ajaxService.put(urlService.offers + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteOffer(id) {
    return ajaxService.delete(urlService.offers + '/' + id).then(response => {
        return Response(response.data);
    })
}