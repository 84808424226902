import { Button, Col, Form, Row, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { getBranches, getProducts } from "../../../data";

const { Option } = Select

export default function Search({ handleSearch, hasBranch, hasProduct, damageProducts, damageReport }) {

    const [form] = Form.useForm();

    const [branches, setBranches] = useState([]);
    const [products, setProducts] = useState([]);

    const [forceRefresh, setForceRefresh] = useState(true);

    const handleClick = () => {
        const data = form.getFieldsValue()
        handleSearch(data)
    }

    const loadBranches = async () => {
        let response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        const response = await getProducts(forceRefresh)
        if (response) {
            setProducts(response.filter(i => !i.is_combo))
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        loadBranches()
        loadProducts()
    }, [])

    function renderButtons() {
        if (hasBranch || hasProduct) {
            return (
                <Col span={8} className="gutter-row">
                    <Form.Item label='&nbsp;'>
                        <Space>
                            <Button onClick={handleClick}>Search</Button>
                            <Button onClick={() => form.resetFields()}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            )
        }
    }

    return (
        <>
            <Form form={form} layout={'vertical'}>
                <Row gutter={16} >
                    {
                        hasProduct &&
                        <Col span={8}>
                            <Form.Item label="Products" name="product_id" labelCol={{ span: 24 }}>
                                <Select
                                    showSearch allowClear mode='multiple'
                                    style={{ width: '100%' }}
                                    placeholder="Select Products"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {(damageReport ? damageProducts : products).map(({ id, name }) => {
                                        return <Option value={id} key={id}>{name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {
                        hasBranch &&
                        <Col span={8}>
                            <Form.Item label="Branches" name="branch_id" labelCol={{ span: 24 }}>
                                <Select
                                    showSearch allowClear mode='multiple'
                                    style={{ width: '100%' }}
                                    placeholder="Select Branches"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {branches.map(({ id, name }) => {
                                        return <Option value={id} key={id}>{name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {renderButtons()}
                </Row>
            </Form>
        </>
    )
}