import { useEffect, useState } from "react";
import { Space, Button, Col, DatePicker, Form, Row, Table, Typography, Select } from "antd";
import { PAGINATION_OPTIONS } from "../../constants";
import windowSize from "react-window-size";
import { filterCustomizeSalesReport, getBranches, getCategories, getCustomers, getCustomizeSalesReport, getOrderNumbers, getProducts, getStoreSetting, getSuppliers } from "../../data";
import ControlSettings from "../../constant/controlSettings";
import moment from "moment";

const { RangePicker } = DatePicker
const { Text, Title } = Typography
const { Option } = Select

const CustomizeSalesReport = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768;
    const [form] = Form.useForm();

    const [forceRefresh, setForceRefresh] = useState(true)
    const [manageInventory, setManageInventory] = useState(false)

    const [suppliers, setSuppliers] = useState([])
    const [customers, setCustomers] = useState([])
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [branches, setBranches] = useState([])
    const [orderNumbers, setOrderNumbers] = useState([])
    const [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => <Text>{moment(date).format('LL')}</Text>
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
        },
        {
            title: 'GRN',
            dataIndex: 'grn_number',
            key: 'grn_number',
            hidden: true
        },
        {
            title: 'Order',
            dataIndex: 'order_number',
            key: 'order_number'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Product',
            dataIndex: 'product_name',
            key: 'product_name'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category'
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            key: 'supplier',
            hidden: true
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center'
        },
        {
            title: 'Sales Price',
            dataIndex: 'selling_price',
            key: 'sales_price',
            align: 'right'
        },
        {
            title: 'Total Sales',
            dataIndex: 'total_sales',
            key: 'total_sales',
            align: 'right'
        },
        {
            title: 'Cost Price',
            dataIndex: 'cost_price',
            key: 'cost_price',
            align: 'right'
        },
        {
            title: 'Total Cost',
            dataIndex: 'total_cost',
            key: 'total_cost',
            align: 'right'
        },
        {
            title: 'Profit',
            dataIndex: 'profit',
            key: 'profit',
            align: 'right'
        },
    ].filter(item => manageInventory ? item : !item.hidden);

    const handleSearch = async (values) => {
        const response = await filterCustomizeSalesReport(values);
        if (response) {
            setDataSource(response)
        }
    }

    const clear = () => {
        form.resetFields();
        handleSearch(null)
    }

    const loadSuppliers = async () => {
        const response = await getSuppliers('?branch_id=' + 0)
        if (response) {
            setSuppliers(response)
        }
    }

    const loadCustomers = async () => {
        const response = await getCustomers('?branch_id=' + 0)
        if (response) {
            setCustomers(response)
        }
    }

    const loadProducts = async () => {
        const response = await getProducts(forceRefresh)
        if (response) {
            setProducts(response)
            setForceRefresh(false)
        }
    }

    const loadCatgories = async () => {
        const response = await getCategories(forceRefresh)
        if (response) {
            setCategories(response)
            setForceRefresh(false)
        }
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadOrderNumbers = async (branch_id) => {
        const response = await getOrderNumbers(branch_id)
        if (response) {
            setOrderNumbers(response)
            setForceRefresh(false)
        }
    }

    const loadSetting = async () => {
        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY)
        if (response) {
            setManageInventory(response.active)
        }
    }

    const initialize = async () => {
        const response = await getCustomizeSalesReport()
        if (response) {
            setDataSource(response)
        }
    }

    useEffect(() => {
        loadSuppliers();
        loadCustomers();
        loadProducts();
        loadCatgories();
        loadBranches();
        initialize();
        loadSetting();
    }, [])

    const renderFilter = (
        <Form form={form} onFinish={handleSearch}>
            <Row gutter={16}>
                <Col span={isMobile ? 24 : 6}>
                    <Form.Item name="date_range" label="Date Range" labelCol={{ span: 24 }}>
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>

                {
                    manageInventory &&
                    <Col span={isMobile ? 24 : 6}>
                        <Form.Item label="Supplier" name="supplier_name" labelCol={{ span: 24 }}>
                            <Select
                                showSearch
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Select Supplier"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {suppliers.map(({ name }, index) => {
                                    return <Option value={name} key={index}>{name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                }

                <Col span={isMobile ? 24 : 6}>
                    <Form.Item label="Customer" name="customer_phone" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Customer"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {customers.map(({ name, phone }, index) => {
                                return <Option value={phone} key={index}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={isMobile ? 24 : 6}>
                    <Form.Item label="Product" name="product_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Product"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {products.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={isMobile ? 24 : 6}>
                    <Form.Item label="Category" name="category_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Category"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {categories.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={isMobile ? 24 : 6}>
                    <Form.Item label="Branch" name="branch_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Branch"
                            onChange={(e) => loadOrderNumbers(e)}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {branches.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={isMobile ? 24 : 6}>
                    <Form.Item label="Order Number" name="order_number" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Order Number"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {orderNumbers.map((orderNumber, index) => {
                                return <Option value={orderNumber} key={index}>{orderNumber}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={isMobile ? 24 : 4} className="gutter-row">
                    <Form.Item label={isMobile ? '' : <Text>&nbsp;</Text>} labelCol={{ span: 24 }}>
                        <Space>
                            <Button htmlType="submit">Search</Button>
                            <Button onClick={clear}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

    const renderHeader = <Title level={3}>Customize Sales</Title>

    return (
        <>
            {renderFilter}
            <Table
                dataSource={dataSource}
                title={() => renderHeader}
                columns={columns}
                pagination={{ ...PAGINATION_OPTIONS }}
                rowKey="index"
            />
        </>
    )
}

export default windowSize(CustomizeSalesReport)