import { Col, Row, Form, Input, Switch, } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const EnableFbrSetting = ({ Setting }) => {
    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='pos_id' label="POS ID"
                        labelCol={{ span: 24 }}
                        initialValue={data.pos_id}
                        rules={[
                            {
                                required: true,
                                message: 'POS id is required',
                            },
                        ]}>
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='access_code' label="Access Code"
                        labelCol={{ span: 24 }}
                        initialValue={data.access_code}
                        rules={[
                            {
                                required: true,
                                message: 'Access code id is required',
                            },
                        ]}>
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='ntn_number' label="Ntn Number"
                        labelCol={{ span: 24 }}
                        initialValue={data.ntn_number}
                        rules={[
                            {
                                required: true,
                                message: 'Ntn number is required',
                            },
                        ]}>
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='mode' label="Mode"
                        labelCol={{ span: 24 }}
                        initialValue={data.mode}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={data.mode == 1 ? true : false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
export default EnableFbrSetting