import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }
const removeDependency = () => { ajaxService.removeDependency() }

export async function getDetailInventoryReports() {
    setDependency()
    return ajaxService.get(urlService.detailInventory).then(response => {
        return Response(response.data);
    })
}

export async function filterDetailInventoryReports(data) {
    removeDependency()
    return await ajaxService.post(urlService.detailInventoryFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getProductInventoryReports() {
    setDependency()
    return await ajaxService.get(urlService.productInventory).then(response => {
        return Response(response.data);
    });
}

export async function filterProductInventoryReports(data) {
    setDependency()
    return await ajaxService.post(urlService.productInventoryFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getOutOfStockReports() {
    setDependency()
    return await ajaxService.get(urlService.outOfStock).then(response => {
        return Response(response.data);
    });
}

export async function filterOutOfStockReports(data) {
    setDependency()
    return await ajaxService.post(urlService.outOfStockFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getProfitLossStatements(data) {
    setDependency()
    return await ajaxService.post(urlService.profitLossStatement, data).then(response => {
        return Response(response.data);
    });
}

export async function getDamageDiscardReport() {
    setDependency()
    return await ajaxService.get(urlService.damageDiscard).then(response => {
        return Response(response.data);
    });
}

export async function filterDamageDiscardReport(data) {
    removeDependency()
    return await ajaxService.post(urlService.damageDiscardFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getIncomExpenseReport() {
    setDependency()
    return await ajaxService.get(urlService.incomeExpense).then(response => {
        return Response(response.data);
    });
}

export async function filterIncomExpenseReport(data) {
    setDependency()
    return await ajaxService.post(urlService.incomeExpenseFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getProductsExpiryReport() {
    setDependency()
    return await ajaxService.get(urlService.productsExpiry).then(response => {
        return Response(response.data);
    });
}

export async function filterProductsExpiryReport(data) {
    setDependency()
    return await ajaxService.post(urlService.productsExpiryFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getCustomizeSalesReport() {
    setDependency()
    return await ajaxService.get(urlService.customizeSales).then(response => {
        return Response(response.data);
    });
}

export async function filterCustomizeSalesReport(data) {
    setDependency()
    return await ajaxService.post(urlService.customizeSalesFilter, data).then(response => {
        return Response(response.data);
    });
}