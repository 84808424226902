const OrderStatus = {
    CONFIRMED: 'Confirmed',
    IN_QUEUE: 'In Queue',
    PROCESSING: 'Processing',
    READY: 'Ready',
    ON_THE_WAY: 'On The Way',
    CANCEL: 'Cancel',
    COMPLETED: 'Completed',
    RETURNED: 'Returned',
    PARTIAL_RETURNED: 'Partial Returned',
};

export default OrderStatus;