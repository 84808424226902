import { useEffect, useState } from "react"
import { getBrands, getCategories, getProduct, getProducts, saveProduct, updateProduct, getBranches, getStoreSetting, getProductStocks, getProductStockForCombo } from "../../../data"
import { Form, Input, Button, Space, Row, Col, Checkbox, Select, notification, Switch, Tooltip, DatePicker, Card, Typography, Divider } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ImageUpload from '../../../components/image-upload';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import _ from 'lodash';
import ajaxService, { GlobalVariable } from "../../../services/ajax-service";
import windowSize from "react-window-size";
import Toggles from "./toggels";
import { handleGenerate } from "../../../utility/skuGenerator";
import ControlSettings from "../../../constant/controlSettings";


const { Option } = Select
const { TextArea } = Input
const { Text } = Typography

const format = "h:mm";

const Combo = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm()
    let history = useHistory()
    let { id } = useParams()

    const [forceRefresh, setForceRefresh] = useState(true)
    const [isDeal, setIsDeal] = useState(false)
    const [text, setText] = useState('')

    const [ecommerce, setEcommerce] = useState(false)
    const [showMobile, setShowMobile] = useState(false)
    const [manageInventory, setManageInventory] = useState(false);

    const [comboProductId, setComboProductId] = useState();
    const [categories, setcategories] = useState([])
    const [brands, setBrands] = useState([])
    const [products, setProducts] = useState([])
    const [fileList, setFileList] = useState([]);
    const [branches, setBranches] = useState([])
    const [sku, setSku] = useState([])
    const [productStocks, setProductStocks] = useState([])

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onFinish = async (values) => {

        let imageData = [];
        if (fileList.length > 0) {
            fileList.map((item) => {
                let { response } = item;
                if (response != undefined) {
                    let { path, success } = response;
                    if (success) {
                        imageData.push(path);
                    }
                } else if (item.url !== undefined) {
                    imageData.push(item.url.replace(GlobalVariable.BASE_IMAGE_URL + '/', ""));
                }
            });
        }

        let data = {
            description: text,
            image: imageData,
            cost_price: values.compare_price,
            has_variant: false,
            is_main: true,
            offer: true,
            urls: values.video_urls,
            is_combo: 1,
            comboProducts: values.comboProducts,
            ...values
        }

        if (id == 0) {
            const saveData = await saveProduct(data);
            if (saveData) {
                history.push({ pathname: "/products" });
                notification.open({ message: 'Product combo has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add product combo', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            // let list = [...imageData]
            // fileList.map((item) => {
            //     if (item.url !== undefined) {
            //         list.push(item.url)
            //     }
            // })
            // data.image = list;
            data.active = values.active
            const updateData = await updateProduct(id, data);
            if (updateData) {
                history.push({ pathname: "/products" });
                notification.open({ message: 'Product combo has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update product combo', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }

    }

    const onFinishFailed = errorInfo => {
        console.error(`Field: ${errorInfo}`)
    }

    const handleAdd = () => {

        const combo = products.find(i => i.id === comboProductId)

        const data = {
            id: combo.id,
            name: combo.name,
            cost_price: combo.cost_price,
            selling_price: combo.selling_price,
            quantity: 1,
            deal_price: combo.selling_price,
        }

        const currentComboProducts = form.getFieldValue('comboProducts') || [];

        const isDuplicate = currentComboProducts.some(product => product.id === data.id);

        if (isDuplicate) {
            console.log('Entry already exists');
            return;
        }

        const updatedComboProducts = [...currentComboProducts, data];

        // Calculate selling price for compare price
        const comparePriceTotals = updatedComboProducts.map(p => p.selling_price * p.quantity);
        const comparePrice = comparePriceTotals.reduce((acc, curr) => acc + curr, 0);

        const sellingPriceTotal = updatedComboProducts.map(p => p.deal_price * p.quantity);
        const sellingPrice = sellingPriceTotal.reduce((acc, curr) => acc + curr, 0);

        const costTotal = updatedComboProducts.map(p => p.cost_price * p.quantity);
        const cost = costTotal.reduce((acc, curr) => acc + curr, 0);

        form.setFieldsValue({ comboProducts: updatedComboProducts, compare_price: comparePrice, selling_price: sellingPrice, cost_price: cost });
    }

    const handleQuantityChange = (index, value) => {
        const currentComboProducts = form.getFieldValue('comboProducts') || [];
        const updatedComboProducts = [...currentComboProducts];

        updatedComboProducts[index].quantity = value;

        // Calculate selling price for compare price
        const totals = updatedComboProducts.map(p => p.selling_price * p.quantity);
        const comparePrice = totals.reduce((acc, curr) => acc + curr, 0);
        // Calculate selling price for compare price
        const sellingPriceTotals = updatedComboProducts.map(p => p.deal_price * p.quantity);
        const sellingPrice = sellingPriceTotals.reduce((acc, curr) => acc + curr, 0);

        form.setFieldsValue({ comboProducts: updatedComboProducts, compare_price: comparePrice, selling_price: sellingPrice });
    };

    const handleDealPriceChange = (index, value) => {
        const currentComboProducts = form.getFieldValue('comboProducts') || [];
        const updatedComboProducts = [...currentComboProducts];

        updatedComboProducts[index].deal_price = value;

        // Calculate selling price for compare price
        const totals = updatedComboProducts.map(p => p.deal_price * p.quantity);
        const sellingPrice = totals.reduce((acc, curr) => acc + curr, 0);

        form.setFieldsValue({ comboProducts: updatedComboProducts, selling_price: sellingPrice });
    };

    const handleRemove = () => {
        const currentComboProducts = form.getFieldValue('comboProducts') || [];
        const updatedComboProducts = [...currentComboProducts];

        // Calculate selling price for compare price
        const totals = updatedComboProducts.map(p => p.selling_price * p.quantity);
        const comparePrice = totals.reduce((acc, curr) => acc + curr, 0);
        // Calculate selling price for compare price
        const sellingPriceTotals = updatedComboProducts.map(p => p.deal_price * p.quantity);
        const sellingPrice = sellingPriceTotals.reduce((acc, curr) => acc + curr, 0);

        form.setFieldsValue({ comboProducts: updatedComboProducts, compare_price: comparePrice, selling_price: sellingPrice });
    }

    const loadCategories = async () => {
        const response = await getCategories(forceRefresh);
        if (response) {
            setcategories(response);
            setForceRefresh(false)
        }
    }

    const loadBrands = async () => {
        const response = await getBrands(forceRefresh);
        if (response) {
            setBrands(response);
            setForceRefresh(false)
        }
    };

    const loadProducts = async () => {

        if (manageInventory) {
            const response = await getProductStockForCombo()
            if (response) {

                setProducts(response)
                setSku(response.map(i => i.sku))
            }
        } else {

            const response = await getProducts(forceRefresh)

            if (response) {
                setProducts(response.filter(i => !i.is_combo))
                setForceRefresh(false)
                setSku(response.map(i => i.sku))
            }
        }
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {

            if (id == 0) {
                form.setFieldsValue({ branches: response.map(branch => branch.id) })
            }

            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            let data = await getProduct(id);

            if (data) {
                setIsDeal(data.is_deal)

                const { category_id, brand_id, description, images, links, expiry_date, product_combo, show_in_ecommerce, show_in_mobile } = data;

                setEcommerce(show_in_ecommerce ? true : false)
                setShowMobile(show_in_mobile ? true : false)

                let array = []
                product_combo.forEach(combo => {
                    let x = products.find(i => i.id === combo.item_id)
                    if (x) {
                        const data = {
                            id: combo.item_id,
                            name: x.name,
                            cost_price: x.cost_price,
                            selling_price: x.selling_price,
                            quantity: combo.quantity,
                            deal_price: combo.deal_price,
                        }

                        array.push(data)
                    }
                });


                const assignedBranches = data.branch_products.filter(product => product.active === 1).map(product => product.branch_id);

                form.setFieldsValue({
                    ...data,

                    category_id: category_id ? parseInt(category_id) : null,

                    brand_id: brand_id ? parseInt(brand_id) : null,

                    video_urls: links,

                    expiry_date: moment(expiry_date),

                    branches: assignedBranches,

                    comboProducts: array
                });

                if (description !== null) {
                    setText(description);
                };

                setFileList(images.map(v => ({
                    uid: v.id,
                    name: 'image.png',
                    status: 'done',
                    url: ajaxService.getImage(v.url),
                    preview: false,
                })))
            }
        }
    }

    const loadStoreSetting = async () => {
        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);
        if (response) {
            setManageInventory(response.active)
            setForceRefresh(true)
        }
    }

    useEffect(() => {
        loadStoreSetting()
        loadCategories()
        loadBrands()
        loadBranches()
    }, [])

    useEffect(() => {
        loadData(id)
        if (forceRefresh) {
            loadProducts()
            setForceRefresh(false)
        }
    }, [products, forceRefresh])

    const initialValues = {
        show_in_pos: false,
        show_in_ecommerce: false,
        display_in_ecommerce: false,
        show_in_mobile: false,
        display_in_mobile: false,
        active: false,
        unlimited_stock: false,
        is_deal: false
    };

    const toggles = (
        <Toggles ecommerce={ecommerce} setEcommerce={setEcommerce} showMobile={showMobile} setShowMobile={setShowMobile} isMobile={isMobile} />
    )

    const comboProductsForm = (
        <Form.List name="comboProducts">
            {(fields, { add, remove }) => (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Combo Products" labelCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Combo products is required'
                                }
                            ]}
                        >
                            <Select
                                showSearch allowClear
                                style={{ width: '100%' }}
                                placeholder="Select Product"
                                onChange={(e) => setComboProductId(e)}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {products.map(({ id, name }) => {
                                    return <Option value={id}>{name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item labelCol={{ span: 24 }} label="&nbsp;">
                            <Button
                                style={{ width: 110, }}
                                // disabled={addBtn}
                                onClick={handleAdd}
                                block
                                icon={<PlusOutlined />}
                            >
                                Add Item
                            </Button>
                        </Form.Item>
                    </Col>

                    {fields.map(({ key, name, ...restField }) => (
                        <>
                            <Divider plain>Combo Products</Divider>
                            <Row gutter={16}>
                                <Col span={4}>
                                    <Form.Item label="Item" name={[name, 'name']} labelCol={{ span: 24 }}>
                                        <input disabled className="productDropDown" />
                                    </Form.Item>
                                </Col>

                                <Col span={3}>
                                    <Form.Item label="Cost Price" name={[name, 'cost_price']} labelCol={{ span: 24 }}>
                                        <input disabled className="productDropDown" />
                                    </Form.Item>
                                </Col>

                                <Col span={3}>
                                    <Form.Item label="Selling Price" name={[name, 'selling_price']} labelCol={{ span: 24 }}>
                                        <input disabled className="productDropDown" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Deal Price" name={[name, 'deal_price']} labelCol={{ span: 24 }}>
                                        <Input
                                            type="number"
                                            step={'0.01'}
                                            placeholder="Enter Deal Price"
                                            onChange={(e) => handleDealPriceChange(name, e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Quantity" name={[name, 'quantity']} labelCol={{ span: 24 }}>
                                        <Input
                                            type="number"
                                            step={0.01}
                                            placeholder="Enter Quantity"
                                            onChange={(e) => handleQuantityChange(name, e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={2}>
                                    <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
                                        <DeleteOutlined style={{ color: 'red', fontSize: '20px', }} onClick={() => { remove(name); handleRemove() }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                        </>
                    ))}
                </Row>
            )}
        </Form.List>
    )

    const renderCombo = () => (
        <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            initialValues={initialValues}
            layout="vertical"
        >
            <Row gutter={16} >
                <Col span={3}></Col>
                <Col span={18}>
                    <Row gutter={16} >

                        <Col span={16}  >
                            <Form.Item labelCol={{ span: 24 }} name='name' label="Name" rules={[
                                {
                                    required: true,
                                    message: 'Product Name is required',
                                },
                            ]}>
                                <Input placeholder="Product Name" />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item labelCol={{ span: 24 }} name='meta_title' label="Meta Title">
                                <Input placeholder="Meta Title" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item labelCol={{ span: 24 }} label="Brand" name="brand_id" rules={[
                                {
                                    required: true,
                                    message: 'Brand is required',
                                },
                            ]}>
                                <Select
                                    allowClear
                                    placeholder="Select Brand"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {brands.map(brand => {
                                        return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item labelCol={{ span: 24 }} label="Category" name="category_id" rules={[
                                {
                                    required: true,
                                    message: 'Category is required',
                                },
                            ]}>
                                <Select
                                    allowClear
                                    placeholder="Select Category"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {categories.map(category => {
                                        return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item labelCol={{ span: 24 }} name='meta_description' label="Meta Description">
                                <Input placeholder="Meta Description" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item labelCol={{ span: 24 }} name="is_deal" valuePropName='checked'>
                                <Checkbox onChange={(e) => setIsDeal(e.target.checked)}>Mark As Deal</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={20}>
                            <Form.Item name="expiry_date" label="Expiry Date"
                                rules={[
                                    {
                                        required: isDeal,
                                        message: 'Expiry date is required'
                                    }
                                ]}>
                                <DatePicker style={{ width: '30%' }} placeholder="Select Expiry Date" disabled={isDeal ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            {comboProductsForm}
                        </Col>

                        <Col span={16}>
                            <Form.Item labelCol={{ span: 24 }} name='short_description' label="Short Description">
                                <TextArea maxLength={300} placeholder="Maximum Length Is 300" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name='branches' label="Assign To Branches">
                                <Select mode="multiple" placeholder="Branches" style={{ width: '100%' }}>
                                    {branches.map(({ id, name }) => (
                                        <Option key={id} value={id}>{name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={4}>
                            <Form.Item labelCol={{ span: 24 }} name="active" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </Col>

                        {/* Toggles for pos , ecommerce & mobile*/}
                        {toggles}

                        <Col span={24}>
                            <Form.Item labelCol={{ span: 24 }} label="Description" >
                                <CKEditor
                                    className='editor'
                                    editor={ClassicEditor}
                                    onReady={editor => editor.setData(text)}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        setText(data)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item name='sku' label="SKU" rules={[
                                    {
                                        required: true,
                                        message: 'SKU is required',
                                    },
                                ]}>
                                    <Input placeholder="SKU" allowClear />
                                </Form.Item>
                                <Form.Item label="&nbsp;">
                                    <Button type="primary" onClick={() => handleGenerate(sku, form)}>Genrate</Button>
                                </Form.Item>
                            </Space.Compact>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='cost_price' label="Cost Price">
                                {/* <Input placeholder="Cost Price" type='number' defaultValue={0} /> */}
                                <input style={{ width: '70px' }} disabled className="productDropDown" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='selling_price' label="Selling Price" rules={[
                                {
                                    required: true,
                                    message: 'Selling Price is required',
                                },
                            ]}>
                                <Input placeholder="Selling Price" type='number' defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='compare_price' label="Compare Price">
                                <Input placeholder="Compare Price" type='number' defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='weight' label="Weight In Grams">
                                <Input placeholder="Weight In Grams" type='number' step={0.01} defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='length' label="Lenght In Inches">
                                <Input placeholder="Length In Inches" type='number' step={0.01} defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='breath' label="Breath In Inches">
                                <Input placeholder="Breath In Inches" type='number' defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='height' label="Height In Inches">
                                <Input placeholder="Height In Inches" type='number' step={0.01} defaultValue={0} />
                            </Form.Item>

                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='preparation_time' label="Preparation Time (In minutes)" >
                                <Input type='number' placeholder='Preparation Time' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='low_stock_point' label="Low Stock Point" >
                                <Input type='number' placeholder='Low Stock Point' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='reorder_point' label="Reorder Point" >
                                <Input type='number' placeholder='Reorder Point' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='reorder_qty' label="Reorder Quantity" >
                                <Input type='number' placeholder='Reorder Quantity' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item labelCol={{ span: 24 }} name='sort_order' label="Sort Order" >
                                <Input type='number' placeholder='Sort Order' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item labelCol={{ span: 24 }} label={
                                <span>
                                    Add Youtube Url
                                    <Tooltip placement="top" title={"hello"}>
                                        <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: 10 }} />
                                    </Tooltip>
                                </span>
                            }>
                                <Form.List name="video_urls">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Row gutter={16}>
                                                    <Col span={12}>
                                                        <Form.Item labelCol={{ span: 24 }} {...restField} name={[name, 'url']}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Form.Item labelCol={{ span: 24 }}>
                                                        <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => remove(name)} />
                                                    </Form.Item>
                                                </Row>
                                            ))}
                                            <Col span={12}>
                                                <Form.Item labelCol={{ span: 24 }}>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Upload Multiple Videos Via Embed YouTube URL
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </Form.List>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item labelCol={{ span: 24 }} label="Upload Multiple Images (Max File Size 1MB) but 1 by 1" >
                                <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                            </Form.Item>
                        </Col>

                        <Space>
                            <Button type="primary" htmlType="submit">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>

                    </Row>
                </Col>
                <Col span={3}></Col>
            </Row>
        </Form>
    )

    const renderComboMobile = () => (
        <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            initialValues={initialValues}
        >
            <Row gutter={16} >

                <Col span={24}  >
                    <Form.Item labelCol={{ span: 24 }} name='name' label="Name" rules={[
                        {
                            required: true,
                            message: 'Product Name is required',
                        },
                    ]}>
                        <Input placeholder="Product Name" />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item labelCol={{ span: 24 }} name='meta_title' label="Meta Title">
                        <Input placeholder="Meta Title" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} label="Brand" name="brand_id" rules={[
                        {
                            required: true,
                            message: 'Brand is required',
                        },
                    ]}>
                        <Select
                            allowClear
                            placeholder="Select Brand"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {brands.map(brand => {
                                return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} label="Category" name="category_id" rules={[
                        {
                            required: true,
                            message: 'Category is required',
                        },
                    ]}>
                        <Select
                            allowClear
                            placeholder="Select Category"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {categories.map(category => {
                                return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} name='meta_description' label="Meta Description">
                        <Input placeholder="Meta Description" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} name="is_deal" valuePropName='checked'>
                        <Checkbox onChange={(e) => setIsDeal(e.target.checked)}>Mark As Deal</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="expiry_date" label="Expiry Date"
                        rules={[
                            {
                                required: isDeal,
                                message: 'Expiry date is required'
                            }
                        ]}>
                        <DatePicker style={{ width: '100%' }} placeholder="Select Expiry Date" disabled={isDeal ? false : true} />
                    </Form.Item>
                </Col>


                <Col span={24}>
                    <Form.List name="comboProducts">
                        {(fields, { add, remove }) => (
                            <Row gutter={16}>
                                <Col span={20}>
                                    <Form.Item label="Combo Products" labelCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Combo products is required'
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Select Product"
                                            onChange={(e) => setComboProductId(e)}
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {products.map(({ id, name }) => {
                                                return <Option value={id}>{name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={4}>
                                    <Form.Item labelCol={{ span: 24 }} label="&nbsp;">
                                        <Button onClick={handleAdd}>Add</Button>
                                    </Form.Item>
                                </Col>

                                {fields.map(({ key, name, ...restField }) => (
                                    <>
                                        <br />
                                        <Card style={{ width: '100%' }} headStyle={{ height: 5 }}
                                            title={[
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Form.Item name={[name, 'name']}>
                                                        <input disabled className="productDropDown" style={{ fontWeight: 'bolder', marginBottom: 20 }} />
                                                    </Form.Item>
                                                    <DeleteOutlined style={{ color: 'red', fontSize: '20px' }} onClick={() => { remove(name); handleRemove() }} />
                                                </div>
                                            ]}>
                                            <div style={{ display: 'flex', marginBottom: 0 }}>
                                                <Text style={{ fontWeight: 'bolder' }}>Cost Price :</Text>
                                                <Form.Item name={[name, 'cost_price']}>
                                                    <input disabled className="productDropDown" style={{ position: 'relative', bottom: 5 }} />
                                                </Form.Item>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <Text style={{ fontWeight: 'bolder' }}>Selling Price :</Text>
                                                <Form.Item name={[name, 'selling_price']}>
                                                    <input disabled className="productDropDown" style={{ position: 'relative', bottom: 5 }} />
                                                </Form.Item>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <Text style={{ fontWeight: 'bolder' }}>Deal Price :</Text>
                                                <Form.Item name={[name, 'deal_price']}>
                                                    <Input
                                                        type="number"
                                                        step={'0.01'}
                                                        placeholder="Enter Deal Price"
                                                        style={{ position: 'relative', bottom: 5, marginLeft: 10 }}
                                                        onChange={(e) => handleDealPriceChange(name, e.target.value)}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <Text style={{ fontWeight: 'bolder' }}>Quantity Price :</Text>
                                                <Form.Item name={[name, 'quantity']}>
                                                    <Input
                                                        type="number"
                                                        step={0.01}
                                                        placeholder="Enter Quantity"
                                                        style={{ position: 'relative', bottom: 5, marginLeft: 10 }}
                                                        onChange={(e) => handleQuantityChange(name, e.target.value)}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Card >
                                    </>
                                ))}
                            </Row>
                        )}
                    </Form.List>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} name='short_description' label="Short Description">
                        <TextArea rows={5} maxLength={300} placeholder="Maximum Length Is 300" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} label="Description" >
                        <CKEditor
                            className='editor'
                            editor={ClassicEditor}
                            onReady={editor => editor.setData(text)}
                            onChange={(event, editor) => {
                                const data = editor.getData()
                                setText(data)
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={13}>
                    <Form.Item labelCol={{ span: 24 }} name="active" label="Active" valuePropName="checked">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>
                </Col>

                {/* Toggles for pos , ecommerce & mobile*/}
                {toggles}

                <Col span={24}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Form.Item name='sku' label="SKU" rules={[
                            {
                                required: true,
                                message: 'SKU is required',
                            },
                        ]}>
                            <Input placeholder="SKU" allowClear />
                        </Form.Item>
                        <Form.Item label="&nbsp;">
                            <Button type="primary" onClick={() => handleGenerate(sku, form)}>Genrate</Button>
                        </Form.Item>
                    </Space.Compact>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='cost_price' label="Cost Price">
                        {/* <Input placeholder="Cost Price" type='number' defaultValue={0} /> */}
                        <input style={{ width: '70px' }} disabled className="productDropDown" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='selling_price' label="Selling Price" rules={[
                        {
                            required: true,
                            message: 'Selling Price is required',
                        },
                    ]}>
                        <Input placeholder="Selling Price" type='number' defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='compare_price' label="Compare Price">
                        <Input placeholder="Compare Price" type='number' defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='weight' label="Weight In Grams">
                        <Input placeholder="Weight In Grams" type='number' step={0.01} defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='length' label="Lenght In Inches">
                        <Input placeholder="Length In Inches" type='number' step={0.01} defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='breath' label="Breath In Inches">
                        <Input placeholder="Breath In Inches" type='number' defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='height' label="Height In Inches">
                        <Input placeholder="Height In Inches" type='number' step={0.01} defaultValue={0} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='preparation_time' label="Preparation Time (In minutes)" >
                        <Input type='number' placeholder='Preparation Time' />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='low_stock_point' label="Low Stock Point" >
                        <Input type='number' placeholder='Low Stock Point' />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='reorder_point' label="Reorder Point" >
                        <Input type='number' placeholder='Reorder Point' />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='reorder_qty' label="Reorder Quantity" >
                        <Input type='number' placeholder='Reorder Quantity' />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item labelCol={{ span: 24 }} name='sort_order' label="Sort Order" >
                        <Input type='number' placeholder='Sort Order' />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name='branches' label="Assign To Branches">
                        <Select mode="multiple" placeholder="Branches" style={{ width: '100%' }}>
                            {branches.map(({ id, name }) => (
                                <Option key={id} value={id}>{name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} label={
                        <span>
                            Add Youtube Url
                            <Tooltip placement="top" title={"hello"}>
                                <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: 10 }} />
                            </Tooltip>
                        </span>
                    }>
                        <Form.List name="video_urls">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item labelCol={{ span: 24 }} {...restField} name={[name, 'url']}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Form.Item labelCol={{ span: 24 }}>
                                                <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Row>
                                    ))}
                                    <Col span={24}>
                                        <Form.Item labelCol={{ span: 24 }}>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                <span style={{ fontSize: 13 }}>Upload Multiple Videos Via Embed YouTube URL</span>
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item labelCol={{ span: 24 }} label="Upload Multiple Images (Max File Size 1MB) but 1 by 1" >
                        <ImageUpload onChange={onChange} fileList={fileList} crop={true} />
                    </Form.Item>
                </Col>

                <Space>
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                </Space>

            </Row>

        </Form >
    )

    return isMobile ? renderComboMobile() : renderCombo()

}

export default windowSize(Combo)