import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const removeDependency = () => ajaxService.removeDependency()

export async function getStores(refresh = false) {
    removeDependency()
    if (refresh) {
        return await loadResource(urlService.stores, 'stores');
    } else {
        let stores = await loadState('stores');

        if (stores == null) {
            return await loadResource(urlService.stores, 'stores');
        } else {
            return Response(stores)
        }
    }
}

export async function getStore(id) {
    removeDependency()
    let store = await loadState('store');
    if (store) {
        return store;
    } else {
        return ajaxService.get(urlService.stores + '/' + id).then(response => {
            localStorage.setItem('store',JSON.stringify(response.data.payload))
            return Response(response.data);
        })
    }
}

export async function saveStore(data) {
    removeDependency()
    return ajaxService.post(urlService.stores, data).then(response => {
        return Response(response.data);
    })
}

export async function updateStore(id, data) {
    removeDependency()
    return ajaxService.put(urlService.stores + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function saveStorePermissions(id, data) {

    return ajaxService.post(urlService.storePermissions + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function getStorePermissions(id) {
    return ajaxService.get(urlService.storePermissions + '/' + id).then(response => {
        return Response(response.data);
    })
}