import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Space, Switch, notification, Select, DatePicker } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { getStaffManagement, saveStaffManagement, updateStaffManagement, getBranches } from '../../data';
import windowSize from 'react-window-size';
import moment from 'moment';

const { RangePicker } = DatePicker;

const { Option } = Select

const options = [
    { label: 'Cleaner', value: 'Cleaner' },
    { label: 'Floor Manager', value: 'Floor Manager' },
    { label: 'Cashier', value: 'Cashier' },
    { label: 'Manager', value: 'Manager' },
    { label: 'Accountant', value: 'Accountant' },
    { label: 'Waiter', value: 'Waiter' },
];

const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));

function StaffManagementForm({ windowWidth }) {
    const dateFormat = 'YYYY-MM-DD';

    const [form] = Form.useForm();
    const isMobile = windowWidth <= 768;
    const [forceRefresh, setForceRefresh] = useState(true)
    const [branches, setBranches] = useState([])


    let history = useHistory();
    let { id } = useParams();

    const onFinish = async (values) => {
        if (id == 0) {
            const saveData = await saveStaffManagement(values);
            if (saveData) {
                history.push({ pathname: '/staff-managements' });
                notification.open({ message: 'Staff has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add staff', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const updateData = await updateStaffManagement(id, values);
            if (updateData) {
                history.push({ pathname: '/staff-managements' });
                notification.open({ message: 'Staff has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update staff', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            let response = await getStaffManagement(id);
            const staffBranches = (response.staff_branches).map(i => i.branch_id)

            if (response != null) {

                let data = {
                    name: response.name,
                    phone: response.phone,
                    employee_code: response.employee_code,
                    phone: response.name,
                    appointment_date: moment(response.appointment_date),
                    role: response.role,
                    salary: response.salary,
                    branches: staffBranches,
                    active: response.active
                }
                form.setFieldsValue({ ...data });
            }
        }
    }

    useEffect(() => {
        loadBranches()
        loadData(id);
    }, []);

    const renderStaffManagementForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    initialValues={{
                        active: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name='name' label="Name" rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        },
                                    ]}>
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='phone' label="Phone">
                                        <Input placeholder="Phone" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='employee_code' label="Employee Code" >
                                        <Input placeholder="Employee Code" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='appointment_date' label="Appointment Date">
                                        {/* <DatePicker style={{ width: '100%' }} format={dateFormat} /> */}
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='role' label="Role" rules={[
                                        {
                                            required: true,
                                            message: 'Role is required',
                                        },
                                    ]}>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Select Supplier"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {sortedOptions.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='salary' label="Salary">
                                        <Input placeholder="Salary" type='number' step={0.01} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='branches' label="Branches" rules={[{ required: true, message: 'Branches is required' }]} >
                                        <Select allowClear mode="multiple" placeholder="Select Branches"
                                            style={{
                                                width: '100%',
                                            }}>
                                            {
                                                branches.map(({ id, name }) => (
                                                    <Option value={id} key={id}>{name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='active' label="Active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        );
    }

    const renderStaffManagementFormMobile = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    initialValues={{
                        active: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}>
                    <Row gutter={16} >
                        <Col span={24}>
                            <Form.Item name='name' label="Name" rules={[
                                {
                                    required: true,
                                    message: 'Name is required',
                                },
                            ]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='phone' label="Phone" >
                                <Input placeholder="Phone" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='employee_code' label="Employee Code" >
                                <Input placeholder="Employee Code" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='appointment_date' label="Appointment Date" >
                                <DatePicker style={{ width: '100%' }} format={dateFormat} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='role' label="Role" rules={[
                                {
                                    required: true,
                                    message: 'Role is required',
                                },
                            ]}>
                                <Select
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select Staff"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    <Option value='cleaner'>Cleaner</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='salary' label="Salary">
                                <Input placeholder="Salary" type='number' step={0.01} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='branches' label="Branches" rules={[{ required: true, message: 'Branches is required' }]} >
                                <Select allowClear mode="multiple" placeholder="Select Branches"
                                    style={{
                                        width: '100%',
                                    }}>
                                    {
                                        branches.map(({ id, name }) => (
                                            <Option value={id} key={id}>{name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='active' label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Row>
                </Form>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderStaffManagementFormMobile()
        } else {
            return renderStaffManagementForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

};
export default windowSize(StaffManagementForm)