import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Typography, notification, Row, Col, Avatar, Modal, Switch, Card, Image } from 'antd';
import { useHistory } from 'react-router-dom'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ajaxService from '../../../services/ajax-service';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined, MenuOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { getCategories, deleteCategory, updateCategory, getBranchCategories } from '../../../data';
import windowSize from 'react-window-size';
import { PAGINATION_OPTIONS } from '../../../constants';
import { updateDataSource } from '../../../utility/updateDataSource';

const { Title, Text } = Typography;

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

function Category({ windowWidth }) {

  const history = useHistory();
  const isMobile = windowWidth <= 768;
  let branch_id = localStorage.getItem('branch_id')

  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(true);

  const updateValue = async (id, checked, type) => {

    const updatedCategories = updateDataSource(dataSource, id, checked);
    setDataSource(updatedCategories);

    const active = await updateCategory(id, { type: type, value: checked })
    if (active) {
      setForceRefresh(true)
      if (checked === true) {
        notification.open({ message: 'Category activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      } else if (checked === false) {
        notification.open({ message: 'Category deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      }
    }
    else {
      notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      className: 'drag-visible',
      render: (image) => <Avatar shape="square" size={64} src={image ? ajaxService.getImage(image) : null} />

    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'drag-visible',
    },
    {
      title: 'Parent',
      dataIndex: 'parent_id',
      key: 'parent_id',
      render: (active) => <Text >{dataSource.map((v) => v.id == active && v.name)}</Text>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      hidden: true,
      render: (active, record) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={active == 1 ? true : false}
          onChange={(checked) => updateValue(record.id, checked, 'active')}
        />
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      hidden: true,
      render: (text, record) => (
        <Space size='small'>
          <Button onClick={() => history.push({ pathname: "/categories/form/" + record.id })}  >Edit</Button>
          <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ].filter(item => branch_id ? !item.hidden : item);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      setDataSource(newData)
      const categoryId = dataSource[oldIndex].id
      setSortItem(newIndex, categoryId)
    }
  };

  const setSortItem = async (id, sort_order) => {
    let data = { id, sort_order }
    const response = await ajaxService.post('sort');
    if (response.status === 200) {
      notification.open({ message: 'Sort Order Updated...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      window.location.reload()
    } else {
      notification.open({ message: 'Sort Order Error...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const deleteRow = async () => {
    let category_id = localStorage.getItem('category');
    const data = await deleteCategory(category_id);

    if (data) {
      setShowModal(false);
      setForceRefresh(true);
      notification.open({ message: 'Category has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to delete category', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const openDeleteModal = (id) => {
    localStorage.setItem('category', id);
    setShowModal(true)
  }

  const initialize = async () => {
    let data = [];

    if (branch_id) {
      data = await getBranchCategories(branch_id)
    } else {
      data = await getCategories(forceRefresh);
    }

    if (data) {
      setDataSource(data);
      setForceRefresh(false);
    }
  }

  useEffect(() => {
    if (forceRefresh) {
      initialize();
      setForceRefresh(false)
    }
  }, [forceRefresh])

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title level={3}>Categories</Title>
        </div>
        <Space size="small" >
          <Button style={{ display: branch_id ? 'none' : '' }} type="primary" onClick={() => history.push({ pathname: "/categories/form/0" })}>
            Add Category
          </Button>
        </Space>
      </div>
    );
  }

  const renderCategory = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <Table
              dataSource={dataSource}
              columns={columns}
              title={() => renderHeader()}
              pagination={{ ...PAGINATION_OPTIONS }}
              rowKey="id"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </Col>
        </Row>

      </div>
    )
  }

  const renderCategoryMobile = () => {
    return (
      <>
        {
          dataSource.map(category => {
            return (
              <>
                <br />
                <Card
                  style={{ width: '100%' }}
                  actions={[
                    <Space size='small' style={{ display: branch_id ? 'none' : '' }}>
                      <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/categories/form/" + category.id })}  >Edit</Button>
                      <Button type='primary' icon={<DeleteOutlined />} onClick={() => openDeleteModal(category.id)} danger>Delete</Button>
                    </Space>
                  ]}>
                  <Image.PreviewGroup>
                    <Image width={250} height={150} sizes="small" src={category.image} />
                  </Image.PreviewGroup>
                  <br />
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{ajaxService.getImage(category.name)}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Parent :
                    <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{dataSource.map((v) => v.id == category.parent_id && v.name)}</span>
                  </Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Active :
                    <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                      <Switch
                        onChange={(val) => updateValue(category.id, val, 'active')}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={category.active == 1 ? true : false} />
                    </span>
                  </Text>
                  <br />
                </Card>
              </>
            );
          })
        }
      </>
    )
  }

  const renderModal = () => {
    return (
      <Modal
        title="Delete Category"
        centered
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" onClick={() => deleteRow()}>
            Delete
          </Button>
          ,
          <Button key="back" onClick={() => setShowModal(false)}>
            Close
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Are you sure you want to delete this category?</p>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return renderCategoryMobile()
    } else {
      return renderCategory()
    }
  }

  return (
    <>
      {isMobile && renderHeader()}
      {renderContent()}
      {renderModal()}
    </>
  )

}

export default windowSize(Category)