import { useState, useEffect } from "react"
import { Avatar, Button, Card, Modal, Space, Switch, Table, Typography, notification, Image } from "antd"
import { PAGINATION_OPTIONS } from "../../../constants"
import { deleteCuisine, getBranch, getCuisines, updateCuisine } from "../../../data"
import { useHistory } from "react-router-dom"
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { ajaxService } from "../../../services"
import { updateDataSource } from "../../../utility/updateDataSource"
import windowSize from "react-window-size"

const { Title, Text } = Typography

function Cuisines({ windowWidth }) {

    const isMobile = windowWidth <= 768
    let history = useHistory()

    const [dataSource, setDataSource] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const branch_id = localStorage.getItem('branch_id')

    const updateValue = async (id, checked, type) => {

        const updatedCuisines = updateDataSource(dataSource, id, checked);
        setDataSource(updatedCuisines);

        const active = await updateCuisine(id, { type: type, value: checked })
        if (active) {
            setForceRefresh(true)
            if (checked === true) {
                notification.open({ message: 'Cuisine activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (checked === false) {
                notification.open({ message: 'Cuisine deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => <Avatar shape="square" size={64} src={image ? ajaxService.getImage(image) : null} />
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            hidden: true,
            render: (active, record) => (
                <Switch
                    onChange={(checked) => updateValue(record.id, checked, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={active == 1 ? true : false}
                />
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 100,
            hidden: true,
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: '/cuisines/form/' + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            )
        },
    ].filter(item => branch_id ? !item.hidden : item);

    const deleteRow = async () => {
        let cuisine_id = localStorage.getItem('cuisine_id')
        const data = await deleteCuisine(cuisine_id);
        if (data) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Cuisine has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to deleted cuisine', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('cuisine_id', id);
        setShowModal(true)
    }

    const initialize = async () => {
        let data = await getCuisines(forceRefresh);
        if (data) {
            if (branch_id) {
                let response = await getBranch(branch_id)
                if (response) {
                    const filteredArray = data.filter((item) =>
                        response.cuisine.includes(item.name)
                    );
                    setDataSource(filteredArray)
                }
            } else {
                setDataSource(data);
            }
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Cuisines</Title>
            <Space size="small" >
                <Button style={{ display: branch_id ? 'none' : '' }} type="primary" onClick={() => history.push({ pathname: '/cuisines/form/0' })}>Add Cuisine</Button>
            </Space>
        </div>
    )

    const renderModal = () => (
        <Modal
            title="Delete Cuisine"
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={[
                <Button key="delete" onClick={() => deleteRow()}>
                    Delete
                </Button>,
                <Button key="back" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Are you sure you want to delete this cuisine?</p>
            </div>
        </Modal>
    )

    const renderCuisines = () => (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader()}
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )

    const renderCuisinesMobile = () => (
        <>
            {
                dataSource.map(cuisine => (
                    <>
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            actions={[
                                <Space size='small' style={{ display: branch_id ? 'none' : '' }}>
                                    <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/cuisines/form/" + cuisine.id })}>Edit</Button>
                                    <Button type='primary' icon={<DeleteOutlined />} onClick={() => openDeleteModal(cuisine.id)} danger>Delete</Button>
                                </Space>
                            ]}>
                            <Image.PreviewGroup>
                                <Image width="100%" sizes="small" src={ajaxService.getImage(cuisine.image)} />
                            </Image.PreviewGroup>
                            <br />
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{cuisine.name}</span></Text>
                            <br />
                            <Text style={{ fontWeight: 'bolder' }}>Active :
                                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                    <Switch
                                        onChange={(val) => updateValue(cuisine.id, val, 'active')}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={cuisine.active == 1 ? true : false} />
                                </span>
                            </Text>
                            <br />
                        </Card>
                    </>
                ))
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderHeader()}
                    {renderCuisinesMobile()}
                </>
            )
        }
        else {
            return renderCuisines()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal()}
        </>
    )

}

export default windowSize(Cuisines)