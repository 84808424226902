import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Space, Switch, notification, Divider, TimePicker, Select, Typography, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getBranch, getBranches, getCuisines, saveBranch, updateBranch } from '../../data';
import windowSize from 'react-window-size';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import _ from 'lodash';
import { getAbilities } from '../../data/user-service';
import IndustrySettings from '../../constant/industrySettings';
import { Limits } from '../../constant';
import { GOOGLE_MAPS_API_KEY } from '../../constants';

const { Option } = Select;
const { Text } = Typography;
const format = "h:mm A";


function BranchForm({ windowWidth, google }) {

    const [cuisines, setCuisines] = useState([]);
    const [branches, setBranches] = useState([]);
    const [storeRestriction, setStoreRestriction] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(true);
    const [latitude, setLatitude] = useState(-37.8063982)
    const [longitude, setLongitude] = useState(144.9337319)
    const [onlineOrders, setOnlineOrders] = useState(true)
    const [isDelivery, setIsDelivery] = useState(true)
    const [isPickup, setIsPickup] = useState(true)
    const [isDinein, setIsDinein] = useState(true)

    const [form] = Form.useForm();
    const isMobile = windowWidth <= 768;

    let history = useHistory();
    let { id } = useParams();
    const newAdmin = localStorage.getItem('newAdmin') ?? false

    const onFinish = async (values) => {

        let data = {
            name: values.name,
            disclaimer: values.disclaimer,
            email: values.email,
            phone: values.phone,
            min_order_amount: values.min_order_amount,
            city: values.city,
            country: values.country,
            latitude: latitude,
            longitude: longitude,
            zipcode: values.zipcode,
            address: values.address,
            website: values.website,
            facebook: values.facebook,
            instagram: values.instagram,
            snapchat: values.snapchat,
            twitter: values.twitter,
            opening_time: values.opening_time.format('YYYY-MM-DD HH:mm:ss'),
            closing_time: values.closing_time.format('YYYY-MM-DD HH:mm:ss'),
            is_main: values.is_main,
            active: values.active,
            cuisine: values.cuisine,
            newAdmin: newAdmin,
            online_orders: values.online_orders,
            pickup : values.pickup,
            delivery : values.delivery,
            dinein : values.dinein,
            pickup_time : values.pickup_time,
            dinein_time : values.dinein_time,
            delivery_time : values.delivery_time,
        }

        if (id == 0) {
            const saveData = await saveBranch(data);
            if (saveData) {
                if (newAdmin) {
                    localStorage.removeItem('newAdmin')
                    const abilities = await getAbilities()
                    if (abilities) {
                        localStorage.setItem('abilities', abilities.abilities)
                    }
                }

                history.push({ pathname: '/' });
                notification.open({ message: 'Branch has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add branch', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const updateData = await updateBranch(id, data);

            if (updateData) {
                history.push({ pathname: '/' });
                notification.open({ message: 'Branch has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update branch', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh);
        if (response.length > 0) {
            setBranches(response);

            const store = response[0].store;

            if (store && store.store_restrictions) {
                setStoreRestriction(store.store_restrictions);
            } else {
                setStoreRestriction([]);
            }

            setForceRefresh(false);
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            let response = await getBranch(id);
            if (response != null) {
                let data = { ...response }
                data.opening_time = moment(data.opening_time, format)
                data.closing_time = moment(data.closing_time, format)
                form.setFieldsValue({ ..._.omit(data, ['cuisine']), });

                if (response.cuisine) {
                    form.setFieldsValue({ cuisine: response.cuisine })
                }
                setLatitude(data.latitude)
                setLongitude(data.longitude)
            }
        }
    }

    const loadCuisines = async () => {
        let response = await getCuisines(forceRefresh);
        if (response) {

            let data = response.filter(i => i.active)

            setCuisines(data.map(i => i.name));
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        loadData(id);
        loadCuisines()
        loadBranches()
    }, []);

    const onMapClicked = (mapProps, map, event) => {
        setLatitude(event.latLng.lat())
        setLongitude(event.latLng.lng())
    }

    const googleMap = (
        <div style={{ height: '50vh', width: '100%' }}>
            <Map
                google={google}
                zoom={14}
                onClick={onMapClicked}
                disableDefaultUI={true}
                center={{
                    lat: latitude,
                    lng: longitude
                }}
                initialCenter={
                    {
                        lat: latitude,
                        lng: longitude
                    }
                }
                centerAroundCurrentLocation={true}
                visible={true}
            >
                <Marker position={{ lat: latitude, lng: longitude }} />
            </Map>
        </div>
    )

    const renderButton = () => {

        if (id > 0 || (!storeRestriction || storeRestriction.find(i => i.type === Limits.BRANCH_LIMITS)?.value !== branches.length)) {
            return (
                <Space>
                    <Button htmlType="submit" type="primary">Submit</Button>
                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                </Space>
            )
        } else {
            return <Text style={{ color: 'red' }}>{Limits.MESSAGE}</Text>
        }
    }

    const renderBranchForm = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    initialValues={{
                        active: true,
                        is_main: true,
                        online_orders: true,
                        pickup: true,
                        dinein: true,
                        delivery: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Divider plain> Store Info </Divider>
                                <Col span={12}>
                                    <Form.Item name='name' label="Name" rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        },
                                    ]}>
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='email' label="Email" rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: 'Email is required',
                                        },
                                    ]}>
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='phone' label="Phone" rules={[
                                        {
                                            required: true,
                                            message: 'Phone number is required',
                                        },
                                    ]}>
                                        <Input placeholder="Phone" type='number' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Disclaimer" name='disclaimer' rules={[
                                        {
                                            required: true,
                                            message: 'Desclaimer is required',
                                        },
                                    ]}>
                                        <Input placeholder="Disclaimer" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='opening_time' label="Opening time" rules={[
                                        {
                                            required: true,
                                            message: 'Opening time is required',
                                        },
                                    ]}>
                                        <TimePicker use12Hours style={{ width: "100%" }} format={format} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='closing_time' label="Closing time" rules={[
                                        {
                                            required: true,
                                            message: 'Closing time is required',
                                        },
                                    ]}>
                                        <TimePicker use12Hours style={{ width: "100%" }} format={format} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='min_order_amount' label="Minimum Order Amount" rules={[
                                        {
                                            required: true,
                                            message: 'Min. order amount is required',
                                        },
                                    ]}>
                                        <Input placeholder="Min. order amount" type='number' />
                                    </Form.Item>
                                </Col>
                                {
                                    IndustrySettings.CUISINE ? (
                                        <Col span={12}>
                                            <Form.Item name='cuisine' label="Cuisine">
                                                <Select placeholder="Select Cuisine"
                                                    mode='multiple'
                                                    showSearch
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {cuisines.map(i => {
                                                        return <Option value={i}>{i}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : null
                                }

                                <Divider plain> Store Address </Divider>
                                <Col span={12}>
                                    <Form.Item name='address' label="Address" rules={[
                                        {
                                            required: true,
                                            message: 'Address is required',
                                        },
                                    ]}>
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='zipcode' label="Zip Code" rules={[
                                        {
                                            required: true,
                                            message: 'Zip Code is required',
                                        },
                                    ]}>
                                        <Input placeholder="Zip Code" type='number' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='city' label="City" rules={[
                                        {
                                            required: true,
                                            message: 'City is required',
                                        },
                                    ]}>
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='country' label="Country" rules={[
                                        {
                                            required: true,
                                            message: 'Country is required',
                                        },
                                    ]}>
                                        <Input placeholder="Country" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Location on Map">
                                        {googleMap}
                                    </Form.Item>
                                </Col>

                                <Divider plain> Social </Divider>
                                {/* <Col span={12}>
                                    <Form.Item name='website' label="Website">
                                        <Input placeholder="Website" />
                                    </Form.Item>
                                </Col> */}
                                <Col span={12}>
                                    <Form.Item name='facebook' label="Facebook" >
                                        <Input placeholder="Facebook" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='instagram' label="Instagram">
                                        <Input placeholder="Instagram" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='snapchat' label="Snapchat">
                                        <Input placeholder="Snapchat" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='twitter' label="Twitter">
                                        <Input placeholder="Twitter" />
                                    </Form.Item>
                                </Col>

                                <Divider plain> Other Options </Divider>
                                <Col span={8}>
                                    <Form.Item name='is_main' label="Mark as main branch" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name='active' label="Active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name='online_orders' label="Take online orders" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                            onChange={(e) => setOnlineOrders(e)}
                                        />
                                    </Form.Item>
                                </Col>
                                {onlineOrders &&
                                    <>
                                        <Col span={8}>
                                            <Form.Item name='delivery' label="Delivery" valuePropName="checked">
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    defaultChecked
                                                    onChange={e => setIsDelivery(e)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name='pickup' label="Pickup / Take away" valuePropName="checked">
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    defaultChecked
                                                    onChange={e => setIsPickup(e)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {
                                            IndustrySettings.CUISINE && <Col span={8}>
                                                <Form.Item name='dinein' label="Dinein" valuePropName="checked">
                                                    <Switch
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        defaultChecked
                                                        onChange={e => setIsDinein(e)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        }

                                        <Col span={8}>
                                            <Form.Item name='delivery_time' label="Delivery in x minutes" valuePropName="checked"
                                                rules={[
                                                    {
                                                        required: isDelivery,
                                                        message: 'Delivery time is required',
                                                    },
                                                ]}
                                            >
                                                <InputNumber min={1} readOnly={!isDelivery} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name='pickup_time' label="Pickup Ready in x minutes" valuePropName="checked"
                                                rules={[
                                                    {
                                                        required: isPickup,
                                                        message: 'Pickup time is required',
                                                    },
                                                ]}
                                            >
                                                <InputNumber min={1} readOnly={!isPickup} />
                                            </Form.Item>
                                        </Col>
                                        {
                                            IndustrySettings.CUISINE &&
                                            <Col span={8}>
                                                <Form.Item name='dinein_time' label="Dinein ready in x minutes" valuePropName="checked"
                                                    rules={[
                                                        {
                                                            required: isDinein,
                                                            message: 'Dinein time is required',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber min={1} readOnly={!isDinein} />
                                                </Form.Item>
                                            </Col>
                                        }
                                    </>
                                }
                            </Row>

                            <Row>
                                <Form.Item>
                                    {renderButton()}
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        );
    }

    const renderBranchFormMobile = () => {
        return (
            <Form
                layout={'vertical'}
                initialValues={{
                    active: true,
                    is_main: true,

                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >

                    <Divider plain> Basic Info </Divider>
                    <Col span={24}>
                        <Form.Item name='name' label="Name" rules={[
                            {
                                required: true,
                                message: 'Name is required',
                            },
                        ]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='email' label="Email" rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Email is required',
                            },
                        ]}>
                            <Input placeholder="Email" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='phone' label="Phone" rules={[
                            {
                                required: true,
                                message: 'Phone number is required',
                            },
                        ]}>
                            <Input placeholder="Phone" type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Disclaimer" name='disclaimer' rules={[
                            {
                                required: true,
                                message: 'Desclaimer is required',
                            },
                        ]}>
                            <Input placeholder="Disclaimer" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='min_order_amount' label="Minimum Order Amount" rules={[
                            {
                                required: true,
                                message: 'Min. order amount is required',
                            },
                        ]}>
                            <Input placeholder="Min. order amount" type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='opening_time' label="Opening time" rules={[
                            {
                                required: true,
                                message: 'Opening time is required',
                            },
                        ]}>
                            <TimePicker use12Hours style={{ width: "100%" }} format={format} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='closing_time' label="Closing time" rules={[
                            {
                                required: true,
                                message: 'Closing time is required',
                            },
                        ]}>
                            <TimePicker use12Hours style={{ width: "100%" }} format={format} />
                        </Form.Item>
                    </Col>
                    {IndustrySettings.CUISINE ? (
                        <Col span={24}>
                            <Form.Item name='cuisine' label="Cuisine">
                                <Select placeholder="Select Cuisine"
                                    mode='multiple'
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {cuisines.map(i => {
                                        return <Option value={i}>{i}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}


                    <Divider plain> Address </Divider>
                    <Col span={24}>
                        <Form.Item name='address' label="Address" rules={[
                            {
                                required: true,
                                message: 'Address is required',
                            },
                        ]}>
                            <Input placeholder="Address" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='zipcode' label="Zip Code" rules={[
                            {
                                required: true,
                                message: 'Zip Code is required',
                            },
                        ]}>
                            <Input placeholder="Zip Code" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='city' label="City" rules={[
                            {
                                required: true,
                                message: 'City is required',
                            },
                        ]}>
                            <Input placeholder="City" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='country' label="Country" rules={[
                            {
                                required: true,
                                message: 'Country is required',
                            },
                        ]}>
                            <Input placeholder="Country" />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Google Map">
                            {googleMap}
                        </Form.Item>
                    </Col>

                    <Divider plain> Social </Divider>
                    {/* <Col span={24}>
                        <Form.Item name='website' label="Website" rules={[
                            {
                                required: true,
                                message: 'Website is required',
                            },
                        ]}>
                            <Input placeholder="Website" />
                        </Form.Item>
                    </Col> */}
                    <Col span={24}>
                        <Form.Item name='facebook' label="Facebook" rules={[
                            {
                                required: true,
                                message: 'Facebook is required',
                            },
                        ]}>
                            <Input placeholder="Facebook" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='instagram' label="Instagram" rules={[
                            {
                                required: true,
                                message: 'Instagram is required',
                            },
                        ]}>
                            <Input placeholder="Instagram" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='snapchat' label="Snapchat" rules={[
                            {
                                required: true,
                                message: 'Snapchat is required',
                            },
                        ]}>
                            <Input placeholder="Snapchat" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='twitter' label="Twitter" rules={[
                            {
                                required: true,
                                message: 'Twitter is required',
                            },
                        ]}>
                            <Input placeholder="Twitter" />
                        </Form.Item>
                    </Col>
                    <Divider plain> Status </Divider>
                    <Col span={8}>
                        <Form.Item name='is_main' label="Mark as main branch " valuePropName="checked">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='active' label="Active" valuePropName="checked">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='online_orders' label="Take online orders" valuePropName="checked">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                                onChange={(e) => setOnlineOrders(e)}
                            />
                        </Form.Item>
                    </Col>
                    {onlineOrders &&
                        <>
                            <Col span={8}>
                                <Form.Item name='delivery' label="Delivery" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                        onChange={e => setIsDelivery(e)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='pickup' label="Pickup / Take away" valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                        onChange={e => setIsPickup(e)}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                IndustrySettings.CUISINE && <Col span={8}>
                                    <Form.Item name='dinein' label="Dinein" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                            onChange={e => setIsDinein(e)}
                                        />
                                    </Form.Item>
                                </Col>
                            }

                            <Col span={8}>
                                <Form.Item name='delivery_time' label="Delivery in x minutes" valuePropName="checked"
                                    rules={[
                                        {
                                            required: isDelivery,
                                            message: 'Delivery time is required',
                                        },
                                    ]}
                                >
                                    <InputNumber min={1} readOnly={!isDelivery} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='pickup_time' label="Pickup ready in x minutes" valuePropName="checked"
                                    rules={[
                                        {
                                            required: isPickup,
                                            message: 'Pickup time is required',
                                        },
                                    ]}
                                >
                                    <InputNumber min={1} readOnly={!isPickup} />
                                </Form.Item>
                            </Col>
                            {
                                IndustrySettings.CUISINE &&
                                <Col span={8}>
                                    <Form.Item name='dinein_time' label="Dinein ready in x minutes" valuePropName="checked"
                                        rules={[
                                            {
                                                required: isDinein,
                                                message: 'Dinein time is required',
                                            },
                                        ]}
                                    >
                                        <InputNumber min={1} readOnly={!isDinein} />
                                    </Form.Item>
                                </Col>
                            }
                        </>
                    }
                    <Divider plain>Actions</Divider>
                    <Col span={24} style={{ position: 'relative', left: 80, top: 20 }}>
                        <Form.Item>
                            {renderButton()}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderBranchFormMobile()
        } else {
            return renderBranchForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )

};


const WindowSizeBranchForm = windowSize(BranchForm)

export default GoogleApiWrapper({
    apiKey: GOOGLE_MAPS_API_KEY
})(WindowSizeBranchForm);