import { Card, Col, Divider, Row, Table, Typography } from "antd"
import { useState } from "react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getPackage } from "../../data"

const { Title } = Typography
function PackageView() {

    let { id } = useParams()

    const [data, setData] = useState({})
    const [items, setItems] = useState([])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'descriptin'
        },
        {
            title: 'Available',
            key: 'available',
            render: (text, record) => (
                <p>{record.available == 1 ? "yes" : 'No'}</p>
            )
        }
    ]

    const initialize = async (id) => {
        const response = await getPackage(id)
        if (response) {
            setData(response)
            setItems(response.items)
        }
    }

    useEffect(() => {
        initialize(id)
    }, [])

    return (
        <>
            <Card>
                <Title level={3}>Package</Title>
                <br />
                <Row style={{marginLeft:20}}>
                    <Col span={6}><b>Name</b></Col>
                    <Col span={6}>{data.name}</Col>
                    <Divider />
                    <Col span={6}><b>Description</b></Col>
                    <Col span={6}>{data.description}</Col>
                    <Divider />
                    <Col span={6}><b>Price</b></Col>
                    <Col span={6}>{data.price}</Col>
                    <Divider />
                </Row>

                <Row>
                    <Title level={3}>Package Items</Title>
                    <Divider className='divide' />
                </Row>
                <Table
                    pagination={false}
                    dataSource={items}
                    columns={columns}
                />
            </Card>
        </>
    )
}

export default PackageView