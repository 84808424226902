import { useState } from "react";
import { Button, Card, Col, DatePicker, Form, Row, Select, Space, Typography } from "antd";
import { filterIncomExpenseReport } from "../../../data";
import moment from "moment";

const { Option } = Select
const { Text } = Typography
const { RangePicker } = DatePicker

const IncomeExpenseMobile = ({ header, branches, radio, format }) => {

    const [form] = Form.useForm()

    const [dataSource, setDataSource] = useState({})

    const handleSearch = async (values) => {
        const response = await filterIncomExpenseReport(values)
        if (response) {
            setDataSource(response)
        }
    }

    const renderFilter = (
        <Form form={form} onFinish={handleSearch}>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="date_range" label="Date Range" labelCol={{ span: 24 }}>
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Branches" name="branch_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Branches"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {branches.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} className="gutter-row">
                    <Space>
                        <Button htmlType="submit" type="primary">Search</Button>
                        <Button onClick={() => { form.resetFields(); form.submit() }}>Clear</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    )

    let items = radio == 1 ? dataSource.income : (radio == 2 ? dataSource.sales : dataSource.expense)

    return (
        <>
            {renderFilter}
            <br />
            {
                Object.keys(dataSource).length > 0 &&
                <>
                    {header}
                    <br /><br />

                    {
                        items.map(i => (
                            <>
                                <br />
                                <Card title={<Text style={{ fontWeight: 'bolder' }}>{moment(i.date).format(format)}</Text>} style={i.parent_id > 0 ? { background: '#FFCCCC' } : {}}>
                                    <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.branch_name}</span></Text>
                                    {
                                        radio != 2 &&
                                        <>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Description : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.reference}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Amount : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.amount}</span></Text>
                                        </>
                                    }
                                    {
                                        radio == 2 &&
                                        <>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Order Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.order_number}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Total Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.total_cost}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Sales : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.subtotal}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Gross Profit / Loss : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(i.subtotal - i.total_cost).toFixed(2)}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Shipping : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.shipping}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Discount : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.discount}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Total Sales : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.total}</span></Text>
                                            <br />
                                            <Text style={{ fontWeight: 'bolder' }}>Net Profit / Loss : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{(i.total - i.total_cost).toFixed(2)}</span></Text>
                                        </>
                                    }
                                </Card>
                            </>
                        ))
                    }

                </>
            }

        </>
    )
}

export default IncomeExpenseMobile