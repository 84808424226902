import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getUsers(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.users, 'users');
    } else {
        let users = await loadState('users');

        if (users == null) {
            return await loadResource(urlService.users, 'users');
        } else {
            return Response(users);
        }
    }
}

export async function getUser(id) {
    let users = await loadState('users');
    if (users !== null) {
        return users.find(user => user.id == id);
    } else {
        return ajaxService.get(urlService.users, { id: id }).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveUser(data) {
    setDependency()
    return ajaxService.post(urlService.users, data).then(response => {
        return Response(response.data);
    })
}

export async function updateUser(id, data) {
    return ajaxService.put(urlService.users + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteUser(id) {
    return ajaxService.delete(urlService.users + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function filterUser(data) {
    return await ajaxService.post(urlService.userFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function authUser() {
    ajaxService.removeDependency()
    return ajaxService.get(urlService.user).then(response => {
        return response.data;
    })
}

export async function getAbilities() {
    ajaxService.removeDependency()
    return ajaxService.get(urlService.abilities).then(response => {
        return Response(response.data);
    })
}



