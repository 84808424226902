import React, { useState, useEffect } from 'react';
import { Table, Space, Typography, Button, Modal, Avatar, notification, Switch, Card, Image } from 'antd';
import { useHistory } from "react-router-dom";
import { EditOutlined, CloseCircleOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { deleteBrand, getBranchBrands, getBrands, updateBrand } from '../../../data';
import windowSize from 'react-window-size';
import { PAGINATION_OPTIONS } from '../../../constants';
import { ajaxService } from '../../../services';
import { updateDataSource } from '../../../utility/updateDataSource';

const { Title, Text } = Typography;

function Brands({ windowWidth }) {

  const isMobile = windowWidth <= 768
  let history = useHistory();
  let branch_id = localStorage.getItem('branch_id')

  const [brands, setBrands] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(true);

  const updateValue = async (id, checked, type) => {

    const updatedBrands = updateDataSource(brands, id, checked);
    setBrands(updatedBrands);

    const active = await updateBrand(id, { type: type, value: checked })
    if (active) {
      setForceRefresh(true)
      if (checked === true) {
        notification.open({ message: 'Brand  activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      } else if (checked === false) {
        notification.open({ message: 'Brand  deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      }
    }
    else {
      notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <Avatar shape="square" size={64} src={image ? ajaxService.getImage(image) : null} />
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      hidden: true,
      render: (active, record) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={active == 1 ? true : false}
          onChange={(checked) => updateValue(record.id, checked, 'active')}
        />
      )

    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      hidden: true,
      render: (text, record) => (
        <Space size='small'>
          <Button onClick={() => history.push({ pathname: "/brands/form/" + record.id })}>Edit</Button>
          <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
        </Space>
      ),
    },

  ].filter(item => branch_id ? !item.hidden : item);


  const deleteRow = async () => {
    const brand_id = localStorage.getItem('brand')
    let data = await deleteBrand(brand_id);
    if (data) {
      setShowModal(false)
      setForceRefresh(true)
      notification.open({ message: 'Brand has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to deleted brand', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }

  const openDeleteModal = (id) => {
    localStorage.setItem('brand', id);
    setShowModal(true)
  }

  const addBrand = () => {
    history.push({ pathname: "/brands/form/0" });
  }

  const initialize = async () => {
    let data = [];

    if (branch_id) {
      data = await getBranchBrands(branch_id);
    } else {
      data = await getBrands(forceRefresh);
    }

    if (data) {
      setBrands(data);
      setForceRefresh(false)
    }
  }

  useEffect(() => {
    if (forceRefresh) {
      initialize();
      setForceRefresh(false)
    }
  }, [forceRefresh])

  const renderHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={3}>Brand</Title>
      </div>
      <Space size="small" >
        <Button style={{ display: branch_id ? 'none' : '' }} type="primary" onClick={() => history.push({ pathname: "/brands/form/0" })}>
          Add Brand
        </Button>
      </Space>
    </div>
  );

  const renderBrands = () => (
    <Table
      dataSource={brands}
      columns={columns}
      title={() => renderHeader()}
      pagination={{ ...PAGINATION_OPTIONS }}
    />
  )

  const renderBrandsMobile = () => (
    <>
      {
        brands.map(brand => (
          <>
            <br />
            <Card
              style={{ width: '100%' }}
              actions={[
                <Space size='small' style={{ display: branch_id ? 'none' : '' }}>
                  <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/brands/form/" + brand.id })}>Edit</Button>
                  <Button type='primary' icon={<DeleteOutlined />} onClick={() => openDeleteModal(brand.id)} danger>Delete</Button>
                </Space>
              ]}>
              <Image.PreviewGroup>
                <Image width={250} height={150} sizes="small" src={ajaxService.getImage(brand.image)} />
              </Image.PreviewGroup>
              <br />
              <br />
              <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{brand.name}</span></Text>
              <br />
              <Text style={{ fontWeight: 'bolder' }}>Active :
                <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                  <Switch
                    onChange={(val) => updateValue(brand.id, val, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={brand.active == 1 ? true : false} />
                </span>
              </Text>
              <br />
            </Card>
          </>
        ))
      }
    </>
  )

  const renderModal = () => {
    return (
      <Modal
        title="Delete Brand"
        centered
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" onClick={() => deleteRow()}>
            Delete
          </Button>
          ,
          <Button key="back" onClick={() => setShowModal(false)}>
            Close
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Are you sure you want to delete this brand?</p>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return (
        <>
          {renderHeader()}
          {renderBrandsMobile()}
        </>
      )
    }
    else {
      return renderBrands()
    }
  }

  return (
    <>
      {renderContent()}
      {renderModal()}
    </>
  )
}

export default windowSize(Brands)