import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Form, Input, Row, Space, message, notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getCustomer, getCustomers, saveCustomer, updateCustomer } from "../../data";
import windowSize from "react-window-size";
import { useState } from "react";
import customer from ".";

function CustomerForm({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm()
    let history = useHistory()
    let { id } = useParams()

    const [customers, setCustomers] = useState([])

    const onFinish = async (values) => {

        const notUnique = customers.find(i => i.phone == values.phone);

        if (notUnique) {
            return message.error("Duplicate Phone Number. Please provide a unique phone number.")
        }

        if (id > 0) {
            const response = await updateCustomer(id, values)
            if (response) {
                history.push({ pathname: '/customers-report' })
                notification.open({ message: 'Customer has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update customer', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const response = await saveCustomer(values)
            if (response) {
                history.push({ pathname: '/customers-report' })
                notification.open({ message: 'Customer has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.error(`Failed : ${errorInfo}`)
    }

    const loadCustomers = async () => {
        const branch_id = localStorage.getItem('branch_id') ?? 0
        const data = await getCustomers('?branch_id=' + branch_id);
        if (data) {
            setCustomers(data.filter(i => i.id != id));
        }
    }

    const loadData = async (id) => {
        if (id > 0) {
            const response = await getCustomer(id)
            if (response) {
                form.setFieldsValue(response)
            }
        }
    }

    useEffect(() => {
        loadData(id)
        loadCustomers()
    }, [])

    const renderCustomerForm = () => (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row gutter={16}>
                <Col span={6}></Col>
                <Col span={12}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
                                <Input placeholder="Enter Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Phone is required' }]}>
                                <Input placeholder="Enter Phone" type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Space>
                                <Button type="primary" htmlType="submit">Submit</Button>
                                <Button type="primary" onClick={() => history.goBack()}>Cancel</Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )

    const renderCustomerFormMobile = () => (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="name" label="Name">
                        <Input placeholder="Enter Name" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="phone" label="Phone">
                        <Input placeholder="Enter Phone" type="number" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Space>
                        <Button type="primary" htmlType="submit">Submit</Button>
                        <Button type="primary" onClick={() => history.goBack()}>Cancel</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderCustomerFormMobile()
        } else {
            return renderCustomerForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(CustomerForm)