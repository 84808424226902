import { Col, Form, Input, Row, Switch } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { getBranches } from "../../data";
import { useEffect, useState } from "react";

const EnableDelivery = ({ Setting, form }) => {

    const [forceRefresh, SetforceRefresh] = useState(true)

    let data;
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    const loadBranches = async () => {
        const loadData = await getBranches(forceRefresh);
        if (loadData) {
            let branches = [];
            loadData.map((item, index) => {
                branches.push({
                    name: item.name,
                    id: item.id,
                    active: data ? (data[index].active ? true : false) : false,
                    time: data ? (data[index].time ?? null) : null
                });
            })
            form.setFieldsValue({ branches_delivery: [...branches] })
            SetforceRefresh(false)
        }
    }

    useEffect(() => {
        loadBranches()
    }, [])

    return (
        <>
            <Row gutter={16}>
                <Form.List name="branches_delivery">
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <>
                                    <br />  <br />
                                    <Col span={12}>
                                        <Form.Item name={[name, 'name']}>
                                            <input style={{ border: 'none', background: 'none' }} type="text" disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="" name={[name, 'active']} valuePropName="checked">
                                            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item name={[name, 'time']}>
                                            <Input type="number" placeholder="Values in minutes" />
                                        </Form.Item>
                                    </Col>
                                </>
                            ))}

                        </>
                    )}
                </Form.List>
            </Row >
        </>
    )
}
export default EnableDelivery