import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Modal, Row, Space, Table, Typography } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import moment from "moment";
import { PAGINATION_OPTIONS } from "../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getBranchesLedger } from "../../data";
import { ajaxService } from "../../services";

const { Title, Text } = Typography

const format = 'DD-MMM-yyy'

const BranchLedger = () => {

    let history = useHistory()

    const { id } = useParams()

    const [showDetailsModal, setShowDetailsModal] = useState(false)

    const [ledger, setledger] = useState({})

    const [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'Date',
            key: 'created_at',
            render: (text, record) => (<Text>{moment(record.date ?? record.created_at).format(format)}</Text>)
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            render: (text, record) => (<Text>{record?.branch?.name ?? null}</Text>)
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes'
        },
        {
            title: 'Debit',
            key: 'Debit',
            render: (text, record) => (<Text>{record.cash_in == 0 ? record.amount : ''}</Text>)
        },
        {
            title: 'Credit',
            key: 'credit',
            render: (text, record) => (<Text>{record.cash_in == 1 ? record.amount : ''}</Text>)
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance) => (<Text>{balance.toFixed(2)}</Text>)
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <Space>
                    <Button type="primary" onClick={() => openDetailsModal(record.id)}>Details</Button>
                </Space>
            )

        },
    ]

    const openDetailsModal = (ledgerID) => {
        const storeLedger = dataSource.find(i => i.id == ledgerID)
        setledger(storeLedger)
        setShowDetailsModal(true)

    }

    const initialize = async () => {
        const response = await getBranchesLedger(`?branch_id=${id}`)
        if (response) {
            setDataSource(response)
        }
    }

    useEffect(() => {
        initialize();
    }, [])

    const renderLedgerTable = (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ ...PAGINATION_OPTIONS }}
            rowKey='id'
        />
    )

    const renderDetailsModal = (
        <Modal
            title="Details"
            centered
            open={showDetailsModal}
            footer={null}
            onCancel={() => setShowDetailsModal(false)}
            width='50%'
            style={{
                top: '20px',
            }}>
            <Row>
                <Row>
                    <Col span={12}><Title level={5}>Date</Title></Col>
                    <Col span={12}><Text>{moment(ledger.date ?? ledger.created_at).format(format)}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Branch</Title></Col>
                    <Col span={12}><Text>{ledger?.branch?.name ?? null}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Order Number</Title></Col>
                    <Col span={12}><Text>{ledger.order_number}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Transaction ID</Title></Col>
                    <Col span={12}><Text>{ledger.transaction_id}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Notes</Title></Col>
                    <Col span={12}><Text>{ledger.notes}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Debit</Title></Col>
                    <Col span={12}><Text>{ledger.cash_in == 0 ? ledger.amount : '0.00'}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Credit</Title></Col>
                    <Col span={12}><Text>{ledger.cash_in == 1 ? ledger.amount : '0.00'}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Balance</Title></Col>
                    <Col span={12}><Text>{ledger.balance ? (ledger.balance).toFixed(2) : null}</Text></Col>
                    <Divider style={{ marginTop: 10 }} />

                    <Col span={12}><Title level={5}>Attachment</Title></Col>
                    <Col span={12}><a href={ajaxService.getImage(ledger.attachment)} target="_blank" >{ledger.attachment ? ajaxService.getImage(ledger.attachment) : null}</a></Col>
                </Row>
            </Row>
        </Modal>
    )

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Branch Ledger</Title>
            <Button type="primary" onClick={() => history.push({ pathname: '/' })} icon={<ArrowLeftOutlined />}>Back To Dashboard</Button>
        </div>
    )

    return (
        <>
            <Card>
                {renderHeader}
                <br />
                {renderLedgerTable}
            </Card>
            {renderDetailsModal}
        </>
    )
}

export default BranchLedger