import React, { useEffect, useState } from 'react';
import { Typography, Button, Space, Table, Modal, notification, Card } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { deleteBranch } from '../../data';
import windowSize from 'react-window-size';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../../constants';
import { Limits } from '../../constant';
import LimitExceedModal from '../../utility/limit_exceed_modal';

const { Title, Text } = Typography;
const format = "h:mm A"

const Branch = ({ store, setForceRefresh, branchesLength, windowWidth }) => {

  const storeRestriction = store.store_restrictions ? store.store_restrictions : null;
  const branches = store?.branches;

  const [showModal, setShowModal] = useState(false);
  const isMobile = windowWidth <= 768;

  let history = useHistory();
  const store_id = localStorage.getItem('store_id');

  const columns = [
    {
      title: '#',
      key: 'sno',
      render: (text, object, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      key: 'address',
      render: (text, record) => (<Text>{record.address}, <span style={{ color: 'blue' }}>{record.country}</span></Text>)
    },
    {
      title: 'Contact',
      key: 'email',
      render: (text, record) => (
        <>
          <Text>{record.email}</Text>
          <br />
          <Text>{record.phone}</Text>
        </>
      )
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Opening time',
      dataIndex: 'opening_time',
      key: 'openning_time',
      render: (openning_time) => (<Text>{moment(openning_time, "h:mm:ss").format(format)}</Text>)
    },
    {
      title: 'Closing time',
      dataIndex: 'closing_time',
      key: 'closing_time',
      render: (closing_time) => (<Text>{moment(closing_time, "h:mm:ss").format(format)}</Text>)
    },
    {
      title: 'Main',
      dataIndex: 'is_main',
      key: 'is_main',
      render: (is_main) => <Text>{is_main ? 'Yes' : 'No'}</Text>
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active) => <Text>{active ? 'Yes' : 'No'}</Text>
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      with: 100,
      render: (text, record) => (
        <Space size='small'>
          <Button type="primary" onClick={() => history.push({ pathname: "/branches/ledger/" + record.id })}>View Ledger</Button>
          <Button onClick={() => onClick(record.id)}>Enter</Button>
          <Button onClick={() => history.push({ pathname: "/branches/" + store_id + "/form/" + record.id })}>Edit</Button>
          <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const deleteRow = async () => {
    let branch_id = localStorage.getItem('branch')
    const data = await deleteBranch(branch_id);

    if (data) {
      setShowModal(false)
      setForceRefresh(true)
      notification.open({ message: 'Branch has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to deleted branch', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }

  const openDeleteModal = (id) => {
    localStorage.setItem('branch', id);
    setShowModal(true)
  }

  const onClick = (id) => {
    localStorage.setItem('branch_id', id)
    history.push({ pathname: '/branch-dashboard' })
  }

  const handleAddBranch = () => {
    if (!storeRestriction || storeRestriction.find(i => i.type === Limits.BRANCH_LIMITS)?.value !== branchesLength) {
      history.push({ pathname: "/branches/" + store_id + "/form/0" });
    } else {
      LimitExceedModal()
    }
  }

  const renderHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Title level={3}>Branches</Title>
      <Space size="small" >
        <Button type="primary" onClick={handleAddBranch}>Add Branch</Button>
      </Space>
    </div>
  );

  const renderBranch = () => (
    <>
      <Table
        dataSource={branches}
        columns={columns}
        title={() => renderHeader()}
        pagination={{ ...PAGINATION_OPTIONS }}
        rowKey="id"
      />
    </>
  )

  const renderBranchMobile = () => (
    <>
      {
        branches.map(branch => {
          return (
            <>
              <Card title={branch.name} style={{ width: '100%', marginBottom: 10 }}
                actions={[
                  <>
                    <Space size='small'>
                      <Button type="primary" onClick={() => history.push({ pathname: "/branches/ledger/" + branch.id })}>View Ledger</Button>
                      <Button type='default' icon={<EyeOutlined />} onClick={() => onClick(branch.id)} >Enter</Button>
                    </Space>
                    <br /> <br />
                    <Space>
                      <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/branches/" + store_id + "/form/" + branch.id })}>Edit</Button>
                      <Button type='primary' icon={<DeleteOutlined />} onClick={() => openDeleteModal(branch.id)} danger>Delete</Button>
                    </Space>
                  </>
                ]}>
                <Text style={{ fontWeight: 'bolder' }}>Address : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.address}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Country : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.country}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Email : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.email}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Phone : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.phone}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Currency : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.currency}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Opening Time : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(branch.opening_time, "h:mm:ss").format(format)}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Closing Time : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(branch.closing_time, "h:mm:ss").format(format)}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Main : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.is_main ? 'Yes' : 'No'}</span></Text>
                <br />
                <Text style={{ fontWeight: 'bolder' }}>Active : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch.active ? 'Yes' : 'No'}</span></Text>
              </Card>
            </>
          );
        })
      }
    </>
  )

  const renderModal = () => {
    return (
      <Modal
        title="Delete Branch"
        centered
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" onClick={() => deleteRow()}>
            Delete
          </Button>
          ,
          <Button key="back" onClick={() => setShowModal(false)}>
            Close
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Are you sure you want to delete this branch?</p>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return (
        <>
          <Button type="primary" size='small' style={{ width: '100%', marginBottom: 10 }} onClick={handleAddBranch}>Add Branch</Button>
          {renderBranchMobile()}
        </>
      )
    } else {
      return renderBranch()
    }
  }

  return (
    <>
      {renderContent()}
      {renderModal()}
    </>
  );

}

export default windowSize(Branch)