import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Avatar, Layout, Menu } from 'antd';
import { getStoreSettings } from '../data/storeSetting-service';
import { PERMISSIONS } from '../constant/permissions';
import ControlSettings from '../constant/controlSettings';

import {
    LoginOutlined,
    MenuUnfoldOutlined,
    AimOutlined,
    ShoppingCartOutlined,
    SoundOutlined,
    ReadOutlined,
    AppstoreOutlined,
    AppstoreAddOutlined,
    DollarCircleOutlined,
    DownCircleOutlined,
    CarOutlined,
    UserOutlined,
    ControlOutlined,
    ArrowLeftOutlined,
    TeamOutlined,
    EnvironmentOutlined,
    SwapOutlined,
    UnorderedListOutlined,
    StockOutlined,
    BoxPlotOutlined,
    AlertOutlined,
    ExclamationCircleOutlined,
    FlagOutlined,
    CreditCardOutlined,
    LineChartOutlined,
    DeleteOutlined,
    AccountBookOutlined,
    QuestionCircleOutlined,
    CalendarOutlined,
    FileExcelOutlined,
    ReconciliationOutlined,
    BookOutlined,
} from '@ant-design/icons';
import {
    FaUsers,
    FaUserCog,
    FaIndustry,
    FaProductHunt,
    FaCartPlus,
    FaChartLine,
    FaExclamationTriangle,
    FaChartBar
} from 'react-icons/fa';
import MENAGEINVENTORYCOSTING from '../constant/manageInventoryCosting';

const { Header } = Layout;

const iconSize = 17;

// Selected Submenu Color
const submenuColor = "#173366"

export default function MainHeader() {

    const location = useLocation()
    let history = useHistory();
    // const navigate = useNavigate();

    let branchID = localStorage.getItem('branch_id')
    const [manageInventory, setManageInventory] = useState(0);
    const [permissions, setPermissions] = useState([])
    const [offerControlBranch, setOfferControlBranch] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(true);

    const [catalogueVisible, setCatalogueVisible] = useState(false);
    const [ecommerceVisible, setEcommerceVisible] = useState(false);
    const [manageInventoryVisible, setManageInventoryVisible] = useState(false);
    const [userManagementVisible, setUserManagementVisible] = useState(false);
    const [reportsVisible, setReportsVisible] = useState(false);
    const [profileVisible, setProfileVisible] = useState(false);

    const [currentMenu, setCurrentMenu] = useState('/');

    const onClick = (key) => {
        if (key == 'logout') {
            logout()
        } else {
            setCurrentMenu(key);
        }
    };

    // PERMISSIONS
    const checkPermission = (permission) => {
        return true;
        if (permissions && permissions.length > 0) {
            return permissions.includes(permission);
        }
    }

    const logout = () => {
        localStorage.clear();
        history.push({ pathname: '/' })
    }

    const loadStoreSettings = async () => {
        const response = await getStoreSettings(forceRefresh);
        if (response) {
            const manageInventoryData = response.find(i => i.slug === ControlSettings.MANAGE_INVENTORY);
            if (manageInventoryData) {
                setManageInventory({ active: manageInventoryData.active, ...JSON.parse(manageInventoryData.setting) })
            }

            const branchControlOfferData = response.find(i => i.slug === ControlSettings.OFFER_BRANCH_CONTROL);
            if (branchControlOfferData) {
                setOfferControlBranch(branchControlOfferData.active)
            }

            setForceRefresh(false)
        }
    }

    useEffect(() => {

        loadStoreSettings();

        const getPermissions = async () => {
            const perms = await localStorage.getItem('abilities')?.split(",");
            setPermissions(perms)
        }

        getPermissions();

        const catalogueItems = ['/brands', '/categories', '/products', '/importer', '/offer'];
        const profileItems = ['/profile'];
        const ecommerceItems = ['/pages', '/payment-methods', '/shipping-methods'];
        const manageInventoryItems = ['/purchase-orders', '/purchase-grn', '/suppliers', '/stock-transfer', '/damage-discard-products', '/reconciliations'];
        const userManagementItems = ['/roles', '/staff-managements', '/users'];
        const reportsItems = ['/customers-report', '/product-inventory-report', '/low-stock-report', '/out-of-stock-report', '/profit-and-loss-report', '/sales-income-expense-report', '/customize-sales-report', '/product-ledger-report', '/detailed-inventory-report', '/damage-discard-report', '/product-expiry-report'];

        const isCatalogueOpen = catalogueItems.some((item) => location.pathname.startsWith(item));
        const isProfileOpen = profileItems.some((item) => location.pathname.startsWith(item));
        const isEcommerceOpen = ecommerceItems.some((item) => location.pathname.startsWith(item));
        const isManageInventoryOpen = manageInventoryItems.some((item) => location.pathname.startsWith(item));
        const isUserManagementOpen = userManagementItems.some((item) => location.pathname.startsWith(item));
        const isReportsOpen = reportsItems.some((item) => location.pathname.startsWith(item));

        setCatalogueVisible(isCatalogueOpen)
        setProfileVisible(isProfileOpen);
        setEcommerceVisible(isEcommerceOpen);
        setManageInventoryVisible(isManageInventoryOpen);
        setUserManagementVisible(isUserManagementOpen);
        setReportsVisible(isReportsOpen);

        let path = location.pathname;

        if (path === '/') {
            setCurrentMenu('storesDasboard')
        } else if (isCatalogueOpen) {
            setCurrentMenu('catalogue')
        } else if (isEcommerceOpen) {
            setCurrentMenu('ecommerce')
        } else if (isManageInventoryOpen) {
            setCurrentMenu('manage_inventory')
        } else if (isUserManagementOpen) {
            setCurrentMenu('user_management')
        } else if (isReportsOpen) {
            setCurrentMenu('reports')
            if (branchID > 0) {
                setCurrentMenu('customers_report')
            }
        } else if (isProfileOpen) {
            setCurrentMenu('profileDropDown')
        }
        else {
            const menuPath = path.replace(/^\//, '').replace(/-/g, '_');

            const parts = menuPath.split("/");

            setCurrentMenu(parts[0])
        }

    }, [location]);

    const customers = ([
        checkPermission(PERMISSIONS.GET_ALL_CUSTOMERS) && {
            label: "Customers",
            key: 'customers_report',
            icon: <FaUsers size={iconSize} />,
            link: '/customers-report',
            onClick: () => history.push({ pathname: '/customers-report' }),
            color: location.pathname == '/customers-report' ? submenuColor : ''
        }
    ]);

    const offer = ([
        checkPermission(PERMISSIONS.GET_ALL_OFFERS) && {
            label: <p><span><SoundOutlined /> </span> Offers</p>,
            key: 'offer',
            link: '/offer',
            onClick: () => history.push({ pathname: '/offer' }),
            color: location.pathname.startsWith('/offer') ? submenuColor : ''
        }
    ])

    const catalogueChilds = ([
        checkPermission(PERMISSIONS.GET_ALL_BRANDS) && {
            label: <p><span><AimOutlined /></span> Brands</p>,
            key: 'brands',
            link: '/brands',
            onClick: () => history.push({ pathname: '/brands' }),
            color: location.pathname.startsWith('/brands') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_CATEGORIES) && {
            label: <p><span><ReadOutlined /></span> Categories</p>,
            key: 'categories',
            link: '/categories',
            onClick: () => history.push({ pathname: '/categories' }),
            color: location.pathname.startsWith('/categories') ? submenuColor : ''

        },
        checkPermission(PERMISSIONS.GET_ALL_PRODUCTS) && {
            label: <p><span><AppstoreOutlined /> </span> Products</p>,
            key: 'products',
            link: '/products',
            onClick: () => history.push({ pathname: '/products' }),
            color: location.pathname === '/products' ? submenuColor : ''
        },
        branchID > 0 ? (offerControlBranch ? { ...offer[0] } : '') : { ...offer[0] },
        !branchID &&
        {
            label: <p><span><FileExcelOutlined /> </span> Importer</p>,
            key: 'importer',
            link: '/importer',
            onClick: () => history.push({ pathname: '/importer' }),
            color: location.pathname.startsWith('/importer') ? submenuColor : ''
        },
    ]);

    const catalogue = ([
        (checkPermission(PERMISSIONS.GET_ALL_BRANDS) || checkPermission(PERMISSIONS.GET_ALL_CATEGORIES) || checkPermission(PERMISSIONS.GET_ALL_PRODUCTS)) && {
            label: branchID > 0 ? "Branch Catalogue " : "Admin Catalogue",
            key: 'catalogue',
            icon: <DownCircleOutlined />,
            children: catalogueVisible ? '' : catalogueChilds,
        }
    ])

    const ecommerceChilds = ([
        checkPermission(PERMISSIONS.GET_ALL_PAGES) && {
            label: <p><span><MenuUnfoldOutlined /> </span> Pages</p>,
            key: 'pages',
            link: '/pages',
            onClick: () => history.push({ pathname: '/pages' }),
            color: location.pathname.startsWith('/pages') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_PAYMENT_METHODS) && {
            label: <p><span><DollarCircleOutlined /> </span> Payment Methods</p>,
            key: 'payment_methods',
            link: '/payment-methods',
            onClick: () => history.push({ pathname: '/payment-methods' }),
            color: location.pathname.startsWith('/payment-methods') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_SHIPPING_METHODS) && {
            label: <p><span><CarOutlined /> </span> Shipping Methods</p>,
            key: 'shipping_methods',
            link: '/shipping-methods',
            onClick: () => history.push({ pathname: '/shipping-methods' }),
            color: location.pathname.startsWith('/shipping-methods') ? submenuColor : ''
        },
        {
            label: <p><span><BookOutlined /> </span> Landing Page</p>,
            key: 'landing-page',
            link: '/setting',
            onClick: () => history.push({ pathname: '/setting' }),
            color: location.pathname.startsWith('/setting') ? submenuColor : ''
        }
    ]);

    const useManagementChilds = ([
        checkPermission(PERMISSIONS.GET_ALL_ROLES) && {
            label: <p><span><FaUserCog size={iconSize} /> </span> Roles</p>,
            key: 'roles',
            link: '/roles',
            onClick: () => history.push({ pathname: '/roles' }),
            color: location.pathname.startsWith('/roles') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_STAFF_MANAGEMENTS) && {
            label: <p><span><TeamOutlined size={iconSize} /> </span> Staff</p>,
            key: 'staff_managements',
            link: '/staff-managements',
            onClick: () => history.push({ pathname: '/staff-managements' }),
            color: location.pathname.startsWith('/staff-managements') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_USERS) && {
            label: <p><span><FaUsers size={iconSize} /> </span> Users</p>,
            key: 'users',
            link: '/users',
            onClick: () => history.push({ pathname: '/users' }),
            color: location.pathname.startsWith('/users') ? submenuColor : ''
        },
    ]);

    const reportsWhenManageInventoryActive = ([
        checkPermission(PERMISSIONS.GET_ALL_PRODUCT_STOCKS) && {
            label: <p><span><StockOutlined size={iconSize} /> </span> Product Ledger</p>,
            key: 'product_ledger_report',
            link: '/product-ledger-report',
            onClick: () => history.push({ pathname: '/product-ledger-report' }),
            color: location.pathname.startsWith('/product-ledger-report') ? submenuColor : ''
        },
        (checkPermission(PERMISSIONS.DETAILED_INVENTORY) && manageInventory?.costing !== MENAGEINVENTORYCOSTING.AVERAGE) && {
            label: <p><span><UnorderedListOutlined size={iconSize} /> </span> Detailed Inventory</p>,
            key: 'detailed_inventory_report',
            link: '/detailed-inventory-report',
            onClick: () => history.push({ pathname: '/detailed-inventory-report' }),
            color: location.pathname.startsWith('/detailed-inventory-report') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.DAMAGE_DISCARD) && {
            label: <p><span><FaExclamationTriangle size={iconSize} /> </span> Damage & Discard</p>,
            key: 'damage_discard_report',
            link: '/damage-discard-report',
            onClick: () => history.push({ pathname: '/damage-discard-report' }),
            color: location.pathname.startsWith('/damage-discard-report') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.PRODUCTS_EXPIRY_REPORT) && {
            label: <p><span><CalendarOutlined size={iconSize} /> </span> Products Expiry Report</p>,
            key: 'product_expiry_report',
            link: '/product-expiry-report',
            onClick: () => history.push({ pathname: '/product-expiry-report' }),
            color: location.pathname.startsWith('/product-expiry-report') ? submenuColor : ''
        },
    ]);

    const reportsChilds = ([
        ...customers,
        checkPermission(PERMISSIONS.PRODUCT_INVENTORY) && {
            label: <p><span><BoxPlotOutlined size={iconSize} /> </span> Product Inventory</p>,
            key: 'product_inventory_report',
            link: '/product-inventory-report',
            onClick: () => history.push({ pathname: '/product-inventory-report' }),
            color: location.pathname == '/product-inventory-report' ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.PRODUCT_INVENTORY) && {
            label: <p><span><AlertOutlined size={iconSize} /> </span> Low Stock</p>,
            key: 'low_stock_report',
            link: '/low-stock-report',
            onClick: () => history.push({ pathname: '/low-stock-report' }),
            color: location.pathname == '/low-stock-report' ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.OUT_OF_STOCK) && {
            label: <p><span><ExclamationCircleOutlined size={iconSize} /> </span> Out Of Stock</p>,
            key: 'out_of_stock_report',
            link: '/out-of-stock-report',
            onClick: () => history.push({ pathname: '/out-of-stock-report' }),
            color: location.pathname == '/out-of-stock-report' ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.PROFIT_LOSS_STATEMENTS) && {
            label: <p><span><LineChartOutlined size={iconSize} /> </span> Profit & Loss Statements</p>,
            key: 'profit_and_loss_report',
            link: '/profit-and-loss-report',
            onClick: () => history.push({ pathname: '/profit-and-loss-report' }),
            color: location.pathname == '/profit-and-loss-report' ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.INCOME_EXPENSE_REPORT) && {
            label: <p><span><AccountBookOutlined size={iconSize} /> </span> Sales, Income & Expense</p>,
            key: 'sales_income_expense_report',
            link: '/sales-income-expense-report',
            onClick: () => history.push({ pathname: '/sales-income-expense-report' }),
            color: location.pathname == '/sales-income-expense-report' ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.CUSTOMIZE_SALES) && {
            label: <p><span><FaChartBar size={iconSize} /> </span> Customize Sales Report</p>,
            key: 'customize_sales_report',
            link: '/customize-sales-report',
            onClick: () => history.push({ pathname: '/customize-sales-report' }),
            color: location.pathname == '/customize-sales-report' ? submenuColor : ''
        },

    ]);

    if (manageInventory.active) {
        reportsChilds.push(...reportsWhenManageInventoryActive);
    }

    const storeManageInventoryChilds = ([
        checkPermission(PERMISSIONS.GET_ALL_PURCHASE_ORDERS) && {
            label: <p><span><FaCartPlus size={iconSize} /> </span> Purchase Orders</p>,
            key: 'purchase_orders',
            link: '/purchase-orders',
            onClick: () => history.push({ pathname: '/purchase-orders' }),
            color: location.pathname.startsWith('/purchase-orders') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_PURCHASE_GRN) && {
            label: <p><span><FaProductHunt size={iconSize} /> </span> Received Goods (GRN)</p>,
            key: 'purchase_grn',
            link: '/purchase-grn',
            onClick: () => history.push({ pathname: '/purchase-grn' }),
            color: location.pathname.startsWith('/purchase-grn') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_SUPPLIERS) && {
            label: <p><span><FaIndustry size={iconSize} /> </span> Suppliers</p>,
            key: 'suppliers',
            link: '/suppliers',
            onClick: () => history.push({ pathname: '/suppliers' }),
            color: location.pathname.startsWith('/suppliers') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_STOCK_TRANSFERS) && {
            label: <p><span><SwapOutlined size={iconSize} /> </span> Stock Transfer</p>,
            key: 'stock_transfer',
            link: '/stock-transfer',
            onClick: () => history.push({ pathname: '/stock-transfer' }),
            color: location.pathname.startsWith('/stock-transfer') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_ALL_DAMAGE_DISCARD_PRODUCT) && {
            label: <p><span><DeleteOutlined size={iconSize} /> </span> Damage & Discard</p>,
            key: 'damage_discard_products',
            link: '/damage-discard-products',
            onClick: () => history.push({ pathname: '/damage-discard-products' }),
            color: location.pathname.startsWith('/damage-discard-products') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.VIEW_PRICE_BOOK) && {
            label: <p><span><AppstoreOutlined /> </span> Pricebook</p>,
            key: 'pricebook',
            link: '/products/priceBook',
            onClick: () => history.push({ pathname: '/products/priceBook' }),
            color: location.pathname === '/products/priceBook' ? submenuColor : ''
        },        
    ]);

    const branchManageInventoryChilds = ([
        (manageInventory.purchase_order && checkPermission(PERMISSIONS.GET_ALL_PURCHASE_ORDERS)) && {
            label: <p><span><FaCartPlus size={iconSize} /> </span> Purchase Orders</p>,
            key: 'purchase_orders',
            link: '/purchase-orders',
            onClick: () => history.push({ pathname: '/purchase-orders' }),
            color: location.pathname.startsWith('/purchase-orders') ? submenuColor : ''
        },
        (manageInventory.purchase_grn && checkPermission(PERMISSIONS.GET_ALL_PURCHASE_GRN)) && {
            label: <p><span><FaProductHunt size={iconSize} /> </span> Received Goods (GRN)</p>,
            key: 'purchase_grn',
            link: '/purchase-grn',
            onClick: () => history.push({ pathname: '/purchase-grn' }),
            color: location.pathname.startsWith('/purchase-grn') ? submenuColor : ''
        },
        (manageInventory.supplier_ledger && manageInventory.supplier) && checkPermission(PERMISSIONS.GET_ALL_SUPPLIERS) && {
            label: <p><span><FaIndustry size={iconSize} /> </span> Suppliers</p>,
            key: 'suppliers',
            link: '/suppliers',
            onClick: () => history.push({ pathname: '/suppliers' }),
            color: location.pathname.startsWith('/suppliers') ? submenuColor : ''
        },
        checkPermission(PERMISSIONS.GET_All_RECONCILIATIONS) && {
            label: <p><span><ReconciliationOutlined size={iconSize} /> </span> Reconciliations</p>,
            key: 'reconciliations',
            link: '/reconciliations',
            onClick: () => history.push({ pathname: '/reconciliations' }),
            color: location.pathname.startsWith('/reconciliations') ? submenuColor : ''
        }
    ]);

    const manageInventoryChilds = branchID ? branchManageInventoryChilds : storeManageInventoryChilds;

    const profileChilds = ([
        {
            label: <p><span><UserOutlined size={iconSize} /> </span> Profile</p>,
            key: 'profile',
            link: '/profile',
            onClick: () => history.push({ pathname: '/profile' }),
            color: location.pathname.startsWith('/profile') ? submenuColor : ''
        },
        {
            label: "Logout",
            key: 'logout',
            icon: <LoginOutlined />,
        },
    ])

    const branchItems = [
        {
            label: <Link href="/">Back to Store</Link>,
            key: 'backToStore',
            icon: <ArrowLeftOutlined />,
            onClick: () => { localStorage.removeItem('branch_id'); history.push({ pathname: '/' }) }
        },
        checkPermission(PERMISSIONS.STORE_DASHBOARD) && {
            label: "Dashboard",
            key: 'branch_dashboard',
            icon: <ShoppingCartOutlined />,
            onClick: () => history.push({ pathname: '/branch-dashboard' }),
        },
        ...catalogue,
        checkPermission(PERMISSIONS.GET_ALL_ORDERS) && {
            label: "Orders",
            key: 'orders',
            icon: <ShoppingCartOutlined />,
            onClick: () => history.push({ pathname: '/orders' }),
        },
        checkPermission(PERMISSIONS.GET_ALL_BANNERS) && {
            label: "Banner",
            key: 'banners',
            icon: <FlagOutlined />,
            onClick: () => history.push({ pathname: '/banners' }),
        },
        checkPermission(PERMISSIONS.GET_ALL_ACCOUNTS) && {
            label: "Account",
            key: 'accounts',
            icon: <CreditCardOutlined />,
            onClick: () => history.push({ pathname: '/accounts' }),
        },
        ...customers,
        checkPermission(PERMISSIONS.GET_ALL_TABLE_MANAGEMENTS) && {
            label: "Table Management",
            key: 'table_managements',
            icon: <EnvironmentOutlined />,
            onClick: () => history.push({ pathname: '/table-managements' }),
        },
        manageInventory.active && (manageInventory.purchase_order || manageInventory.purchase_grn || manageInventory.supplier) &&
        (checkPermission(PERMISSIONS.GET_ALL_PAGES) || checkPermission(PERMISSIONS.GET_ALL_PAYMENT_METHODS) || checkPermission(PERMISSIONS.GET_ALL_SHIPPING_METHODS)) && {
            label: "Manage Inventory",
            key: 'manage_inventory',
            icon: <DownCircleOutlined />,
            children: manageInventoryVisible ? '' : manageInventoryChilds,
        }
    ]

    function reportsPermission() {
        return (
            checkPermission(PERMISSIONS.GET_ALL_CUSTOMERS) ||
            checkPermission(PERMISSIONS.VIEW_PRODUCT_LEDGER) ||
            checkPermission(PERMISSIONS.DETAILED_INVENTORY) ||
            checkPermission(PERMISSIONS.DAMAGE_DISCARD) ||
            checkPermission(PERMISSIONS.PRODUCT_INVENTORY) ||
            checkPermission(PERMISSIONS.OUT_OF_STOCK) ||
            checkPermission(PERMISSIONS.PROFIT_LOSS_STATEMENTS) ||
            checkPermission(PERMISSIONS.CUSTOMIZE_SALES) ||
            checkPermission(PERMISSIONS.INCOME_EXPENSE_REPORT)
        );
    }

    const storeItems = [
        checkPermission(PERMISSIONS.GET_ALL_STORES) && {
            label: "Store",
            key: 'storesDasboard',
            icon: <AppstoreAddOutlined />,
            onClick: () => history.push({ pathname: '/' }),
        },
        ...catalogue,
        (checkPermission(PERMISSIONS.GET_ALL_PAGES) || checkPermission(PERMISSIONS.GET_ALL_PAYMENT_METHODS) || checkPermission(PERMISSIONS.GET_ALL_SHIPPING_METHODS)) && {
            label: "eCommerce",
            key: 'ecommerce',
            icon: <DownCircleOutlined />,
            children: ecommerceVisible ? '' : ecommerceChilds,
        },
        manageInventory.active ? (
            (checkPermission(PERMISSIONS.GET_ALL_PAGES) || checkPermission(PERMISSIONS.GET_ALL_PAYMENT_METHODS) || checkPermission(PERMISSIONS.GET_ALL_SHIPPING_METHODS)) && {
                label: "Manage Inventory",
                key: 'manage_inventory',
                icon: <DownCircleOutlined />,
                children: manageInventoryVisible ? '' : manageInventoryChilds,
            }
        ) : (
            (checkPermission(PERMISSIONS.UPDATE_STORE_SETTING)) && {
                label: "Manage Inventory",
                key: 'stock_update',
                onClick: () => history.push({ pathname: '/stock-update' }),
                // onClick: () => history.push({ pathname: '/stock-update' }),
                icon: <FaChartLine />,
            }
        ),
        (checkPermission(PERMISSIONS.GET_ALL_ROLES) || checkPermission(PERMISSIONS.GET_ALL_STAFF_MANAGEMENTS) || checkPermission(PERMISSIONS.GET_ALL_USERS)) && {
            label: "User Management",
            key: 'user_management',
            icon: <DownCircleOutlined />,
            children: userManagementVisible ? '' : useManagementChilds,
        },
        reportsPermission() && {
            label: "Reports",
            key: 'reports',
            icon: <DownCircleOutlined />,
            children: reportsVisible ? '' : reportsChilds,
        },
        checkPermission(PERMISSIONS.GET_ALL_STORE_SETTING) && {
            label: "Store Settings",
            key: 'store_settings',
            icon: <ControlOutlined />,
            onClick: () => history.push({ pathname: '/store-settings' }),
        }
    ]

    const conditionalizeItems = branchID ? branchItems : storeItems;

    const MainItems = [
        ...conditionalizeItems,
        {
            label: <Link to="/help" >Help</Link>,
            key: 'help',
            icon: <QuestionCircleOutlined />,
            style: branchID > 0 ? {} : { textAlign: 'right', width: '21%' }
        },
        {
            label: <Avatar icon={<UserOutlined />} />,
            key: 'profileDropDown',
            children: profileVisible ? '' : profileChilds,
        },
    ]

    const items = () => {
        if (catalogueVisible) return catalogueChilds;
        if (profileVisible) return profileChilds;
        if (ecommerceVisible) return ecommerceChilds;
        if (manageInventoryVisible) return manageInventoryChilds;
        if (userManagementVisible) return useManagementChilds;
        if (reportsVisible && branchID == null) return reportsChilds;
        return [''];
    }

    const subMenuOnclick = (item) => {
        if (item.key == 'logout') {
            logout()
        } else {
            history.push({ pathname: item.link })
        }
    }

    function renderMenuItems(items) {
        return items
            .filter(Boolean) // Remove null values from the array
            .map((item,index) => (
                <li key={index} className="menu-item">
                    <a href={item.link} style={{ color: item.color }}>{item.icon} {item.label}</a>
                </li>
            ));
    }

    return (
        <>
            <Header>
                <Menu
                    mode="horizontal"
                    items={MainItems}
                    selectedKeys={[currentMenu]}
                    defaultSelectedKeys={['stores']}
                    onClick={(e) => onClick(e.key)}
                    style={{ background: '#003366', color: '#FFF' }}
                />
            </Header>
            {/* Sub Menu */}
            <ul className="custom-sub-menu" >
                {renderMenuItems(items())}
            </ul>
        </>
    )
}