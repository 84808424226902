function generateEAN13Barcode(number) {
    if (!/^\d{12}$/.test(number)) {
        console.error("Invalid EAN-13 barcode input. It should be a 12-digit number.");
        return null;
    }

    var sum = 0;
    var oddSum = 0;
    var evenSum = 0;
    var digits = number.split('').map(Number);

    for (var i = 0; i < digits.length; i++) {
        if (i % 2 === 0) {
            oddSum += digits[i];
        } else {
            evenSum += digits[i];
        }
    }

    sum = oddSum + evenSum * 3;
    var checkDigit = (10 - (sum % 10)) % 10;
    var barcode = number + checkDigit;

    return barcode;
}

export const handleGenerate = async (sku, form) => {

    let randomSKU;

    do {
        randomSKU = Math.floor(Math.random() * 10 ** 12).toString();
    } while (sku.includes(randomSKU));

    form.setFieldsValue({ sku: generateEAN13Barcode(randomSKU) });
}