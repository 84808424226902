import { Button, Card, Col, Empty, Form, Input, Radio, Row, Select, Space, Table, Typography } from "antd"
import { useEffect, useState } from "react"
import { filterProductsExpiryReport, getBranches, getProducts, getProductsExpiryReport } from "../../data"
import { PAGINATION_OPTIONS } from "../../constants"
import windowSize from "react-window-size"

const { Option } = Select
const { Title, Text } = Typography

const columns = [
    {
        title: 'Expiry Date',
        dataIndex: 'expiry_date',
        key: 'expiry_date',
    },
    {
        title: 'Branch',
        dataIndex: 'branch_name',
        key: 'branch_name',
    },
    {
        title: 'Product',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'GRN #',
        dataIndex: 'grn_number',
        key: 'grn_number',
    },
    {
        title: 'Days Remaining',
        dataIndex: 'grn_number',
        key: 'grn_number',
        render : (a , data) => {
            const today = new Date();
            const expiryDate = new Date(data.expiry_date);
            const differenceMs = expiryDate - today;
            const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
            return differenceDays;
        }
    },    
    {
        title: 'Available Stock',
        dataIndex: 'available_stock',
        key: 'available_stock',
        width: '13%',
        render: (available_stock) => <center><Text>{available_stock}</Text></center>
    },
]

const ProductsExpiryReport = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm();

    const [radio, setRadio] = useState(0)
    const [forceRefresh, setForceRefresh] = useState(true)
    const [loading, setLoading] = useState(false)

    const [days, setDays] = useState(30)
    const [selectType, setSelectType] = useState(0)

    const [dataSource, setDataSource] = useState([])
    const [branches, setBranches] = useState([])
    const [products, setProducts] = useState([])

    const handleSearch = async (type) => {

        setLoading(true)

        if (type === "clear") {
            form.resetFields();
            setRadio(0)
            // setSelectType(0)
            // setDays(1)
        }

        const values = form.getFieldsValue()

        const data = { ...values, days: days, selectType: selectType }

        const response = await filterProductsExpiryReport(data)

        if (response) {
            setDataSource(response)
        } else {
            setDataSource([])
        }
        setLoading(false)
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    const loadProducts = async () => {
        const response = await getProducts(forceRefresh)
        if (response) {
            setProducts(response.filter(i => !i.is_combo))
            setForceRefresh(false)
        }
    }

    const initialize = async () => {
        const response = await getProductsExpiryReport()
        if (response) {
            setDataSource(response)
        } else {
            setDataSource([])
        }
    }

    useEffect(() => {
        initialize()
        loadBranches()
        loadProducts()
    }, [])

    const renderHeader = <Title level={3}>Products Expiry</Title>

    const renderFilter = (
        <>
            <Form form={form} layout={'vertical'}>
                <Row gutter={16} >
                    {/* <Col span={isMobile ? 24 : 6}>
                        <Form.Item label="Product" name="product_id" labelCol={{ span: 24 }}>
                            <Select
                                showSearch allowClear
                                style={{ width: '100%' }}
                                placeholder="Select Product"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {products.map(({ id, name }, i) => (
                                    <Option key={'product-' + i} value={id}>{name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col span={isMobile ? 24 : 6}>
                        <Form.Item label="Branch" name="branch_id" labelCol={{ span: 24 }}>
                            <Select
                                showSearch allowClear
                                style={{ width: '100%' }}
                                placeholder="Select branch"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {branches.map(({ id, name }, i) => (
                                    <Option key={'branch-' + i} value={id}>{name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* <Col span={isMobile ? 24 : 5}>
                        <Form.Item label={isMobile ? "" : " "} name="type" labelCol={{ span: 24 }} initialValue={0}>
                            <Radio.Group onChange={e => setRadio(e.target.value)} defaultValue={0}>
                                <Radio value={0}>Expired Products</Radio>
                                <Radio value={1}>Days Wise</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col> */}

                    <Col span={isMobile ? 24 : 7}>
                        <Form.Item label={isMobile ? "" : " "} labelCol={{ span: 24 }}>
                            <Input.Group compact>
                                <Select
                                    style={{ width: '45%' }}
                                    // disabled={radio === 1 ? false : true}
                                    defaultValue={selectType}
                                    onChange={(e) => setSelectType(e)}
                                >
                                    <Option value={0}>Expires within days</Option>
                                    <Option value={1}>Expires after days</Option>
                                </Select>
                                <Input type="number"
                                    defaultValue={days}
                                    style={{ width: '30%' }}
                                    // disabled={radio === 1 ? false : true}
                                    onChange={(e) => setDays(e.target.value)}
                                />
                            </Input.Group>
                        </Form.Item>
                    </Col>

                    <Col span={isMobile ? 24 : 7}>
                        <Form.Item label={' '}>
                            <Space>
                                <Button onClick={() => handleSearch("search")}>Search</Button>
                                <Button onClick={() => handleSearch("clear")}>Clear</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <br />
        </>
    )

    const renderProductExpiry = (
        <>
            {renderFilter}
            <Table
                title={() => renderHeader}
                dataSource={dataSource}
                columns={columns}
                pagination={{ ...PAGINATION_OPTIONS }}
                loading={loading}
            />
        </>

    )

    const renderProductExpiryMobile = (
        <>
            {

                dataSource.length > 0 ? (
                    dataSource.map(i => (
                        <>
                            <br />
                            <Card>
                                <Text style={{ fontWeight: 'bolder' }}>Expiry Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.expiry_date}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.branch_name}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Product : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.name}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>GRN # : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.grn_number}</span></Text>
                                <br />
                                <Text style={{ fontWeight: 'bolder' }}>Available Stock :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}> {i.available_stock}</span></Text>
                            </Card>
                        </>
                    ))
                ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
            }
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return (
                <>
                    {renderFilter}
                    {renderHeader}
                    {renderProductExpiryMobile}
                </>
            )
        } else {
            return renderProductExpiry
        }
    }

    return renderContent()

}

export default windowSize(ProductsExpiryReport)