import { getStore } from "../data";

let store = {};

async function fetchIndustryData() {
    const storeId = localStorage.getItem('store_id');

    if (storeId) {
        try {
            const response = await getStore(storeId);
            if (response) {
                store = response;
            }
        } catch (error) {
            console.error('Error fetching industry data:', error);
        }
    }
}

fetchIndustryData();

const IndustrySettings = {
    COMBO: false,
    GENERICNAME: false,
    ADDON: false,
    CUISINE: false,
    EXPIRYDATE: false,
    SRB: false,
    FBR: false,
};

// Use a Promise to wait for the data to be fetched
const industryDataPromise = new Promise((resolve) => {
    const interval = setInterval(() => {
        if (Object.keys(store).length !== 0) {
            // Once the 'store' object is populated, resolve the promise
            clearInterval(interval);
            resolve(store);
        }
    }, 200);
});

// Use the Promise to update the IndustrySettings object
industryDataPromise.then((data) => {
    Object.assign(IndustrySettings, {
        COMBO: data.industry?.combo ?? false,
        GENERICNAME: data.industry?.generic_name ?? false,
        ADDON: data.industry?.addon ?? false,
        CUISINE: data.industry?.cuisine ?? false,
        EXPIRYDATE: data.industry?.expiry_date ?? false,
        SRB: data.industry?.srb ?? false,
        FBR: data.industry?.fbr ?? false,
    });
});

export default IndustrySettings;
