import { Col, Row, Form, Switch } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const ManageCustomers = ({ Setting }) => {

    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (

        <Row gutter={16}>

            <Col span={12}>
                <Form.Item name='customersLedger' label="Customers Ledger"
                    labelCol={{ span: 24 }}
                    initialValue={data?.customersLedger}>
                    <Switch
                        style={{ marginLeft: 10 }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data?.customersLedger} />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name='email_registration' label="Email Registration"
                    labelCol={{ span: 24 }}
                    initialValue={data?.email_registration}>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={data?.email_registration} />
                </Form.Item>
            </Col>
        </Row>

    )
}

export default ManageCustomers