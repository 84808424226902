import React, { useEffect } from 'react';
import { Form, Input, Button, notification, Row, Col, DatePicker, Space } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getRole, saveRole, updateRole } from '../../data';
import windowSize from 'react-window-size';

const RoleForm = ({ windowWidth }) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const isMobile = windowWidth <= 768
  let { id } = useParams();


  const onFinish = async (values) => {

    let data = { 
      name: values.title.replace(/\s+/g, '-').toLowerCase(), 
      title: values.title 
    }

    if (id == 0) {
      const saveData = await saveRole(data);
      if (saveData) {
        history.push({ pathname: "/roles" });
        notification.open({ message: 'Role has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      } else {
        notification.open({ message: 'Unable to add role', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      }

    } else {
      const updateData = await updateRole(id, data);
      if (updateData) {
        history.push({ pathname: "/roles" });
        notification.open({ message: 'Role has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      } else {
        notification.open({ message: 'Unable to update role', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      }
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const loadData = async (id) => {
    if (id > 0) {
      let data = await getRole(id);
      if (data) {
        form.setFieldsValue({ ...data });
      }
    }
  }

  useEffect(() => {
    loadData(id);
  }, []);

  const renderRoleForm = () => {
    return (
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}>
        <Row gutter={16} >
          <Col span={6}></Col>
          <Col span={12} >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name='title' label="Title" rules={[
                  {
                    required: true,
                    message: 'Title field is required',
                  },
                ]}>
                  <Input placeholder="Title" />
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* <Form.Item name='name' label="Name" rules={[
                  {
                    required: true,
                    message: 'Name field is required',
                  },
                ]}>
                  <Input placeholder="Name" readOnly={true} />
                </Form.Item> */}
              </Col>
            </Row>
            <Row>
              <Form.Item>
                <Space>
                  <Button htmlType="submit" type="primary">Submit</Button>
                  <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                </Space>
              </Form.Item>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
      </Form>
    );
  }

  const renderRoleFormMobile = () => {
    return (
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}>
        <Row gutter={16} >
          <Col span={24}>
            <Form.Item name='title' label="Title" rules={[
              {
                required: true,
                message: 'Title field is required',
              },
            ]}>
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='name' label="Name" rules={[
              {
                required: true,
                message: 'Name field is required',
              },
            ]}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Space>
                <Button htmlType="submit" type="primary">Submit</Button>
                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }


  const renderContent = () => {
    if (isMobile) {
      return renderRoleFormMobile()
    } else {
      return renderRoleForm()
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )

};


export default windowSize(RoleForm)