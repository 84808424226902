
import { ajaxService, urlService } from '../services';
import { Response } from "../utility";

const removeDependency = () => { ajaxService.removeDependency() }
const setDependency = () => { ajaxService.setDependency() }

export async function exportProductSampleExcel() {
    removeDependency()
    const url = ajaxService.generateUrl(urlService.exportSampleProductExcel);
    return url.replace("v1/api/", "");
}

export async function importProductsExcel(data) {
    removeDependency()
    return ajaxService.post(urlService.uploadProductsExcel, data).then(response => {
        return Response(response.data);
    })
}

export async function exportStockSampleExcel(branch_id) {
    setDependency()
    const url = ajaxService.generateUrl(urlService.exportSampleStockExcel + '/' + branch_id);
    return url.replace("v1/api/", "");
}

export async function importStokcsExcel(branch_id, data) {
    setDependency()
    return ajaxService.post(urlService.uploadStocksExcel + '/' + branch_id, data).then(response => {
        return Response(response.data);
    })
}

export async function exportProductListingExcel(branch_id) {
    setDependency()
    const url = ajaxService.generateUrl(urlService.exportListingProductExcel + '/' + branch_id);
    return url.replace("v1/api/", "");
}

export async function exportAppBackupExcel(branch_id) {
    setDependency()
    const url = ajaxService.generateUrl(urlService.exportAppBackupExcel);
    return url.replace("v1/api/", "");
}