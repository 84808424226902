import React, { useEffect, useState } from "react";
import { Button, Card, DatePicker, Divider, Empty, Form, Input, Modal, Select, Space, Table, Typography, notification } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useLocation, useParams } from "react-router-dom";
import { getBranches, getLedger, saveLedger } from "../data";
import { PAGINATION_OPTIONS } from "../constants";
import windowSize from "react-window-size";
import moment from "moment";

const { Option } = Select
const { Title, Text } = Typography
const { TextArea } = Input

const format = 'DD-MMM-yyy'

function Ledger({ windowWidth }) {

    const isMobile = windowWidth <= 768
    const location = useLocation()
    const { id } = useParams()
    const [form] = Form.useForm()
    let branch_id = localStorage.getItem('branch_id') ?? 0

    const [data, setData] = useState([])
    const [ledger, setledger] = useState([])
    const [branches, setBranches] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true)
    const [type, setType] = useState('')

    const columns = [
        {
            title: 'Date',
            key: 'created_at',
            render: (text, record) => (<Text>{moment(record.date ?? record.created_at).format(format)}</Text>)
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            hidden: true,
        },
        {
            title: 'Description',
            dataIndex: 'reference',
            key: 'reference'
        },
        {
            title: 'Debit',
            key: 'Debit',
            render: (text, record) => (<Text>{record.cash_in == 0 ? record.amount : ''}</Text>)
        },
        {
            title: 'Credit',
            key: 'credit',
            render: (text, record) => (<Text>{record.cash_in == 1 ? record.amount : ''}</Text>)
        },
        {
            title: 'Balance',
            key: 'balance',
            render: (text, { balance, panding_amount }) => <Text>{branch_id ? balance.toFixed(2) : panding_amount}</Text>
        },
    ].filter(item => branch_id ? !item.hidden : item)

    const onFinish = async (values) => {

        let data = {
            branch_id: values.branch_id ?? branch_id,
            type: type,
            id: id,
            amount: values.amount,
            date: values.date,
            reference: values.reference,
            param: location.search
        }

        const response = await saveLedger(data)
        if (response) {
            setShowModal(false);
            initialize(id)
            form.resetFields()
            notification.open({
                message: type === 'credit' ? 'Receipt has been done' : 'Payment has been done',
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />
            })
        } else {
            notification.open({ message: 'Unable to add any transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const handleClick = (type) => {
        setShowModal(true)
        setType(type)
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }


    const initialize = async (id) => {
        const response = await getLedger(`${id}${location.search}=ledger&branch_id=${branch_id}`)
        if (response) {
            let ledgerData = response.ledger.filter(i => i.amount > 0)
            setledger(ledgerData.slice().reverse())
            setData(response)
        }
    }

    useEffect(() => {
        initialize(id)
        loadBranches()
    }, [])

    const renderLedgerTable = () => (
        <Table
            dataSource={ledger}
            columns={columns}
            pagination={{ ...PAGINATION_OPTIONS }}
        />
    )

    const renderModal = () => (
        <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Modal
                title={type === 'credit' ? 'Receipt (CR)' : 'Payment (DR)'}
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="submit" onClick={form.submit}>
                        Submit
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                {
                    !branch_id &&
                    <Form.Item label="Branch" name="branch_id" labelCol={{ span: 24 }} initialValue={branch_id !== 0 ? branch_id : null}
                        rules={[
                            {
                                required: true,
                                message: "Branch is required"
                            }
                        ]}
                    >
                        <Select placeholder="Select branch">
                            {branches.map((branch) => (
                                <Option value={branch.id}>{branch.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                }
                <Form.Item label="Amount" name="amount" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Amount is required"
                        }
                    ]}
                >
                    <Input type="number" step={0.01} placeholder="Enter amount" />
                </Form.Item>
                <Form.Item label="Date" name="date" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Date is required"
                        }
                    ]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Description" name="reference" labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: "Description is required"
                        }
                    ]}
                >
                    <TextArea rows={3} placeholder="Enter description" />
                </Form.Item>
            </Modal>
        </Form >
    )

    const renderButtons = (
        <div style={!isMobile ? { display: 'flex', flexDirection: 'row-reverse' } : {}}>
            <Space style={{ marginRight: '10px' }}>
                <Button
                    type="primary" danger
                    onClick={() => handleClick('debit')}
                >
                    Payment (DR)
                </Button>
                <Button type="primary"
                    onClick={() => handleClick('credit')}
                >
                    Receipt (CR)
                </Button>
            </Space>
        </div>
    )

    const renderHeader = (
        <Title level={3}>{location.search == '?supplier' ? 'Supplier Ledger' : "Customer Ledger"}</Title>
    )

    const renderDetails = (
        <>
            <Text><b>{location.search == '?supplier' ? 'Supplier' : "Customer"} Name : </b> </Text>
            <Text>{data.name}</Text>
            <br />
            <Text><b>Phone Number : </b></Text>
            <Text>{data.phone}</Text>
            <br />
            <Text><b>Email : </b> </Text>
            <Text>{data.email}</Text>
        </>
    )

    const renderLedger = () => (
        <>
            <Card>
                {renderHeader}
                <br />
                {renderDetails}
                {renderButtons}
                <br />
                {renderLedgerTable()}
            </Card>
        </>
    )

    const renderLedgerMobile = () => (
        <>
            {renderHeader}
            {renderButtons}
            <br />
            <Card>
                <Divider plain>Details</Divider>
                {renderDetails}
                <Divider plain>Ledger</Divider>

                {
                    ledger.length > 0 ? (

                        ledger.map(l => (
                            <>
                                <Card>
                                    <Text style={{ fontWeight: 'bolder' }}>Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{moment(l.date ?? l.created_at).format(format)}</span></Text>
                                    <br />
                                    {
                                        !branch_id &&
                                        <>
                                            <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{l.branch_name}</span></Text>
                                            <br />
                                        </>
                                    }
                                    <Text style={{ fontWeight: 'bolder' }}>Description : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{l.reference}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Debit : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{l.cash_in == 0 ? l.amount : ''}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Credit : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{l.cash_in == 1 ? l.amount : ''}</span></Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Balance : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{branch_id ? l.balance.toFixed(2) : l.panding_amount}</span></Text>
                                    <br />
                                </Card>
                                <br />
                            </>
                        ))
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                }

            </Card>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderLedgerMobile()
        }
        else {
            return renderLedger()
        }
    }

    return (
        <>
            {renderContent()}
            {renderModal()}
        </>
    )


}

export default windowSize(Ledger)