import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getPurchaseOrders(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.purchaseOrders, 'purchaseOrders');
    } else {
        let purchaseOrders = await loadState('purchaseOrders');

        if (purchaseOrders == null) {
            return await loadResource(urlService.purchaseOrders, 'purchaseOrders');
        } else {
            return Response(purchaseOrders);
        }
    }
}

export async function getPurchaseOrder(id) {
    setDependency()
    return ajaxService.get(urlService.purchaseOrders + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function savePurchaseOrder(data) {
    setDependency()
    return ajaxService.post(urlService.purchaseOrders, data).then(response => {
        return Response(response.data);
    })
}

export async function updatePurchaseOrder(id, data) {
    setDependency()
    return ajaxService.put(urlService.purchaseOrders + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deletePurchaseOrder(id) {
    return ajaxService.delete(urlService.purchaseOrders + '/' + id).then(response => {
        return Response(response.data);
    })
}