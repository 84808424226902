import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Typography, Modal, notification, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { deleteOffer, getOffers } from '../../data';
import windowSize from 'react-window-size';
import { PAGINATION_OPTIONS } from '../../constants';
const { Title, Text } = Typography;


function Offer({ windowWidth }) {

    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(true);
    const isMobile = windowWidth <= 768

    const branch_id = localStorage.getItem('branch_id')

    const history = useHistory();

    const columns = [

        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',

        },

        {
            title: 'Start date',
            dataIndex: 'start_date',
            key: 'start_date',

        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',

        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',

        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: "/offer/form/" + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            ),
        },
    ];

    const initialize = async () => {
        const data = await getOffers(forceRefresh);

        const branchWiseData = data.filter(offer =>
            offer.offer_branches.some(branch => branch.branch_id == branch_id)
        );

        setDataSource(branch_id > 0 ? branchWiseData : data);
        setForceRefresh(false)
    }

    const deleteRow = async () => {
        const offer_id = localStorage.getItem('offer')
        let response = await deleteOffer(offer_id);
        if (response) {
            setShowModal(false)
            setForceRefresh(true)
            notification.open({ message: 'Offer has been deleted successfully', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to delete offer...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

        }
    }
    const openDeleteModal = (id) => {
        localStorage.setItem('offer', id);
        setShowModal(true)
    }

    useEffect(() => {
        initialize();
    }, [])

    const renderHeader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title level={3}>Offers</Title>
                <Button type="primary" onClick={() => history.push({ pathname: "/offer/form/0" })} >Add Offer</Button>
            </div>
        )
    }

    const renderModal = () => {
        return (
            <Modal
                title="Delete Offer"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>
                    ,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this offer?</p>
                </div>
            </Modal>
        )
    }

    const renderOffer = () => {
        return (
            <div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                    pagination={{ ...PAGINATION_OPTIONS }}
                />
            </div>
        )
    }

    const renderOfferMobile = () => {
        return (
            <>
                {
                    dataSource.map(offer => {
                        return (
                            <>
                                <br />
                                <Card
                                    style={{ width: '100%' }}
                                    actions={[
                                        <Space size='small'>
                                            <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: "/offer/form/" + offer.id })}>Edit</Button>
                                            <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(offer.id)}>Delete</Button>
                                        </Space>
                                    ]}>
                                    <Text style={{ fontWeight: 'bolder' }}>Title :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{offer.title}</span>
                                    </Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Start Date :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{offer.start_date}</span>
                                    </Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>Start Time :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{offer.start_time}</span>
                                    </Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>End Date :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{offer.end_date}</span>
                                    </Text>
                                    <br />
                                    <Text style={{ fontWeight: 'bolder' }}>End time :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{offer.end_time}</span>
                                    </Text>
                                    <br />
                                </Card>
                                <br />
                            </>
                        );
                    })
                }
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderOfferMobile()
        } else {
            return renderOffer()
        }
    }

    return (
        <>
            {isMobile && renderHeader()}
            {renderContent()}
            {renderModal()}
        </>
    )


}
export default windowSize(Offer)