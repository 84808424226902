import { useEffect, useState } from "react"
import { Button, Card, Col, Divider, Form, Input, Row, Select, Space, Typography, notification } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useHistory } from "react-router-dom"
import { getBranchProducts, getBranches, getStoreSetting, saveStockTransfer } from "../../data"
import { SwapOutlined, CloseCircleOutlined, CheckCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import windowSize from "react-window-size"
import ControlSettings from "../../constant/controlSettings"
import MENAGEINVENTORYCOSTING from "../../constant/manageInventoryCosting"
import dataService from "../../services/data-service"

const { Option } = Select
const { Text } = Typography

const StockTransferForm = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    let history = useHistory()
    const [form] = Form.useForm()

    const [branches, setBranches] = useState([])
    const [products, setProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [productTransfers, setProductTransfers] = useState([]);

    const [manageInventory, setManageInventory] = useState({});
    const [fromBranch, setFromBranch] = useState(0)
    const [forceRefresh, setForceRefresh] = useState(true)

    const addTransferProduct = (item, quantity) => {

        let existingItem = productTransfers.find(i => i.product_id === item.product_id && i.grn_number === item.grn_number);

        if (existingItem == null) {
            item.quantity = quantity;
            setProductTransfers([...productTransfers, item])
        } else {
            let updatedItems = productTransfers.map(i => {
                if (i.product_id === item.product_id && i.grn_number === item.grn_number) {
                    i.quantity = quantity
                }
                return i;
            });

            setProductTransfers([...updatedItems])
        }
    }

    const onFinish = async (values) => {
        let data = {
            from_branch: values.form_branch,
            to_branch: values.to_branch,
            description: values.description,
            stocks: productTransfers
        }

        const response = await saveStockTransfer(data)

        if (response) {
            history.push({ pathname: "/stock-transfer" });
            notification.open({ message: 'Stock transfer has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to add stock transfer', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed: ', errorInfo)
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(response)
        }
    }

    const handleSelectedProducts = () => {

        form.resetFields(['products'])
        const response = products.filter(i => i.id === selectedProducts);

        if (response) {
            let data = [];
            if (manageInventory.active && manageInventory.costing == MENAGEINVENTORYCOSTING.AVERAGE) {
                data = response.map(product => {
                    const productStockArray = product.product_stock;
                    product.product_stock = [productStockArray[productStockArray.length - 1]];
                    return product
                });
            } else {

                const currentTransferredProducts = form.getFieldValue('transferredProducts') || [];
                data = [...new Set([...currentTransferredProducts, ...products.filter(i => i.id === selectedProducts).filter(newResponse => !currentTransferredProducts.some(existingResponse => existingResponse.id === newResponse.id))])];
            }
            form.setFieldsValue({ transferredProducts: [...data] });
        }
    }

    const handleProducts = async (e) => {
        if (e) {
            setFromBranch(e)
            const response = await dataService.getShortProducts({ branch_id: e , stock : 1 });
            if (response && response.length > 0) {
                setProducts(response.filter(i => i.stock > 0))
            }
        }
    }

    const manageInventorySetting = async () => {
        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);
        if (response) {
            setManageInventory({ active: response.active, ...JSON.parse(response.setting) })
        }
    }

    useEffect(() => {
        loadBranches()
        manageInventorySetting()
    }, [])

    const renderStockTransferForm = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} key="col" >
                        <Row gutter={16} key="row">
                            <Col span={11}>
                                <Form.Item name='form_branch' label="From Branch" rules={[
                                    {
                                        required: true,
                                        message: 'From branch is required',
                                    },
                                ]}>
                                    <Select placeholder="Select From Branch" onChange={(e) => handleProducts(e)} allowClear>
                                        {
                                            branches.map(({ id, name }) => (
                                                <Option key={id} value={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Form.Item label=" ">
                                    <SwapOutlined />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item name='to_branch' label="To Branch" rules={[
                                    {
                                        required: true,
                                        message: 'To branch is required',
                                    }
                                ]}>
                                    <Select placeholder="Select To Branch" allowClear>
                                        {
                                            branches.map(({ id, name }) => (
                                                <Option key={id} value={id} disabled={fromBranch === id ? true : false}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={23}>
                                <Form.Item name='description' label="Description">
                                    <TextArea rows={3} placeholder="Description" />
                                </Form.Item>
                            </Col>

                            <Divider />

                            <Col span={12}>
                                <Form.Item name='products' label="Products"
                                // rules={[{ required: true, message: 'Products is required' }]} 
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        // mode="multiple"
                                        placeholder="Select Product"
                                        onChange={setSelectedProducts}
                                        style={{
                                            width: '100%',
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {
                                            products.map(({ id, name }) => (
                                                <Option value={id} key={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label=" ">
                                    <Button onClick={handleSelectedProducts}>Add</Button>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                {
                                    <>
                                        <Form.List name="transferredProducts">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => {

                                                        let items = form.getFieldsValue().transferredProducts
                                                        let item = items.find((i, x) => x === index)
                                                        let stocks = item.product_stock.filter(i => i.available_stock > 0)

                                                        return (
                                                            <>
                                                                <Divider style={{ margin: 0, position: 'relative', top: 10 }} />
                                                                <Row gutter={16} key={"row-" + key}>
                                                                    <Col span={17}>
                                                                        <Space>
                                                                            <Text><b>Product Name : </b></Text>
                                                                            <Form.Item label=" " name={[name, 'name']}>
                                                                                <input disabled className="productDropDown" style={{ marginBottom: 5 }} />
                                                                            </Form.Item>
                                                                        </Space>
                                                                        <div style={{ position: 'absolute', right: '5px', bottom: '28px' }}>
                                                                            <DeleteOutlined style={{ color: 'red', cursor: 'pointer', marginLeft: 10 }} onClick={() => remove(name)} />
                                                                        </div>
                                                                        {/* <DeleteOutlined style={{ color: 'red', cursor: 'pointer', }} onClick={() => remove(name)} /> */}
                                                                    </Col>
                                                                </Row>

                                                                <Row style={{ margin: "10px 5px" }} gutter={16} key={"rowGrn-" + key}>
                                                                    {
                                                                        manageInventory.costing !== MENAGEINVENTORYCOSTING.AVERAGE &&
                                                                        <Col span={5}>
                                                                            <Text><b>GRN</b></Text>
                                                                        </Col>
                                                                    }
                                                                    <Col span={5} >
                                                                        <Text><b>Available Qty</b></Text>
                                                                    </Col>
                                                                    <Col span={5} >
                                                                        <Text><b>Price</b></Text>
                                                                    </Col>
                                                                    <Col span={5} >
                                                                        <Text><b>Transfer Qty</b></Text>
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    stocks.map((item, index) => (
                                                                        <Row style={{ margin: "10px 5px" }} gutter={16} key={"stock-" + index}>
                                                                            {manageInventory.costing !== MENAGEINVENTORYCOSTING.AVERAGE &&
                                                                                <Col span={5}><Text>{item.grn_number}</Text></Col>
                                                                            }
                                                                            <Col span={5}><Text>{item.available_stock}</Text></Col>
                                                                            <Col span={5}><Text>{item.retail_price}</Text></Col>
                                                                            <Col span={5}>
                                                                                <Input type="number" onChange={(e) => addTransferProduct(item, e.target.value)} />
                                                                            </Col>
                                                                        </Row>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </Form.List >
                                    </>
                                }
                            </Col>

                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        </>
    )

    const renderStockTransferFormMobile = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={12} key="row">
                    <Col span={24}>
                        <Form.Item name='form_branch' label="From Branch" rules={[
                            {
                                required: true,
                                message: 'From branch is required',
                            },
                        ]}>
                            <Select placeholder="Select From Branch" onChange={(e) => handleProducts(e)} allowClear>
                                {
                                    branches.map(({ id, name }) => (
                                        <Option key={id} value={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={1}>
                        <Form.Item label=" ">
                            <SwapOutlined />
                        </Form.Item>
                    </Col> */}
                    <Col span={24}>
                        <Form.Item name='to_branch' label="To Branch" rules={[
                            {
                                required: true,
                                message: 'To branch is required',
                            }
                        ]}>
                            <Select placeholder="Select To Branch" allowClear>
                                {
                                    branches.map(({ id, name }) => (
                                        <Option key={id} value={id} disabled={fromBranch === id ? true : false}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='description' label="Description">
                            <TextArea rows={3} placeholder="Description" />
                        </Form.Item>
                    </Col>

                    <Divider />

                    <Col span={20}>
                        <Form.Item name='products' label="Products">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Select Product"
                                onChange={setSelectedProducts}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {
                                    products.map(({ id, name }) => (
                                        <Option value={id} key={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label=" ">
                            <Button onClick={handleSelectedProducts}><PlusOutlined /></Button>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        {
                            <>
                                <Form.List name="transferredProducts">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }, index) => {

                                                let items = form.getFieldsValue().transferredProducts
                                                let item = items.find((i, x) => x === index)
                                                let stocks = item.product_stock.filter(i => i.available_stock > 0)

                                                return (
                                                    <>
                                                        <br />
                                                        <Card headStyle={{ height: 5 }} title={[
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Form.Item name={[name, 'name']}>
                                                                    <input disabled className="productDropDown" style={{ fontWeight: 'bolder', marginBottom: 20 }} />
                                                                </Form.Item>
                                                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => remove(name)} />
                                                            </div>
                                                        ]}>
                                                            {
                                                                stocks.map((item, index) => (
                                                                    <>

                                                                        {
                                                                            manageInventory.costing !== MENAGEINVENTORYCOSTING.AVERAGE &&
                                                                            <>
                                                                                <Text style={{ fontWeight: 'bolder' }}>Grn :
                                                                                    <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.grn_number}</span>
                                                                                </Text>
                                                                                <br />
                                                                            </>
                                                                        }
                                                                        <Text style={{ fontWeight: 'bolder' }}>Available Qty :
                                                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.available_stock}</span>
                                                                        </Text>
                                                                        <br />
                                                                        <Text style={{ fontWeight: 'bolder' }}>Price :
                                                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.retail_price}</span>
                                                                        </Text>
                                                                        <br />
                                                                        <Text style={{ fontWeight: 'bolder' }}>Transfer Qty :
                                                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                                                <Input type="number" style={{ width: "50%" }} onChange={(e) => addTransferProduct(item, e.target.value)} />
                                                                            </span>
                                                                        </Text>
                                                                        <Divider />
                                                                    </>
                                                                ))
                                                            }
                                                        </Card>
                                                    </>
                                                )
                                            })}
                                        </>
                                    )}
                                </Form.List >
                            </>
                        }
                    </Col>

                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type="primary">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Row>

            </Form >
        </>
    )

    return isMobile ? renderStockTransferFormMobile() : renderStockTransferForm()

}

export default windowSize(StockTransferForm)