import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getCuisines(refresh = false) {
    ajaxService.removeDependency()
    if (refresh) {
        return await loadResource(urlService.cuisines, 'cuisines');
    } else {
        let cuisines = await loadState('cuisines');

        if (cuisines == null) {
            return await loadResource(urlService.cuisines, 'cuisines');
        } else {
            return Response(cuisines);
        }
    }
}

export async function getCuisine(id) {
    let cuisines = await loadState('cuisines');
    if (cuisines !== null) {
        return cuisines.find(cuisine => cuisine.id == id);
    } else {
        return ajaxService.get(urlService.cuisines + '/' + id).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveCuisine(data) {
    setDependency()
    return ajaxService.post(urlService.cuisines, data).then(response => {
        return Response(response.data);
    })
}

export async function updateCuisine(id, data) {
    setDependency()
    return ajaxService.put(urlService.cuisines + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteCuisine(id) {
    setDependency()
    return ajaxService.delete(urlService.cuisines + '/' + id).then(response => {
        return Response(response.data);
    })
}