import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getTableManagements(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.tableManagements, 'tableManagements');
    } else {
        let tableManagements = await loadState('tableManagements');

        if (tableManagements == null) {
            return await loadResource(urlService.tableManagements, 'tableManagements');
        } else {
            return Response(tableManagements);
        }
    }
}

export async function getTableManagement(id) {
    let tableManagements = await loadState('tableManagements');
    if (tableManagements !== null) {
        return tableManagements.find(tableManagement => tableManagement.id == id);
    } else {
        return ajaxService.get(urlService.tableManagements, { id: id }).then(response => {
            return Response(response.data);
        })
    }
}

export async function saveTableManagement(data) {
    setDependency()
    return ajaxService.post(urlService.tableManagements, data).then(response => {
        return Response(response.data);
    })
}

export async function updateTableManagement(id, data) {
    setDependency()
    return ajaxService.put(urlService.tableManagements + '/' + id, data).then(response => {
        return Response(response.data);
    })
}

export async function deleteTableManagement(id) {
    setDependency()
    return ajaxService.delete(urlService.tableManagements + '/' + id).then(response => {
        return Response(response.data);
    })
}