export const DOMAIN = process.env.REACT_APP_API_ENDPOINT;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBBbVqoUH1W8HjNDGM5fXE3tRmAY_jqSjE';

export const PAGINATION_OPTIONS = {
    current: 1,
    pageSize: 50,
    defaultCurrent: 1,
    defaultPageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: ['50', '100', '200', '500', '1000'],
    // hideOnSinglePage: true,
    total: 10,
    position: ['topRight', 'bottomRight'],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    // size : 'small',
    // simple : true,
}
