import { Button, Col, Form, Input, notification, Row, Select, Space, Switch } from "antd"
import { CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from "react"
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getPaymentMethod, savePaymentMethod, updatePaymentMethod } from "../../data";
import windowSize from "react-window-size";

const { TextArea } = Input;

const PaymentMethodForm = ({ windowWidth }) => {
    let history = useHistory()
    const [form] = Form.useForm()
    const isMobile = windowWidth <= 768
    let { id } = useParams()

    const onFinish = async (values) => {
        let data = {
            name: values.name,
            service_charges: values.service_charges,
            description: values.description,
            settings: values.settings,
            active: values.active
        }

        if (id == 0) {
            const saveData = await savePaymentMethod(data)
            if (saveData) {
                history.push({ pathname: "/payment-methods" });
                notification.open({ message: 'Payment method has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add payment method', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const updateData = await updatePaymentMethod(id, data)
            if (updateData) {
                history.push({ pathname: "/payment-methods" });
                notification.open({ message: 'Payment method has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to update payment method', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    const loadData = async (id) => {
        if (id > 0) {
            const data = await getPaymentMethod(id)
            if (data) {
                form.setFieldsValue({ ...data })
            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    const renderPaymentMethod = () => {
        return (
            <>
                <Form
                    layout={'vertical'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >
                        <Col span={6}></Col>
                        <Col span={12} >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Payment Type" name={'name'} rules={[
                                        {
                                            required: true,
                                            message: 'Payment type is required',
                                        }]}>
                                        {/* <Select showSearch allowClear
                                        placeholder={"Select Payment Type"}
                                        onChange={(e) => setPaymentMethod(e)}>
                                        <Option value={'Paypal'} key={"paypal"}>Paypal</Option>
                                        <Option value={'WaafiPay'} key={"waafiPay"}>WaafiPay</Option>
                                        <Option value={'Mobile Payment'} key={"mobile-payment"}>Mobile Payment</Option>
                                        <Option value={'Debit & Credit Card'} key={"debit-credit-card"}>Debit & Credit Card</Option>
                                        <Option value={'Cash On Delivery'} key={"cash-on-delivery"}>Cash On Delivery</Option>
                                    </Select> */}
                                        <Input placeholder="Payment Method" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Service Charges" name='service_charges' rules={[
                                        {
                                            required: true,
                                            message: 'Service charges is required',
                                        }]}>
                                        <Input placeholder="Service Charges" type="number" step={0.01} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Description" name='description' rules={[
                                        {
                                            required: true,
                                            message: 'Description is required',
                                        }]}>
                                        <TextArea rows={3} placeholder="Description" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Settings" name='settings' rules={[
                                        {
                                            required: true,
                                            message: 'Settings is required',
                                        }]}>
                                        <Input placeholder="Settings" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="active" label="Active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                    </Space>
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </>
        )
    }

    const renderPaymentMethodMobile = () => {
        return (
            <>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    initialValues={{
                        active: true
                    }}>
                    <Row gutter={16} >
                        <Col span={24}>
                            <Form.Item label="Payment Type" name={'name'} rules={[
                                {
                                    required: true,
                                    message: 'Payment type is required',
                                }]}>
                                <Input placeholder="Payment Method" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Service Charges" name='service_charges' rules={[
                                {
                                    required: true,
                                    message: 'Service charges is required',
                                }]}>
                                <Input placeholder="Service Charges" type="number" step={0.01} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Description" name='description' rules={[
                                {
                                    required: true,
                                    message: 'Description is required',
                                }]}>
                                <TextArea rows={3} placeholder="Description" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Settings" name='settings' rules={[
                                {
                                    required: true,
                                    message: 'Settings is required',
                                }]}>
                                <Input placeholder="Settings" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="active" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderPaymentMethodMobile()
        } else {
            return renderPaymentMethod()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}
export default windowSize(PaymentMethodForm)