import { Col, Row, Form, Input } from "antd";

const WhatsappMessaging = ({ Setting }) => {

    let data
    if (Setting == null) {
        Setting = ''
        data = Setting
    }
    else {
        data = JSON.parse(Setting)
    }

    return (

        <Row gutter={16}>

            <Col span={24}>
                <Form.Item name='wa_url' label="Url"
                    labelCol={{ span: 24 }}
                    initialValue={data?.wa_url}>
                    <Input style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name='wa_token' label="Token"
                    labelCol={{ span: 24 }}
                    initialValue={data?.wa_token}>
                    <Input style={{ width: '100%' }} />
                </Form.Item>
            </Col>
        </Row>

    )
}

export default WhatsappMessaging