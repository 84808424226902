import { Card, DatePicker, Typography, Form, Row, Select, Col, Space, Button, Divider } from "antd"
import React, { useEffect, useState } from "react"
import { getBranches, getProfitLossStatements } from "../../data"
import windowSize from "react-window-size"

const { RangePicker } = DatePicker
const { Title, Text } = Typography
const { Option } = Select

const ProfitLossStatements = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    const [form] = Form.useForm()

    const [branches, setBranches] = useState([])

    const [summary, setSummary] = useState({})

    const [forceRefresh, setForceRefresh] = useState(true)

    const handleSearch = async (values) => {
        const response = await getProfitLossStatements(values)
        if (response) {
            setSummary(response)
        }
    }

    const loadBranches = async () => {
        let response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        loadBranches()
    }, [])

    const renderFilter = () => (
        <Form form={form} onFinish={handleSearch}>
            <Row gutter={16}>
                <Col span={isMobile ? 24 : 6}>
                    <Form.Item name="date_range" label="Date Range" labelCol={{ span: 24 }}>
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col span={isMobile ? 24 : 6}>
                    <Form.Item label="Branches" name="branch_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch
                            allowClear
                            // mode='multiple'
                            style={{ width: '100%' }}
                            placeholder="Select Branches"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {branches.map(({ id, name }) => {
                                return <Option value={id} key={id}>{name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={isMobile ? 24 : 4} className="gutter-row">
                    <Form.Item label={isMobile ? '' : <Text>&nbsp;</Text>} labelCol={{ span: 24 }}>
                        <Space>
                            <Button htmlType="submit">Search</Button>
                            <Button onClick={() => { form.resetFields(); setSummary({}) }}>Clear</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )

    return (
        <>
            {renderFilter()}
            <Card>
                <Title level={3}>Profit & Loss Statements </Title>

                {
                    Object.keys(summary).length > 0 &&
                    <>
                        <div style={{ marginTop: 25 }}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Title level={5}>Sales :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.sales ?? 0}</h3>
                                </Col>

                                <Col span={12}>
                                    <Title level={5}>Sales return :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.sales_return ?? 0}</h3>
                                </Col>

                                <Divider />

                                <Col span={12}>
                                    <Title level={5}>Net sales:</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.net_sales ?? 0}</h3>
                                </Col>

                                <Divider />

                                <Col span={12}>
                                    <Title level={5}>Cost of goods sold :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.cost ?? 0}</h3>
                                </Col>

                                <Col span={12}>
                                    <Title level={5}>Cost of goods return :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.cost_return ?? 0}</h3>
                                </Col>

                                <Divider />

                                <Col span={12}>
                                    <Title level={5}>Net cost of goods sold :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.net_cost ?? 0}</h3>
                                </Col>

                                <Divider />
                                <Divider />

                                <Col span={12}>
                                    <Title level={5}>Gross profit :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.gross_profit ?? 0}</h3>
                                </Col>

                                <Divider />

                                <Col span={12}>
                                    <Title level={5}>Other incomes :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.income ?? 0}</h3>
                                </Col>

                                <Col span={12}>
                                    <Title level={5}>Other Expenses :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.expense ?? 0}</h3>
                                </Col>

                                <Divider />

                                <Col span={12}>
                                    <Title level={5}>Operating profit :</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <h3>{summary.profit ?? 0}</h3>
                                </Col>
                                <Divider />
                            </Row>
                        </div>
                    </>
                }
            </Card >
        </>
    )
}

export default windowSize(ProfitLossStatements)