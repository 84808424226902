import { useEffect, useState, useRef } from "react"
import { Button, Card, Col, Row, Select, Steps, Typography, message, notification, Modal, Upload, Popconfirm } from "antd"
import { CheckCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { exportProductListingExcel, exportProductSampleExcel, exportStockSampleExcel, getBranches, getStoreSetting, importProductsExcel, importStokcsExcel } from "../../data"
import { ajaxService } from "../../services";
import ControlSettings from "../../constant/controlSettings";
import MENAGEINVENTORYCOSTING from "../../constant/manageInventoryCosting";

const { Title } = Typography
const { Option } = Select

function Importer() {

    const anchorRef = useRef(null);

    const [forceRefresh, setForceRefresh] = useState(true)
    const [showImportModal, setImportModal] = useState(false);
    const [manageInventory, setManageInventory] = useState(false);

    const [selectedBranch, setSelectedBranch] = useState(0);
    const [selectedBranchForListing, setSelectedBranchForListing] = useState(0);
    const [type, setType] = useState('');

    const [branches, setBranches] = useState([]);
    const [fileList, setFileList] = useState([]);

    const errorMessage = 'Please select a branch before performing any stock actions.';

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        setFileList(fileList);
    }

    const resetState = () => {
        setImportModal(false);
        setFileList([]);
    };

    const downloadSampleExcel = async (type) => {

        let url = "";

        if (type === "stock") {

            if (selectedBranch === 0) {
                return message.error(errorMessage)
            }
            url = await exportStockSampleExcel(selectedBranch);

        } else if (type === 'productListing') {
            url = await exportProductListingExcel(selectedBranchForListing);
        } else {
            url = await exportProductSampleExcel();
        }
        anchorRef.current.href = url;

        anchorRef.current.click();

    }

    const beforeUpload = file => {
        const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
        if (['.xls', '.xlsx'].includes(fileExtension)) {
            setFileList([file]); // Add the Excel file to the fileList
            return true;
        } else {
            message.error('Please upload a valid Excel file.');
            return false;
        }
    };

    const handleImport = async (type) => {

        if (fileList.length > 0) {

            let link = '';
            const { success, path } = fileList[0].response || {};
            if (success) link = path;

            let response = {};
            if (type === 'stocks') {

                if (selectedBranch === 0) {
                    return message.error(errorMessage)
                }

                response = await importStokcsExcel(selectedBranch, { url: link })
            } else {
                response = await importProductsExcel({ url: link })
            }

            if (response) {
                resetState();
                notification.open({ message: `The ${type} has been successfully imported.`, icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            return message.error("Please select an Excel file to upload. Only Excel files (.xlsx, .xls) are allowed for upload.")
        }
    }

    const menageInventorySetting = async () => {
        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);
        if (response) {
            setManageInventory({ active: response.active, ...JSON.parse(response.setting) })
        }
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        loadBranches()
        menageInventorySetting()
    }, [])

    const renderHeader = <Title level={3}>Importer</Title>

    const renderContentHeadings = (heading) => (
        <>
            <Title level={5}>{heading}</Title>
            <hr />
        </>
    )

    const renderProductUpload = (
        <>
            {renderContentHeadings('Product Upload')}
            <br />
            <Steps current={5}
                items={[
                    { title: 'Step 1', description: 'In the sample excel file, first row is a sample data. before uploading delete that row other wise it would be uploaded as product.' },
                    { title: 'Step 2', description: 'This is a description.' },
                    { title: 'Step 3', description: 'This is a description.' },
                ]}
            />
            <br />
            <Row gutter={16}>
                <Col span={8}>
                    <Card>
                        <Popconfirm
                            title="Download Product Sample"
                            description="Do you want to download this sample?"
                            onConfirm={() => downloadSampleExcel('product')}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" style={{ width: '100%' }}>Download Sample</Button>
                        </Popconfirm>
                        {/* <label>Note: </label>
                        <p>In the sample excel file, first row is a sample data. before uploading delete that row other wise it would be uploaded as product.</p> */}
                    </Card>
                </Col>

                <Col span={8}>
                    <Card>
                        <Button type="primary" style={{ width: '100%' }} onClick={() => { setImportModal(true); setType('products') }}>Import Products</Button>
                    </Card>
                </Col>

                <Col span={8}>
                    <Card>
                        <Button type="primary" style={{ width: '100%' }}>Import Zip</Button>
                    </Card>
                </Col>
            </Row>
        </>
    )

    const renderImportStock = (
        <>
            {renderContentHeadings('Import Stock')}
            <br />
            <Steps
                current={5}
                items={[
                    { title: 'Step 1', description: 'This is a description.' },
                    {
                        title: 'Step 2',
                        description: manageInventory.active ? "Please ensure that all null columns are filled before uploading the Excel file. Otherwise, your data might be incomplete and lead to issues." : 'Please note that if you enter values in both the Cost Price and Selling Price columns, it will update the corresponding pricing information for the product.'
                    }
                ]}
            />
            <br />

            <center>
                <Select onChange={(value) => setSelectedBranch(value)} style={{ width: '300px' }} placeholder="Select Branch">
                    {branches.map(branch => (
                        <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                    ))}
                </Select>
            </center>
            <br />

            <Row gutter={16}>
                <Col span={12}>
                    <Card>
                        <Popconfirm
                            title="Download Stock Sample"
                            description="Do you want to download this sample?"
                            onConfirm={() => downloadSampleExcel('stock')}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" style={{ width: '100%' }}>Download Sample</Button>
                        </Popconfirm>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card>
                        <Button type="primary" style={{ width: '100%' }} onClick={() => { setImportModal(true); setType('stocks') }}>Import Stocks</Button>
                    </Card>
                </Col>
            </Row>
        </>
    )

    const onChange = (value) => {
        if (value === undefined) {
            setSelectedBranchForListing(0);
        } else {
            setSelectedBranchForListing(value);
        }
    }

    const renderProductListingButton = (
        <>
            {renderContentHeadings('Export Product Listing')}

            <center>
                <Select allowClear onChange={onChange} style={{ width: '40%' }} placeholder="Select Branch">
                    {branches.map(branch => (
                        <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                    ))}
                </Select>
                <br />      <br />
                <Popconfirm
                    title="Download Product Listing"
                    description="Do you want to download this listing?"
                    onConfirm={() => downloadSampleExcel('productListing')}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" style={{ width: '40%' }}>Download Product Listing</Button>
                </Popconfirm>
            </center>
        </>
    )

    const renderImportModal = (
        <Modal
            title={type === "stocks" ? "Import Stocks" : "Import Products"}
            centered
            open={showImportModal}
            onCancel={resetState}
            footer={[
                <Button key="upload" onClick={() => handleImport(type)}>Import</Button>,
                <Button key="cancel" onClick={resetState}>Cancel</Button>
            ]}
        >
            <center>
                <Upload
                    action={ajaxService.getImageUploadUrl}
                    listType="picture"
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    onRemove={() => setFileList([])}
                    maxCount={1}
                    onChange={handleChange}
                    method={"post"}
                    name="excel"
                    headers={{
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }}
                >
                    <Button icon={<UploadOutlined />}>Upload Excel File (Max: 1)</Button>
                </Upload>
            </center>
        </Modal>
    )

    return (
        <>
            <a ref={anchorRef} target='_blank'></a>
            <Card>
                {renderHeader}
                <Row>
                    <Col span={12}>
                        <Card>
                            {renderProductUpload}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            {renderImportStock}
                        </Card>
                    </Col>
                    {
                        (manageInventory.active && manageInventory?.costing === MENAGEINVENTORYCOSTING.AVERAGE) &&
                        <Col Col span={12}>
                            {renderProductListingButton}
                        </Col>
                    }
                </Row>
            </Card >
            {renderImportModal}
        </>
    )
}
export default Importer