import React, { useState, useEffect } from 'react';
import { Table, Card, Typography } from 'antd';
import { PAGINATION_OPTIONS } from '../../constants';
import { filterDamageDiscardReport, getDamageDiscardReport } from '../../data';
import Summary from './layout/summary';
import Search from './layout/search';
import windowSize from 'react-window-size';
import DamageDiscardMobile from './mobile_screen/damageDiscardMobile';

const { Text } = Typography

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Product',
        dataIndex: 'product_name',
        key: 'product_name',
    },
    {
        title: 'Branch',
        dataIndex: 'branch_name',
        key: 'branch_name',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (type) => <Text>{type == 0 ? "Discard" : "Damage"}</Text>
    },
    {
        title: 'Refrence ID',
        dataIndex: 'refrence_id',
        key: 'refrence_id',
        align:'center'
    },
    {
        title: 'Total Cost',
        key: 'total_cost',
        dataIndex: 'total_cost',
        align: 'center',
    },
];

function DamageDiscardReport({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [dataSource, setDataSource] = useState([]);
    const [products, setProducts] = useState([]);

    const [totalProducts, setTotalProducts] = useState(0);
    const [totalUnits, setTotalUnits] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const handleSearch = async (data) => {
        const response = await filterDamageDiscardReport(data)
        if (response) {
            setDataSource(response)
        }
    }

    const initialize = async () => {

        let data = await getDamageDiscardReport()

        if (data) {
            setDataSource(data)
            setTotalProducts(data.length)
            setTotalUnits(data.reduce((total, i) => total + i.quantity, 0))
            const totalCost = data.reduce((total, i) => total + parseInt(i.total_cost), 0)
            setTotalPrice(totalCost.toFixed(2));
            setProducts(data.map(i => { return { id: i.product_id, name: i.product_name } }));
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    const summary = (
        <Summary
            title='Damage & Discard'
            totalProducts={totalProducts}
            totalUnits={totalUnits}
            totalInventory={totalPrice}
            damageReport={true}
            mobile={isMobile}
        />
    )

    const renderDamageDiscardReport = () => (
        <>
            <Search handleSearch={handleSearch} hasBranch={true} hasProduct={true} damageProducts={products} damageReport={true} />
            <Card>
                {summary}
                <br />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ ...PAGINATION_OPTIONS }}
                    rowKey="index"
                />
            </Card>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            // return <DetailedInventoryMobile summary={summary} hasBranch={true} hasProduct={true} />
            return <DamageDiscardMobile summary={summary} damageProducts={products} />
        } else {
            return renderDamageDiscardReport()

        }
    }

    return renderContent()


}

export default windowSize(DamageDiscardReport)