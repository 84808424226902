import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Card, Button, Statistic, Divider, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined, ShopOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { exportAppBackupExcel, getStore } from '../../data';
import windowSize from 'react-window-size';
import Branch from '../branch';
import Dashboard from '../dasboard';
import { useRef } from 'react';

const { Text } = Typography;

function Store({ windowWidth }) {

  let history = useHistory();
  const isMobile = windowWidth <= 768;
  const anchorRef = useRef(null);
  const authUserID = localStorage.getItem('authUserID')

  const [stores, setStores] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(true);
  const [branchesLength, setBranchesLength] = useState(0);

  const downloadBackupExcel = async () => {

    const url = await exportAppBackupExcel();

    anchorRef.current.href = url;

    anchorRef.current.click();
  }

  const initialize = async () => {
    let store_id = parseInt(localStorage.getItem('store_id'));
    const store = await getStore(store_id);
    if (store) {
      setStores([store]);
      setBranchesLength(store.branches)
      setForceRefresh(false)
    }
  }

  useEffect(() => {
    if (forceRefresh) {
      initialize();
      setForceRefresh(false)
    }
  }, [forceRefresh])

  const renderStore = () => {

    if (stores.length == 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <Card title="" bordered={false}>
            <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <ShopOutlined style={{ fontSize: '256px', color: '#f0f0f0' }} />
              <Text>You have not setup your store</Text>
              <br />
              <Button type="primary" icon={<PlusOutlined />} size="large"
                onClick={() => history.push({ pathname: "/stores/form/0" })}>
                Add Store
              </Button>
            </center>
          </Card>
        </div>
        // <Row>
        //   <Col span={8}>
        //   </Col>
        // </Row>
      );
    } else {
      return (
        <Row gutter={16} >
          {
            stores.map((store, key) => {
              return (
                <Col span={24} key={'col-' + key}>
                  <Card title="" bordered={false}>

                    {/* App Backup Button */}
                    {authUserID == store.user_id &&
                      <>
                        <Popconfirm
                          title="Download Backup"
                          description="Do you want to download backup?"
                          onConfirm={() => downloadBackupExcel('product')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" >Download Backup</Button>
                        </Popconfirm>
                        <Divider />
                      </>
                    }

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                      <div style={{ order: 0, width: '250%' }}>
                        <Row>
                          <Col span={6}>
                            <Statistic title="Store Name" value={store.name} valueStyle={{ fontSize: 18 }} />
                          </Col>
                          <Col span={6}>
                            <Statistic title="Slogan" value={store.slogan} valueStyle={{ fontSize: 18 }} />
                          </Col>
                          <Col span={6}>
                            <Statistic title="Description" value={store.description} valueStyle={{ fontSize: 18 }} />
                          </Col>
                          {store.domain && <Col span={6}>
                            <a href={store.domain} target={'_blank'}> <Statistic title="Store Website" value={store.domain} valueStyle={{ fontSize: 18 }} /> </a>
                          </Col>}
                        </Row>
                      </div>
                      <div style={{ order: 1 }}>
                        {/* <Col span={6}> */}
                        <Button type="primary" icon={<EditOutlined />} size="large"
                          onClick={() => history.push({ pathname: "/stores/form/" + store.id })}>
                          Edit Store
                        </Button>
                        {/* </Col> */}
                      </div>
                    </div>
                    <Divider />

                    <Branch store={store} setForceRefresh={setForceRefresh} branchesLength={branchesLength} />
                    <Dashboard storeId={store.id} />
                  </Card>
                </Col>
              );
            })
          }
        </Row>
      );
    }
  }

  const renderStoreMobile = () => {
    if (stores.length == 0) {
      return (
        <Card bordered={false} >
          <center>
            <Button type="primary" size="medium" icon={<PlusOutlined />}
              onClick={() => history.push({ pathname: "/stores/form/0" })}>
              Add Store
            </Button>
          </center>
        </Card>
      )
    }
    else {
      return (
        <>
          {
            stores.map(store => {
              return (
                <>

                  {/* App Backup Button */}
                  {
                    authUserID == store.user_id &&
                    <Popconfirm
                      title="Download Backup"
                      description="Do you want to download backup?"
                      onConfirm={() => downloadBackupExcel('product')}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" style={{ width: '100%' }}>Download Backup</Button>
                    </Popconfirm>
                  }
                  <Card
                    title={[
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {store.name}
                        <Button icon={<EditOutlined />} type="primary" size="small" onClick={() => history.push({ pathname: "/stores/form/" + store.id })}>
                          Edit Store
                        </Button>
                      </div>
                    ]}
                    style={{ width: '100%' }}
                  >


                    <Text style={{ fontWeight: 'bolder' }}>Slogan : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{store.slogan}</span></Text>
                    <br />

                    <Text style={{ fontWeight: 'bolder' }}>Store Website :
                      <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                        <a href={store.domain} target="_blank">{store.domain}</a>
                      </span>
                    </Text>
                    <br />

                    <Text style={{ fontWeight: 'bolder' }}>Description : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{store.description}</span></Text>

                    {/* Branches */}
                    <Divider plain>Branches</Divider>
                    <Branch store={store} setForceRefresh={setForceRefresh} branchesLength={branchesLength} />
                    {/* Dashboard */}
                    <Divider plain>Dashboard</Divider>
                    <Dashboard storeId={store.id} />
                  </Card>
                  <br />
                </>
              );
            })
          }

        </>

      );
    }
  }

  const renderContent = () => {
    if (isMobile) {
      return renderStoreMobile()
    } else {
      return renderStore()
    }
  }

  return renderContent()
}

export default windowSize(Store)