import { Button, Card, Col, Form, Input, message, notification, Row, Select, Space } from "antd";
import { PlusOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getBranches, getProducts, getPurchaseOrders, getSuppliers, savePurchaseGrn, } from "../../data";
import windowSize from "react-window-size";
import dataService from "../../services/data-service";

const { Option } = Select

const PurchaseGrnForm = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768
    const [form] = Form.useForm();
    const [addBtn, setAddBtn] = useState();
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState([]);
    const [branches, setBranches] = useState([]);
    const [products, setProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(true);

    let history = useHistory()
    let { id } = useParams()
    const branch_id = localStorage.getItem('branch_id') ? parseInt(localStorage.getItem('branch_id')) : null

    const onFinish = async (values) => {

        if (values.purchaseGrnItems.length > 0) {

            if (parseInt(id) === 0) {
                const saveData = await savePurchaseGrn(values)
                if (saveData) {
                    history.push({ pathname: "/purchase-grn" });
                    notification.open({ message: 'Purchase grn has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to add purchase grn', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }else{
                // message.error('Please provide at least one grn item')
            }

        } else {
            message.error('Please provide at least one grn item')
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadPurchaseOrders = async () => {
        const data = await getPurchaseOrders();
        if (data) {
            let publishProducts = branch_id ? data.filter(i => i.status === 1 && i.branch_id === branch_id) : data.filter(i => i.status === 1);
            setPurchaseOrderNumber(publishProducts);
        }
    }

    const loadProducts = async () => {
        const data = await dataService.getShortProducts();
        if (data) {
            setProducts(data);
            setForceRefresh(false)
        }
    }

    const loadBranches = async () => {
        const data = await getBranches(forceRefresh);
        if (data) {
            setBranches(data);
            setForceRefresh(false)
        }
    }

    const loadSuppliers = async () => {
        const response = await getSuppliers('?branch_id=' + branch_id);
        if (response) {
            const sortedData = response.sort((a, b) => a.name.localeCompare(b.name));
            setSuppliers(sortedData);
            setForceRefresh(false);
        }
    }

    useEffect(() => {
        loadPurchaseOrders()
        loadBranches()
        loadProducts()
        loadSuppliers()
    }, [])

    const onChange = (e) => {

        setAddBtn(e)

        let po = purchaseOrderNumber.find(i => i.order_number == e);
        if (e != undefined) {
            form.setFieldsValue({ order_number: po.order_number, purchaseGrnItems: po.items, supplier: po.supplier, branch_id: po.branch_id })
        } else {
            form.setFieldsValue({ order_number: '', purchaseGrnItems: '', supplier: null, branch_id: null })
        }

    }

    const renderPurchaseGrnForm = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    supplier: null,
                    purchaseGrnItems: []
                }}
            >
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Col span={12}>
                                <Form.Item label="Purchase Order Number" name='purchase_order_number'>
                                    <Select
                                        showSearch allowClear
                                        style={{ width: '100%' }}
                                        onChange={(e) => onChange(e)}
                                        placeholder="Select Purchase Order Number"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {purchaseOrderNumber.map(orderNumber => {
                                            return <Option value={orderNumber.order_number}>{orderNumber.order_number}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Supplier Name" name='supplier' rules={[{ required: true, message: ' Supplier is required' }]}>
                                    {/* <Input placeholder="Supplier Name" /> */}
                                    <Select placeholder="Select Supplier"
                                        showSearch
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        <Option value='anonymous_purchase' key={id}>Anonymous Purchase</Option>
                                        {
                                            suppliers.map(({ id, name }) => (
                                                <Option value={name} key={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name='branch_id' label="Branch"
                                    initialValue={branch_id}
                                    rules={[{ required: true, message: 'Branch is required' }]} >
                                    <Select placeholder="Select Branch"
                                        style={{
                                            width: '100%',
                                        }}>
                                        {
                                            branches.map(({ id, name },index) => {
                                                if (branch_id) {
                                                    if (id === branch_id) {
                                                        return (
                                                            <Option value={id} key={index}>{name}</Option>
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <Option value={id} key={index}>{name}</Option>
                                                    )
                                                }
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.List name="purchaseGrnItems">

                                    {(fields, { add, remove }) => (
                                        <>
                                            <Form.Item>
                                                <Button
                                                    style={{ width: 110, }}
                                                    disabled={addBtn == null ? false : true}
                                                    onClick={() => add()}
                                                    block icon={<PlusOutlined />}>
                                                    Add Item
                                                </Button>
                                            </Form.Item>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Row key={key} style={{ marginBottom: 8 }} gutter={16}>
                                                    <Col span={11}>
                                                        {
                                                            addBtn != null ?
                                                                <>
                                                                    <Form.Item
                                                                        name={[name, 'product_id']}
                                                                        style={{ display: 'none' }}
                                                                    >
                                                                        <input disabled className="productDropDown" />
                                                                    </Form.Item>
                                                                    <Form.Item label="Item" name={[name, 'product_name']}>
                                                                        <input disabled className="productDropDown" />
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <Form.Item label="Item" name={[name, 'product_id']}>
                                                                    <Select
                                                                        showSearch allowClear
                                                                        style={{ width: '100%' }}
                                                                        placeholder="Select Product"
                                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                        {products.map((product,index) => {
                                                                            return <Option value={product.id} key={index}>{product.name}</Option>
                                                                        })}
                                                                    </Select>
                                                                </Form.Item>
                                                        }
                                                    </Col>
                                                    <Col span={5}>
                                                        <Form.Item label="Quantity" name={[name, 'quantity']} rules={[
                                                            {
                                                                required: true,
                                                                message: 'Quantity is required',
                                                            }]}>
                                                            <Input type="number" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={5}>
                                                        <Form.Item label="Cost" name={[name, 'cost']} rules={[
                                                            {
                                                                required: true,
                                                                message: 'cost is required',
                                                            }]}>
                                                            <Input type="number" step={'0.01'} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Form.Item label=" ">
                                                            <DeleteOutlined style={{ color: 'red', fontSize: '20px', }} onClick={() => remove(name)} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Form >
        </>
    )

    const renderPurchaseGrnFormMobile = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{ supplier: null, purchaseGrnItems: [] }}
            >

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Purchase Order Number" name='purchase_order_number'>
                            <Select
                                showSearch allowClear
                                style={{ width: '100%' }}
                                onChange={(e) => onChange(e)}
                                placeholder="Select Purchase Order Number"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {purchaseOrderNumber.map(orderNumber => {
                                    return <Option value={orderNumber.order_number}>{orderNumber.order_number}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Supplier Name" name='supplier' rules={[{ required: true, message: ' Supplier is required' }]}>
                            {/* <Input placeholder="Supplier Name" /> */}
                            <Select placeholder="Select Supplier"
                                showSearch
                                style={{ width: '100%' }}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option value='anonymous_purchase' key={id}>Anonymous Purchase</Option>
                                {
                                    suppliers.map(({ id, name }) => (
                                        <Option value={name} key={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name='branch_id' label="Branch"
                            initialValue={branch_id}
                            rules={[{ required: true, message: 'Branch is required' }]} >
                            <Select placeholder="Select Branch" style={{ width: '100%' }}>
                                {
                                    branches.map(({ id, name }) => {
                                        if (branch_id) {
                                            if (id === branch_id) {
                                                return (
                                                    <Option value={id}>{name}</Option>
                                                )
                                            }
                                        } else {
                                            return (
                                                <Option value={id}>{name}</Option>
                                            )
                                        }
                                    }
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.List name="purchaseGrnItems">

                            {(fields, { add, remove }) => (
                                <>
                                    <Form.Item>
                                        <Button
                                            style={{ width: 110, }}
                                            disabled={addBtn == null ? false : true}
                                            onClick={() => add()}
                                            block icon={<PlusOutlined />}>
                                            Add Item
                                        </Button>
                                    </Form.Item>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Card key={key} style={{ display: 'flex', marginBottom: 8 }}>

                                                {
                                                    addBtn != null ?
                                                        <>
                                                            <Form.Item name={[name, 'product_id']} style={{ display: 'none' }}>
                                                                <input disabled className="productDropDown" />
                                                            </Form.Item>

                                                            <Form.Item label="Item :" name={[name, 'product_name']}>
                                                                <input disabled className="productDropDown" />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <Form.Item label="Item :" name={[name, 'product_id']}>
                                                            <Select
                                                                showSearch allowClear
                                                                style={{ width: '100%' }}
                                                                placeholder="Select Product"
                                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                {products.map(product => {
                                                                    return <Option value={product.id}>{product.name}</Option>
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                }
                                                <Form.Item label="Quantity" name={[name, 'quantity']} rules={[
                                                    {
                                                        required: true,
                                                        message: 'Quantity is required',
                                                    }]}>
                                                    <Input type="number" />
                                                </Form.Item>

                                                <Form.Item label="Cost" name={[name, 'cost']} rules={[
                                                    {
                                                        required: true,
                                                        message: 'cost is required',
                                                    }]}>
                                                    <Input type="number" step={'0.01'} />
                                                </Form.Item>

                                                <Button type="primary" danger icon={<DeleteOutlined />} style={{ width: "100%" }} onClick={() => remove(name)}>Delete</Button>

                                            </Card>
                                        </>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Col>

                </Row>
                <Row>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type="primary">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Row>

            </Form>
        </>
    )

    const renderContent = () => {
        if (isMobile) {
            return renderPurchaseGrnFormMobile()
        } else {
            return renderPurchaseGrnForm()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default windowSize(PurchaseGrnForm)