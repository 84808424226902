import { Col, DatePicker, Form, Row, Select, Space, Button, Typography, Card, Empty } from "antd"
import { filterProductStock } from "../../../data";
import { useState } from "react";
import moment from "moment";
import MENAGEINVENTORYCOSTING from "../../../constant/manageInventoryCosting";

const { RangePicker } = DatePicker;
const { Option } = Select
const { Text } = Typography

const ProductLedgerMobile = ({ branches, products, format, redIfInvalidEntryType, manageInventory }) => {

    const [form] = Form.useForm()

    const [items, setItems] = useState([])
    const [data, setData] = useState([])
    const mainBranch = branches.find(i => i.is_main == 1)?.id

    const handleSearch = async (values) => {
        const response = await filterProductStock(values)
        if (response) {
            setData(response)
            setItems(response.filter(i => i.branch_id == mainBranch))
        }
    }

    const onChange = (id) => {
        setItems(data.filter(i => i.branch_id == id))
    }

    const renderFilter = (
        <Form form={form} layout={'vertical'} onFinish={handleSearch}>
            <Row gutter={16} >
                <Col span={24}>
                    <Form.Item label="Product" name="product_id" labelCol={{ span: 24 }}>
                        <Select
                            showSearch allowClear
                            style={{ width: '100%' }}
                            placeholder="Select Product"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {products.map(({ id, name }, i) => (
                                <Option key={'product-' + i} value={id}>{name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Date" name="date" labelCol={{ span: 24 }}>
                        <RangePicker style={{ width: '100%' }} allowClear />
                    </Form.Item>
                </Col>
                <Col span={24} className="gutter-row">
                    <Space>
                        <Button htmlType="submit" type="primary">Search</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    )

    return (
        <>
            {renderFilter}
            {
                data.length > 0 &&
                <>
                    <br />
                    <Text style={{ fontWeight: 'bolder' }}>Branches</Text>
                    <Select
                        showSearch allowClear
                        style={{ width: '100%' }}
                        placeholder="Select branch"
                        onChange={onChange}
                        defaultValue={mainBranch}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {branches.map(({ id, name }, i) => (
                            <Option key={'branch-' + i} value={id}>{name}</Option>
                        ))}
                    </Select>
                    <br />  <br />
                    {
                        items.length > 0 ? (

                            items.map(i => (
                                <>
                                    <br />
                                    <Card title={<Text style={{ fontStyle: 'italic', fontWeight: 'bolder' }}>{moment(i.created_at).format(format)}</Text>}>

                                        <Text style={{ fontWeight: 'bolder' }}>Branch : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.branch_name}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Product : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.product_name}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Supplier : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.supplier}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>GRN # : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.grn_number}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Entry Type :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10, color: redIfInvalidEntryType(i.entry_type) }}>
                                                {i.entry_type}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Stock :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10, color: redIfInvalidEntryType(i.entry_type) }}>
                                                {i.stock}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Available Stock : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.available_stock}</span></Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.cost}</span></Text>
                                        <br />
                                        {manageInventory?.costing === MENAGEINVENTORYCOSTING.AVERAGE &&
                                            <>
                                                <Text style={{ fontWeight: 'bolder' }}>Average Cost : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{i.average_cost}</span></Text>
                                                <br />
                                            </>
                                        }
                                        <Text style={{ fontWeight: 'bolder' }}>Selling Price :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {i.entry_type === 2 ? i.sold_price : i.retail_price}
                                            </span>
                                        </Text>

                                    </Card>
                                </>
                            ))

                        ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                    }
                </>
            }
        </>
    )
}

export default ProductLedgerMobile