import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, notification, Row, Switch, Typography } from "antd";
import { CloseOutlined, CheckOutlined, SettingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { filterStoreSetting, getStore, getStoreSetting, getStoreSettings, updateStoreSetting } from "../../data";
import { useHistory } from "react-router-dom";
import FormModal from '../../components/form-modal';
import windowSize from "react-window-size";
import MENAGEINVENTORYCOSTING from "../../constant/manageInventoryCosting";
import ControlSettings from "../../constant/controlSettings";
import IndustrySettings from "../../constant/industrySettings";

const { Title, Text } = Typography;

function StoreSettings({ windowWidth }) {

    const [forceRefresh, setForceRefresh] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [slug, setSlug] = useState(null);
    const [Id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [setting, setSetting] = useState('');
    const isMobile = windowWidth <= 768
    let history = useHistory()
    const store_id = localStorage.getItem('store_id');

    let conditionalize = [...dataSource]; // Make a copy of the dataSource array

    if (!IndustrySettings.COMBO) {
        conditionalize = conditionalize.filter(i => i.slug !== ControlSettings.COMBO_INVENTORY);
    }
    if (!IndustrySettings.FBR) {
        conditionalize = conditionalize.filter(i => i.slug !== ControlSettings.ENABLE_FBR_SETTINGS);
    }
    if (!IndustrySettings.SRB) {
        conditionalize = conditionalize.filter(i => i.slug !== ControlSettings.SRB_SETTINGS);
    }

    const updateValue = async (id, val, type) => {

         const active = await updateStoreSetting(id, { type: type, value: val })
        if (active) {
            setForceRefresh(true)
            if (val === true) {
                notification.open({ message: 'Control activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (val === false) {
                notification.open({ message: 'Control  deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const search = async (value) => {
        var formData = new FormData();
        formData.append('title', value);

        const data = await filterStoreSetting(formData);
        if (data) {
            setDataSource(data);
            if (data.length == 0) {
                notification.open({ message: value + ' not found', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            notification.open({ message: 'Setting not found', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const initialize = async () => {
        const data = await getStoreSettings(forceRefresh);
        if (data) {
            const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);
            if (response) {

                const row = { active: response.active, ...JSON.parse(response.setting) };

                let rows = row.active && row.costing === MENAGEINVENTORYCOSTING.SPECIFICIDENTIFICATION ? data.filter(item => item.slug !== ControlSettings.COMBO_INVENTORY) : data;

                setDataSource(rows);
                setForceRefresh(false);
            }
        }
    };

    useEffect(() => {
        if (forceRefresh) {
            initialize()
            setForceRefresh(false)
        }
    }, [forceRefresh])

    const renderSearchBox = () => {
        return (
            <Form>
                <Row gutter={16}>
                    <Col span={isMobile ? 24 : 10}>
                        <Form.Item>
                            <Input placeholder='Search Settings' onChange={(e) => search(e.target.value)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    const renderPath = ({ setting_type, route, slug, id, title, setting }) => {
        if (setting_type) {
            return history.push({ pathname: route })
        } else {
            setId(id)
            setSlug(slug)
            setTitle(title)
            setModalVisible(true)
            setSetting(setting)
        }
    }

    const renderHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Store Settings</Title>
        </div>
    )

    const renderStoreSettings = () => (
        <>
            <Row gutter={16}>
                {
                    conditionalize.map(control => (
                        <>
                            <Col span={isMobile ? 24 : 6}>
                                <Card
                                    style={{ height: 145, marginBottom: '40px' }}
                                    actions={[
                                        ![ControlSettings.ENABLE_MOBILE, ControlSettings.COMBO_INVENTORY, ControlSettings.OFFER_BRANCH_CONTROL].includes(control.slug) && (
                                            <SettingOutlined onClick={() => renderPath(control)} key="setting" />
                                        ),

                                        !ControlSettings.BRANCH_CATALOG.includes(control.slug) &&
                                        <Switch
                                            onChange={val => {
                                                updateValue(control.id, val, 'active')
                                            }}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked={control.active}
                                        />
                                    ]}
                                >
                                    <Text style={{ fontWeight: 'bolder' }} level={3}>
                                        {control.title}
                                    </Text>
                                </Card>
                            </Col>
                        </>
                    ))
                }
            </Row>
        </>
    )

    return (
        <>
            {renderHeader}
            {renderSearchBox()}
            {renderStoreSettings()}
            <FormModal display={modalVisible} toggle={setModalVisible} slug={slug} id={Id} title={title} setting={setting} mobile={isMobile} setForceRefresh={setForceRefresh} />
        </>
    )
}
export default windowSize(StoreSettings)