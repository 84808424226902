const UrlService = {
    categories: 'categories',
    pages: 'pages',
    profile: 'profile',
    users: 'users',
    user: 'user',
    stores: 'stores',
    branches: 'branches',
    roles: 'roles',
    rolePermissions: 'role/permission',
    brands: 'brands',
    paymentMethods: 'payment-methods',
    shippingMethods: 'shipping-methods',
    userFilter: 'users/filter',
    products: 'products',
    productFilter: 'products/filter',
    orders: 'orders',
    status: 'status',
    orderFilter: 'orders/filter',
    offers: 'offers',
    storeSettings: 'store-settings',
    storeSettingFilter: 'store-settings/filter',
    suppliers: 'suppliers',
    purchaseOrders: 'purchase-orders',
    purchaseGrn: 'purchase-grn',
    ledger: 'ledger',
    customers: 'customers',
    packages: 'packages',
    storePermissions: 'stores/permission',
    tableManagements: 'table-managements',
    staffManagements: 'staff-managements',
    branchProducts: 'products/branch-products',
    stockTransfer: 'stock-transfer',
    productStock: 'product-stock',
    productStockFilter: 'product-stock/filter',
    branchBrands: 'brands/branch-brands',
    branchCategories: 'categories/branch-categories',
    detailInventory: 'detailed-inventory',
    detailInventoryFilter: 'detailed-inventory/filter',
    banners: 'banners',
    cuisines: 'cuisines',
    productInventory: 'product-inventory',
    productInventoryFilter: 'product-inventory/filter',
    outOfStock: 'out-of-stock',
    outOfStockFilter: 'out-of-stock/filter',
    saleReturn: 'sale-return',
    accounts: 'accounts',
    profitLossStatement: 'profit-loss-statement',
    damageDiscard: 'damage-discard',
    damageDiscardFilter: 'damage-discard/filter',
    dashboard: 'store-dashboard',
    abilities: 'abilities',
    damageDiscardProducts: 'damage-discard-products',
    incomeExpense: 'income-expense',
    incomeExpenseFilter: 'income-expense/filter',
    industries: 'industries',
    productsExpiry: 'products-expiry',
    productsExpiryFilter: 'products-expiry/filter',
    exportSampleProductExcel: 'export-sample-product-excel',
    uploadProductsExcel: 'upload-products-excel',
    exportSampleStockExcel: 'export-sample-stock-excel',
    uploadStocksExcel: 'upload-stocks-excel',
    forgotPassword: 'password/email',
    resetPassword: 'password/reset',
    storesLedger: 'stores-ledger',
    orderNumbers: 'order-numbers',
    customizeSales: 'customize-sales',
    customizeSalesFilter: 'customize-sales/filter',
    productStockForCombo: "product-stock/combo",
    exportListingProductExcel: 'export-listing-product-excel',
    reconciliations: 'reconciliation',
    productsWithStocks: 'reconciliation/form',
    exportAppBackupExcel: 'export-app-backup-excel'
}

export default UrlService;