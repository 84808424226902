import { useEffect, useState } from "react";
import { Col, Form, Row, Select, Button, Space, Typography, Divider, Input, notification, message, Modal, Table, Card } from "antd";
import { getBranchProducts, getBranches, getStoreSetting, saveDamageDiscardProducts } from "../../data";
import { CloseCircleOutlined, CheckCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import windowSize from "react-window-size";
import ControlSettings from "../../constant/controlSettings";
import MENAGEINVENTORYCOSTING from "../../constant/manageInventoryCosting";
import dataService from "../../services/data-service";

const { Text } = Typography
const { Option } = Select

const DamageDiscardProductForm = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    let history = useHistory()
    const [form] = Form.useForm()

    const [forceRefresh, setForceRefresh] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const [manageInventory, setManageInventory] = useState({});

    const [branches, setBranches] = useState([])
    const [products, setProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [productTransfers, setProductTransfers] = useState([]);

    const columns = [
        {
            title: 'GRN',
            dataIndex: 'grn_number',
            key: 'grn_number',
        },
        {
            title: 'Product',
            // dataIndex: 'name',
            key: 'name',
            render: (text, { product_name, name }) => <Text>{product_name ? product_name : name}</Text>
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (qty) => <Text>{parseInt(qty)}</Text>
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Total Cost',
            key: 'total_cost',
            render: (text, record) => <Text>{(record.price * record.quantity).toFixed(2)}</Text>
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => <Text style={{ color: type == 0 ? '#f59556' : '#FF0000' }}>{type === 0 ? "Discard" : "Damage"}</Text>
        },
    ];

    const onFinish = async () => {

        if (productTransfers.length === 0) return message.error('Please add a product. If you have already added a product, please check the quantity.')

        const values = form.getFieldsValue()

        let items = [];
        productTransfers.map(i => {
            let row = {
                product_id: i.branchCatalog ? i.id : i.product_id,
                quantity: i.quantity,
                total_cost: i.price * i.quantity,
                type: i.type ?? 1,
                grn_number: i.grn_number ?? null,
                cost: i.price,
            }

            items.push(row)
            return i;
        })

        let data = {
            branch_id: values.branch_id,
            description: values.description ?? null,
            items: items,
        }

        let response = await saveDamageDiscardProducts(data)
        if (response) {
            setShowModal(false)
            history.push({ pathname: "/damage-discard-products" });
            notification.open({ message: 'Actions has been performed successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to perform action', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const onFinishFailed = (error) => {
        console.error(`Field: ${error}`)
    }

    const handleProducts = async (e) => {
        const response = await dataService.getShortProducts({ branch_id: e , stock : 1 });
        if (response && response.length > 0) {
            setProducts(response.filter(i => i.stock > 0))
        }
    }

    const addProductQty = (item, quantity) => {

        let existingItem = productTransfers.find(i => i.id === item.id && i.grn_number === item.grn_number);

        if (existingItem == null) {
            item.quantity = quantity;
            setProductTransfers([...productTransfers, item])
        } else {
            let updatedItems = productTransfers.map(i => {
                if (i.id === item.id && i.grn_number === item.grn_number) {
                    i.quantity = quantity
                }
                return i;
            });

            setProductTransfers([...updatedItems])
        }
    }

    const addProductType = (item, e) => {

        let existingItem = productTransfers.find(i => i.id === item.id && i.grn_number === item.grn_number);

        if (existingItem == null) {
            item.type = e;
            setProductTransfers([...productTransfers, item])
        } else {
            let updatedItems = productTransfers.map(i => {
                if (i.id === item.id && i.grn_number === item.grn_number) {
                    i.type = e
                }
                return i;
            });

            setProductTransfers([...updatedItems])
        }
    }

    const handleSelectedProducts = () => {
        form.resetFields(['products'])

        const response = products.filter(i => i.id === selectedProducts);
        if (response) {
            let data = []
            if (manageInventory.active && manageInventory.costing == MENAGEINVENTORYCOSTING.AVERAGE) {
                data = response.map(product => {
                    const productStockArray = product.product_stock;
                    product.product_stock = [productStockArray[productStockArray.length - 1]];
                    return product
                });
            }
            else {
                const currentTransferredProducts = form.getFieldValue('transferredProducts') || [];
                data = [...new Set([...currentTransferredProducts, ...products.filter(i => i.id === selectedProducts).filter(newResponse => !currentTransferredProducts.some(existingResponse => existingResponse.id === newResponse.id))])];
            }
            form.setFieldsValue({ transferredProducts: [...data] });
        }
    }

    const loadBranches = async () => {
        const response = await getBranches(forceRefresh)
        if (response) {
            setBranches(response)
            setForceRefresh(response)
        }
    }

    const manageInventorySetting = async () => {
        const response = await getStoreSetting(ControlSettings.MANAGE_INVENTORY);
        if (response) {
            setManageInventory({ active: response.active, ...JSON.parse(response.setting) })
        }
    }

    useEffect(() => {
        loadBranches()
        manageInventorySetting()
    }, [])

    useEffect(() => {
        setProductTransfers([...productTransfers])
    }, [showModal])

    const confirmationModal = (data) => (
        <Modal
            title="Confirmation summary"
            open={showModal}
            onOk={onFinish}
            onCancel={() => setShowModal(false)}
            width="50%">
            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        </Modal>
    )

    const renderDamageDiscardProductForm = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} key="col" >
                        <Row gutter={16} key="row">
                            <Col span={24}>
                                <Form.Item name='branch_id' label="Branch" rules={[
                                    {
                                        required: true,
                                        message: 'Branch is required',
                                    },
                                ]}>
                                    <Select placeholder="Select Branch" onChange={(e) => handleProducts(e)} allowClear>
                                        {
                                            branches.map(({ id, name }) => (
                                                <Option key={id} value={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='description' label="Description">
                                    <TextArea rows={3} placeholder="Description" />
                                </Form.Item>
                            </Col>

                            <Divider />

                            <Col span={12}>
                                <Form.Item name='products' label="Products"
                                // rules={[{ required: true, message: 'Products is required' }]} 
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        // mode="multiple"
                                        placeholder="Select Product"
                                        onChange={setSelectedProducts}
                                        style={{
                                            width: '100%',
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {
                                            products.map(({ id, name }) => (
                                                <Option value={id} key={id}>{name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label=" ">
                                    <Button onClick={handleSelectedProducts}>Add</Button>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                {
                                    <>
                                        <Form.List name="transferredProducts">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => {

                                                        const items = form.getFieldsValue().transferredProducts;
                                                        const item = items.find((_, x) => x === index);
                                                        const stocks = item.product_stock.filter(i => i.available_stock > 0);
                                                        const selectedProds = !item.branchCatalog ? stocks.map((stock) => ({
                                                            ...stock, qty: stock.available_stock, price: manageInventory.costing === MENAGEINVENTORYCOSTING.AVERAGE ? stock.average_cost : stock.cost,
                                                        })) : [item].map((i) => ({
                                                            ...i, qty: i.productQuantity, price: i.cost_price,
                                                        }));

                                                        return (
                                                            <>
                                                                <Divider style={{ margin: 0, position: 'relative', top: 10 }} />
                                                                <Row gutter={16} key={"row-" + key}>
                                                                    <Col span={17}>
                                                                        <Space>
                                                                            <Text><b>Product Name : </b></Text>
                                                                            <Form.Item label=" " name={[name, 'name']}>
                                                                                <input disabled className="productDropDown" style={{ marginBottom: 5 }} />
                                                                            </Form.Item>
                                                                        </Space>
                                                                        <div style={{ position: 'absolute', right: '5px', bottom: '28px' }}>
                                                                            <DeleteOutlined style={{ color: 'red', cursor: 'pointer', marginLeft: 10 }} onClick={() => remove(name)} />
                                                                        </div>
                                                                        {/* <DeleteOutlined style={{ color: 'red', cursor: 'pointer', }} onClick={() => remove(name)} /> */}
                                                                    </Col>
                                                                </Row>

                                                                <Row style={{ margin: "10px 5px" }} gutter={16} key={"rowGrn-" + key}>
                                                                    {
                                                                        (!item.branchCatalog && manageInventory.costing !== MENAGEINVENTORYCOSTING.AVERAGE) &&
                                                                        <Col span={5}>
                                                                            <Text><b>GRN</b></Text>
                                                                        </Col>
                                                                    }
                                                                    <Col span={5} >
                                                                        <Text><b>Available Qty</b></Text>
                                                                    </Col>
                                                                    <Col span={5} >
                                                                        <Text><b>Cost</b></Text>
                                                                    </Col>
                                                                    <Col span={5} >
                                                                        <Text><b>Quantity</b></Text>
                                                                    </Col>
                                                                    <Col span={4} >
                                                                        <Text><b>Type</b></Text>
                                                                    </Col>
                                                                </Row>

                                                                {
                                                                    selectedProds.map((item, index) => (
                                                                        <Row style={{ margin: "10px 5px" }} gutter={16} key={"stock-" + index}>
                                                                            {
                                                                                (!item.branchCatalog && manageInventory.costing !== MENAGEINVENTORYCOSTING.AVERAGE) &&
                                                                                <Col span={5}>
                                                                                    <Text>{item.grn_number}</Text>
                                                                                </Col>
                                                                            }
                                                                            <Col span={5}>
                                                                                <Text>{item.qty}</Text>
                                                                            </Col>
                                                                            <Col span={5}>
                                                                                <Text>{item.price}</Text>
                                                                            </Col>
                                                                            <Col span={5}>
                                                                                <Input type="number" onChange={(e) => addProductQty(item, e.target.value)} placeholder="0" />
                                                                            </Col>
                                                                            <Col span={4}>
                                                                                <Select style={{ width: '100%' }} onChange={(e) => addProductType(item, e)} defaultValue={1}>
                                                                                    <Option value={1}>Damage</Option>
                                                                                    <Option value={0}>Discard</Option>
                                                                                </Select>
                                                                            </Col>
                                                                        </Row >
                                                                    ))
                                                                }

                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </Form.List >
                                    </>
                                }
                            </Col>

                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Form.Item>
                                <Space>
                                    {/* <Button htmlType="submit" type="primary" >Submit</Button> */}
                                    <Button type="primary" onClick={() => setShowModal(true)}>Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >

            {confirmationModal(productTransfers)}
        </>
    );

    const renderDamageDiscardProductFormMobile = () => (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Row gutter={16} key="row">
                    <Col span={24}>
                        <Form.Item name='branch_id' label="Branch" rules={[
                            {
                                required: true,
                                message: 'Branch is required',
                            },
                        ]}>
                            <Select placeholder="Select Branch" onChange={(e) => handleProducts(e)} allowClear>
                                {
                                    branches.map(({ id, name }) => (
                                        <Option key={id} value={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name='description' label="Description">
                            <TextArea rows={3} placeholder="Description" />
                        </Form.Item>
                    </Col>

                    <Divider />

                    <Col span={20}>
                        <Form.Item name='products' label="Products">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Select Product"
                                onChange={setSelectedProducts}
                                style={{
                                    width: '100%',
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {
                                    products.map(({ id, name }) => (
                                        <Option value={id} key={id}>{name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label=" ">
                            <Button onClick={handleSelectedProducts}><PlusOutlined /></Button>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        {
                            <>
                                <Form.List name="transferredProducts">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }, index) => {

                                                const items = form.getFieldsValue().transferredProducts;
                                                const item = items.find((_, x) => x === index);
                                                const stocks = item.product_stock.filter(i => i.available_stock > 0);
                                                const selectedProds = !item.branchCatalog ? stocks.map((stock) => ({
                                                    ...stock, qty: stock.available_stock, price: manageInventory.costing === MENAGEINVENTORYCOSTING.AVERAGE ? stock.average_cost : stock.cost,
                                                })) : [item].map((i) => ({
                                                    ...i, qty: i.productQuantity, price: i.cost_price,
                                                }));

                                                return (
                                                    <>

                                                        <br />
                                                        <Card headStyle={{ height: 5 }} title={[
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Form.Item name={[name, 'name']}>
                                                                    <input disabled className="productDropDown" style={{ fontWeight: 'bolder', marginBottom: 20 }} />
                                                                </Form.Item>
                                                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => remove(name)} />
                                                            </div>
                                                        ]}
                                                        >
                                                            {
                                                                selectedProds.map((item, index) => (
                                                                    <>
                                                                        {
                                                                            (!item.branchCatalog && manageInventory.costing !== MENAGEINVENTORYCOSTING.AVERAGE) &&
                                                                            <>
                                                                                <Text style={{ fontWeight: 'bolder' }}>Grn :
                                                                                    <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.grn_number}</span>
                                                                                </Text>
                                                                                <br />
                                                                            </>
                                                                        }
                                                                        <Text style={{ fontWeight: 'bolder' }}>Available Qty :
                                                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.qty}</span>
                                                                        </Text>
                                                                        <br />
                                                                        <Text style={{ fontWeight: 'bolder' }}>Price :
                                                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{item.price}</span>
                                                                        </Text>
                                                                        <br />
                                                                        <Text style={{ fontWeight: 'bolder' }}>Quantity :
                                                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                                                <Input type="number" style={{ width: "50%" }} onChange={(e) => addProductQty(item, e.target.value)} placeholder="0" />
                                                                            </span>
                                                                        </Text>
                                                                        <br />
                                                                        <br />
                                                                        <Text style={{ fontWeight: 'bolder' }}>Type :
                                                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                                                <Select style={{ width: '50%' }} onChange={(e) => addProductType(item, e)} defaultValue={1}>
                                                                                    <Option value={1}>Damage</Option>
                                                                                    <Option value={0}>Discard</Option>
                                                                                </Select>
                                                                            </span>
                                                                        </Text>
                                                                        {/* <Col span={4}>
                                                                            <Select style={{ width: '100%' }} onChange={(e) => addProductType(item, e)} defaultValue={1}>
                                                                                <Option value={1}>Damage</Option>
                                                                                <Option value={0}>Discard</Option>
                                                                            </Select>
                                                                        </Col> */}
                                                                    </>
                                                                ))
                                                            }
                                                        </Card>


                                                        {/* <Row style={{ margin: "10px 5px" }} gutter={16} key={"rowGrn-" + key}>
                                                            {
                                                                !item.branchCatalog &&
                                                                <Col span={5}>
                                                                    <Text><b>GRN</b></Text>
                                                                </Col>
                                                            }
                                                            <Col span={5} >
                                                                <Text><b>Available Qty</b></Text>
                                                            </Col>
                                                            <Col span={5} >
                                                                <Text><b>Price</b></Text>
                                                            </Col>
                                                            <Col span={5} >
                                                                <Text><b>Quantity</b></Text>
                                                            </Col>
                                                            <Col span={4} >
                                                                <Text><b>Type</b></Text>
                                                            </Col>
                                                        </Row>

                                                        {
                                                            selectedProds.map((item, index) => (
                                                                <Row style={{ margin: "10px 5px" }} gutter={16} key={"stock-" + index}>
                                                                    {
                                                                        !item.branchCatalog &&
                                                                        <Col span={5}>
                                                                            <Text>{item.grn_number}</Text>
                                                                        </Col>
                                                                    }
                                                                    <Col span={5}>
                                                                        <Text>{item.qty}</Text>
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Text>{item.price}</Text>
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Input type="number" onChange={(e) => addProductQty(item, e.target.value)} placeholder="0" />
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <Select style={{ width: '100%' }} onChange={(e) => addProductType(item, e)} defaultValue={1}>
                                                                            <Option value={1}>Damage</Option>
                                                                            <Option value={0}>Discard</Option>
                                                                        </Select>
                                                                    </Col>
                                                                </Row >
                                                            ))
                                                        } */}

                                                    </>
                                                )
                                            })}
                                        </>
                                    )}
                                </Form.List >
                            </>
                        }
                    </Col>

                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Form.Item>
                        <Space>
                            {/* <Button htmlType="submit" type="primary" >Submit</Button> */}
                            <Button type="primary" onClick={() => setShowModal(true)}>Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form >

            {confirmationModal(productTransfers)}
        </>
    );

    return isMobile ? renderDamageDiscardProductFormMobile() : renderDamageDiscardProductForm()

}

export default windowSize(DamageDiscardProductForm)