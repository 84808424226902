const Scenarios = {
    LIST: "list",
    EDIT: "edit",
    PRICEBOOK: "price-book",
    CATALOGUE: "catalogue",
    OFFERSEARCH: "offer-search",
    BASICSEARCH : "basic-search",
    PRODUCTINVENTORY: "product-inventory",
    SHORTREPORT: "short-report",
    CARD: "card",
    DETAIL: "detail",
}

export default Scenarios;