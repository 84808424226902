import React, { useEffect, useState } from "react";
import { Avatar, Button, Card } from "antd";
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { getProfile } from "../../data";
import windowSize from "react-window-size";

const { Meta } = Card;

function Profile({ windowWidth }) {

    const isMobile = windowWidth <= 768

    const [profile, setProfile] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)

    let history = useHistory()

    const initialize = async () => {
        const data = await getProfile(forceRefresh)
        if (data) {
            setProfile(data)
            setForceRefresh(false)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    return (
        <>
            <Card style={!isMobile ? { width: 370 } : {}}
                cover={
                    <>
                        <center>
                            {/* <Avatar size={170} src="https://api.dicebear.com/6.x/adventurer/svg?seed=Mia" shape={'square'} icon={<UserOutlined />} /> */}
                            <Avatar size={170} src="https://avatars.dicebear.com/api/bottts/your-custom-seed.svg" shape={'square'} icon={<UserOutlined />} />
                        </center>
                    </>
                }
                actions={[
                    <Button icon={<EditOutlined key="edit" />} type="primary" style={{ marginLeft: 10 }}
                        onClick={() => history.push({ pathname: '/profile/change-password/form/' + profile.id })}>
                        Change Password
                    </Button>,
                    <Button icon={<EditOutlined key="edit" />} type="primary"
                        onClick={() => history.push({ pathname: '/profile/form/' + profile.id })}>
                        Edit Profile
                    </Button>,
                ]}
            >
                <Meta
                    // avatar={<Avatar src="https://avatars.dicebear.com/api/bottts/your-custom-seed.svg" />}
                    avatar={<Avatar src="https://api.dicebear.com/6.x/adventurer/svg?seed=Mia" />}
                    title="Profile Name"
                    description={profile.name}
                />
            </Card >
        </>
    )
}

export default windowSize(Profile)