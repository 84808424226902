import React, { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Input, Button, Checkbox, Card, Row, Col, notification } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ajaxService from '../../services/ajax-service'
import { authUser } from '../../data';

const Login = () => {

    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/" } };

    const onFinish = async (values) => {
        const response = await ajaxService.getAccessToken(values.username, values.password);

        let { access_token, message, title, success } = response.data;

        if (success) {

            localStorage.setItem('token', access_token);

            const user = await authUser();

            if (user) {

                localStorage.setItem("authUserID", user.id);

                if (user.is_super_admin) {
                    notification.open({ message: 'You are not authorized to login into store', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                } else {
                    if (user.email_verified_at == null) {
                        history.replace({ pathname: "/pandingAproval" })
                    } else {
                        if (user.verified === 1) {

                            localStorage.setItem('store_id', user.store_id);

                            history.replace(from);
                        } else {
                            history.replace({ pathname: "/pandingAproval", search: '?email=verified' })
                        }
                    }
                }
            }

        }else{
            notification.open({ message: title , description : message, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (location.search === '?via=password-reset') {
            notification.open({ message: 'Your password has been changed', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    }, [])

    return (
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
            <Col span={3}></Col>
            <Col span={18}>
                <Row>
                    <Col span={8}>

                    </Col>
                    <Col span={8}>
                        <Card title="Sign in to your account" bordered={false} style={{ width: '100%' }}>
                            <Form
                                //{...layout}
                                layout={'vertical'}
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Username is required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Password is required',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item name="remember" valuePropName="checked">
                                    <Checkbox>Keep me signed in</Checkbox>
                                </Form.Item>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <a onClick={() => history.push({ pathname: '/forgotPassword' })}>Forgot Password?</a>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={8}>

                    </Col>
                </Row>

            </Col>
            <Col span={3}></Col>
        </Row>
    );
};

export default Login