import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }
const branchDependency = (path) => { return ajaxService.getBranchDependencyUrl(path) }

export async function getAccounts(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(branchDependency(urlService.accounts), 'accounts');
    } else {
        let accounts = await loadState('accounts');

        if (accounts == null) {
            return await loadResource(branchDependency(urlService.accounts), 'accounts');
        } else {
            return Response(accounts);
        }
    }
}

export async function saveAccount(data) {
    setDependency()
    return ajaxService.post(branchDependency(urlService.accounts), data).then(response => {
        return Response(response.data);
    })
}