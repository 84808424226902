import { Avatar, Button, Space, Table, Typography } from "antd"
import { useEffect } from "react"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { getStores } from "../../data"

const { Title } = Typography
function Stores() {

    let history = useHistory()

    const [stores, setStores] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
        },
        {
            title: 'Logo',
            key: 'logo',
            render: (text, record) => <Avatar shape="square" size={64} src={record.logo} />
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/stores/permissions/' + record.id })}>Permissions</Button>
                    </Space>)
            }
        },
    ]

    const initialize = async () => {
        const data = await getStores(forceRefresh);
        setStores(data);
        setForceRefresh(false)
    }

    useEffect(() => {
        initialize();
    }, [])

    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Stores</Title>
                </div>
            </>
        )
    }
    return (
        <>
            <Table
                dataSource={stores}
                columns={columns}
                title={() => renderHeader()}
            />
        </>
    )

}
export default Stores