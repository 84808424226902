import { Switch, Col, Typography, Form } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Toggles = ({ ecommerce, setEcommerce, showMobile, setShowMobile, isMobile }) => (
    <>
        <Col span={isMobile ? 12 : 4}>
            <Form.Item name='show_in_pos' label="Show In POS" valuePropName='checked' >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                />
            </Form.Item>
        </Col>


        <Col span={isMobile ? 12 : 4}>
            <Form.Item name='show_in_ecommerce' label="Show In Ecommerce" valuePropName='checked' >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(e) => setEcommerce(e)}
                    defaultChecked
                />
            </Form.Item>
        </Col>
        {
            ecommerce &&
            <Col span={isMobile ? 12 : 4}>
                <Form.Item name='display_in_ecommerce' label={<Text>Display Only <span style={{ color: '#FF6600' }}>(Not For Sale)</span></Text>} valuePropName='checked' >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                </Form.Item>
            </Col>
        }

        <Col span={isMobile ? 12 : 4}>
            <Form.Item name='show_in_mobile' label="Show In Mobile" valuePropName='checked' >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(e) => setShowMobile(e)}
                    defaultChecked
                />
            </Form.Item>
        </Col>
        {
            showMobile &&
            <Col span={isMobile ? 15 : 4}>
                <Form.Item name='display_in_mobile' label={<Text>Display Only <span style={{ color: '#FF6600' }}>(Not For Sale)</span></Text>} valuePropName='checked' >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                </Form.Item>
            </Col>
        }

    </>
);

export default Toggles
